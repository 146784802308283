import * as React from "react";
import Colocation from "./colocation";
import Interconnect from "./interconnect";
import { useEffect } from "react";
import RoleModel from "services/roleServices";
import Swal from "sweetalert2";
import Loading from "views/super-admin/pre-loader/loader";

const Provisioning = ({
  retriveCurrentData,
  token,
  permission,
  setShow,
  isEdit,
  setIsEdit,
  data,
  getRoleDetail,
  setIsLoading,
}) => {
  const specificPermission = () => {
    const filteredPermissions = permission.filter((el) => el.is_specific === 1);
    if (filteredPermissions.length > 0) {
      return filteredPermissions.map((el) => ({ ...el, value: 0 }));
    } else {
      return [];
    }
  };
  const generalPermission = () => {
    const filteredPermissions = permission.filter((el) => el.is_specific == 0);
    if (filteredPermissions.length > 0) {
      return filteredPermissions.map((el) => ({ ...el, value: 0 }));
    } else {
      return [];
    }
  };

  const onSubmit = (state) => {
    setIsLoading(true);
    RoleModel.createRoleAndPermissions(token, "provisioning", state)
      .then(async (res) => {
        retriveCurrentData();
        getRoleDetail(res.data.data.roleId);
        setIsLoading(false);
        Swal.fire({
          title: "Updated successfully.",
          html: "",
          icon: "success",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        Swal.fire({
          title: "",
          html: err.error.response.data.message,
          icon: "warning",
          confirmButtonText: "Ok",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        });
      });
  };
  return (
    <>
      <Colocation
        permission={generalPermission()}
        specificPermission={specificPermission()}
        onSubmit={onSubmit}
        getRoleDetail={getRoleDetail}
        data={data}
      ></Colocation>
      <Interconnect
        permission={generalPermission()}
        specificPermission={specificPermission()}
        onSubmit={onSubmit}
        getRoleDetail={getRoleDetail}
        data={data}
      ></Interconnect>
    </>
  );
};

export default Provisioning;
