import { css } from 'glamor'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BinIcon } from 'views/super-admin/Tickets/shared/Icons'
import ConfirmDeleteModal from '../ConfirmDeleteModal'

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between !important',
    marginTop: '15px',
    alignItems: 'flex-end'
  },
  title: {
    fontSize: '24px'
  },
  removeAllSection: {
    cursor: 'pointer'
  },
  removeAllTitle: {
    fontSize: '14px'
  },
  icon: {
    paddingBottom: '5px',
    marginLeft: '10px'
  }

}

const VisitorListTitle = ({ text, active, handleClick }) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  const onClick = () => {
    setShow(true)
  }

  return (
    <>
      <div {...css(styles.row)}>
        <div {...css(styles.title)}>{text}</div>
        {active &&
          <div
            className='d-flex justify-content-end'
            {...css(styles.removeAllSection)}
            onClick={onClick}
          >
            <div {...css(styles.removeAllTitle)}>
              {t('create-ticket.site-access.visitor-remove-all')}
            </div>
            <div {...css(styles.icon)}><BinIcon onClick={onClick} /></div>
          </div>}
      </div>
      <ConfirmDeleteModal
        show={show}
        setShow={setShow}
        message={t('create-ticket.site-access.visitor-remove-all-confirm')}
        handleClickOk={() => {
          setShow(false)
          handleClick()
        }}
      />
    </>
  )
}

const VisitorListTitleMemo = ({ text, active, handleClick }) => {
  return useMemo(() => {
    return (
      <VisitorListTitle
        text={text}
        active={active}
        handleClick={handleClick}
      />
    )
  }, [text, active])
}

export default VisitorListTitleMemo
