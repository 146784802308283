import { axiosWithToken as axios } from "utils/axios";
class ProvisioningServices {
  async getServices(token, body) {
    return await axios(token).post(`getServices`, body);
  }

  async getPopupDetail(token, tid) {
    return await axios(token).get(`getProvisionTicket?tid=` + tid);
  }

  async getServicesChart(token, body) {
    return await axios(token).post(`getServiceChartData`, body);
  }

  async getStatus(token, projectId) {
    return await axios(token).get(`getServiceStages?projectId=` + projectId);
  }

  async updateStatus(token, serviceId, body) {
    return await axios(token).post(
      `updateServiceStage?serviceId=` + serviceId,
      body
    );
  }

  async getServiceAttachments(token, serviceId) {
    return await axios(token).get(
      `getServiceAttachments?serviceId=` + serviceId
    );
  }

  async addServiceAttachment(token, serviceId, data) {
    return await axios(token).post(
      `addServiceAttachment?serviceId=` + serviceId,
      data
    );
  }

  async getServiceAttachment(token, serviceId, attachmentId) {
    return await axios(token).get(
      `getServiceAttachmentDetail?serviceId=` +
        serviceId +
        `&&attachmentId=` +
        attachmentId
    );
  }

  async deleteServiceAttachment(token, serviceId, attachmentId) {
    return await axios(token).delete(
      `deleteServiceAttachment?serviceId=` +
        serviceId +
        `&&attachmentId=` +
        attachmentId
    );
  }

  async createServiceNote(token, serviceId, note) {
    return await axios(token).post(
      `createServiceNote?serviceId=` + serviceId,
      note
    );
  }

  async createServiceMessage(token, serviceId, message) {
    return await axios(token).post(
      `createServiceMessage?serviceId=` + serviceId,
      message
    );
  }

  async getServiceUsageData(token, serviceId) {
    return await axios(token).get(`getServiceUsageData?serviceId=` + serviceId);
  }

  async updateServices(token, serviceId, body) {
    return await axios(token).put(
      `updateServices?serviceId=` + serviceId,
      body
    );
  }
  async assignTaskTo(token, serviceId, body) {
    return await axios(token).post(
      `serviceAssignTaskTo?serviceId=` + serviceId,
      body
    );
  }

  async getAssignees(token) {
    return await axios(token).get(`getAssignees`);
  }

  // add follower
  async serviceSubscribe(token, serviceId) {
    return await axios(token).post(`serviceSubscribe?serviceId=` + serviceId);
  }
  // unFollower
  async serviceUnsubscribe(token, serviceId) {
    return await axios(token).post(`serviceUnsubscribe?serviceId=` + serviceId);
  }
  // Invite Follower
  async serviceInviteFollower(token, serviceId, data) {
    return await axios(token).post(
      `serviceInviteFollower?serviceId=` + serviceId,
      data
    );
  }
  // get Follower
  async serviceFollower(token, serviceId) {
    return await axios(token).get(`serviceFollower?serviceId=` + serviceId);
  }
  // Delete Follower
  async serviceRemoveFollower(token, serviceId, contactId) {
    return await axios(token).post(
      `serviceRemoveFollower?serviceId=` +
        serviceId +
        `&&contactId=` +
        contactId
    );
  }
  // Get space list
  async getSpaceList(token, body) {
    return await axios(token).post(`getSpaceList`, body);
  }
  // Get patch panel list
  async getPatchPanelList(token, body) {
    return await axios(token).post(`getPatchPanelList`, body);
  }
  // Get patch panel port list
  async getPatchPanelPortList(token, body) {
    return await axios(token).post(`getPatchPanelPortList`, body);
  }
  // Get patch panel port list
  async getPatchPanelListBySpaceId(token, space_id, body) {
    return await axios(token).post(
      `getPatchPanelsBySpaceId?space_id=${space_id}`,
      body
    );
  }

  // Get patch panel port list
  async getPatchPanelPortsBySpaceIdAndPatchPanelId(
    token,
    space_id,
    patch_panel_id,
    body
  ) {
    return await axios(token).post(
      `getPatchPanelPortsBySpaceIdAndPatchPanelId?space_id=${space_id}&&patch_panel_id=${patch_panel_id}`,
      body
    );
  }

  // Get patch panel port list by panel id
  async getPatchPanelPortsByPatchPanelId(token, patch_panel_id, body) {
    return await axios(token).post(
      `getPatchPanelPortsByPanelId?patch_panel_id=${patch_panel_id}`,
      body
    );
  }

  async getServiceTags(token) {
    return await axios(token).get(`getServiceTags`);
  }

  async createServiceTag(token, body) {
    return await axios(token).post(`createServiceTag`, body);
  }

  async getProvisioningPermissions(token, id) {
    return await axios(token).get(`provisioningPermission?id=${id}`);
  }
}

export default new ProvisioningServices();
