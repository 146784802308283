import React, { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import XError from "components/Auth/Card/XError"; // Adjust the import according to your file structure
import { t } from "i18next"; // Adjust according to your i18n setup
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import TechDocsService from "services/techDocsService";
import AuthContext from "context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "../techDocs.css";

const TechDocsTypesModal = ({
  isOpen,
  setIsLoading,
  closeModal,
  types,
  getTechDocsTypes,
}) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };
  const [isEdit, setIsEdit] = useState(false);
  const [typeValue, setTypeValue] = useState("");
  const [typeId, setTypeId] = useState("");

  const onSubmit = async () => {
    if (isEdit) {
      handleUpdate(typeId);
    } else {
      if (typeValue.length > 0) {
        handleAdd();
      }
    }
  };

  const handleClose = () => {
    closeModal();
  };
  const deleteType = async (id) => {
    setIsLoading(true);
    await TechDocsService.deleteTechDocsType(localStorage.getItem("token"), id)
      .then((res) => {
        getTechDocsTypes();
      })
      .catch((err) => {
        Swal.fire({
          text: err.error.response.data.error.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "OK",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateType = async (id) => {
    setIsLoading(true);
    await TechDocsService.updateTechDocsType(
      localStorage.getItem("token"),

      { name: typeValue },
      id
    )
      .then((res) => {
        clear();
        getTechDocsTypes();
      })
      .catch((err) => {
        Swal.fire({
          text: err.error.response.data.error.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "OK",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const addType = async (id) => {
    setIsLoading(true);
    await TechDocsService.createTechDocsType(localStorage.getItem("token"), {
      name: typeValue,
      type: "General",
      order: types?.length + 1,
    })
      .then((res) => {
        clear();
        getTechDocsTypes();
      })
      .catch((err) => {
        Swal.fire({
          text: err.error.response.data.error.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "OK",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure to delete this Type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteType(id);
      }
    });
  };

  const handleUpdate = (id) => {
    Swal.fire({
      text: "Are you sure to update this Type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        updateType(id);
      } else {
        clear();
      }
    });
  };

  const handleAdd = (id) => {
    Swal.fire({
      text: "Are you sure to add this Type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        addType();
      } else {
        clear();
      }
    });
  };

  const clear = () => {
    setIsEdit(false);
    setTypeId("");
    setTypeValue("");
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="modal-header mt-59">
        <h3 className="modal-title">Modify Type</h3>
        <span onClick={handleClose}>
          <CloseIcon style={{ cursor: "pointer" }} />
        </span>
      </div>
      <div className="modal-body">
        <div className="card">
          <div
            className="card-body"
            style={{ padding: "0px", overflow: "hidden" }}
          >
            <div className="basic-form">
              <form>
                <p className="fake_label">Type</p>
                <div className="d-flex justify-content-between align-items-center">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={""}
                    style={{ width: "65%" }}
                    onChange={(event) => setTypeValue(event.target.value)}
                    value={typeValue}
                  />

                  <button
                    type="button"
                    className="btn save-btn-new mr_1"
                    onClick={onSubmit}
                    style={{
                      background: "#ff7a00",
                      color: "#fff",
                      borderRadius: "0.25rem",
                      width: "6rem",
                      padding: "0.5rem 0.5rem",
                      fontSize: "1rem",
                    }}
                  >
                    {isEdit ? "Update" : "Add Type"}
                  </button>
                  <div style={{ width: "6rem" }}>
                    {isEdit && (
                      <button
                        type="button"
                        className="btn save-btn-new mr_1"
                        onClick={clear}
                        style={{
                          background: "#ff7a00",
                          color: "#fff",
                          borderRadius: "0.25rem",
                          width: "6rem",
                          padding: "0.5rem 0.5rem",
                          fontSize: "1rem",
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div
              className="table-container tech_docs_table"
              style={{ marginTop: "2rem", padding: "0px" }}
            >
              <table style={{ width: "75%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "7rem" }}>Action</th>

                    <th style={{ width: "16rem" }}>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {types &&
                    types.length > 0 &&
                    types.map((item, index) => (
                      <tr key={index} className="table-row">
                        <td>
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setIsEdit(true);
                              setTypeId(item.id);
                              setTypeValue(item.name);
                            }}
                          ></EditIcon>
                          {item.type === "General" && (
                            <DeleteIcon
                              onClick={() => {
                                handleDelete(item.id);
                              }}
                              style={{ marginLeft: "1rem", cursor: "pointer" }}
                            ></DeleteIcon>
                          )}
                        </td>

                        <td>{item.name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TechDocsTypesModal;
