import { remove } from 'lodash';
import React, { useState, useEffect } from 'react';
import FilterOperatorBox from './filterOperatorBox';
import { active } from 'glamor';

const SearchBox = ({
  groupBy,
  setIsSelectedGroup,
  setFilter,
  setPageNumber,
  filter,
  setGroupBy,
  setIsGroupBy,
  searchCategory,
  textOperators,
  dateOperators,
  numberOperators
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOperator, setSelectedOperator] = useState(null);

  const HandleSearch = (val) => {
    setSearchValue(val);
    if (val.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const HandleDropdown = (data, operator) => {
    if (searchValue.length > 0 || operator.value == 'is_set' || operator.value == 'is_not_set') {
      setShowDropdown(() => false);
      let newFilter = {
        id: data.id,
        name: data.field,
        value: searchValue,
        operator: operator.value,
        filterName: `${data.name} ${operator.name}${
          operator.value == 'is_set' || operator.value == 'is_not_set' ? '' : ` "${searchValue}"`
        }`,
        type: 'search'
      };
      setIsSelectedGroup('');
      setFilter((filter) => [...filter, newFilter]);
      setPageNumber(1);
      setSearchValue('');
    }
  };

  const removeFilter = (index) => {
    setIsSelectedGroup('');
    setFilter([...filter.slice(0, index), ...filter.slice(index + 1)]);
  };

  return (
    <React.Fragment>
      <div className="tc_drop1-out">
        <div className="tc_drop1" style={{ width: '100%' }}>
          <div className="inp-out">
            {!!groupBy?.filterName && (
              <div className="applied_filter">
                <img
                  alt=""
                  src="\images\sliders.svg"
                  style={{ cursor: 'pointer' }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="icon"
                />
                <span className="name"> {groupBy.filterName}</span>
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => {
                    setGroupBy({});
                    setIsGroupBy(false);
                    setIsSelectedGroup('');
                  }}
                ></i>
              </div>
            )}
            {filter &&
              filter.length > 0 &&
              filter.map((filter, index) => {
                return (
                  <div className="applied_filter d-flex justify-content-between">
                    <span
                      className="name"
                      style={{
                        textOverflow: 'ellipsis',
                        maxWidth: '15rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      data-bs-toggle="tooltip"
                      data-placement="top"
                      title={filter.filterName}
                    >
                      {' '}
                      {filter.filterName}
                    </span>
                    <span>
                      {' '}
                      <i className="fa-solid fa-xmark" onClick={() => removeFilter(index)}></i>
                    </span>
                  </div>
                );
              })}
            <input
              type="text"
              onFocus={() => {
                setSelectedOperator(null);
              }}
              onChange={(e) => HandleSearch(e.target.value)}
              value={searchValue}
            />
            <div className="after"></div>
          </div>
          <div className="img-out" onClick={() => setShowDropdown(() => !showDropdown)}>
            {' '}
            <img alt="" src="\images\arrow.png" className="arrow-img" />
          </div>
        </div>
        {showDropdown ? (
          <div className="tc_drop1-opt">
            {searchCategory &&
              searchCategory.map((data, i) => {
                return (
                  <div className="">
                    <div
                      onClick={() => setSelectedOperator(i)}
                      className={
                        i == selectedOperator
                          ? 'active tc_opt position-relative'
                          : 'inactive tc_opt position-relative'
                      }
                    >
                      Search '{data.name}' for {searchValue}
                      {i == selectedOperator && (
                        <FilterOperatorBox
                          operators={
                            data.name == 'ID'
                              ? numberOperators
                              : data.name == 'Site Visit Date Start' ||
                                data.name == 'Site Visit Date End' ||
                                data.name == 'Creation Time'
                              ? dateOperators
                              : textOperators
                          }
                          HandleDropdown={HandleDropdown}
                          selectedSearchCategoryData={data}
                          searchValue={searchValue}
                        ></FilterOperatorBox>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : null}
        <span
          data-toggle="tooltip"
          data-placement="bottom"
          title="Preferred Date Format: 
          2024 (yyyy)
          2024-03 (yyyy-MM)
          2024-03-22 (yyyy-MM-dd)
          2024-03-22 11 (yyyy-MM-dd HH)
          2024-03-22 11:05 (yyyy-MM-dd HH:mm)"
          style={{ position: 'absolute', right: '-10%', top: '3%', fontSize: '1.5rem' }}
        >
          <i class="fa-solid fa-circle-info"></i>
        </span>
      </div>
    </React.Fragment>
  );
};

export default SearchBox;
