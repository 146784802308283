export const EditUserIcon = ({ onClick }) => {
  return (
    <div
      style={{ width: '20px', height: '18px', cursor: 'pointer' }}
      onClick={onClick}
    >
      <svg viewBox='0 -0.06 20.109 20.109' xmlns='http://www.w3.org/2000/svg' fill='#000000'>
        <g id='SVGRepo_bgCarrier' stroke-width='0' />
        <g id='SVGRepo_iconCarrier'> <g id='edit-user-4' transform='translate(-2 -2.016)'> <path id='secondary' fill='#FF7A00' d='M20.71,16.09,15.8,21H13V18.2l4.91-4.91a1,1,0,0,1,1.4,0l1.4,1.4A1,1,0,0,1,20.71,16.09Z' /> <path id='primary' d='M20.71,16.09,15.8,21H13V18.2l4.91-4.91a1,1,0,0,1,1.4,0l1.4,1.4A1,1,0,0,1,20.71,16.09Z' fill='none' stroke='#000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /> <path id='primary-2' data-name='primary' d='M17,9.27A2.44,2.44,0,0,0,17,9a6,6,0,1,0-6.44,6' fill='none' stroke='#000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /> <path id='primary-3' data-name='primary' d='M6.92,13.72A7,7,0,0,0,3,20a1,1,0,0,0,1,1H9' fill='none' stroke='#000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /> </g> </g>
      </svg>
    </div>
  )
}
