import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import Loading from 'views/super-admin/pre-loader/loader';
import ErrorModel from 'views/super-admin/Ticket/components/errormodel';
import AccessCard from './AccessCardTab/accessCard';
import AssociatedService from './AssociatedServiceTab/associatedService';
import AuthContext from 'context';
import CloseIcon from '@mui/icons-material/Close'
const ContactDetailModal = (props) => {
  const { t } = useTranslation();
  const [active, setActive] = useState('IN');
  const [isLoading, setIsLoading] = useState(false);
  const [errorpopup, setErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something went Wrong !');
  const [editNote, setEditNote] = useState(false);
  const [note, setNote] = useState(props.data?.internal_notes);
  const contextStore = useContext(AuthContext);

  // const camelCase = (str) => {
  //   return str[0].toUpperCase() + str.slice(1);
  // };

  const handleClose = async () => {
    await Swal.fire({
      title: 'Are you sure?',
      text: 'Any changes made will not be saved.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        props.setShow(false);
      }
    });
  };
  const handleDownload = () => {
    // Create a temporary anchor element
    const link = document.createElement('a');
    // Set the href attribute to the base64 encoded image data
    link.href = `data:image/png;base64,${props.img}`;
    // Set the download attribute to define the file name
    link.download = `profile_image_${props.data.id}.png`;
    // Simulate a click on the anchor element to trigger the download
    link.click();
  };
  const checkPermission = () => {
    if (contextStore.getAuth?.role?.contact == 3) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <div
        class="modal show bd-example-modal-lg"
        id="account-detail-modal"
        style={{ display: 'block' }} /*onClick={()=> closeModal()}*/
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <Loading
          loading={isLoading}
          background="rgba(0, 0, 0, 0.40)"
          loaderColor="rgb(248, 153, 3)"
        />
        <div class="modal-dialog modal-xl accountDetail">
          <div class="modal-content" id="content-area" style={{ height: '95vh' }}>
            <div className="modal-header my-4" style={{ alignItems: 'flex-start' }}>
              <div>
                <h3 className="modal-title"> {props.data.name}</h3>
                <p className="valueText"> {props.data.company}</p>
              </div>
              <div className="d-flex">
              <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </div>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-4">
                  <table className="table-borderless">
                    <tbody>
                      <tr>
                        <td className="text_bold">Job Position </td>
                        <td></td>
                        <td className="valueText">{props.data.job_position}</td>
                      </tr>
                      <tr>
                        <td className="text_bold">Phone </td>
                        <td></td>
                        <td className="valueText">{props.data.phone}</td>
                      </tr>
                      <tr>
                        <td className="text_bold">Mobile </td>
                        <td></td>
                        <td className="valueText">{props.data.mobile}</td>
                      </tr>
                      <tr>
                        <td className="text_bold">Email </td>
                        <td></td>
                        <td className="valueText">{props.data.email}</td>
                      </tr>
                      <tr>
                        <td className="text_bold">Language </td>
                        <td></td>
                        <td className="valueText">{props.data.lang}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-8">
                  <div
                    className="imageContainer"
                    style={{ textAlign: 'center', cursor: 'pointer' }}
                    onClick={handleDownload}
                  >
                    <img
                      src={`data:image/png;base64,${props.img}`}
                      alt="Image 1"
                      className="img-fluid"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7">
                  <table className="table-borderless">
                    <tbody>
                      <tr>
                        <td className="text_bold">Individual Type </td>
                        <td>
                          <span className="card_box_chips">
                            {props.data.individual_type.map((type) => (
                              <span className="card_box_chip">{type.name}</span>
                            ))}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-5">
                  <table className="table-borderless">
                    <tbody>
                      <tr>
                        <td className="text_bold">Permanent Site Access </td>
                        <td>
                          <span className="card_box_chips">
                            {props.data.permanent_site_access.map((psa) => (
                              <span className="card_box_chip">{psa.name}</span>
                            ))}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <table className="table-borderless">
                    <tbody>
                      <tr>
                        <td className="text_bold">PIN Code </td>
                        <td></td>
                        <td className="valueText">{props.data.pin_code}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-8"></div>
              </div>
              <div className="row">
                <div className="col-md-10">
                  <table className="table-borderless">
                    <tbody>
                      <tr>
                        <td className="text_bold">End Customer </td>
                        <td></td>
                        <td>
                          <span className="card_box_chips">
                            {props.data.end_customer.map((end) => (
                              <span className="card_box_chip">{end.name}</span>
                            ))}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div class="row pt-2">
                <div class="col-md-12">
                  <div style={{ position: 'relative' }} className="test">
                    <div class="btnstyle">
                      <div class="btn-group">
                        <a
                          href="#"
                          className={`btn msg-button ${active == 'IN' ? 'bactive' : ''}`}
                          onClick={() => {
                            setActive('IN');
                          }}
                        >
                          Internal Notes
                        </a>
                        <a
                          href="#"
                          className={`btn msg-button ${active == 'IAC' ? 'bactive' : ''}`}
                          onClick={() => {
                            setActive('IAC');
                          }}
                        >
                          Issued Access Card
                        </a>
                        <a
                          href="#"
                          className={`btn msg-button ${active == 'ASI' ? 'bactive' : ''}`}
                          onClick={() => {
                            setActive('ASI');
                          }}
                        >
                          Associated Service ID
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {active == 'IN' && (
                  <div class="row pt-2">
                    <div style={{ display: 'flex' }}>
                      <h5 class="history-title" style={{ lineHeight: 1 }}>
                        Internal Notes
                      </h5>
                      {checkPermission() && (
                        <div style={{ marginLeft: '10px' }}>
                          <a href="javascript:void(0)" style={{ marginRight: '10px' }}>
                            {editNote ? (
                              <i
                                class="fa fa-solid fa-xmark"
                                style={{ fontSize: '1.5rem' }}
                                onClick={() => {
                                  setEditNote(!editNote);
                                  setNote(props.data?.internal_notes);
                                }}
                              ></i>
                            ) : (
                              <i
                                class="fa fa-solid fa-pencil"
                                style={{ fontSize: '1rem' }}
                                onClick={() => {
                                  setEditNote(!editNote);
                                }}
                              ></i>
                            )}
                          </a>
                          {editNote && (
                            <a
                              href="javascript:void(0)"
                              onClick={async () => {
                                setIsLoading(true);
                                await props.updateNote({ internal_notes: note }, props?.data?.id);
                                setEditNote(false);
                                setIsLoading(false);
                              }}
                            >
                              <i class="fa fa-solid fa-save" style={{ fontSize: '1.3rem' }}></i>
                            </a>
                          )}
                        </div>
                      )}
                    </div>

                    <div class="col-md-9">
                      {!editNote && (
                        <div class="">
                          <div
                            style={{ whiteSpace: 'pre-wrap' }}
                            dangerouslySetInnerHTML={{
                              __html: note || ''
                            }}
                          />
                        </div>
                      )}
                      {editNote && (
                        <div class="col-md-9">
                          <textarea
                            rows="3"
                            cols="100"
                            onChange={(e) => {
                              setNote(e.target.value);
                            }}
                          >
                            {note}
                          </textarea>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {active == 'IAC' && (
                  <AccessCard
                    data={props?.data?.access_card_details}
                    setIsLoading={setIsLoading}
                    setErrorPopup={setErrorPopup}
                    setErrorMsg={setErrorMsg}
                    updateNote={props.updateNote}
                    id={props?.data?.id}
                    checkPermission={checkPermission}
                  />
                )}
                {active == 'ASI' && (
                  <AssociatedService
                    data={props?.data?.associated_companies_ids}
                    endData={props?.data?.end_customer_ids}
                    setIsLoading={setIsLoading}
                    setErrorPopup={setErrorPopup}
                    setErrorMsg={setErrorMsg}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {errorpopup && <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />}
      </div>
    </React.Fragment>
  );
};

export default ContactDetailModal;
