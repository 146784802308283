import { css } from 'glamor'
import ShipmentTicketFormContext from '../../context/ShipmentTicketFormContext'
import { useContext, useState } from 'react'
import TicketCard from 'views/super-admin/Tickets/shared/TicketCard'
import PackageList from '../PackageList'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TicketCheckBox from 'views/super-admin/Tickets/shared/TicketCheckBox'
import TicketButton from 'views/super-admin/Tickets/shared/TicketButton'
import { TICKET_COMMON_STYLES } from 'views/super-admin/Tickets/shared/constants'
import useCreateShipmentTicket from '../../hooks/useCreateShipmentTicket'

const ShipmentConfirmationForm = ({ closeModal }) => {
  const { t } = useTranslation()
  const {
    formik,
    companies,
    services,
    handlingInstructions,
    closeModal: closeMainModal
  } = useContext(ShipmentTicketFormContext)

  const {
    subject,
    description,
    companyName,
    serviceId,
    expectedDate,
    courierCompany,
    carLicense,
    loadingDockRequired,
    loadingDockStartTime,
    loadingDockEndTime,
    handlingInstruction,
    packageList
  } = formik.values

  const handleCloseModal = () => {
    closeModal()
    closeMainModal()
  }
  const {
    postNewTicket,
    requestOnFlight
  } = useCreateShipmentTicket({ closeModal: handleCloseModal })

  const [agreeCollection, setAgreeCollection] = useState(true)

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100%'
    },
    section: {
      '& > *': {
        padding: '0 20px 0 20px'
      }
    },
    ...TICKET_COMMON_STYLES
  }

  const getValue = (list, value) => {
    try {
      return list.find(c => c.id === value).name
    } catch (err) {
      return ''
    }
  }

  const listItem = (name, value) => {
    return (
      <div style={{ fontSize: '14px' }} className='d-flex justify-content-sm-start'>
        {name && <div style={{ width: '250px', fontWeight: '600' }}>{name}:</div>}
        <div style={{ maxWidth: '250px', paddingLeft: name && '5px', fontWeight: '400' }}>{value}</div>
      </div>
    )
  }

  const getMoment = (object, format) => {
    try {
      return moment(object)?.format && moment(object).format(format)
    } catch (err) {
      return ''
    }
  }

  const list = [
    { name: t('create-ticket.site-access.ticket-subject'), value: subject },
    { name: t('create-ticket.site-access.company-name'), value: getValue(companies, companyName) },
    { name: t('create-ticket.site-access.service-id'), value: getValue(services, serviceId) },
    { name: t('create-ticket.site-access.description'), value: description },
    { name: t('create-ticket.shipment.expected-pickup-delivery-date'), value: getMoment(expectedDate, 'YYYY-MM-DD') },
    { name: t('create-ticket.shipment.courier-company'), value: courierCompany },
    { name: t('create-ticket.shipment.car-license'), value: carLicense },
    { name: t('create-ticket.shipment.handlingInstruction'), value: getValue(handlingInstructions, handlingInstruction) },
    {
      value: loadingDockRequired === 'yes'
        ? t('create-ticket.shipment.loading-dock-date-time', {
          date: getMoment(expectedDate, 'YYYY-MM-DD'),
          start: getMoment(loadingDockStartTime, 'HH:mm'),
          end: getMoment(loadingDockEndTime, 'HH:mm')
        })
        : t('create-ticket.shipment.loading-dock-not-use')
    }

  ]

  const handleClick = () => {
    if (agreeCollection && !requestOnFlight) {
      postNewTicket()
    }
  }

  return (
    <div {...css(styles.container)}>
      <div className='mb-3'>
        {list.map(item => (
          listItem(item.name, item.value)
        ))}
      </div>
      <div>
        <TicketCard
          label={t('create-ticket.shipment.package-list')}
          extraStyles={{
            maxHeight: '200px',
            overflowY: 'scroll'
          }}
        >
          <PackageList
            details={packageList}
            readOnly
          />
        </TicketCard>
      </div>
      <div>
        <TicketCard label={t('create-ticket.remote-hands.reminder-label')}>
          <>
            <div style={{ fontSize: '14px' }} className='pb-3'>
              {t('create-ticket.shipment.confirmation.reminder-text')}
            </div>
            <div style={{ fontSize: '14px' }}>
              <TicketCheckBox
                label={t('create-ticket.site-access.agree-collection')}
                fontSize='14px'
                checked={agreeCollection}
                active
                handelCheckboxToggle={() => setAgreeCollection(!agreeCollection)}
              />
            </div>
          </>
        </TicketCard>
      </div>
      <div {...css(styles.section)} className='d-flex justify-content-sm-center'>
        <TicketButton
          label={t('create-ticket.label')}
          variant='primary'
          icon={<i {...css(styles.icon)} className='fa-regular fa-pen-to-square' />}
          active={agreeCollection && !requestOnFlight}
          buttonType='submit'
          width='200px'
          handleClick={handleClick}
        />
      </div>
    </div>
  )
}

export default ShipmentConfirmationForm
