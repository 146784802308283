import { useEffect } from "react";
import { Button } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import DomainIcon from "@mui/icons-material/Domain";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
const FirstRow = ({
  state,
  handleChange,
  isEdit,
  site,
  permission,
  priority,
  isTypeTrue,
  setUpdateTicketDetails,
  escalateTeams,
  handleExit,
}) => {
  const renderRedinessDropdown = (state) => {
    return (
      <div
        className="dropdown-menu dropdown-menu-end"
        style={{ width: "max-content" }}
      >
        {state?.readiness_id === "normal" && (
          <>
            <a href="#" className="dropdown-item ai-icon">
              <i
                class="fa-solid fa-circle fa-xl"
                style={{ color: "green" }}
              ></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("done")}
              >
                Ready
              </span>
            </a>
            <a href="#" className="dropdown-item ai-icon">
              <i class="fa-solid fa-circle fa-xl" style={{ color: "red" }}></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("blocked")}
              >
                Blocked
              </span>
            </a>
          </>
        )}
        {state?.readiness_id === "done" && (
          <>
            <a href="#" className="dropdown-item ai-icon">
              <i class="fa-solid fa-circle fa-xl" style={{ color: "gray" }}></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("normal")}
              >
                In Progress
              </span>
            </a>
            <a href="#" className="dropdown-item ai-icon">
              <i class="fa-solid fa-circle fa-xl" style={{ color: "red" }}></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("blocked")}
              >
                Blocked
              </span>
            </a>
          </>
        )}
        {state?.readiness_id === "blocked" && (
          <>
            <a href="#" className="dropdown-item ai-icon">
              <i
                class="fa-solid fa-circle fa-xl"
                style={{ color: "green" }}
              ></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("done")}
              >
                Ready
              </span>
            </a>
            <a href="#" className="dropdown-item ai-icon">
              <i class="fa-solid fa-circle fa-xl" style={{ color: "gray" }}></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("normal")}
              >
                In Progress
              </span>
            </a>
          </>
        )}
        {state?.readiness_id === false && (
          <>
            <p style={{ fontSize: "14px" }}>
              "N/A": There is an invalid value for the Readiness from the BOSS.
              <br></br>You can update the status by selecting one of the options
              below:
            </p>
            <a href="#" className="dropdown-item ai-icon">
              <i class="fa-solid fa-circle fa-xl" style={{ color: "red" }}></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("blocked")}
              >
                Blocked
              </span>
            </a>
            <a href="#" className="dropdown-item ai-icon">
              <i
                class="fa-solid fa-circle fa-xl"
                style={{ color: "green" }}
              ></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("done")}
              >
                Ready
              </span>
            </a>
            <a href="#" className="dropdown-item ai-icon">
              <i class="fa-solid fa-circle fa-xl" style={{ color: "gray" }}></i>
              <span
                className="ps-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={() => handleChange("normal")}
              >
                In Progress
              </span>
            </a>
          </>
        )}
      </div>
    );
  };
  return (
    <div className="modal-header mt-59">
      <div
        style={{
          display: "flex",
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <ul className="navbar-nav header-right">
          <li
            className="nav-item dropdown header-profile"
            style={{ paddingRight: "0rem" }}
          >
            <a
              className="nav-link"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              style={{ marginLeft: "0rem" }}
            >
              {state?.readiness_id ? (
                <i
                  class="fa-solid fa-circle fa-2xl"
                  style={{
                    color:
                      state?.readiness_id === "normal"
                        ? state?.readiness_id === "done"
                          ? "green"
                          : "gray"
                        : state?.readiness_id === "done"
                        ? "green"
                        : "red",
                  }}
                ></i>
              ) : (
                <div className="invalid_readiness">N/A</div>
              )}
            </a>
            {renderRedinessDropdown(state)}
          </li>
        </ul>
        <div className="d-flex align-items-center">
          <div className="domain_icon">
            <DomainIcon style={{ paddingRight: ".5rem" }} fontSize="large" />
          </div>
          <div>
            <span class="sel">{site}</span>
          </div>
        </div>
        <div>
          {isEdit && isTypeTrue && permission ? (
            <div>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div>
                  <h3>Severity:</h3>
                </div>
                <div>
                  <select
                    style={{
                      height: "35px",
                      borderColor: "#ff7a00",
                      boxShadow: "0 0 0 1px #ff7a00",
                    }}
                    defaultValue={parseInt(state?.priorityid)}
                    onChange={(event) =>
                      setUpdateTicketDetails((prev) => ({
                        ...prev,
                        priority_id: parseInt(event.target.value),
                      }))
                    }
                  >
                    {priority.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <div className="newRelease_icon">
                <NewReleasesIcon
                  style={{ paddingRight: "0.5rem" }}
                  fontSize="large"
                />
              </div>
              <div>
                {" "}
                <span class="sel-s1">{state?.priority || ""}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {permission && (
            <span>
              {state?.priority == "P0" ||
              state?.priority == "P1" ||
              state?.priority == "I1" ||
              state?.priority == "E1" ? (
                <Button
                  variant="text"
                  className="p-1 w-25"
                  onClick={escalateTeams}
                >
                  <img src="\img\teams.png"></img>
                </Button>
              ) : (
                ""
              )}
            </span>
          )}
        </div>
      </div>
      <div className="close_icon">
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={handleExit}
          fontSize="large"
        />
      </div>
    </div>
  );
};
export default FirstRow;
