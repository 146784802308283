import { XError } from "components/common"
import React, { useEffect, useState } from "react"
import RoleModel from "services/roleServices"
import Swal from "sweetalert2"
import { LogoutOnSessionExpire } from "common/helpers"
import accountService from "services/accountService"
import { useTranslation } from "react-i18next"
import CloseIcon from '@mui/icons-material/Close'

const errorInit = {
  accountName: "",
  reseller: "",
  accountType: "",
  country: "",
  contactEmail: "",
  contactPhone: "",
  accountCode: "",
}

function UpdateAccount({ token, data, show, setShow, retriveCurrentData }) {
  const [modalIsOpen, setModelIsOpen] = useState(false)
  const [countries, setCountries] = useState([])
  const [accountType, setaccountType] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [accountData, setAccountData] = useState([])
  const [state, setState] = useState({
    accountName: "",
    accountlocalName: "",
    accountCode: "",
    reseller: "",
    accountType: "",
    address: "",
    country: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    description: "",
  })
  const [errors, setError] = useState(errorInit)
  const { t } = useTranslation()
  useEffect(() => {
    getCountries()
    getAccountType()
    getData()
    setModelIsOpen(show)
    setState({
      id: data.id,
      accountName: data.name,
      accountlocalName: data.local_name,
      accountCode: data.account_code,
      reseller: data.reseller_id,
      accountType: data.account_type,
      address: data.address,
      country: data.country,
      contactName: data.contact_name,
      contactEmail: data.contact_email,
      contactPhone: data.contact_phone,
      description: data.desc,
    })

    return () => {
      setState({})
    }
  }, [show, setState])

  const getData = async () => {
    await accountService.getAllAccount(token).then((res) => {
      setAccountData(res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)))

      setIsLoading(false)
    })
  }

  function closeModal() {
    setModelIsOpen(false)
    setShow(false)
    document.body.classList.remove("modal-open")
  }

  const getCountries = async () => {
    await RoleModel.countryService(token, true)
      .then(async ({ data }) => {
        await setCountries(data.data)
      })
      .catch((err) => {
        LogoutOnSessionExpire(err)
        /*redirect to 500 page */
      })
  }
  const getAccountType = async () => {
    await accountService
      .getAllAccountType(token, true)
      .then(async ({ data }) => {
        await setaccountType(data.data)
      })
      .catch((err) => {
        LogoutOnSessionExpire(err)
      })
  }

  const renderCountryList = () => {
    return (
      countries &&
      countries.map((country) => {
        return (
          <option value={country.id} key={country.id}>
            {country.name}{" "}
          </option>
        )
      })
    )
  }
  const renderAccountTypeList = () => {
    return (
      accountType &&
      accountType.map((type) => {
        return (
          <option value={type.id} key={type.id}>
            {type.name}{" "}
          </option>
        )
      })
    )
  }

  const onSubmit = () => {
    setIsLoading(true)
    if (checkValidation()) {
      accountService
        .updateAccount(token, state)
        .then(async (res) => {
          setIsLoading(false)
          closeModal()
          Swal.fire(t("account.account_updated"))

          retriveCurrentData()
        })
        .catch((err) => {
          setIsLoading(false)
          LogoutOnSessionExpire(err)
          let error = {
            accountName: "",
            reseller: "",
            accountType: "",
            country: "",
            contactEmail: "",
            contactPhone: "",
          }
          let serverError = err.response.data.errors

          if (serverError?.accountName) {
            error.accountName = serverError.accountName
          }
          if (serverError?.country) {
            error.country = serverError.country
          }

          if (serverError?.reseller) {
            error.reseller = serverError.reseller
          }
          if (serverError?.accountType) {
            error.accountType = serverError.accountType
          }
          if (serverError?.contactEmail) {
            error.contactEmail = serverError.contactEmail
          }
          if (serverError?.contactPhone) {
            error.contactPhone = serverError.contactPhone
          }
          if (serverError?.accountCode) {
            error.accountCode = serverError.accountCode
          }
          setError({ ...error })
        })
    } else {
      setIsLoading(false)
    }
  }
  const onDelete = () => {
    closeModal()

    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        accountService
          .destroy(token, { id: data.id })
          .then(async () => {
            retriveCurrentData()
          })
          .catch((err) => {
            LogoutOnSessionExpire(err)
            // redirect 404
          })
      }
    })
  }
  const checkValidation = () => {
    let error = {
      accountName: "",
      reseller: "",
      accountType: "",
      country: "",
      contactEmail: "",
      contactPhone: "",
      accountCode: "",
    }

    const {
      accountName,
      reseller,
      accountType,
      country,
      contactEmail,
      contactPhone,
      accountCode,
    } = state

    let flag = true

    if (
      accountName === "" ||
      accountName === null ||
      accountName === undefined
    ) {
      error.accountName = "The Account Name field is required."
      flag = false
    } else {
      if (accountName && accountName.length > 50) {
        error.accountName = "Account name should be less then 50 characters"
        flag = false
      }
    }
    if (country === "" || country === null || country === undefined) {
      error.country = "The country field is required."
      flag = false
    }
    if (reseller === "" || reseller === null || reseller === undefined) {
      error.reseller = "The Reseller field is required."
      flag = false
    }
    if (
      accountType === "" ||
      accountType === null ||
      accountType === undefined
    ) {
      error.accountType = "The Account Type field is required."
      flag = false
    }
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    if (contactEmail && !contactEmail.match(isValidEmail)) {
      error.contactEmail = "The email must be a valid email address."
      flag = false
    }

    /*const isValidMobile = /^\+{0,1}?([0-9]{0,2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/;
    
    if(contactPhone && !contactPhone.match(isValidMobile)){
      error.contactPhone = "The Contact Phone should not greater than 12 digit.";
      flag = false;
    }*/
    if (accountCode && accountCode.length > 10) {
      error.accountCode = "Account code should be less then 10 characters"
      flag = false
    }
    setError({ ...error })
    return flag
  }
  const checkNumber = (e) => {
    const re = /^\+?([0-9 ()+\b]+$)/

    // if value is not blank, then test the regex

    if (e.target.value === "" || e.target.value.match(re)) {
      setState({ ...state, contactPhone: e.target.value })
    }
  }

  return (
    <div
      className="modal bd-example-modal-lg update-model"
      tabIndex="-1"
      id="updatemodel"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
      isopen={modalIsOpen.toString()}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t("account.update_account")}</h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              onClick={() => closeModal()}
              />

          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.account_name")}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          value={state?.accountName ? state.accountName : ""}
                          className="form-control"
                          onChange={(event) =>
                            setState({
                              ...state,
                              accountName: event.target.value,
                            })
                          }
                          placeholder={t("account.account_name")}
                          maxLength={50}
                        />
                        <XError message={errors?.accountName} />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.local_account_name")}
                        </label>
                        <input
                          type="text"
                          value={
                            state?.accountlocalName
                              ? state.accountlocalName
                              : ""
                          }
                          className="form-control"
                          onChange={(event) =>
                            setState({
                              ...state,
                              accountlocalName: event.target.value,
                            })
                          }
                          placeholder={t("account.local_account_name")}
                          maxLength={150}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.account_code")}
                        </label>
                        <input
                          type="text"
                          value={state?.accountCode ? state.accountCode : ""}
                          className="form-control"
                          onChange={(event) =>
                            setState({
                              ...state,
                              accountCode: event.target.value,
                            })
                          }
                          placeholder={t("account.account_code")}
                          maxLength={10}
                        />
                        <XError message={errors?.accountCode} />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313"></div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.reseller")}{" "}
                          <small>
                            <span className="text-danger">*</span>&nbsp; &nbsp;(
                            {t("account.na_for_end_customer")})
                          </small>
                        </label>
                        <select
                          value={state?.reseller !== 0 ? state.reseller : 0}
                          onChange={(event) =>
                            setState({ ...state, reseller: event.target.value })
                          }
                          className="default-select form-control wide"
                        >
                          {" "}
                          <option value="">{t("account.choose")}...</option>
                          <option value={0}>NA</option>
                          {accountData &&
                            accountData.map((account) => {
                              if (account.account_type == 2) {
                                return (
                                  <option value={account?.id} key={account.id}>
                                    {account?.name}
                                  </option>
                                )
                              }
                            })}
                        </select>
                        <XError message={errors?.reseller} />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.account_type")}
                          <small className="text-danger">*</small>
                        </label>
                        <select
                          value={state?.accountType ? state.accountType : ""}
                          onChange={(event) =>
                            setState({
                              ...state,
                              accountType: event.target.value,
                            })
                          }
                          className="default-select form-control wide"
                        >
                          {" "}
                          <option value="">{t("account.choose")}...</option>
                          {renderAccountTypeList()}
                        </select>
                        <XError message={errors?.accountType} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {t("account.address")}
                        </label>
                        <input
                          type="text"
                          value={state?.address ? state.address : ""}
                          className="form-control"
                          onChange={(event) =>
                            setState({ ...state, address: event.target.value })
                          }
                          placeholder={t("account.address")}
                          maxLength={450}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.country")}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <select
                          value={state?.country}
                          onChange={(event) =>
                            setState({ ...state, country: event.target.value })
                          }
                          className="default-select form-control wide"
                        >
                          {" "}
                          <option value="">{t("account.choose")}...</option>
                          {renderCountryList()}
                        </select>
                        <XError message={errors?.country} />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313"></div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.contact_name")}
                        </label>
                        <input
                          type="text"
                          value={state?.contactName ? state.contactName : ""}
                          className="form-control"
                          onChange={(event) =>
                            setState({
                              ...state,
                              contactName: event.target.value,
                            })
                          }
                          placeholder={t("account.contact_name")}
                          maxLength={50}
                        />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313"></div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.contact_email")}
                        </label>
                        <input
                          type="email"
                          value={state?.contactEmail ? state.contactEmail : ""}
                          className="form-control"
                          onChange={(event) =>
                            setState({
                              ...state,
                              contactEmail: event.target.value,
                            })
                          }
                          placeholder={t("account.contact_email")}
                          maxLength={50}
                        />
                        <XError message={errors?.contactEmail} />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {t("account.contact_phone")}
                        </label>
                        <input
                          type="text"
                          value={state?.contactPhone ? state.contactPhone : ""}
                          className="form-control"
                          onChange={(event) => checkNumber(event)}
                          placeholder={t("account.contact_phone")}
                          maxLength={13}
                        />
                        <XError message={errors?.contactPhone} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {t("account.description")}
                        </label>
                        <textarea
                          value={state?.description ? state.description : ""}
                          className="form-control"
                          onChange={(event) =>
                            setState({
                              ...state,
                              description: event.target.value,
                            })
                          }
                          placeholder={t("account.description")}
                          style={{ height: "70px" }}
                          maxLength={450}
                        ></textarea>
                      </div>
                    </div>

                    <div
                      className="toolbar toolbar-bottom mt-51"
                      style={{ textAlign: "right" }}
                      role="toolbar"
                    >
                      <button
                        type="button"
                        style={{ marginRight: "1rem" }}
                        onClick={() => onDelete()}
                        className="btn btn-danger mr_1"
                      >
                        {t("common.delete")}
                      </button>
                      <button
                        type="button"
                        onClick={closeModal}
                        style={{ marginRight: "1rem" }}
                        className="btn btn-outline-primary mr_1"
                      >
                        {" "}
                        {t("common.cancel")}{" "}
                      </button>
                      {isLoading ? (
                        <button type="button" className="btn btn-primary mr_1">
                          {" "}
                          {t("common.loading")}...{" "}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={onSubmit}
                          className="btn btn-primary"
                        >
                          {" "}
                          {t("common.submit")}{" "}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateAccount
