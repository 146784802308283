import { axiosWithToken as axios } from 'utils/axios';

class CrossConnectService {
	/*
	|-------------------------------------------------------------------------------
	| 	Get Cross Connects By Data Center Id
	|-------------------------------------------------------------------------------
	*/
	async getCrossConnectsByDc ({token, dcId, search,offset}) {
	console.log("offset",offset);
    let query = `getCrossConnects?dummy=0`
    if (dcId) {
      query = `${query}&data_center_id=${dcId}&offset=${offset}`
    }    
    if (search) {
      query = `${query}&search=${search}`
    }
		return await axios(token).get(query);
	}

	/*
	|-------------------------------------------------------------------------------
	| 	Get Data For Add Cross Connects Form
	|-------------------------------------------------------------------------------
	*/  

	async getAddCrossConnectsData ({token, dcId, countryId}) {
		return await axios(token).get(`getAddCrossConnectsData?data_center_id=${dcId}&country=${countryId}`);
	}

	/*
	|-------------------------------------------------------------------------------
	| 	Add New Cross Connect
	|-------------------------------------------------------------------------------
	*/  

  async createCrossConnect(token,data){

		return await axios(token).post(`createCrossConnect`,data);
	}

  /*
	|-------------------------------------------------------------------------------
	| 	Update Cross Connect
	|-------------------------------------------------------------------------------
	*/  

  async updateCrossConnect(token,data){

		return await axios(token).post(`updateCrossConnect`, data);
	}

  /*
	|-------------------------------------------------------------------------------
	| 	Delete Cross Connect
	|-------------------------------------------------------------------------------
	*/  

  async deleteCrossConnect(token,data){

		return await axios(token).post(`deleteCrossConnect`,data);
	}
}

export default new CrossConnectService()