import React, { useState } from 'react';

const SerachBox = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const HandleSearch = (val) => {
    setSearchValue(() => val);
    if (val.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
      props.setQuery('');
    }
  };

  const HandleDropdown = (val) => {
    setShowDropdown(() => false);
    props.setQuery({
      name: val,
      value: searchValue
    });
    props.setPageNumber(1);
  };

  return (
    <React.Fragment>
      <div className="tc_drop1-out">
        <div class="tc_drop1">
          <div className="inp-out">
            <input type="text" onChange={(e) => HandleSearch(e.target.value)} value={searchValue} />
            <div className="after"></div>
          </div>
          <div className="img-out" onClick={() => setShowDropdown(() => !showDropdown)}>
            {' '}
            <img alt="" src="\images\arrow.png" className="arrow-img" />
          </div>
        </div>
        {showDropdown ? (
          <div className="tc_drop1-opt">
            {props.searchCategory &&
              props.searchCategory.map((data) => {
                return (
                  <div className="tc_opt" onClick={() => HandleDropdown(data.field)}>
                    Search '{data.name}' for {searchValue}
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default SerachBox;
