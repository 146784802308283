import AuthContext from "context";
import moment from "moment";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateTicketService from "services/createTicketService";
import Swal from "sweetalert2";
import * as utils from "../../shared/utils";

const REMOTE_HANDS_PREFIX = "Remote Hands ";

const useCreateRemoteHandsTicket = ({ closeModal, teams }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [requestOnFlight, setRequestOnFlight] = useState(false);

  const postNewTicket = async (values) => {
    // console.log({ teams })
    try {
      const {
        country,
        companyName,
        operatingSite,
        customer,
        serviceId,
        remoteHandsType,
        ticketType,
        requestSubject,
        description,
        scheduleStartTime,
        scheduleEndTime,
        banId,
        services,
        dataCenters,
        countries,
      } = values;

      const dataCenterCountry = utils.getDataCenterCountry(
        operatingSite,
        dataCenters,
        countries
      );

      const payload = {
        ticket_sub_type_id: ticketType,
        team_id: utils.getTeamIdByCountry(
          teams,
          country,
          REMOTE_HANDS_PREFIX,
          dataCenterCountry,
          countries
        ),
        customer_id: parseInt(customer, 10),
        prority_id: 0,
        operating_site_id: parseInt(operatingSite, 10),
        designated_company_id: parseInt(companyName, 10),
        service_id: parseInt(serviceId, 10),
        space_id: services.find((service) => serviceId).space_id,
        subject: requestSubject,
        description,
        ban_id: banId,
        is_scheduled: remoteHandsType === "scheduled",
      };
      if (remoteHandsType === "scheduled") {
        payload.start_date = moment(scheduleStartTime).utc();
        payload.end_date = moment(scheduleEndTime).utc();
      }
      if (requestOnFlight === false) {
        setRequestOnFlight(true);
        // console.log(payload)
        const result = await CreateTicketService.createTicket(
          authContext.token(),
          payload,
          "remote-hands"
        );
        // console.log(result.data.data)
        const ticketId = result.id;
        setRequestOnFlight(false);
        closeModal();
        await Swal.fire({
          icon: "success",
          title: `${t("done")}!`,
          text: t("create-ticket.site-access.success", {
            ticket: `${requestSubject} (#${ticketId})`,
          }),
          confirmButtonText: "Ok",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        })
      }
    } catch (err) {
      setRequestOnFlight(false);
      await Swal.fire(t("error"), err.message);
    }
  };

  return {
    postNewTicket,
    requestOnFlight,
  };
};

export default useCreateRemoteHandsTicket;
