import PrintIcon from "@mui/icons-material/Print";
import RefreshIcon from "@mui/icons-material/Refresh";
const SecondRow = ({ title, type, refresh, handlePrint, permission }) => {
  return (
    <>
      <div className="modal-header mt-2 mb-3">
        <span class="display-title">
          {title}
          <span
            style={{
              fontSize: "1rem",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {" " + type}
          </span>
        </span>
        <div className="fc-side-out">
          <div
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "flex-end",
            }}
          >
            <RefreshIcon
              style={{ cursor: "pointer" }}
              onClick={() => refresh(true)}
              fontSize="large"
              data-html2canvas-ignore="true"
            />

            <div style={{ width: "4rem" }}>
              {permission("Print") && (
                <PrintIcon
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handlePrint();
                  }}
                  fontSize="large"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondRow;
