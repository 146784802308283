import React, { useEffect, useState, useContext, useRef } from "react";
import Navigation from "./navigation";
import RoleService from "services/roleServices";
import UserService from "services/userServices";
import AuthContext from "context";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import StarIcon from "@mui/icons-material/Star";
import Swal from "sweetalert2";

const Header = ({
  getFloorData,
  setIsSelectedGroup,
  isSelectedGroup,
  setDataCenterList,
  setSelectedDataCenterForTicket,
  setSelectedCountryForTicket,
  setSelectedDataCenterForProvisioning,
  setSelectedCountryForProvisioning,
  setSelectedDataCenterForDatacenter,
  setSelectedCountryForDatacenter,
  setCountriesForTickets,
}) => {
  const contextStore = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [dataCenter, setDataCenter] = useState([]);
  const [countryText, setCountryText] = useState("Country");
  const [countrycount, setCountryCount] = useState("");
  const [selectedDataCenter, setSelectedDataCenter] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const [defaultPage, setDefaultPage] = useState(null);

  useEffect(() => {
    getAllCountry();
  }, []);

  const getAllCountry = () => {
    RoleService.countryService(contextStore.token())
      .then((res) => {
        setCountries(res.data.data);
        if (setCountriesForTickets) {
          setCountriesForTickets(res.data.data);
        }
        setCountryCount(res.data.data.length);
        if (
          res.data.data.length === 1 ||
          location.pathname == "/provisioning"
        ) {
          setCountryText(res.data.data[0].name);
          getDataCenter(res.data.data[0]);
          contextStore.setSelectedCountry(res.data.data[0]);
          setCountryAndDatacenterForPages("country", res.data.data[0]);
          localStorage.setItem("cid", res.data.data[0].id);
          localStorage.setItem("country", res.data.data[0].name);
        } else {
          setCountryText("Country");
          getDataCenter("");
          setCountryAndDatacenterForPages("country", "");
          contextStore.setSelectedCountry("");
        }
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        /*500 internal server error*/
      });
  };

  const onCountryChange = (country) => {
    contextStore.setSelectedCountry(country);
    setCountryAndDatacenterForPages("country", country);
    setCountryText(country.name);
    getDataCenter(country);
    if (isSelectedGroup && isSelectedGroup != "") {
      setIsSelectedGroup("");
    }
  };

  const getDataCenter = async (country) => {
    if (country === "") {
      await RoleService.dataCenter(contextStore.token())
        .then((res) => {
          let data = res.data.data.filter((data) => data.dc_type_id === 1);
          data.push({
            name: "ALL",
            id: "all",
            dc_type_id: 1,
          });
          setDataCenter(data);

          if (location.pathname == "/provisioning") {
            setSelectedDataCenterForProvisioning(
              data.filter((el) => el.id == "all")[0]
            );
          }
          if (location.pathname == "/ticket") {
            setSelectedDataCenterForTicket(
              data.filter((el) => el.id == "all")[0]
            );
          }
          if (location.state != null && location.pathname == "/data-center") {
            setSelectedDataCenterForDatacenter(location.state);
            setSelectedDataCenter(location.state);
          } else {
            setSelectedDataCenter(data.filter((el) => el.id == "all")[0]);
          }
          contextStore.setglobalTicketDC(!contextStore.globalTicketDC);
          setDataCenterList(res.data.data);
        })
        .catch((err) => LogoutOnSessionExpire(err));
    } else {
      await RoleService.dataCenterByCountryId(contextStore.token(), country)
        .then((res) => {
          if (res.data.data.length > 1) {
            res.data.data.push({
              name: "ALL",
              id: "all",
            });
            setDataCenter(res.data.data);
            if (setDataCenterList) {
              setDataCenterList(res.data.data);
            }
            setSelectedDataCenter(
              res.data.data.filter((el) => el.id == "all")[0]
            );
            setCountryAndDatacenterForPages(
              "datacenter",
              res.data.data.filter((el) => el.id == "all")[0]
            );
          } else {
            setDataCenter(res.data.data);
            setSelectedDataCenter(res.data.data[0]);
            if (setDataCenterList) {
              setDataCenterList(res.data.data);
            }
            setCountryAndDatacenterForPages("datacenter", res.data.data[0]);
          }
        })
        .catch((err) => LogoutOnSessionExpire(err));
    }
  };
  const onDataCenterChange = (dataCenter) => {
    setSelectedDataCenter(dataCenter);
    setCountryAndDatacenterForPages("datacenter", dataCenter);
    if (isSelectedGroup && isSelectedGroup != "") {
      setIsSelectedGroup("");
    }
  };

  const getCountryName = (dataCenter) => {
    const addresss = dataCenter.address;
    if (addresss) {
      const parts = addresss.split(",");
      let country = parts[parts.length - 1].trim();
      if (country === "South Korea") {
        country = "Korea";
      }
      contextStore.setCountryName(country);
    }
  };

  const onGlobal = () => {
    getAllCountry();
    setCountryText("Country");
    setSelectedDataCenter("");
    getDataCenter();
    if (getFloorData instanceof Function) {
      getFloorData();
    }
    contextStore.setSelectedCountry("");
    setCountryAndDatacenterForPages("country", "");
    setCountryAndDatacenterForPages("datacenter", "");
  };

  const setCountryAndDatacenterForPages = (field, data) => {
    if (field == "country") {
      if (location.pathname == "/provisioning") {
        setSelectedCountryForProvisioning(data);
      }
      if (location.pathname == "/ticket") {
        setSelectedCountryForTicket(data);
      }
      if (location.pathname == "/data-center") {
        setSelectedCountryForDatacenter(data);
      }
    }
    if (field == "datacenter") {
      if (location.pathname == "/provisioning") {
        setSelectedDataCenterForProvisioning(data);
      }
      if (location.pathname == "/ticket") {
        setSelectedDataCenterForTicket(data);
      }
      if (location.pathname == "/data-center") {
        setSelectedDataCenterForDatacenter(data);
      }
    }
  };

  const handleDefaultPage = () => {
    const page =
      location.pathname == "/data-center"
        ? "Data center"
        : location.pathname == "/ticket"
        ? "Tickets"
        : "Provisioning";
    const text = page + " will be set as default page.";
    Swal.fire({
      title: t("common.are_you_sure"),
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        updateDefaultPage(location.pathname);
      }
    });
  };

  const updateDefaultPage = (page) => {
    UserService.updateDefaultPage(contextStore.token(), { default_page: page })
      .then((res) => {
        setDefaultPage(page);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      });
  };

  useEffect(() => {
    setDefaultPage(contextStore.getAuth.default_page);
  }, [contextStore.getAuth.default_page]);

  const getTitle = () => {
    if (location.pathname == defaultPage) {
      if (location.pathname == "/data-center") {
        return "Data Center is set as default page";
      }
      if (location.pathname == "/esg") {
        return "ESG is set as default page";
      }
      if (location.pathname == "/ticket") {
        return "Tickets is set as default page";
      }
      if (location.pathname == "/provisioning") {
        return "Provisioning is set as default page";
      }
    } else {
      return "Set as default page";
    }
  };
  return (
    <React.Fragment>
      <Navigation />
      <div className="dt_tabs header_dash">
        <div className="profile-tab">
          <div className="custom-tab-1 tab_flex">
            <div className="gin_btn">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  {countrycount > 1 && location.pathname != "/provisioning" ? (
                    <button
                      type="text"
                      onClick={() => onGlobal()}
                      className="btn btn-light"
                      style={{
                        background:
                          countryText != "Country" ? "#EAECF2" : "#11263C",
                        color: countryText != "Country" ? "#000000" : "#ffffff",
                      }}
                    >
                      {" "}
                      {t("header.global")}{" "}
                    </button>
                  ) : null}
                </li>
                <li className="nav-item">
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      style={{
                        background:
                          countryText == "Country" ? "#EAECF2" : "#11263C",
                        color: countryText == "Country" ? "#000000" : "#ffffff",
                        border: countryText == "Country" ? "none" : "",
                      }}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {countryText}
                    </button>
                    <div className="dropdown-menu" style={{ margin: "0px" }}>
                      {countries.map((country, i) => {
                        return (
                          <div key={i}>
                            <a
                              href="#sd"
                              style={{ cursor: "pointer" }}
                              onClick={() => onCountryChange(country)}
                              className="dropdown-item form-check-label"
                            >
                              {country.name}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="outer-div">
              <div className="tabs_srll">
                <ul className="nav nav-tabs scroll_tab_dg">
                  {dataCenter &&
                    dataCenter.map((data, index) => {
                      if (
                        selectedDataCenter &&
                        selectedDataCenter.id === data.id
                      ) {
                        return (
                          <li
                            className={
                              index === 0 ? "nav-item ml_20" : "nav-item"
                            }
                            key={index}
                          >
                            <a
                              href="#sds"
                              onClick={() => {
                                onDataCenterChange(data);
                                getCountryName(data);
                              }}
                              style={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                              className="nav-link active show"
                            >
                              <img
                                className="down"
                                src="images/downward-arrow.png"
                              />
                              {data.name}
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            className={
                              index === 0 ? "nav-item ml_20" : "nav-item"
                            }
                            key={index}
                          >
                            <a
                              href="#sds"
                              onClick={() => {
                                onDataCenterChange(data);
                                getCountryName(data);
                              }}
                              style={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                              className="nav-link"
                            >
                              {" "}
                              {data.name}{" "}
                            </a>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
              <div>
                <span>
                  <i>
                    **{" "}
                    {t("header.select_a_country_to_view_partner_data_center")}
                  </i>
                </span>
              </div>
            </div>
            <div>
              <Tooltip
                title={<span style={{ fontSize: "1rem" }}>{getTitle()}</span>}
                TransitionComponent={Zoom}
                placement="left"
                arrow
              >
                <IconButton
                  edge="end"
                  style={{ marginRight: "2rem" }}
                  onClick={
                    defaultPage == location.pathname ? "" : handleDefaultPage
                  }
                >
                  {defaultPage == location.pathname ? (
                    <StarIcon style={{ color: "#FE8600" }} />
                  ) : (
                    <StarBorderIcon style={{ color: "black" }} />
                  )}
                </IconButton>{" "}
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
