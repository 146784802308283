import Datetime from "react-datetime";
import moment from "moment";
import React, { useState, useEffect } from "react";
import "react-datetime/css/react-datetime.css";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
const DataRangeBox = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const [resetKey1, setResetKey1] = useState(Date.now());
  const [resetKey2, setResetKey2] = useState(Date.now());

  const customStyles = {
    height: "30px",
    fontSize: "0.875rem",
    paddingLeft: "0.3rem",
    marginTop: "0px",
    outline: "none",
    boxShadow: "none",
  };

  const validStartDate = (current) => {
    // Only allow dates up to today
    return current.isSameOrBefore(moment(), "day");
  };

  const validEndDate = (current) => {
    if (!startDate) {
      return current.isSameOrBefore(moment(), "day");
    }
    return (
      current.isSameOrAfter(startDate, "day") &&
      current.isSameOrBefore(moment(), "day")
    );
  };

  const handleStartDateChange = (value) => {
    const newStartDate = moment(value).format("YYYY-MM-DD HH:mm:ss");
    setStartDate(newStartDate);
    if (endDate !== null) {
      setResetKey2(Date.now());
      setEndDate(null);
    }
  };

  const handleEndDateChange = (value) => {
    if (value) {
      const newEndDate = moment(value)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      setEndDate(newEndDate);
    } else {
      setEndDate(null);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div style={{ position: "relative", width: "150px" }}>
        <Datetime
          key={resetKey1}
          inputProps={{ style: customStyles, placeholder: "From" }}
          timeFormat={false}
          dateFormat="YYYY-MM-DD"
          onChange={handleStartDateChange}
          isValidDate={validStartDate}
          value={startDate ? moment(startDate) : null}
          closeOnSelect={true}
        />
        <i
          className="fas fa-calendar"
          style={{ position: "absolute", right: "10%", top: "35%" }}
        />
      </div>
      <div>&nbsp;&nbsp;</div>
      <div style={{ position: "relative", width: "150px" }}>
        <Datetime
          key={resetKey2}
          inputProps={{
            style: customStyles,
            placeholder: "To",
            disabled: !startDate,
          }}
          isValidDate={validEndDate}
          timeFormat={false}
          dateFormat="YYYY-MM-DD"
          onChange={handleEndDateChange}
          closeOnSelect={true}
        />
        <i
          className="fas fa-calendar"
          style={{ position: "absolute", right: "10%", top: "35%" }}
        />
      </div>

      <div style={{ width: "3rem", height: "40px", paddingLeft: "0.25rem" }}>
        {(!!startDate || !!endDate) && (
          <IconButton
            aria-label="Example"
            onClick={() => {
              setResetKey1(Date.now());
              setStartDate(null);
              setResetKey2(Date.now());
              setEndDate(null);
            }}
          >
            <ClearIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default DataRangeBox;
