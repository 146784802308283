import React, { useEffect } from 'react';
import Pagination from 'views/super-admin/Ticket/components/shared/usePagination';
const Table = (props) => {
  useEffect(() => {}, [props]);
  const handleClick = (index, data) => {
    if (index == 0 || index == 1 || index + 1 == props.columns.length) {
      props.handleClick(
        data?.operatingsite,
        data?.id,
        data?.tickettypeid,
        data?.ticket_sub_type_name,
        data?.tickettype
      );
    }
  };
  return (
    <React.Fragment>
      <div className="card-body" style={{ overflow: 'auto' }}>
        <div className="table-responsive tckect_tt">
          <table className="table header-border table-borderless table-hover verticle-middle">
            <thead>
              <tr>
                {props.columns &&
                  props.columns.map((column, i) => {
                    return (
                      <th scope="col" style={{ cursor: 'pointer' }}>
                        {column.value}
                        {/* <i
                          className={`fa fa-solid fa-sort-${true ? 'down' : 'up'}`}
                          style={{ fontSize: '1.3rem', paddingLeft: '6px' }}
                        ></i> */}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {' '}
              {props.data &&
                props.data.map((data, i) => {
                  return (
                    <tr>
                      {props.columns &&
                        props.columns.map((column, index) => {
                          return (
                            <td
                              className="pd-l bold-txt cus-search"
                              style={{
                                fontWeight: 500,
                                cursor:
                                  index == 0 || index == 1 || index + 1 == props.columns.length
                                    ? 'pointer'
                                    : ''
                              }}
                              onClick={() => {
                                handleClick(index, data);
                              }}
                            >
                              {data[column.name]}
                              {index + 1 == props.columns.length && (
                                <a>
                                  {' '}
                                  <i className="fas fa-edit"></i>
                                </a>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mt-3">
          {props.data && props.data.length > 0 && (
            <Pagination
              setItemsPerPage={props.setItemsPerPage}
              setPageNumber={props.setPageNumber}
              total={props.total}
              pageNumber={props.pageNumber}
            ></Pagination>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Table;
