import { React, useState } from 'react';
import useGetDimensions from 'views/super-admin/Tickets/ShipmentTicketForm/hooks/useGetDimensions';

const AddPakage = ({ setToggleLine, setUpdateTicketDetails, setState }) => {
  const { lengthDimensions, weightDimensions } = useGetDimensions();
  const [error,setError] = useState(false);
  const [values, setValues] = useState({
    tracking_number: '',
    dimension: '',
    weight: '',
    remarks: '',
    dimension_uom: 10,
    dimension_uom_val: 'cm',
    weight_uom: 4,
    uom: 'g',
    Depth: '',
    width: '',
    height: ''
  });
  const saveClose = () => {
    if(values.weight.trim().length > 0){
      let allVal = {
        tracking_number: values.tracking_number,
        dimension:
          values.height +
          `${values.dimension_uom_val} ` +
          ' x ' +
          values.width +
          ` ${values.dimension_uom_val} ` +
          ' x ' +
          values.Depth +
          ` ${values.dimension_uom_val} `,
        remarks: values.remarks,
        dimension_uom: values.dimension_uom,
        weight_uom: values.weight_uom,
        weight: values.weight
      };
      setUpdateTicketDetails((prev) => ({
        ...prev,
        shipment_details: [...prev.shipment_details, allVal]
      }));
      setState((prev) => ({
        ...prev,
        shipment_details: [...prev.shipment_details, { ...allVal, uom: values.uom }]
      }));
      setValues({
        tracking_number: '',
        dimension: '',
        weight: '',
        remarks: '',
        dimension_uom: '',
        weight_uom: '',
        Depth: '',
        width: '',
        height: ''
      });
      setToggleLine(false);
      setError(false)
    }else{
      setError(true)
    }
    
  };
  const saveNew = () => {
    if(values.weight.trim().length > 0){
      let allVal = {
        tracking_number: values.tracking_number,
        dimension:
          values.height +
          `${values.dimension_uom_val ? values.dimension_uom_val :''} ` +
          ' x ' +
          values.width +
          ` ${values.dimension_uom_val ? values.dimension_uom_val :'' } ` +
          ' x ' +
          values.Depth +
          ` ${values.dimension_uom_val ? values.dimension_uom_val :''} `,
        remarks: values.remarks,
        dimension_uom: values.dimension_uom ? values.dimension_uom : '',
        weight_uom: values.weight_uom ? values.weight_uom : '',
        weight: values.weight ? values.weight: ''
      };
  
      console.log(allVal);
      setUpdateTicketDetails((prev) => ({
        ...prev,
        shipment_details: [...prev.shipment_details, { ...allVal }]
      }));
      setState((prev) => ({
        ...prev,
        shipment_details: [...prev.shipment_details, { ...allVal, uom: values.uom }]
      }));
      setValues({
        tracking_number: '',
        dimension: '',
        weight: '',
        remarks: '',
        dimension_uom: '',
        weight_uom: '',
        Depth: '',
        width: '',
        height: ''
      });
      setError(false)
    }else{
      setError(true)
    }
   
  };

  const discrad = () => {
    setValues({
      tracking_number: '',
      dimension: '',
      weight: '',
      remarks: '',
      dimension_uom: '',
      weight_uom: '',
      Depth: '',
      width: '',
      height: ''
    });
    setToggleLine(false);
  };

  return (
    <div className="timer-pop">
      <div className="main-body1">
        <div>
          <div className="top-cts">
            <div>Add Package Details</div>
            <div className="close" onClick={() => setToggleLine(false)}>
              X
            </div>
          </div>
          <div className="center-cts">
            <div className="va-details">
              <div className="va-left">
                <div className="va-inner">
                  <div className="text text-1">Tracking No</div>
                  <div>
                    <input
                      type="text"
                      value={values.tracking_number}
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, tracking_number: e.target.value }))
                      }
                    />
                  </div>
                </div>
                <div className="va-inner">
                  <div className="text text-1">Weight</div>
                  <div>
                    <input
                      type="text"
                      value={values.weight}
                      onChange={(e) => setValues((prev) => ({ ...prev, weight: e.target.value }))}
                    />
                  </div>
                </div>
                <div className="va-inner">
                  <div>
                    <div className="text text-1">Weight UOM</div>
                  </div>

                  <div>
                    <div>
                      <select
                        style={{ height: '22px' }}
                        className="select1-addpack"
                        onChange={(event) =>
                          setValues((prev) => ({
                            ...prev,
                            weight_uom: parseInt(event.target.value),
                            uom: weightDimensions.filter(
                              (data) => data.value == event.target.value
                            )[0]?.label
                          }))
                        }
                      >
                        {weightDimensions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>{' '}
                <div className="va-inner">
                  <div className="text text-1">Remarks</div>
                  <div>
                    <input
                      type="text"
                      value={values.remarks}
                      onChange={(e) => setValues((prev) => ({ ...prev, remarks: e.target.value }))}
                    />
                  </div>
                </div>
              </div>
              <div className="va-left">
                <div className="va-inner">
                  <div className="text text-1">Height</div>
                  <div>
                    <input
                      type="number"
                      value={values.height}
                      onChange={(e) => setValues((prev) => ({ ...prev, height: e.target.value }))}
                    />
                  </div>
                </div>
                <div className="va-inner">
                  <div className="text text-1">Width</div>
                  <div>
                    <input
                      type="number"
                      value={values.width}
                      onChange={(e) => setValues((prev) => ({ ...prev, width: e.target.value }))}
                    />
                  </div>
                </div>
                <div className="va-inner">
                  <div className="text text-1">Depth</div>
                  <div>
                    <input
                      type="number"
                      value={values.Depth}
                      onChange={(e) => setValues((prev) => ({ ...prev, Depth: e.target.value }))}
                    />
                  </div>
                </div>
                <div className="va-inner">
                  <div className="text text-1">Dimension UOM</div>
                  <div>
                    <select
                      style={{ height: '22px' }}
                      className="select1-addpack"
                      onChange={(event) =>
                        setValues((prev) => ({
                          ...prev,
                          dimension_uom: parseInt(event.target.value),
                          dimension_uom_val: lengthDimensions.filter(
                            (data) => data.value == event.target.value
                          )[0]?.label
                        }))
                      }
                    >
                      {lengthDimensions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {
              error ?
              <h4 style={{color:"red",padding:"1rem"}}>Weight is required.</h4>
              :""
            }
          </div>
          <div className="bottom-cts">
            <div>
              <button className="Save-btn1" onClick={() => saveClose()}>
                SAVE & CLOSE
              </button>
            </div>
            <div>
              <button className="Save-btn1" onClick={() => saveNew()}>
                SAVE & NEW
              </button>
            </div>
            <div>
              <button className="cancel-btn1" onClick={() => discrad()}>
                DISCARD
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPakage;
