import XCard from './Card/X-Card';
import XInput from './Form/X-Input';




export {
	XInput,
	XCard
};
