import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const SubNavigation = ({data}) => {

	const location = useLocation();
	const { t } = useTranslation();
	const activeClass = (route) => {

		if (location.pathname === route) {
			return "plink active";
		}

		return "plink";
	}
	return(
		<div className="col-xl-1">
			<div className="leftside">
			{
				data && data.map((nav,index) => {
					return (
						<p key={index}> 
							<Link 
							style={{whiteSpace: "nowrap"}}
							className={activeClass(nav.path)}  
							to={nav.path} >{t(`cabling.${nav.title}`)} </Link>
						</p>
					)
				})
			}
			</div>
		</div>
	);
}

export default SubNavigation;