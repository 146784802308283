/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Select from "react-select";
// import "react-select/dist/react-select.css";

const SingleSelect = ({
  value,
  setUpdateTicketDetails,
  options,
  setAllSpace,
  customStyles,
}) => {
  const [localOptions, setLocalOptions] = useState([]);

  useEffect(() => {
    if (value?.length === 0) {
      setLocalOptions([]);
    } else {
      setAllSpace(() => [value?.space]);
      setLocalOptions(value);
    }
  }, [value]);

  const onChange = (selected) => {
    setUpdateTicketDetails((prev) => ({
      ...prev,
      service_id: selected ? [selected?.id] : undefined,
    }));

    setAllSpace(() => [selected?.space]);
    setLocalOptions(selected);
  };

  const getOptions = (options) => {
    return options;
  };

  return (
    <>
      <Select
        styles={customStyles}
        className="multiselect"
        hideSelectedOptions
        options={getOptions(options)}
        value={localOptions}
        classNamePrefix="select"
        onChange={onChange}
      />
    </>
  );
};

export default SingleSelect;
