import React, { useState } from "react"
import CanvasJSReact from "@canvasjs/react-charts"
import moment from "moment"

var CanvasJS = CanvasJSReact.CanvasJS
var CanvasJSCharts = CanvasJSReact.CanvasJSChart

const SensorCharts = ({ chartVal, dctime }) => {
  const [Legend1, setLegend1] = useState(true)
  const [Legend2, setLegend2] = useState(true)

  var currentTime = new Date().toLocaleString("en-US", {
    timeZone: dctime,
  })

  if (!chartVal.data || chartVal.data.length === 0) {
    return (
      <div
        style={{
          height: "313px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <span style={{ fontSize: "2rem", color: "red" }}>
          Data Not Available
        </span>
      </div>
    )
  }

  // Create an array to store the data points
  var dataPoints = []
  var dataPoints2 = []
  var brName = []
  let options = {}

  var intervalMinutes = 10
  var numDataPoints = (chartVal.time * 60) / intervalMinutes
  for (var i = 0; i < numDataPoints; i++) {
    var timestamp = new Date(
      currentTime - (numDataPoints - i) * intervalMinutes * 60 * 1000
    )

    dataPoints.push({ x: timestamp, y: null })
    dataPoints2.push({ x: timestamp, y: null })
  }
  for (let i = chartVal.data.length - 1; i >= 0; i--) {
    const utcDateTime = chartVal.data[i].ts_utc
    const localDateTime = moment
      .utc(utcDateTime)
      .tz(dctime)
      .format("YYYY-MM-DD HH:mm:ss")

    const dateObject = new Date(localDateTime)
    const formattedDate = dateObject

    dataPoints.push({
      x: formattedDate,
      y: parseFloat(chartVal.data[i].value.toFixed(2)),
    })
  }

  options = {
    animationEnabled: true,
    title: {
      text: "",
    },
    axisY: {
      title: chartVal.yAxis,
      titleFontSize: 14,
      titleFontWeight: "bold",
      titleFontColor: "black",
    },

    axisX: {
      valueFormatString: "HH:mm",
      interval: 1,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },

    toolTip: {
      shared: true,
    },
    data: [
      {
        type: "spline",
        name: chartVal.title,

        dataPoints: dataPoints,
      },
    ],
  }
  console.log(chartVal, dataPoints)

  return (
    <div className={chartVal.title == "power" && "charHeight"}>
      <div style={{ height: "313px" }}>
        <CanvasJSCharts options={options} />
      </div>
      {chartVal.title == "power" && chartVal.arraylength == 2 && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              width: "796px",
              justifyContent: "center",
              position: "absolute",
              zIndex: 1111111111111,
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
              <div className="legend-1"></div>
              <div
                className={Legend1 ? "legend-txt" : "legend-txt opac"}
                onClick={() => setLegend1(!Legend1)}
              >
                {" "}
                {brName[0]}
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
              <div className="legend-2"></div>
              <div
                className={Legend2 ? "legend-txt" : "legend-txt opac"}
                onClick={() => setLegend2(!Legend2)}
              >
                {" "}
                {brName[1]}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SensorCharts
