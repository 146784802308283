import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import './TicketButton.scss';
import { useMemo } from 'react';

const TicketButton = ({
  label,
  variant,
  icon,
  handleClick,
  active,
  width,
  buttonType,
  closeModal
}) => {
  const styles = {
    button: {
      width: width || null
    }
  };
  return (
    <AwesomeButton
      element={({ children, style, className }) => (
        <button
          onClick={() => {
            if (handleClick) {
              handleClick();
            }
            if (closeModal) {
              {
                closeModal();
              }
            }
          }}
          style={style}
          className={className}
          type={buttonType || 'button'}
        >
          {children}
        </button>
      )}
      className="aws-btn"
      style={styles.button}
      type={variant}
      after={icon}
      disabled={!active}
    >
      {label}
    </AwesomeButton>
  );
};

const TicketButtonMemo = ({
  label,
  variant,
  icon,
  handleClick,
  active,
  width,
  buttonType,
  closeModal
}) => {
  return useMemo(() => {
    return (
      <TicketButton
        label={label}
        variant={variant}
        icon={icon}
        handleClick={handleClick}
        active={active}
        width={width}
        buttonType={buttonType}
        closeModal={closeModal}
      />
    );
  }, [label, variant, icon, active, width, buttonType, closeModal]);
};

export default TicketButtonMemo;
