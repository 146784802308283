import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class RoomServices {

	/*
	|-------------------------------------------------------------------------------
	| Add Room by data Floor ID
	|-------------------------------------------------------------------------------
	*/
	async addRoom (token,data){

		return await axios(token).post(`addRoom`, data);
	}

	/*
	|-------------------------------------------------------------------------------
	| Update Room
	|-------------------------------------------------------------------------------
	*/
	async updateRoom (token,data){

		return await axios(token).post(`updateRoom`, data);
	}

	/*
	|-------------------------------------------------------------------------------
	| Delete Room
	|-------------------------------------------------------------------------------
	*/
	async deleteRoom (token,data){

		return await axios(token).post(`deleteRoom`, data);
	}
}

export default new  RoomServices()