import AuthContext from "context";
import React, { useContext, useEffect, useState, useCallback } from "react";
import Power from "services/powerDistribution";
import _ from "lodash";

const fetchForSearchEntity = async ({
  setFetchingEntity,
  serviceFn,
  setPayload,
  setNextEntity,
  incrementStep,
  setHasMoreEntity,
}) => {
  setFetchingEntity(true);
  let totalCount = 0;

  try {
    const res = await serviceFn();
    const resultLength = res.data.data.length;
    if (resultLength > 0) {
      totalCount = res.data.data.length;
      setPayload(res.data.data);
      setNextEntity(Number(incrementStep));
      if (totalCount === res.data.totalRecords) {
        setHasMoreEntity(false);
      } else {
        setHasMoreEntity(true);
      }
    } else {
      setPayload([]);
      setHasMoreEntity(false);
    }
  } catch (err) {
    console.log(err);
  } finally {
    setFetchingEntity(false);
  }
};

const usePowerDistribution = ({
  setPayload,
  nextBreaker,
  setNextBreaker,
  setHasMoreBreaker,
  setFetchingBreaker,
  nextUPS,
  setNextUPS,
  setHasMoreUPS,
  setFetchingUPS,
  nextRPP,
  setNextRPP,
  setHasMoreRPP,
  setFetchingRPP,
  activeDataCenter,
  navigationActiveTab,
  pmi,
  payload,
}) => {
  const authContext = useContext(AuthContext);
  const incrementStep = parseInt(
    process.env.REACT_APP_POWER_DIST_PAGINATION_OFFSET,
    10
  );



  const [searchQuery, setSearchQuery] = useState("");
  const [hasChanged, setHasChanged] = useState(false);


  useEffect(() => {
    setSearchQuery("");
  }, [activeDataCenter, navigationActiveTab]);

  const filterData = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    debounceFn(value);
  };

  useEffect(() => {
    if (hasChanged) {
      try {
        if (navigationActiveTab === 2) {
          setPayload([]);
          fetchUPSForSearch();
        }
        if (navigationActiveTab === 3) {
          setPayload([]);
          fetchRPPForSearch();
        }
        if (navigationActiveTab === 4) {
          setPayload([]);
          fetchBreakerForSearch();
        }
        setHasChanged(false);
      } catch (e) {
        console.log("filterData error ", e);
      }
    }
  }, [hasChanged]);

  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  function handleDebounceFn() {
    setHasChanged(true);
  }

  const fetchBreakerForSearch = async () => {
    await fetchForSearchEntity({
      setFetchingEntity: setFetchingBreaker,
      serviceFn: async () => {
        return await Power.getBreakerByPmi({
          token: authContext.token(),
          dataCenterId: activeDataCenter,
          offset: 0,
          pmi,
          searchQuery,
        });
      },
      setPayload,
      setNextEntity: setNextBreaker,
      incrementStep,
      setHasMoreEntity: setHasMoreBreaker,
    });
  };

  const fetchUPSForSearch = async () => {
    await fetchForSearchEntity({
      setFetchingEntity: setFetchingUPS,
      serviceFn: async () => {
        return await Power.getUPSNew({
          token: authContext.token(),
          dataCenterId: activeDataCenter,
          offset: 0,
          searchQuery,
        });
      },
      setPayload,
      setNextEntity: setNextUPS,
      incrementStep,
      setHasMoreEntity: setHasMoreUPS,
    });
  };

  const fetchRPPForSearch = async () => {
    await fetchForSearchEntity({
      setFetchingEntity: setFetchingRPP,
      serviceFn: async () => {
        return await Power.getRPPNew({
          token: authContext.token(),
          dataCenterId: activeDataCenter,
          offset: 0,
          searchQuery,
        });
      },
      setPayload,
      setNextEntity: setNextRPP,
      incrementStep,
      setHasMoreEntity: setHasMoreRPP,
    });
  };

  const fetchBreaker = () => {
    setFetchingBreaker(true);
    let totalCount = payload.length;
    console.log("fetchBreaker");
    Power.getBreakerByPmi({
      token: authContext.token(),
      dataCenterId: activeDataCenter,
      offset: nextBreaker,
      pmi,
      // searchQuery,
    })
      .then((res) => {
        totalCount += res.data.data.length;
        setPayload([...payload, ...res.data.data]);
      //  setNextBreaker(Number(nextBreaker) + Number(incrementStep));

        if (totalCount === res.data.totalRecords) {
          setHasMoreBreaker(false);
        }
      })
      .catch((err) => {
        //500 server error
      })
      .finally(() => {
        setFetchingBreaker(false);
      });
  };

  const fetchUPS = () => {
    setFetchingUPS(true);
    let totalCount = payload.length;

    Power.getUPSNew({
      token: authContext.token(),
      dataCenterId: activeDataCenter,
      offset: nextUPS,
      searchQuery,
    })
      .then((res) => {
        totalCount += res.data.data.length;
        setPayload([...payload, ...res.data.data]);
        //setNextUPS(Number(nextUPS) + Number(incrementStep));
        if (totalCount === res.data.totalRecords) {
          setHasMoreUPS(false);
        } else {
          setHasMoreUPS(true);
        }
      })
      .catch((err) => {
        //500 server error
      })
      .finally(() => {
        setFetchingUPS(false);
      });
  };

  const fetchRPP = async () => {

    
    setFetchingRPP(true);
    let totalCount = payload.length;
    await Power.getRPPNew({
      token: authContext.token(),
      dataCenterId: activeDataCenter,
      offset: nextRPP,
      searchQuery,
    })
      .then((res) => {
        totalCount += res.data.data.length;
        setPayload([...payload, ...res.data.data]);
        //setNextRPP(Number(nextRPP) + Number(incrementStep));
        //console.log(Number(nextRPP) + Number(incrementStep))
        if (totalCount === res.data.totalRecords) {
          setHasMoreRPP(false);
        }
      })
      .catch((err) => {
        //500 server error
      })
      .finally(() => {
        setFetchingRPP(false);
      });
  };

  return {
    fetchBreaker,
    fetchUPS,
    fetchRPP,
    filterData,
    searchQuery,
  };
};

export default usePowerDistribution;
