import React, { useEffect, useRef, useState, useContext } from "react";
import StorageContext from "context";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import Modal from "react-modal";
import Pagination from "views/super-admin/Ticket/components/shared/usePagination";
import RoleModel from "services/roleServices";
import { LogoutOnSessionExpire } from "common/helpers";
import Select from "react-select";
import UserModal from "../../../../services/userServices";
const AssignedUserPopup = ({
  token,
  permission,
  retriveCurrentData,
  viewModal,
  setViewModal,
  data,
  setIsLoading,
  isLoading,
  roles,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const { t } = useTranslation();
  const contextStore = useContext(StorageContext);
  const [assignModal, setAssignModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      minHeight: "40%",
    },
  };
  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      overflow: "hidden",
    },
  };

  const customStylesForSelect = {
    control: (provided, state) => ({
      ...provided,
      // Change border color based on focus state
      border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change colors as needed
      "&:hover": {
        border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change hover color if needed
      },
      boxShadow: null,
      height: "3rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
      color: "#8392A5",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
      color: "#8392A5",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "18rem",
    }),
  };

  const closeModal = () => {
    setViewModal(false);
  };

  const closeReassignModal = () => {
    setAssignModal(false);
  };
  useEffect(() => {
    getAssignedUsers(data.id);
  }, []);

  const getAssignedUsers = async (roleId) => {
    let body = {
      page: pageNumber ? pageNumber : 1,
      pageSize: itemsPerPage,
      roleId: roleId,
    };
    setIsLoading(true);
    await RoleModel.getAssignedUser(contextStore.token(), body)
      .then((res) => {
        setAssignedUsers(
          res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1))
        );
        setTotal(res.data.total);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => setIsLoading(false));
  };
  const getStatus = (status) => {
    let stringStatus = "";

    switch (status) {
      case 0:
        stringStatus = (
          <span
            className="badge badge-warning badge-lg light"
            //style={{width:"50%"}}
          >
            Pending
          </span>
        );
        break;
      case 1:
        stringStatus = (
          <span
            className="badge badge-success badge-lg light"
            //style={{width:"50%"}}
          >
            Active
          </span>
        );
        break;
      case 2:
        stringStatus = (
          <span className="badge badge-danger badge-lg light">Inactive</span>
        );
        break;
      default:
        stringStatus = (
          <span
            className="badge badge-success badge-lg light"
            //style={{width:"50%"}}
          >
            Active
          </span>
        );
        break;
    }

    return stringStatus;
  };
  const handleReassign = (user) => {
    setSelectedRole(null);
    setSelectedUser(null);
    setAssignModal(true);
    setSelectedUser(user);
  };
  const formatOptions = (data) => {
    return data.map((item) => ({ value: item.id, label: item.name }));
  };
  const renderHtml = () => {
    return (
      assignedUsers &&
      assignedUsers.length > 0 &&
      assignedUsers.map((user) => {
        return (
          <tr key={user.uuid} className="setting-table">
            <td> {user.name} </td>
            <td> {user.email} </td>

            <td> {getStatus(user.status)} </td>
            <td>
              <span
                className="badge badge-primary badge-lg light"
                style={{
                  color: "black",
                  background: "#d3e7f8",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleReassign(user);
                }}
              >
                {t("rolepermission.reassign")}
              </span>
            </td>
          </tr>
        );
      })
    );
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const body = {
      roleId: selectedRole,
      userId: selectedUser.uuid,
    };

    await RoleModel.reassignRole(contextStore.token(), body)
      .then((res) => {
        getAssignedUsers(data.id);
        Swal.fire(res.data.message);
        setAssignModal(false);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div>
      <Modal isOpen={viewModal} style={customStyles} ariaHideApp={false}>
        {/* <div className="modal-dialog modal-lg">
		        <div className="modal-content">*/}
        <div className="modal-header mt-59">
          <h3 className="modal-title"> {t("rolepermission.assigned_users")}</h3>
          <CloseIcon
            type="button"
            onClick={() => closeModal()}
            data-bs-dismiss="modal"
          />
        </div>
        <div className="modal-body">
          <Modal isOpen={assignModal} style={customStyles2} ariaHideApp={false}>
            {/* <div className="modal-dialog modal-lg">
		        <div className="modal-content">*/}
            <div className="modal-header mt-59">
              <h3 className="modal-title mt-2">
                {" "}
                {t("rolepermission.reassign_role")}
              </h3>
              <CloseIcon
                type="button"
                onClick={() => closeReassignModal()}
                data-bs-dismiss="modal"
              />
            </div>
            <div className="modal-body">
              <div className="card">
                <div
                  className="card-body mt-5"
                  style={{ padding: "0px", overflow: "hidden" }}
                >
                  {assignedUsers && assignedUsers.length == 0 && !isLoading && (
                    <div className="d-flex justify-content-center p-5">
                      <h3 className="modal-title">
                        {" "}
                        {t("rolepermission.no_assigned_users")}
                      </h3>
                    </div>
                  )}

                  {/* <Select
                    styles={customStylesForSelect}
                    options={formatOptions(roles)}
                    value={formatOptions(roles).filter(
                      (el) => el.value === selectedRole
                    )}
                    onChange={(option) => {
                      setSelectedRole(option.value);
                    }}
                    placeholder={`${t("userm.choose")}...`}
                    menuPlacement="auto"
                  /> */}
                  <div className="mb-3 col-md-12 mt-2313">
                    <label className="form-label"> {t("userm.role")}</label>
                    <select
                      id="inputState"
                      value={selectedRole}
                      onChange={(event) => setSelectedRole(event.target.value)}
                      className="nice-select  default- form-control wide"
                    >
                      <option>{t("userm.choose")}...</option>
                      {roles &&
                        roles.map((role) => {
                          return (
                            <option value={role.id} key={role.id}>
                              {role.name}{" "}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div
                    className="toolbar toolbar-bottom d-flex justify-content-end"
                    role="toolbar"
                    style={{ textAlign: "right", marginTop: "4rem" }}
                  >
                    <button
                      type="button"
                      onClick={() => closeReassignModal()}
                      style={{ marginRight: "1rem", marginBottom: "0.25rem" }}
                      className="btn btn-outline-primary mr_1"
                    >
                      {" "}
                      {t("common.cancel")}{" "}
                    </button>

                    <button
                      type="button"
                      onClick={() => onSubmit()}
                      style={{
                        marginBottom: "0.25rem",
                        borderRadius: "0.25rem",
                      }}
                      className="btn btn-primary mr_1"
                      disabled={selectedRole == null}
                    >
                      {" "}
                      {t("common.save")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/*</div>
		    </div>*/}
          </Modal>
          <div className="card">
            <div
              className="card-body mt-5"
              style={{ padding: "0px", overflow: "hidden" }}
            >
              <div className="table-responsive">
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th>
                        <strong> {t("userm.name")} </strong>
                      </th>
                      <th>
                        <strong> {t("userm.username_email")} </strong>
                      </th>
                      <th>
                        <strong> {t("userm.status")} </strong>
                      </th>
                      <th>
                        <strong> {t("userm.actions")} </strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderHtml()}</tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mt-4">
                {assignedUsers && assignedUsers.length > 0 && (
                  <Pagination
                    setItemsPerPage={setItemsPerPage}
                    setPageNumber={setPageNumber}
                    total={total}
                    pageNumber={pageNumber}
                  ></Pagination>
                )}
              </div>

              {assignedUsers && assignedUsers.length == 0 && !isLoading && (
                <div className="d-flex justify-content-center pt-4">
                  <h3 className="modal-title">
                    {" "}
                    {t("rolepermission.no_assigned_users")}
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>

        {/*</div>
		    </div>*/}
      </Modal>
    </div>
  );
};
export default AssignedUserPopup;
