const isEmpty = (field) => {
  if (field === '' || field === null || field === undefined) {
    return true;
  }
  return false;
};

const checkValidation = ({ state, setError}) => {
  let error = {
    installedbase_id: '',
    cc_media_type_id: '',
    accounts_id: '',
    a_side_space_id: '',
    z_side_space_id: '',
    status: '',
    pmi: '',
    desc: '',
  };

  const {
    installedbase_id,
    cc_media_type_id,
    accounts_id,
    a_side_space_id,
    z_side_space_id,
    status,
    pmi,
    desc,
  } = state;

  let flag = true;

  if (isEmpty(installedbase_id)) {
    error.installedbase_id = 'The Installbase ID field is required.';
    flag = false;
  }

  if (isEmpty(accounts_id)) {
    error.accounts_id = 'The Customer field is required.';
    flag = false;
  }

  if (isEmpty(cc_media_type_id)) {
    error.cc_media_type_id = 'The Media Type field is required.';
    flag = false;
  }
  if (isEmpty(a_side_space_id)) {
    error.a_side_space_id = 'The A-side Cabinet ID field is required.';
    flag = false;
  }
  if (isEmpty(z_side_space_id)) {
    error.z_side_space_id = 'The Z-side Cabinet ID field is required.';
    flag = false;
  }
  if (isEmpty(pmi)) {
    error.pmi = 'The PMI field is required.';
    flag = false;
  }
  if (isEmpty(status)) {
    error.status = 'The Status field is required.';
    flag = false;
  }
  if (desc.length > 500) {
    error.desc = 'The Description field should be less than 500 characters.';
    flag = false;
  }
  setError({ ...error });
  return flag;
};

export default {
  isEmpty,
  checkValidation
}