import { XError } from "components/common"
import AuthContext from "context"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import Common from "services/commonService"
import Power from "services/powerDistribution"
import Swal from "sweetalert2"
import { LogoutOnSessionExpire } from "common/helpers"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
const CreateUPS = (props) => {
  const [status, setStatus] = useState([])
  const authContext = useContext(AuthContext)
  const [roomList, setRoomList] = useState([])
  const [transformers, setTransformer] = useState([])
  const [cabinetList, setCabinetList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const [state, setState] = useState({
    name: "",
    desc: "",
    make_id: "",
    model_id: "",
    sn: "",
    transformer_id: "",
    kW: "",
    data_center_id:
      props.activeTab !== undefined
        ? props.activeTab
        : authContext.getNetworkDataCenter?.id,
    room_id: "",
    floor_id: "",
    cabinet_id: "",
    status: "",
    support_status: "",
    support_expiry: "",
  })
  const [error, setError] = useState({
    name: "",
    desc: "",
    make_id: "",
    model_id: "",
    sn: "",
    transformer_id: "",
    kW: "",
    data_center_id:
      props.activeTab !== undefined
        ? props.activeTab
        : authContext.getNetworkDataCenter?.id,
    room_id: "",
    floor_id: "",
    cabinet_id: "",
    status: "",
    support_status: "",
    support_expiry: "",
  })

  const closeModal = () => {
    props.setModalState({
      UPSCreateIsON: false,
      UPSUpdateIsON: false,
      RRPCreateIsON: false,
      RRPUpdateIsON: false,
      BreakerCreateIsON: false,
      BreakerUpdateIsON: false,
    })
    setState({
      name: "",
      desc: "",
      make_id: "",
      model_id: "",
      sn: "",
      transformer_id: "",
      kW: "",
      data_center_id: "",
      room_id: "",
      floor_id: "",
      cabinet_id: "",
      status: "",
      support_status: "",
      support_expiry: "",
    })
  }

  useEffect(() => {
    if (authContext.getStatusData) {
      setStatus(authContext.getStatusData.filter((s) => s.status_type_id === 6))
    }
    // Common.status().then((res) =>
    //   setStatus(res.data.data.filter((s) => s.status_type_id === 6))
    // )
    let activeTab =
      props.activeTab !== undefined
        ? props.activeTab
        : authContext.getNetworkDataCenter?.id
    Power.transformer(authContext.token(), activeTab)
      .then((res) => setTransformer(res.data.data))
      .catch((err) => {
        LogoutOnSessionExpire(err)
        // 500 error
      })
  }, [])

  const onChangeFloor = (floor_id) => {
    setState({ ...state, floor_id: floor_id, room_id: "", cabinet: "" })
    props.getFloorData()

    const floor = props.floorsData.filter(
      (floor) => floor.id === parseInt(floor_id)
    )

    if (floor.length) {
      setRoomList(floor[0].rooms.filter((d) => d.room_type_id === 7))
    }
  }
  const onChangeRoom = (room_id) => {
    setState({ ...state, room_id: room_id, cabinet: "" })
    props.getFloorData()
    const floor = props.floorsData.filter(
      (floor) => floor.id === parseInt(state.floor_id)
    )
    console.log(room_id)
    console.log(floor[0].rooms)
    if (floor.length) {
      const room = floor[0].rooms.filter(
        (room) => room.id === parseInt(room_id)
      )
      console.log(room)
      if (room.length) {
        setCabinetList(room[0].cabinets)
      }
    }
  }

  const onSave = () => {
    setIsLoading(true)

    Power.createUPS(authContext.token(), state)
      .then((res) => {
        closeModal()
        Swal.fire({ icon: "success", text: t("pwrdist.new_ups_created") })
        setIsLoading(false)

        props.setNeedToReload(!props.needToReload)
      })
      .catch((err) => {
        LogoutOnSessionExpire(err)

        setIsLoading(false)
        let error = {
          name: "",
          status: "",
          support_status: "",
        }

        const errors = err?.response?.data?.errors

        if (
          errors?.name !== undefined ||
          errors?.name !== "" ||
          errors?.name !== null
        ) {
          error.name = errors.name
        }

        if (
          errors?.status !== undefined ||
          errors?.status !== "" ||
          errors?.status !== null
        ) {
          error.status = errors.status
        }
        if (
          errors?.support_status !== undefined ||
          errors?.support_status !== "" ||
          errors?.support_status !== null
        ) {
          error.support_status = errors.support_status
        }

        setError({ ...error })
      })
  }

  return (
    <div
      className="modal show bd-example-modal-lg"
      style={{ display: "block" }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t("pwrdist.add_device_details")} </h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              onClick={closeModal}
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.hostname")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <input
                          type="text"
                          value={state.name}
                          onChange={(event) =>
                            setState({ ...state, name: event.target.value })
                          }
                          className="form-control"
                          placeholder={t("pwrdist.hostname")}
                        />
                        <XError message={error.name} />
                      </div>
                      <div className="col-md-6 mt-2313"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.serial_number")}
                        </label>
                        <input
                          type="text"
                          value={state.sn}
                          onChange={(event) =>
                            setState({ ...state, sn: event.target.value })
                          }
                          className="form-control"
                          placeholder={t("pwrdist.serial_number")}
                        />
                        <XError message={error.sn} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.status")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          value={state.status}
                          onChange={(event) =>
                            setState({ ...state, status: event.target.value })
                          }
                          className="default-select form-control wide"
                        >
                          <option>{t("pwrdist.choose")}...</option>
                          {status &&
                            status.map((s) => {
                              return (
                                <option value={s.id} key={s.id}>
                                  {s.status_name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.status} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.floor")}
                        </label>
                        <select
                          value={state.floor_id}
                          onChange={(event) =>
                            onChangeFloor(event.target.value)
                          }
                          className="default-select form-control wide"
                        >
                          <option value="">{t("pwrdist.floor")}</option>
                          {props.floorsData &&
                            props.floorsData.map((list, index) => {
                              if (
                                list.data_center_id ===
                                (props.activeTab !== undefined
                                  ? props.activeTab
                                  : authContext.getNetworkDataCenter?.id)
                              ) {
                                return (
                                  <option value={list.id} key={index}>
                                    {list.name}
                                  </option>
                                )
                              }
                            })}
                        </select>
                        <XError message={error.floor} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.room")}{" "}
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.room_id}
                          onChange={(event) => onChangeRoom(event.target.value)}
                        >
                          <option value="">{t("pwrdist.room")}</option>
                          {roomList &&
                            roomList.map((list, index) => {
                              return (
                                <option value={list.id} key={index}>
                                  {list.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.room} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.cabinet_id")}
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.cabinet_id}
                          onChange={(event) =>
                            setState({
                              ...state,
                              cabinet_id: event.target.value,
                            })
                          }
                        >
                          <option value="">{t("pwrdist.cabinet_rack")}</option>
                          {cabinetList &&
                            cabinetList.map((list, index) => {
                              return (
                                <option value={list.id} key={index}>
                                  {list.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.cabinet_id} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.transformer_id")}{" "}
                        </label>
                        <select
                          value={state.transformer_id}
                          onChange={(event) =>
                            setState({
                              ...state,
                              transformer_id: event.target.value,
                            })
                          }
                          className="default-select form-control wide"
                        >
                          <option>{t("pwrdist.select_transformer")}</option>
                          {transformers &&
                            transformers.map((list, index) => {
                              return (
                                <option value={list.id} key={index}>
                                  {list.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.transformer_id} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mt-2313">
                        <p className="blue_txt_de">
                          {t("pwrdist.maintenance_and_support")}
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.status")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          value={state.support_status}
                          onChange={(event) =>
                            setState({
                              ...state,
                              support_status: event.target.value,
                            })
                          }
                          className="default-select form-control wide"
                        >
                          <option>{t("pwrdist.choose")}...</option>
                          <option value="1">Active</option>
                          <option value="2">Inactive</option>
                        </select>
                        <XError message={error.support_status} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.expiry_date")}
                        </label>
                        <input
                          value={state.support_expiry}
                          onChange={(event) =>
                            setState({
                              ...state,
                              support_expiry: event.target.value,
                            })
                          }
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          type="date"
                          className="form-control"
                        />
                        <XError message={error.support_expiry} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                role="toolbar"
              >
                <button
                  type="button"
                  style={{ marginRight: 15 }}
                  onClick={closeModal}
                  className="btn btn-outline-primary mr_1"
                >
                  {" "}
                  {t("common.cancel")}{" "}
                </button>

                {isLoading ? (
                  <button type="button" className="btn btn-primary">
                    {t("common.loading")} ...
                  </button>
                ) : (
                  <button
                    onClick={() => onSave()}
                    type="button"
                    className="btn btn-primary"
                  >
                    {t("common.save")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateUPS
