import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class PowerDistribution {
  async createUPS(token, data) {
    return await axios(token).post(`createUpps`, data);
  }

  async updateUPS(token, data) {
    return await axios(token).post(`updateUpss`, data);
  }

  async deleteUPS(token, data) {
    return await axios(token).post(`deleteUpss`, data);
  }

  async createRPP(token, data) {
    return await axios(token).post(`createRpps`, data);
  }

  async updateRPP(token, data) {
    return await axios(token).post(`updateRpps`, data);
  }

  async deleteRPP(token, data) {
    return await axios(token).post(`deleteRpps`, data);
  }

  async createBreaker(token, data) {
    return await axios(token).post(`createBreaker`, data);
  }

  async updateBreaker(token, data) {
    return await axios(token).post(`updateBreakers`, data);
  }

  async deleteBreaker(token, data) {
    return await axios(token).post(`deleteBreaker`, data);
  }

  async overview(token, dataCenterId) {
    return await axios(token).get(`getOverview?data_center_id=${dataCenterId}`);
  }
  async getGenerator(token, dataCenterId, offset = 0) {
    return await axios(token).get(
      `getGenerator?data_center_id=${dataCenterId}&offset=${offset}`
    );
  }
  async transformer(token, dataCenterId, offset = 0) {
    return await axios(token).get(
      `getTransformers?data_center_id=${dataCenterId}&offset=${offset}`
    );
  }
  async getUPS(token, dataCenterId, offset = 0) {
    return await axios(token).get(
      `getUpss?data_center_id=${dataCenterId}&offset=${offset}`
    );
  }
  async getUPSNew({ token, dataCenterId, offset, searchQuery }) {
    let query = `getUpss?data_center_id=${dataCenterId}&offset=${offset || 0}`;
    if (searchQuery) {
      query += `&search=${searchQuery}`;
    }
    return await axios(token).get(query);
  }
  async getRPP(token, dataCenterId, offset = 0) {
    return await axios(token).get(
      `getRpps?data_center_id=${dataCenterId}&offset=${offset}`
    );
  }

  async getRPPNew({ token, dataCenterId, offset, searchQuery }) {
    let query = `getRpps?data_center_id=${dataCenterId}&offset=${offset || 0}`;
    if (searchQuery) {
      query += `&search=${searchQuery}`;
    }
    return await axios(token).get(query);
  }

  async getBreaker(token, dataCenterId, offset = 0) {
    return await axios(token).get(
      `getBreakers?data_center_id=${dataCenterId}&offset=${offset}`
    );
  }
  async getBreakerByPmi({ token, pmi, dataCenterId, offset, searchQuery }) {
    let query = `getBreakers?data_center_id=${dataCenterId}&pmi=${pmi}&offset=${
      offset || 0
    }`;
    if (searchQuery) {
      query += `&search=${searchQuery}`;
    }
    return await axios(token).get(query);
  }
  async getBreakerByRoomId(token, roomId, search, offset) {
    let query = `getBreakers?room_id=${roomId}`;
    if (search) {
      query += `&search=${search}`;
    }
    if (offset) {
      query += `&offset=${offset}`;
    }
    return await axios(token).get(query);
  }
  async getSvcCabinets(token, dataCenterId, floorId, roomId) {
    return await axios(token).get(
      `getSvcCabinets?data_center_id=${dataCenterId}&floor_id=${floorId}&room_id=${roomId}`
    );
  }

  async getBreakerByRoomIdws(token, roomId, offset) {
    let query = `getBreakers?room_id=${roomId}`;
    if (offset) {
      query += `&offset=${offset}`;
    }
    return await axios(token).get(query);
  }

  async getBreakerList(token, body) {
    return await axios(token).post(`getBreakersList`, body);
  }
}

export default new PowerDistribution();
