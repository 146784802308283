import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class CabinetServices {
  /*
	|-------------------------------------------------------------------------------
	| 	Get all Cabinets
	|-------------------------------------------------------------------------------
	*/
  async getAllCabinets(token) {
    return await axios(token).get(`getCabinet`);
  }

  /*
	|-------------------------------------------------------------------------------
	| 	Cabinets
	|-------------------------------------------------------------------------------
	*/
  async selectByHallId(token, data) {
    return await axios(token).post(`Cabinets`, data);
  }

  /*
	|-------------------------------------------------------------------------------
	| 	Cabinets
	|-------------------------------------------------------------------------------
	*/
  async searchCabinet(token, data) {
    return await axios(token).post(`getCabinetsList`, data);
  }

  /*
	|-------------------------------------------------------------------------------
	| 	Update Cabinets
	|-------------------------------------------------------------------------------
	*/
  async updateCabinets(token, data) {
    return await axios(token).post(`updateCabinets`, data);
  }

  /*
	|-------------------------------------------------------------------------------
	| 	Get all Cabinets according to data center ID
	|-------------------------------------------------------------------------------
	*/
  async getAllCabinetsDataCenter(token, data_center_id) {
    return await axios(token).get(
      `getCabinet?data_center_id=` + data_center_id
    );
  }

  /*
	|-------------------------------------------------------------------------------
	| 	Get all Cabinets according to room ID
	|-------------------------------------------------------------------------------
	*/
  async getAllCabinetsRoomID(token, room_id) {
    return await axios(token).get(`getCabinet?room_id=` + room_id);
  }

  async getChartData(params) {
    return await axios().get(`getCabinetChartData`, { params });
  }

  async getSensorData(params) {
    return await axios().get(`getHeatMapHistorical`, { params });
  }

  async getCabinetInfo(room_name, table) {
    return await axios().get(
      `getCabinetInfo?room_name=` + room_name + `&table=` + table
    );
  }
}

export default new CabinetServices();
