import React, { useEffect } from 'react';
import Pagination from 'views/super-admin/Ticket/components/shared/usePagination';
const Table = (props) => {
  useEffect(() => {}, [props]);
  return (
    <React.Fragment>
      <div className="card-body" style={{ overflow: 'auto' }}>
        <div className="table-responsive tckect_tt">
          <table className="table header-border table-borderless table-hover verticle-middle">
            <thead>
              <tr>
                {props.columns &&
                  props.columns.map((column, i) => {
                    return (
                      <th scope="col" style={{ cursor: 'pointer' }}>
                        {column.value}
                        {/* <i
                          className={`fa fa-solid fa-sort-${true ? 'down' : 'up'}`}
                          style={{ fontSize: '1.3rem', paddingLeft: '6px' }}
                        ></i> */}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {' '}
              {props.data &&
                props.data.map((data, i) => {
                  return (
                    <tr
                      onClick={() => {
                        props.setIsLoading(true);
                        props.refreshData(data.id);
                        props.setCurrentServiceId(props.currentServiceId);
                      }}
                    >
                      {props.columns &&
                        props.columns.map((column, i) => {
                          return (
                            <td
                              className="pd-l bold-txt cus-search"
                              key={i}
                              style={{ fontWeight: 500, cursor: 'pointer' }}
                            >
                              {data[column.name] ? data[column.name] : 'N/A'}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* <div className="d-flex justify-content-end mt-3">
          {props.data.rows && props.data.rows.length && (
            <Pagination
              setItemsPerPage={props.setItemsPerPage}
              setPageNumber={props.setPageNumber}
              total={props.total}
              pageNumber={props.pageNumber}
            ></Pagination>
          )}
        </div> */}
      </div>
    </React.Fragment>
  );
};
export default Table;
