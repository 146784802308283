import {axiosWithToken as axios} from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';


class DataCenterChart {

	async index(token,dataCenterId){
		
		return await axios(token).get(`getDataCenterChart?data_center_id=${dataCenterId}`);
	}
	
	async store(token,data){
		
		return await axios(token).post(`storeDataCenterChart`,data);
	}
	async destroy(token,data){
		
		return await axios(token).post('deleteDataCenter',data);
	}

	async update(token, data) {
		return await axios(token).post('updateDataCenter',data);
	}

	async getDCPowerSummary(token){
		
		return await axios(token).get(`getDCPowerSummary`);
	}

}

export default new DataCenterChart();
