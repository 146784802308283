import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class DataCenterPerformance {
  async index(token, data) {
    return await axios(token).post(`DataCenterPerformance`, data);
  }

  async updateOrCreate(token, data) {
    return await axios(token).post(`UpdateDataCenterPerformance`, data);
  }

  async uploadPerformanceReport(token, data) {
    return await axios(token).post(`uploadPerformanceReport`, data);
  }

  async updateOrCreatePue(token, data) {
    return await axios(token).post(`addAndUpdatePue`, data);
  }
}

export default new DataCenterPerformance();
