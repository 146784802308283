import AuthContext from "context";
import React, { useContext, useState, useEffect, useRef } from "react";
import Checkbox from "components/common/Checkbox";
import AddEntity from "components/common/AddEntilty";
import CrossConnectService from "services/crossConnectService";
import AddCrossConnect from "../AddCrossConnect";
import UpdateCrossConnect from "../UpdateCrossConnect";
import { useTranslation } from "react-i18next";
import provisioningService from "services/provisioningService";
import Table from "./table";
import SerachBox from "views/super-admin/Accounts/searchBox";
const CrossConnectTable = ({
  selectedDataCenter,
  setIsLoading,
  isReadOnly,
}) => {
  const authContext = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isValueChange, setIsValueChange] = useState(false);
  const [objectForEdit, setObjectForEdit] = useState(null);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const columns = [
    {
      name: "name",
      value: t(`crossconnect.installedBased`),
    },
    {
      name: "customer",
      value: t(`crossconnect.customer`),
    },
    {
      name: "product_attribute",
      value: t(`crossconnect.media_type`),
    },
    {
      name: "a_side_service_id",
      value: t(`crossconnect.a-side-cab`),
    },
    {
      name: "a_side_patchpanel_id",
      value: t(`crossconnect.a_side_panel`),
    },
    {
      name: "a_side_patchpanel_port_id",
      value: t(`crossconnect.a_side_panel_port`),
    },
    {
      name: "z_side_service_id",
      value: t(`crossconnect.z-side-cab`),
    },
    {
      name: "z_side_patchpanel_id",
      value: t(`crossconnect.z_side_panel`),
    },
    {
      name: "z_side_patchpanel_port_id",
      value: t(`crossconnect.z_side_panel_port`),
    },
    {
      name: "task_type",
      value: t(`crossconnect.status`),
    },
  ];
  const searchCategory = [
    {
      field: "name",
      name: t(`crossconnect.installedBased`),
    },
    {
      field: "customer",
      name: t(`crossconnect.customer`),
    },
    {
      field: "product_attribute",
      name: t(`crossconnect.media_type`),
    },
    {
      field: "a_side_service_id",
      name: t(`crossconnect.a-side-cab`),
    },
    {
      field: "a_side_patchpanel_id",
      name: t(`crossconnect.a_side_panel`),
    },
    {
      field: "a_side_patchpanel_port_id",
      name: t(`crossconnect.a_side_panel_port`),
    },
    {
      field: "z_side_service_id",
      name: t(`crossconnect.z-side-cab`),
    },
    {
      field: "z_side_patchpanel_id",
      name: t(`crossconnect.z_side_panel`),
    },
    {
      field: "z_side_patchpanel_port_id",
      name: t(`crossconnect.z_side_panel_port`),
    },
    {
      field: "task_type",
      name: t(`crossconnect.status`),
    },
  ];

  useEffect(() => {
    setData([]);
    getCrossConnects();
  }, [
    selectedDataCenter,
    isValueChange,
    pageNumber,
    query,
    sort,
    sortDirection,
    authContext.selectedCountry.name,
  ]);

  const getCrossConnects = async (first = true) => {
    setIsLoading(true);
    try {
      const country = authContext.selectedCountry.name;
      const filters = [
        {
          name: "country",
          value: country,
        },

        {
          name: "status",
          value: "is_open",
        },
        { name: "category", value: "installed_base" },
        { name: "product_type", value: "CCX%.RC" },
      ];
      if (selectedDataCenter && selectedDataCenter.name != "ALL") {
        filters.push({
          name: "site",
          value: selectedDataCenter?.name,
        });
      }
      if (query) {
        filters.push(query);
      }
      const body = {
        page: pageNumber,
        sort: sort,
        sortDir: sortDirection,
        type: "ccx",
        filters: filters,
      };
      body.filters = body.filters.filter((el) => el.value != undefined);
      await provisioningService
        .getServices(localStorage.getItem("token"), body)
        .then((res) => {
          if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
            setIsLoading(false);
          } else {
            setData(res.data.data.rows);
            setTotal(res.data.data.total);
          }
        })
        .catch((err) => {
          console.log(err);
          //500 error page
        })
        .finally(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("getCrossConnects error ", e);
    }
  };

  const openEditModal = (item) => {
    setObjectForEdit(item);
    setShowUpdateModal(true);
  };

  return (
    <div className="row" style={{ backgroundColor: "white" }}>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-10">
            <h5 className="card-title col-md-6 mb-0">
              {t("crossconnect.cross_connect")} {selectedDataCenter && "for"}{" "}
              {selectedDataCenter.name}
            </h5>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div
              className="grid_card grid_mr h-100 tckt"
              style={{ boxShadow: "none" }}
            >
              <div className="card_head d-flex justify-content-between">
                <SerachBox
                  searchCategory={searchCategory}
                  query={query}
                  setQuery={setQuery}
                  setIsLoading={setIsLoading}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                ></SerachBox>
                <div>
                  {isReadOnly && (
                    <AddEntity
                      label={"Add Cross-connect"}
                      onClick={() => {
                        setShowAddModal(true);
                      }}
                    />
                  )}
                  {showAddModal === true ? (
                    <AddCrossConnect
                      activeDataCenter={selectedDataCenter.id}
                      countryId={selectedDataCenter.country_id}
                      setIsValueChange={setIsValueChange}
                      isValueChange={isValueChange}
                      setShowAddModal={setShowAddModal}
                    />
                  ) : null}
                </div>
              </div>

              <Table
                columns={columns}
                data={data}
                setItemsPerPage={setItemsPerPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                total={total}
                sort={sort}
                setSort={setSort}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
              ></Table>
              {showUpdateModal && objectForEdit && (
                <UpdateCrossConnect
                  editData={objectForEdit}
                  activeDataCenter={selectedDataCenter.id}
                  countryId={selectedDataCenter.country_id}
                  setIsValueChange={setIsValueChange}
                  isValueChange={isValueChange}
                  setShowUpdateModal={setShowUpdateModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrossConnectTable;

const ColumnWithSorting = ({ label, field, toggle, ascending }) => {
  const { t } = useTranslation();
  return (
    <th
      onClick={() => {
        toggle(field);
      }}
      style={{ cursor: "pointer" }}
    >
      <strong> {t(`crossconnect.${label}`)} </strong>
      <i
        className={`fa fa-solid fa-sort-${
          ascending[field] || ascending[field] === undefined ? "up" : "down"
        }`}
      ></i>
    </th>
  );
};
