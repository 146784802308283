import React from "react";
import Header from "./header";
import DataCenterNav from "./Data Center Nav";

const dashboardLayout = (props) => {
  return (
    <React.Fragment>
      {props?.page == "data-center" ? (
        <DataCenterNav
          getFloorData={props.getFloorData}
          setSelectedDataCenter={props.setSelectedDataCenter}
          setSelectedCountry={props.setSelectedCountry}
          selectedDataCenter={props.selectedDataCenter}
          selectedCountry={props.selectedCountry}
        ></DataCenterNav>
      ) : (
        <Header
          selectDataCenter={props.selectDataCenter}
          selectedDataCenter={props.selectedDataCenter}
          getFloorData={props.getFloorData}
          setIsSelectedGroup={props.setIsSelectedGroup}
          isSelectedGroup={props.isSelectedGroup}
          setDataCenterList={props.setDataCenterList}
          setSelectedDataCenterForTicket={props.setSelectedDataCenterForTicket}
          setSelectedCountryForTicket={props.setSelectedCountryForTicket}
          setSelectedDataCenterForProvisioning={
            props.setSelectedDataCenterForProvisioning
          }
          setSelectedCountryForProvisioning={
            props.setSelectedCountryForProvisioning
          }
          setSelectedDataCenterForDatacenter={
            props.setSelectedDataCenterForDatacenter
          }
          setSelectedCountryForDatacenter={
            props.setSelectedCountryForDatacenter
          }
          setCountriesForTickets={props.setCountriesForTickets}
        ></Header>
      )}

      {props.children}
    </React.Fragment>
  );
};

export default dashboardLayout;
