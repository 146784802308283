const cardData = [
		{
			title: 'Incidents',
			graph: [],
			totalNumber:0,
			className:'',
			ticketno:1,
			types:[
				{
					title:"P0"
				},
				{
					title:"P1"
				},
				{
					title:"P2"
				},
				{
					title:"P3"
				},
				{
					title:"I"
				},
				{
					title:"E"
				}
			]
		},
		{
			title: 'Faults',
			graph: [],
			className:'',
			totalNumber:0,
			ticketno:2,
			types:[
				{
					title:"S-1"
				},
				{
					title:"S-2"
				},
				{
					title:"S-3"
				},
				{
					title:"S-4"
				}
			]
		},
		{
			title: 'Site Visits',
			graph: [],
			className:'',
			totalNumber:0,
			ticketno:4,
			types:[
				{
					title:"S-1"
				},
				{
					title:"S-2"
				},
				{
					title:"S-3"
				},
				{
					title:"S-4"
				}
			]
		},
		{
			title: 'Shipments',
			graph: [],
			className:'',
			totalNumber:0,
			ticketno:3,
			types:[
				{
					title:"S-1"
				},
				{
					title:"S-2"
				},
				{
					title:"S-3"
				},
				{
					title:"S-4"
				}
			]
		}, 
		{
			title: 'Remote Hands',
			graph: [],
			className:'',
			totalNumber:0,
			ticketno:5,
			types:[
				{
					title:"S-1"
				},
				{
					title:"S-2"
				},
				{
					title:"S-3"
				},
				{
					title:"S-4"
				}
			]
		}
		
	];
export {
	cardData
}