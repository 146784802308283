import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function NetworkNavigation() {
    const location = useLocation();
	const { t } = useTranslation();
	const activeClass = (route) => {

		if (location.pathname === route) {
			return "plink active";
		}

		return "plink";
	}
  return (
    <>
				<p>
					<Link
					className={activeClass('/setting-network')}
					to="/setting-network" >{t("network.inventory")} </Link>
				</p>
				<p>
					<Link
					className="plink"
					className={activeClass('/setting-rackelevation')}
					to="/setting-rackelevation" >{t("network.rack_elevation")} </Link>
				</p>
                <p>
					<Link
					className="plink"
					className={activeClass('/setting-topology')}
					to="/setting-topology" >{t("network.topology")} </Link>
				</p>
                <p>
					<Link
					className="plink"
					className={activeClass('/setting-fibermap')}
					to="/setting-fibermap" > {t("network.fiber_map")} </Link>
				</p>

		</>
  )
}
