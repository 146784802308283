import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import OddoTest from "views/OddoTest";
import Profile from "views/profile";
import Dashboard from "views/super-admin/Dashboard";
import ESG from "views/super-admin/ESG";
import Cabling from "views/super-admin/Setting/cabling/cabling";
import PatchPanel from "views/super-admin/Setting/cabling/patchPanel";
import Room from "views/super-admin/Setting/cabling/room";
import DataCenter from "views/super-admin/Setting/DataCenter";
import Capacity from "views/super-admin/Setting/DataCenter/capacity";
import PowerDistribution from "views/super-admin/Setting/DataCenter/PowerDistribution";
import Fibermap from "views/super-admin/Setting/Network/fibermap";
import Op from "views/super-admin/Setting/Network/Op";
import Rackelevation from "views/super-admin/Setting/Network/rackelevation";
import Topology from "views/super-admin/Setting/Network/topology";
import Reports from "views/super-admin/Setting/Reports";
import RoleAndPermission from "views/super-admin/Setting/RoleAndPermission";
import User from "views/super-admin/Setting/User";
import StateChart from "views/super-admin/stateChart";
import Test from "views/super-admin/test";
import Ticket from "views/super-admin/Ticket";
import Provisioning from "views/super-admin/Provisioning";
import Widgets from "views/super-admin/Tickets/Widgets";
import CapacityLayout from "views/super-admin/Setting/DataCenter/capacityLayout";
import CrossConnect from "views/super-admin/Setting/DataCenter/CrossConnect";
import StorageContext from "context";
import LandingPage from "common/landingPage";
import Account from "views/super-admin/Accounts";
import Contacts from "views/super-admin/Contacts";
import BCM from "views/super-admin/Setting/DataCenter/BCM";
import Home from "views/super-admin/Home";
import Maintenence from "views/super-admin/Setting/Component/Maintenence";
import { ticketType } from "views/super-admin/Ticket/components/shared/data";

const AuthenticatedRoutes = ({ rolePermission }) => {
  const contextData = React.useContext(StorageContext);
  const TenantId = process.env.REACT_APP_TENANT_ID;
  const domain = window.location.origin;
  let url = `https://login.microsoftonline.com/${TenantId}/oauth2/logout?post_logout_redirect_uri=${domain}`;
  const checkPermission = () => {
    const status = contextData.getMaintenanceModeStatus;
    if (status && status?.isMaintenanceModeOn) {
      if (rolePermission?.role?.name == "Super Admin") {
        return "/setting-maintenance-boss";
      } else {
        localStorage.clear();
        window.location.pathname = url;
      }
    }
    const permission = rolePermission.role;
    if (permission.dcs != 0) {
      return "/data-center";
    } else if (permission.tickets != 1) {
      return "/ticket";
    } else if (permission.provisioning != 1) {
      return "/provisioning";
    } else if (permission.esg != 0) {
      return "/esg";
    } else {
      return "/landing-page";
    }
  };
  const getMaintenaceModeStatus = () => {
    const status = contextData.getMaintenanceModeStatus;
    if (status && status?.isMaintenanceModeOn) {
      return true;
    } else {
      return false;
    }
  };

  const checkTicketAndProvisioningPermission = (type) => {
    if (type == "ticket") {
      if (
        rolePermission?.role?.role_ticket &&
        rolePermission?.role?.role_ticket.length > 0
      ) {
        return rolePermission?.role?.role_ticket.some(
          (item) => item.is_allowed === 1
        );
      } else {
        return false;
      }
    }
    if (type == "provisioning") {
      if (
        rolePermission?.role?.role_provisioning &&
        rolePermission?.role?.role_provisioning.length > 0
      ) {
        return rolePermission?.role?.role_provisioning.some(
          (item) => item.is_allowed === 1
        );
      } else {
        return false;
      }
    }
  };
  return (
    <React.Fragment>
      {rolePermission.role && (
        <Routes>
          {!getMaintenaceModeStatus() && (
            <Route path="/home" element={<Home />} />
          )}
          {!getMaintenaceModeStatus() && rolePermission.role.dcs !== 0 && (
            <Route path="/data-center" element={<Dashboard />} />
          )}
          {checkTicketAndProvisioningPermission("provisioning") && (
            <Route path="/provisioning" element={<Provisioning />} />
          )}
          {rolePermission.role.user_management !== 0 && (
            <Route
              path="/setting-role-and-permissions"
              element={<RoleAndPermission />}
            />
          )}
          {rolePermission.role.user_management !== 0 && (
            <Route path="/setting-user" element={<User />} />
          )}
          {rolePermission.role.dcs !== 0 && (
            <Route path="/setting-data-center" element={<DataCenter />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route
              path="/setting-power-distibution"
              element={<PowerDistribution />}
            />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/setting-network" element={<Op />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/setting-capacity" element={<Capacity />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/setting-bcm" element={<BCM />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/setting-cross-connect" element={<CrossConnect />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route
              path="/setting-cabling-and-patch-nw-rooms"
              element={<Room />}
            />
          )}
          {!getMaintenaceModeStatus() && (
            <Route
              path="/setting-cabling-and-patch-cabling"
              element={<Cabling />}
            />
          )}
          {!getMaintenaceModeStatus() && (
            <Route
              path="/setting-cabling-and-patch-patch-panels"
              element={<PatchPanel />}
            />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/setting-rackelevation" element={<Rackelevation />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/setting-topology" element={<Topology />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/setting-fibermap" element={<Fibermap />} />
          )}
          {!getMaintenaceModeStatus() &&
            rolePermission.role.user_management !== 0 && (
              <Route path="/setting-activity" element={<Reports />} />
            )}
          {rolePermission?.role?.name == "Super Admin" && (
            <Route path="/setting-maintenance-boss" element={<Maintenence />} />
          )}
          {rolePermission?.role?.name == "Super Admin" && (
            <Route
              path="/setting-maintenance-ops-portal"
              element={<Maintenence />}
            />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/test-graph" exact element={<Test />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/graph-state-chart" exact element={<StateChart />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/profile" element={<Profile />} />
          )}

          {!getMaintenaceModeStatus() && rolePermission.role.esg != 0 && (
            <Route path="/esg" element={<ESG />} />
          )}
          {checkTicketAndProvisioningPermission("ticket") && (
            <Route path="/ticket" element={<Ticket />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/tickets" element={<Widgets />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route path="/oddo-test" element={<OddoTest />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route exact path="/capacity-layout" element={<CapacityLayout />} />
          )}
          {!getMaintenaceModeStatus() && (
            <Route exact path="/landing-page" element={<LandingPage />} />
          )}
          {!getMaintenaceModeStatus() && rolePermission.role.accounts != 0 && (
            <Route path="/accounts" element={<Account />} />
          )}
          {!getMaintenaceModeStatus() && rolePermission.role.contact != 1 && (
            <Route path="/contacts" element={<Contacts />} />
          )}
          <Route path="*" element={<Navigate to={checkPermission()} />} />
        </Routes>
      )}
    </React.Fragment>
  );
};

export default AuthenticatedRoutes;
