import AuthContext from "context";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Floors from "services/floorServices";
import BreakerCreate from "../Breaker/create";
import RRPCreate from "../RRP/create";
import UPSCreate from "../UPS/create";
import { LogoutOnSessionExpire } from "common/helpers";
import "./NavigationTab.css";
import { useTranslation } from "react-i18next";

const NavigationTab = ({
  navigationActiveTab,
  setNavigationActiveTab,
  activeTab,
  needToReload,
  setNeedToReload,
  lastActivity,
  setNextUPS,
  setFetchingUPS,
  setHasMoreUPS,
  setNextRPP,
  setFetchingRPP,
  setHasMoreRPP,
  setNextBreaker,
  setFetchingBreaker,
  setHasMoreBreaker,
  incrementStep,
  searchQuery,
  filterData,
}) => {
  const [toggleModal, setModalState] = useState({
    UPSCreateIsON: false,
    UPSUpdateIsON: false,
    RRPCreateIsON: false,
    RRPUpdateIsON: false,
    BreakerCreateIsON: false,
    BreakerUpdateIsON: false,
  });
  const contextStore = useContext(AuthContext);
  const [floorsData, setFloorData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getFloorData();
  }, []);

  const resetVar = () => {
    setNextUPS(0);
    setFetchingUPS(false);
    setHasMoreUPS(false);
    setNextRPP(0);
    setFetchingRPP(false);
    setHasMoreRPP(false);
    setNextBreaker(0);
    setFetchingBreaker(false);
    setHasMoreBreaker(false);
  };

  const getFloorData = async () => {
    await Floors.findAllFloor(contextStore.token())
      .then((res) => {
        setFloorData(res.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        /*500 internal server error*/
      });
  };

  const activeClass = (id) => {
    if (navigationActiveTab === id) {
      return "btn btn-secondary";
    }
    return "btn btn-light";
  };

  const OpenModel = () => {
    if (navigationActiveTab === 2) {
      setModalState({
        ...toggleModal,
        UPSCreateIsON: true,
      });
    }

    if (navigationActiveTab === 3) {
      setModalState({
        ...toggleModal,
        RRPCreateIsON: true,
      });
    }

    if (navigationActiveTab === 4) {
      setModalState({
        ...toggleModal,
        BreakerCreateIsON: true,
      });
    }
  };

  return (
    <div id="pro">
      <div id="title">
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-6">
            {lastActivity ? (
              <p>
                {t("pwrdist.latest_update")} {moment(lastActivity).format("YYYY-MM-DD hh:mm")}{" "}
                HKT
              </p>
            ) : null}
          </div>
          {navigationActiveTab === 1 || navigationActiveTab === 5 ? null : (
            <div className="col-12 col-sm-12 col-lg-6 text-right">
              <div className="add_device_btn">
                <a href="#sd" style={{ cursor: "pointer" }} onClick={OpenModel}>
                  <img
                    src="/images/plus-circle-blue.svg"
                    alt=""
                    style={{ width: "1rem" }}
                  />{" "}
                  &nbsp; {t("pwrdist.add_device")}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="settings-navigation-tab-with-filter">
          <div className="col-12 col-sm-8 col-lg-6 col-xl-5">
            <div className="de_line_btn">
              <button
                type="button"
                onClick={() => {
                  setNavigationActiveTab(1);
                }}
                className={activeClass(1)}
              >
                 {t("pwrdist.overview")}
              </button>

              <button
                type="button"
                onClick={() => {
                  setNavigationActiveTab(2);
                  resetVar();
                }}
                className={activeClass(2)}
              >
                {t("pwrdist.u_p_s")}
              </button>

              <button
                type="button"
                onClick={() => {
                  setNavigationActiveTab(3);
                  resetVar();
                }}
                className={activeClass(3)}
              >
                {t("pwrdist.r_p_p_s")}
              </button>

              <button
                type="button"
                onClick={() => {
                  setNavigationActiveTab(4);
                  resetVar();
                }}
                className={activeClass(4)}
              >
                {t("pwrdist.breakers")}
              </button>
            </div>
          </div>
          {navigationActiveTab !== 1 && (
            <div>
              <div>
                <span>{t("pwrdist.filter")}: </span>
                <input
                  type="search"
                  value={searchQuery}
                  style={{
                    marginBottom: "1rem",
                    border: "1px solid #92979A",
                  }}
                  onChange={filterData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-12 col-sm-12 col-lg-6"></div>

      {toggleModal.UPSCreateIsON ? (
        <UPSCreate
          activeTab={activeTab}
          needToReload={needToReload}
          setNeedToReload={setNeedToReload}
          floorsData={floorsData}
          getFloorData={getFloorData}
          setModalState={setModalState}
        />
      ) : null}

      {toggleModal.RRPCreateIsON ? (
        <RRPCreate
          setModalState={setModalState}
          activeDataCenter={activeTab}
          setIsValueChange={setNeedToReload}
          floorsData={floorsData}
          getFloorData={getFloorData}
          isValueChange={needToReload}
        />
      ) : null}

      {toggleModal.BreakerCreateIsON ? (
        <BreakerCreate
          setModalState={setModalState}
          activeDataCenter={activeTab}
          setIsValueChange={setNeedToReload}
          floorsData={floorsData}
          getFloorData={getFloorData}
          isValueChange={needToReload}
        />
      ) : null}
    </div>
  );
};

export default NavigationTab;
