/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Select, { components } from "react-select";
// import "react-select/dist/react-select.css";

const Option = (props) => {
  return (
    <div>
      <components.Option
        {...props}
        className="ticket-multi-select-field-multi-select-option"
      >
        {/* <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '} */}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue
    {...props}
    className="ticket-multi-select-field-multi-select-input"
  >
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const MultiSelectField = ({
  value,
  setUpdateTicketDetails,
  options,
  setAllSpace,
  setAllRooms,
  company,
  initialCompany,
  customStyles,
}) => {
  const [localOptions, setLocalOptions] = useState([]);

  useEffect(() => {
    if (value?.length === 0) {
      setLocalOptions([]);
    } else {
      setLocalOptions(value);
      let AllValues = [];
      let spaces = [];
      let rooms = [];
      for (let i = 0; i < value.length; i++) {
        AllValues.push(value[i].value);
        let newArr = options.filter((data) => data.value === value[i].value);
        if (newArr) {
          if (newArr[0]?.space) {
            spaces.push(newArr[0].space);
          }
          if (newArr[0]?.room) {
            rooms.push(newArr[0].room);
          }
        }
      }
      setAllRooms([...rooms]);
      setAllSpace([...spaces]);
      setUpdateTicketDetails((prev) => ({ ...prev, service_id: AllValues }));
    }
  }, [value]);

  useEffect(() => {
    if (company != initialCompany) {
      setLocalOptions([]);
      setAllSpace([]);
      setAllRooms([]);
    }
  }, [company]);

  const onChange = (selected) => {
    let spaces = [];
    let rooms = [];
    let AllValues = [];
    for (let i = 0; i < selected.length; i++) {
      AllValues.push(selected[i].value);
      let newArr = options.filter((data) => data.value === selected[i].value);
      if (newArr) {
        if (newArr[0]?.space) {
          spaces.push(newArr[0].space);
        }
        if (newArr[0]?.room) {
          rooms.push(newArr[0].room);
        }
      }
    }

    setAllSpace([...spaces]);
    setAllRooms([...rooms]);
    setUpdateTicketDetails((prev) => ({ ...prev, service_id: AllValues }));
    setLocalOptions(selected);
  };

  const getOptions = (options) => {
    return options;
  };

  return (
    <>
      <Select
        styles={customStyles}
        className="multiselect"
        isMulti
        hideSelectedOptions
        options={getOptions(options)}
        value={localOptions}
        classNamePrefix="select"
        allowSelectAll
        components={{ Option, MultiValue }}
        onChange={onChange}
      />
    </>
  );
};

export default MultiSelectField;
