const RelatedTicketsTable = ({ sub_tickets, trancateTitle }) => {
  return (
    <div class="site-table" style={{ width: "90%" }}>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="site-tr">
              Ticket ID
            </th>
            <th scope="col" class="site-tr">
              Ticket Name
            </th>
            <th scope="col" class="site-tr">
              Severity
            </th>
            <th scope="col" class="site-tr">
              Ticket Sub-Type
            </th>
            <th scope="col" class="site-tr">
              Customer
            </th>
            <th scope="col" class="site-tr">
              AssignTo
            </th>
            <th scope="col" class="site-tr">
              Stage
            </th>
            <th scope="col" class="site-tr">
              Company
            </th>
            <th scope="col" class="site-tr">
              Created On
            </th>
          </tr>
        </thead>
        <tbody>
          {sub_tickets &&
            sub_tickets.map((data, key) => {
              return (
                <tr>
                  <td class="site-tr">{data?.id || "-"}</td>
                  <td class="site-tr">
                    {trancateTitle(data?.subject, 15) || "-"}
                  </td>
                  <td class="site-tr">{data?.severity || "-"}</td>
                  <td class="site-tr">{data?.ticket_type || "-"}</td>
                  <td class="site-tr">
                    {trancateTitle(data?.customer, 15) || "-"}
                  </td>
                  <td class="site-tr">
                    {trancateTitle(data?.assigned_to, 10) || "-"}
                  </td>
                  <td class="site-tr">{data?.ticket_status || "-"}</td>
                  <td class="site-tr">
                    {trancateTitle(data?.company, 12) || "-"}
                  </td>
                  <td class="site-tr">{data?.create_date || "-"}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
export default RelatedTicketsTable;
