import AuthContext from "context";
import { useContext, useEffect, useState } from "react";
import CreateTicketService from "services/createTicketService";
import _ from "lodash";

const useGetServiceIds = (companyId, siteId, setLoading, id) => {
  const authContext = useContext(AuthContext);

  const [services, setServices] = useState([]);
  const [isServiceLoading, setIsServiceLoading] = useState(false);

  const getServiceIds = async () => {
    const getServiceIdsDto = await CreateTicketService.getServiceIds({
      token: authContext.token(),
      companyId,
      siteId,
      space_related: id === "site-access" || id === "shipment" ? true : false,
    });
    setServices(
      _.orderBy(getServiceIdsDto.data?.data, ["name"], ["asc"])
        .filter((service) => service.ns_is_remote_hands === false)
        .map((service) => {
          const { id, name, space } = service;
          return {
            ...service,
            label: `${name}${!!space ? " / " + space : ""}`,
            value: id,
          };
        })
    );
    setIsServiceLoading(false);
  };

  useEffect(() => {
    try {
      if (companyId && siteId) {
        setLoading(true);
        setIsServiceLoading(true);
        getServiceIds();
        setLoading(false);
      }
    } catch {
      setIsServiceLoading(false);
      setLoading(false);
    }
  }, [companyId, siteId]);

  return {
    services,
    isServiceLoading,
  };
};

export default useGetServiceIds;
