/* eslint-disable default-case */
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import {
  S1,
  S2,
  S3,
  S4,
  Today,
  P0,
  P1,
  P2,
  P3,
  I,
  E,
} from "components/ColorTile";
import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
ChartJS.register(ArcElement, Tooltip, Legend);

// custom tooltip function
function external(context) {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML =
      "<div class='tooltip-arrow'></div><div class='tooltip-content'></div>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem) {
    let indicator = bodyItem.lines[0].split(":")[0]; // text part
    let value = bodyItem.lines[0].split(":")[1]; // the value
    return [indicator, value];
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = "<div>";

    titleLines.forEach(function (title) {
      innerHtml += "<tr><th>" + title + "</th></tr>";
    });
    innerHtml += "</div><span>";

    bodyLines.forEach(function (body, i) {
      const colors = tooltipModel.labelColors[i];
      let style = "background: black";
      let style2 = "background: " + colors.backgroundColor;
      style2 += "; padding: .2rem .5rem";
      style2 += "; border-radius: .25rem";
      style += "; border-radius: .25rem";
      style += "; white-space: nowrap";
      if (
        bodyLines[0][0] === "Blocked" ||
        bodyLines[0][0] === "Reserved" ||
        bodyLines[0][0] === "ROFR"
      ) {
        style2 += "; color: white";
      } else {
        style2 += "; color: black";
      }
      if (bodyLines[0][0] === "Blocked") {
        style2 += "; border: 1px solid white";
      }
      style += "; padding: .5rem";
      style += "; color: white";
      style += "; font-size: 14px";
      const span =
        '<span style="' +
        style +
        '">' +
        '<span style="' +
        style2 +
        '">' +
        bodyLines[0][0] +
        "</span>" +
        " : " +
        bodyLines[0][1] +
        "</span>";
      innerHtml += "<span>" + span + "</span>";
    });
    innerHtml += "</span>";

    let tableRoot = tooltipEl.querySelector("div");
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  const offset = 10;

  // Set initial position near the cursor
  let leftPos = position.left + window.pageXOffset + tooltipModel.caretX + 30;
  let topPos = position.top + window.pageYOffset + tooltipModel.caretY + -10;

  // Display, position, and set styles for TEXT inside only
  tooltipEl.style.opacity = 1;
  tooltipEl.style.transition = "opacity .3s, left .25s, top .25s";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.zIndex = 5;
  tooltipEl.style.left = `${leftPos}px`;
  tooltipEl.style.top = `${topPos}px`;
  tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
  tooltipEl.style.pointerEvents = "none";

  function adjustTooltipPosition() {
    const tooltipRect = tooltipEl.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Check if the tooltip is outside the right edge of the viewport
    if (tooltipRect.right + tooltipRect.width + 100 > viewportWidth) {
      tooltipEl.style.left = `${leftPos - tooltipRect.width - 60}px`;
      tooltipEl.classList.add("tooltip-left");
      tooltipEl.classList.remove("tooltip-right");
    } else {
      tooltipEl.classList.add("tooltip-right");
      tooltipEl.classList.remove("tooltip-left");
    }

    // Check if the tooltip is outside the left edge of the viewport
    if (tooltipRect.left < 0) {
      tooltipEl.style.left = `${offset}px`;
    }

    // Check if the tooltip is outside the bottom edge of the viewport
    if (tooltipRect.bottom > viewportHeight) {
      tooltipEl.style.top = `${topPos - tooltipRect.height - offset}px`;
    }

    // Check if the tooltip is outside the top edge of the viewport
    if (tooltipRect.top < 0) {
      tooltipEl.style.top = `${offset}px`;
    }
  }

  // Adjust tooltip position initially
  adjustTooltipPosition();

  // Follow the mouse pointer
  // context.chart.canvas.addEventListener('mousemove', function(event) {
  //   if (tooltipModel.opacity !== 0) {
  //     tooltipEl.style.left = event.clientX + 30 + "px";
  //     tooltipEl.style.top = event.clientY + -10 + "px";
  //   }
  // });

  // Move if too close to the pointer
  context.chart.canvas.addEventListener("mousemove", function (event) {
    const tooltipRect = tooltipEl.getBoundingClientRect();
    const pointerX = event.clientX;
    const pointerY = event.clientY;

    const distanceX = Math.abs(pointerX - tooltipRect.left);
    const distanceY = Math.abs(pointerY - tooltipRect.top);
    const threshold = 25; // Distance threshold to move the tooltip
    if (distanceX < threshold && distanceY < threshold) {
      tooltipEl.style.left = `${pointerX + 20}px`;
      // tooltipEl.style.top = `${topPos + -10}px`

      // Ensure the tooltip is fully visible within the viewport
      adjustTooltipPosition();
    }
  });
}

const graphSetting = {
  maintainAspectRatio: false,
  rotation: -90,
  circumference: 180,
  cutout: 60,
  plugins: {
    tooltip: {
      enabled: false,
      external: external, // custom tooltip
      // boxPadding: 7, // spacing of the text from the clr box
      // xAlign: "left", // direction of tooltip
      // displayColors: false, // show or hide the clr box
    },
    legend: {
      display: false,
    },
    datalabels: {
      formatter: (value) => {
        return value;
      },
    },
  },
};

const Card = ({ cardData, state, menu, setMenu }) => {
  const [graphData, setGraphData] = useState(cardData);
  const { t } = useTranslation();
  const contextStore = useContext(AuthContext);

  useEffect(() => {
    getIncident();
    getFaults();
    getShipment();
    getSiteVisit();
    getRemoteHands();
    console.log("graphData hiii-", state);
  }, [state]);

  const checkPermission = (ticketTypeNo) => {
    return contextStore.getAuth?.role?.role_ticket.some(
      (item) => item.is_allowed === 1 && item.ticket_type_id == ticketTypeNo
    );
  };
  const getIncident = () => {
    let p0 = 0;
    let p1 = 0;
    let p2 = 0;
    let p3 = 0;
    let i = 0;
    let e = 0;
    let total = 0;

    state &&
      state.map((data) => {
        const result = data.incident.result;
        total = data.incident.total;
        if (result.P0) {
          p0 += result.P0;
        }

        if (result.P1) {
          p1 += result.P1;
        }

        if (result.P2) {
          p2 += result.P2;
        }

        if (result.P3) {
          p3 += result.P3;
        }

        if (result.I1 || result.I2 || result.I3) {
          i +=
            (result.I1 ? result.I1 : 0) +
            (result.I2 ? result.I2 : 0) +
            (result.I3 ? result.I3 : 0);
        }

        if (result.E1 || result.E2 || result.E3) {
          e +=
            (result.E1 ? result.E1 : 0) +
            (result.E2 ? result.E2 : 0) +
            (result.E3 ? result.E3 : 0);
        }
      });

    let incidents = graphData.filter((data) => data.title === "Incidents");
    incidents[0].totalNumber = total;
    incidents[0].graph = [p0, p1, p2, p3, i, e];

    const res = graphData.map(
      (obj) => incidents.find((o) => o.title === obj.title) || obj
    );
    setGraphData(res);
  };
  const getFaults = () => {
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    let s4 = 0;
    let total = 0;
    state &&
      state.map((data) => {
        const result = data.fault.result;
        total = data.fault.total;
        if (result["S-1"]) {
          s1 += result["S-1"];
        }

        if (result["S-2"]) {
          s2 += result["S-2"];
        }

        if (result["S-3"]) {
          s3 += result["S-3"];
        }
        if (result["S-4"]) {
          s4 += result["S-4"];
        }
      });

    let faults = graphData.filter((data) => data.title === "Faults");
    faults[0].totalNumber = total;
    faults[0].graph = [s1, s2, s3, s4];

    const res = graphData.map(
      (obj) => faults.find((o) => o.title === obj.title) || obj
    );

    setGraphData(res);
  };

  const getShipment = () => {
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    let s4 = 0;
    let total = 0;
    state &&
      state.map((data) => {
        const result = data.shipments.result;
        total = data.shipments.total;
        if (result["S-1"]) {
          s1 += result["S-1"];
        }

        if (result["S-2"]) {
          s2 += result["S-2"];
        }

        if (result["S-3"]) {
          s3 += result["S-3"];
        }
        if (result["S-4"]) {
          s4 += result["S-4"];
        }
      });

    let faults = graphData.filter((data) => data.title === "Shipments");
    faults[0].totalNumber = total;
    faults[0].graph = [s1, s2, s3, s4];

    const res = graphData.map(
      (obj) => faults.find((o) => o.title === obj.title) || obj
    );
    setGraphData(res);

    // let count = 0;

    // state &&
    //   state.map((data) => {
    //     if (data.ticket_type_id === 3) {
    //       return count++;
    //     }

    // faults[0].totalNumber = count;
    // faults[0].graph = [count];

    // const res = graphData.map(
    //   (obj) => faults.find((o) => o.title === obj.title) || obj
    // );
  };
  const getSiteVisit = () => {
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    let s4 = 0;
    let total = 0;
    state &&
      state.map((data) => {
        const result = data.site_access.result;
        total = data.site_access.total;

        if (result["S-1"]) {
          s1 += result["S-1"];
        }

        if (result["S-2"]) {
          s2 += result["S-2"];
        }

        if (result["S-3"]) {
          s3 += result["S-3"];
        }
        if (result["S-4"]) {
          s4 += result["S-4"];
        }
      });

    let faults = graphData.filter((data) => data.title === "Site Visits");
    faults[0].totalNumber = total;
    faults[0].graph = [s1, s2, s3, s4];

    const res = graphData.map(
      (obj) => faults.find((o) => o.title === obj.title) || obj
    );
    setGraphData(res);
  };

  const getRemoteHands = () => {
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    let s4 = 0;
    let total = 0;
    state &&
      state.map((data) => {
        const result = data["remote-hands"].result;
        total = data["remote-hands"].total;
        if (result["S-1"]) {
          s1 += result["S-1"];
        }

        if (result["S-2"]) {
          s2 += result["S-2"];
        }

        if (result["S-3"]) {
          s3 += result["S-3"];
        }
        if (result["S-4"]) {
          s4 += result["S-4"];
        }
      });

    let faults = graphData.filter((data) => data.title === "Remote Hands");
    faults[0].totalNumber = total;
    faults[0].graph = [s1, s2, s3, s4];

    const res = graphData.map(
      (obj) => faults.find((o) => o.title === obj.title) || obj
    );
    setGraphData(res);
  };

  //     args.title === "Remote Hands" ||
  //     args.title === "Shipments" ||
  const getGraphData = (args) => {
    if (args.title === "Incidents") {
      return {
        labels: ["P0", "P1", "P2", "P3", "I", "E"],
        datasets: [
          {
            data: args.graph,
            backgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            hoverBackgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderWidth: 3,
          },
        ],
      };
    } else if (args.title === "Faults") {
      return {
        labels: ["S1", "S2", "S3", "S4"],
        datasets: [
          {
            data: args.graph,
            backgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            hoverBackgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderWidth: 3,
          },
        ],
      };
    } else if (args.title === "Site Visits") {
      return {
        labels: ["S1", "S2", "S3", "S4"],
        datasets: [
          {
            data: args.graph,
            backgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            hoverBackgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderWidth: 3,
          },
        ],
      };
    } else if (args.title === "Shipments") {
      return {
        labels: ["S1", "S2", "S3", "S4"],
        datasets: [
          {
            data: args.graph,
            backgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            hoverBackgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderWidth: 3,
          },
        ],
      };
    } else if (args.title === "Remote Hands") {
      return {
        labels: ["S1", "S2", "S3", "S4"],
        datasets: [
          {
            data: args.graph,
            backgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            hoverBackgroundColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderColor: [
              "#e03138",
              "#f78600",
              "#f2dc34",
              "#40ff00",
              "#1b70c0",
              "#c2adc2",
            ],
            borderWidth: 3,
          },
        ],
      };
    } else {
      return {
        labels: ["P0", "P1", "P2", "P3", "I", "E"],
        datasets: [
          {
            data: args.graph,
            backgroundColor: [
              //"#e03138",
              "#f78600",
            ],
            hoverBackgroundColor: [
              //"#e03138",
              "#f78600",
            ],
            borderColor: [
              //"#e03138",
              "#f78600",
            ],
            borderWidth: 0,
          },
        ],
      };
    }
  };

  const labelColor = (text, i) => {
    let label;

    switch (text) {
      case "S-1":
        label = <S1 key={text} />;
        break;
      case "S-2":
        label = <S2 key={text} />;
        break;
      case "S-3":
        label = <S3 key={text} />;
        break;
      case "S-4":
        label = <S4 key={text} />;
        break;
      case "Today":
        label = <Today key={text} />;
        break;
      case "P0":
        label = <P0 key={text} />;
        break;
      case "P1":
        label = <P1 key={text} />;
        break;
      case "P2":
        label = <P2 key={text} />;
        break;
      case "P3":
        label = <P3 key={text} />;
        break;
      case "I":
        label = <I key={text} />;
        break;
      case "E":
        label = <E key={text} />;
        break;

      /*  case "Tomorrow":
          label= <Tomorrow/>;
        break;*/
    }
    return label;
  };

  return (
    graphData &&
    graphData.map((data, index) => {
      return (
        <>
          {checkPermission(data.ticketno) && (
            <div className="col-lg-2 col-md-12 col-sm-12 col-12" key={index}>
              <div
                className={"grid_card set_h " + data.className}
                onClick={() => {
                  const permission = checkPermission(data.ticketno);
                  if (permission) {
                    setMenu(data.ticketno);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="card_head">
                  <div className="txt_card">
                    <h3>{t(`ticket.${data.title}`)}</h3>
                  </div>
                  <div className="txt_card_2">
                    {data.types.map((type, i) => {
                      return labelColor(type.title);
                    })}
                  </div>
                </div>
                <div
                  className="card_diag outer"
                  style={{ height: "200px", width: "190px" }}
                >
                  <Doughnut data={getGraphData(data)} options={graphSetting} />
                  <p className="percent">
                    {data.totalNumber} <br />
                    <span>{t(`ticket.${data.title}`)}</span>{" "}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      );
    })
  );
};

export default Card;
