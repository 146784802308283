import { useEffect } from "react";

const TicketDetailTab = ({
  setActive,
  active,
  permission,
  follows,
  unfollow,
  follow,
  setButtonText,
  setIcon,
  setButtonStyle,
  icon,
  buttonStyle,
  buttonText,
  setAddFollower,
  delfollower,
  follower,
  ticketType,
}) => {
  useEffect(() => {
    console.log(permission);
  }, [permission]);
  return (
    <div class="btnstyle">
      <div class="btn-group">
        {ticketType == "siteAccess" && (
          <a
            href="#"
            className={`btn msg-button ${active == "site" ? "bactive" : ""}`}
            onClick={() => {
              setActive("site");
            }}
          >
            Site Access Details
          </a>
        )}
        {ticketType == "shipmentInbound" && (
          <a
            href="#"
            className={`btn msg-button ${active == "shipIn" ? "bactive" : ""}`}
            onClick={() => {
              setActive("shipIn");
            }}
          >
            Package Details
          </a>
        )}
        {ticketType == "shipmentOutbound" && (
          <a
            href="#"
            className={`btn msg-button ${active == "shipOut" ? "bactive" : ""}`}
            onClick={() => {
              setActive("shipOut");
            }}
          >
            Package Details
          </a>
        )}
        {ticketType == "incident" && (
          <a
            href="#"
            className={`btn msg-button ${
              active == "incident" ? "bactive" : ""
            }`}
            onClick={() => {
              setActive("incident");
            }}
          >
            Related Tickets
          </a>
        )}

        {permission("Log Notes") && (
          <a
            href="#"
            className={`btn msg-button ${active == "log" ? "bactive" : ""}`}
            onClick={() => {
              setActive("log");
            }}
          >
            Log Note
          </a>
        )}

        {permission("Send Message") && (
          <a
            href="#"
            className={`btn msg-button ${active == "message" ? "bactive" : ""}`}
            onClick={() => {
              setActive("message");
            }}
          >
            Send Message
          </a>
        )}
        <a
          href="#"
          className={`btn msg-button ${
            active == "attachment" ? "bactive" : ""
          }`}
          onClick={() => {
            setActive("attachment");
          }}
        >
          Attachment
        </a>
        <a
          href="#"
          className={`btn msg-button ${active == "history" ? "bactive" : ""}`}
          onClick={() => {
            setActive("history");
          }}
        >
          History
        </a>
        {ticketType == "siteAccess" && (
          <a
            href="#"
            className={`btn msg-button ${
              active == "site_logs" ? "bactive" : ""
            }`}
            onClick={() => {
              setActive("site_logs");
            }}
          >
            Site Access Logs
          </a>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
        }}
      >
        {permission("Follow") && (
          <a
            href="#"
            onClick={follows ? unfollow : follow}
            onMouseOver={() => {
              setButtonText(follows ? "Unfollow" : "Follow");
              setIcon(follows ? "fas fa-close" : "");
              setButtonStyle({
                color: follows ? "red" : "black",
              });
            }}
            onMouseOut={() => {
              setButtonText(follows ? "Following" : "Follow");
              setIcon(follows ? "fas fa-check" : "");
              setButtonStyle({
                color: follows ? "#2cc970" : "black",
              });
            }}
          >
            <span className={icon} style={buttonStyle}></span>{" "}
            <b style={buttonStyle}>{buttonText}</b>
          </a>
        )}

        <ul className="navbar-nav header-right">
          <li className="nav-item dropdown header-profile">
            <a
              className="nav-link"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <i
                className="fas fa-user"
                id="don"
                style={{ color: "#228683" }}
              ></i>{" "}
              <span
                className="ms-1"
                style={{
                  color: "#228683",
                  fontWeight: "bold",
                }}
              >
                {follower.length}{" "}
              </span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-end"
              style={{ width: "max-content" }}
              id="follwerDropdown"
            >
              {permission("Follow") && (
                <a
                  href="#"
                  className="dropdown-item ai-icon"
                  onClick={() => setAddFollower(true)}
                >
                  <span className="">Add Followers </span>
                </a>
              )}
              {permission && <hr />}
              <table>
                <thead>
                  {follower &&
                    follower.map((data, key) => (
                      <tr className="">
                        <th>
                          <img
                            src="images/Icon.svg"
                            style={{
                              width: "18px",
                              height: "18px",
                            }}
                          />{" "}
                        </th>
                        <th>
                          <span className="">{data.name} </span>{" "}
                        </th>
                        {/* <th style={{cursor:"pointer"}}> <i className="fas fa-pencil" id="don"></i></th> */}
                        {permission("Follow") && (
                          <th
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="images/fclose.svg"
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                              }}
                              onClick={() => delfollower(data.contact_id)}
                            />
                          </th>
                        )}
                      </tr>
                    ))}
                </thead>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TicketDetailTab;
