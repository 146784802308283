import { useEffect, useState } from 'react';
import Table from './table';
import { isEmpty } from 'lodash';
const GroupByTable = ({
  columns,
  groups,
  setItemsPerPage,
  setPageNumber,
  total,
  pageNumber,
  userTimzone,
  readinessChange,
  newTicket,
  handleModalPopupOnClick,
  timzonePrefix,
  escalateTeams,
  checkIfIncidentTicket,
  playing,
  getStatus,
  state,
  isCollapse,
  isSelectedGroup,
  setIsSelectedGroup,
  totalTicketsCount,
  setState,
  setIsCollapse,
  groupBy,
  sidePopValDb,
  setSidePopValDb,
  setSort,
  sort,
  setSortDirection,
  sortDirection,
  SidePopVal,
  setSidePopVal,
  menu
}) => {
  const [selectedId, setSelectedId] = useState('');
  useEffect(() => {
    if (isSelectedGroup == '') {
      setSelectedId('');
    }
  }, [isSelectedGroup]);
  // useEffect(() => {
  //   alert(isCollapse)
  //   if(!isCollapse){
  //     setPageNumber(1)
  //   }
  // }, [isCollapse])
  return (
    <div className="card-body">
      {groups &&
        groups.length > 0 &&
        groups.map((data, index) => {
          return (
            <div>
              <div
                className="table_group"
                onClick={() => {
                  if (data.id !== selectedId) {
                    setState([]);
                  }
                  if (data.id === selectedId) {
                    setIsCollapse(!isCollapse);
                  } else {
                    setPageNumber(1);
                    setIsCollapse(false);
                  }
                  setIsSelectedGroup(!isEmpty(groupBy) ? data.id : '');
                  setSelectedId(data.id);
                }}
              >
                {data.id === selectedId ? (
                  <div className="table_group_name">
                    {isCollapse ? (
                      <i className="fa-solid fa-caret-right"></i>
                    ) : (
                      <i className="fa-solid fa-caret-down"></i>
                    )}
                  </div>
                ) : (
                  <div className="table_group_name">
                    {' '}
                    <i className="fa-solid fa-caret-right"></i>
                  </div>
                )}

                <span>
                  {data.name} ({data.count})
                </span>
              </div>
              {!isCollapse && selectedId == data.id && state && state.length > 0 && (
                <div
                  className="table-responsive tckect_tt table-scroll-set"
                  style={{
                    top: '0px',
                    position: 'static',
                    maxHeight: '330px',
                    width: '100%'
                  }}
                >
                  <Table
                    columns={columns}
                    data={state}
                    setItemsPerPage={setItemsPerPage}
                    setPageNumber={setPageNumber}
                    total={totalTicketsCount}
                    pageNumber={pageNumber}
                    userTimzon={userTimzone}
                    readinessChange={readinessChange}
                    newTicket={newTicket}
                    handleModalPopupOnClick={handleModalPopupOnClick}
                    timzonePrefix={timzonePrefix}
                    escalateTeams={escalateTeams}
                    checkIfIncidentTicket={checkIfIncidentTicket}
                    playing={playing}
                    getStatus={getStatus}
                    sidePopValDb={sidePopValDb}
                    setSidePopValDb={setSidePopValDb}
                    setSort={setSort}
                    setSortDirection={setSortDirection}
                    sort={sort}
                    sortDirection={sortDirection}
                    SidePopVal={SidePopVal}
                    setSidePopVal={setSidePopVal}
                    menu={menu}
                  ></Table>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default GroupByTable;
