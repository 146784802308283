import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loginAzure, verifyEmail } from "services/auth";
import Swal from "sweetalert2";
import { loginRequest } from "../authConfig";
import { LogoutOnSessionExpire } from "common/helpers";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "services/auth";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const LoginWithAzure = ({ contextData, navigate, HOME }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // const [message,setMessage] = useState({
  //     message:"",
  //     type:""
  // });

  // useEffect(() => {
  //   if (token && location.pathname.length > 150) {
  //     verifyEmail({ vToken: token })
  //       .then((res) => {
  //         // setMessage({
  //         //     message:res.data.message,
  //         //     type:"success"
  //         // })
  //       })
  //       .catch((err) => {
  //         LogoutOnSessionExpire(err);
  //         // setMessage({
  //         //     message:err.response.data.errors,
  //         //     type:"danger"
  //         // })
  //       });
  //   }
  // }, []);

  const { instance } = useMsal();

  // const isAuthenticated = useIsAuthenticated();

  let { token } = useParams();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const handleLogin = (loginType) => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        console.log(res);
        localStorage.setItem("accessToken", res.accessToken);

        res.refreshToken = JSON.parse(
          sessionStorage.getItem(
            res.account.homeAccountId +
              "-login.windows.net-refreshtoken-" +
              res.account.idTokenClaims.aud +
              "----"
          )
        ).secret;
        // debugger
        instance
          .acquireTokenSilent({
            ...loginRequest,
          })
          .then((response) => {
            console.log(response);
          });

        localStorage.setItem("accessToken", res.accessToken);

        res.refreshToken = JSON.parse(
          sessionStorage.getItem(
            res.account.homeAccountId +
              "-login.windows.net-refreshtoken-" +
              res.account.idTokenClaims.aud +
              "----"
          )
        ).secret;

        instance
          .acquireTokenSilent({
            ...loginRequest,
          })
          .then((response) => {
            console.log(response);
          });

        localStorage.setItem("accessToken", res.accessToken);

        loginAzure({ data: res })
          .then((res) => {
            console.log(res);
            localStorage.setItem("token", res?.data?.access_token);

            if (res && res.data && res.data.data && res.data.data.length) {
              localStorage.setItem("istimeout", res?.data.data[0]?.isTimeout);
            }
            auth(res?.data?.access_token).then((res) => {
              console.log(res);
              contextData.setAuth(res.data.user);
              contextData.setMonthYear(
                res.data.currentMonth,
                res.data.currentYear
              );
              contextData.setDataCenter(res.data.dataCenter);
              contextData.setCountry(res.data.country);
            });
            contextData.login(res?.data?.access_token);
            console.log(res.data?.data[0]?.default_page);
            console.log(
              !!res.data?.data[0]?.default_page
                ? res.data?.data[0]?.default_page
                : HOME
            );
            navigate(
              !!res.data?.data[0]?.default_page
                ? res.data?.data[0]?.default_page
                : HOME
            );
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            Swal.fire({
              icon: "error",
              text: err.response.data,
            });

            // handleLogout();
          });
      })
      .catch((e) => {
        LogoutOnSessionExpire(e);
        console.log(e);
      });
  };

  // const ProfileContent = () => {

  //     const { instance, accounts } = useMsal();
  //     const [graphData, setGraphData] = useState(null);

  //     function RequestProfileData() {
  //         // Silently acquires an access token which is then attached to a request for MS Graph data
  //         instance.acquireTokenSilent({
  //             ...loginRequest,
  //             account: accounts[0]
  //         }).then((response) => {
  //             callMsGraph(response.accessToken).then(response => setGraphData(response));
  //         });
  //     }

  //     return (
  //         <>
  //             <h5 className="card-title">Welcome {accounts[0].name}</h5>
  //             {graphData ?
  //                 console.log(graphData)
  //                 :
  //                 <button variant="secondary" onClick={RequestProfileData}>Request Profile Information</button>

  //             }
  //             <button variant="secondary" onClick={handleLogout}>Logout</button>
  //         </>
  //     );
  // };
  return (
    <div className="App" style={{ marginTop: "3rem" }}>
      <div className="text-center" style={{ marginBottom: 10 }}>
        <button
          type="submit"
          style={{
            lineHeight: 2,
            background: "#010028",
            border: "1px solid #010028",
          }}
          onClick={() => handleLogin("popup")}
          className="btn btn-primary btn-block"
        >
          {/* <svg
            style={{ marginRight: 10 }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-microsoft"
            viewBox="0 0 16 16"
          >
            <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
          </svg>{" "} */}
          <IconButton aria-label="Example" style={{ color: "#ffffff" }}>
            <LoginIcon />
          </IconButton>
          {t("auth.sign_in_with_azure_active_directory")}
        </button>
        {contextData.getMaintenanceModeStatus &&
          contextData.getMaintenanceModeStatus?.isMaintenanceModeOn && (
            <Stack
              sx={{
                width: "100%",
                fontSize: "1rem",
                padding: "6px !important",
                marginTop: "3rem",
              }}
            >
              <Alert
                variant="outlined"
                severity="warning"
                sx={{
                  fontSize: "1rem",
                  textAlign: "left",
                  border: "2px solid #fe8600",
                  fontWeight: "500",
                }}
              >
                Maintenance mode has been turned{" "}
                {contextData.getMaintenanceModeStatus?.status_current == 2
                  ? "On"
                  : "Off"}
                .<br></br>
                <b> Data Sync is in progress</b>.<br></br>
                Please wait for a moment for the process to be completed before
                accessing the Ops Portal.
              </Alert>
            </Stack>
          )}
      </div>
    </div>
  );
};

export default LoginWithAzure;