import { css } from 'glamor';
import { useState } from 'react';
import { TICKET_COMMON_STYLES } from '../constants';
import './TicketInputField.scss';

const TicketInputField = ({
  id,
  value,
  label,
  required,
  active,
  backgroundColor,
  setFieldValue,
  placeholder
}) => {
  const [focused, setFocused] = useState(false);
  const styles = {
    backgound: {
      backgroundColor: `${backgroundColor || '#fff'} !important`
    },
    borderStyle: {
      border: '0px'
    },
    padding: {
      paddingLeft: '0px'
    },
    ...TICKET_COMMON_STYLES
  };

  return (
    <div className="ticket-input-field form-floating" {...css(styles.borderBottom, styles.padding)}>
      <input
        {...css(styles.backgound, styles.borderStyle)}
        disabled={!active}
        type="text"
        value={value}
        className="form-control"
        id="floatingInput"
        placeholder={placeholder || ' '}
        onChange={(event) => {
          let strippedString = event.target.value.replace(/(<([^>]+)>)/gi, '');
          setFieldValue(id, strippedString);
        }}
        onFocus={(e) => {
          setFocused(true);
        }}
        onBlur={(e) => {
          setFocused(false);
        }}
      />
      <label htmlFor="floatingInput">
        {!focused ? label : placeholder || label}
        {required && (
          <span {...css(styles.span)} className="red_star">
            *
          </span>
        )}
      </label>
    </div>
  );
};

export default TicketInputField;
