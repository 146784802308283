import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import networkServices from "services/networkServices";
import "./networkStyle.css";

const status = {
  inventory: false,
  rackElevation: false,
  topology: false,
  fiberMap: false,
};

const Network = ({ selectedDataCenter }) => {
  const [activeTab, setActiveTab] = useState({
    inventory: true,
    rackElevation: false,
    topology: false,
    fiberMap: false,
  });
  const authContext = useContext(AuthContext);
  const [counter, setCounter] = useState(1);
  const [fileExists, setFileExists] = useState(false);
  const [currentDataCenter, setCurrentDataCenter] = useState([]);
  const [i, setI] = useState(0);
  const [prevdisable, setPrevDisable] = useState(false);
  const [nextdisable, setNextDisable] = useState(false);
  const [image, setImage] = useState("");
  const [allNetworkData, setAllNetworkData] = useState([]);

  useEffect(() => {
    clear();
    AllCounter(selectedDataCenter, 1);
    selectDataCenterFloor(selectedDataCenter, 1);

    networkServices
      .networkByDataCenterId(authContext.token(), selectedDataCenter)
      .then((res) => {
        setAllNetworkData(res.data.data);
      });
  }, [selectedDataCenter, activeTab]);

  const clear = () => {
    setCounter(1);
    setFileExists(false);
    setI(0);
    setPrevDisable(false);
    setNextDisable(false);
    setImage("");
  };

  const AllCounter = async (e, k) => {
    let filePath = "";

    if (activeTab.rackElevation) {
      if (e.country_id !== "" && e.id !== "") {
        filePath = `${authContext.getAssetPath}/images/${e.country_id}/${e.id}/rackelevation/${k}.png`;
      } else {
        filePath = "Data Not Available";
      }
    }
    if (activeTab.fiberMap) {
      if (e.country_id !== "" && e.id !== "") {
        filePath = `${authContext.getAssetPath}/images/${e.country_id}/${e.id}/fibermap/${k}.png`;
      } else {
        filePath = "Data Not Available";
      }
    }
    if (activeTab.topology) {
      if (e.country_id !== "" && e.id !== "") {
        filePath = `${authContext.getAssetPath}/images/${e.country_id}/${e.id}/topology/${k}.png`;
      } else {
        filePath = "Data Not Available";
      }
    }
    let fileres = await fetch(filePath, { method: "HEAD" }).then((res) => {
      if (res.ok) {
        setI(k);
        AllCounter(e, k + 1);
      } else {
        return "not found";
      }
    });
  };

  const selectDataCenterFloor = async (e, c = counter) => {
    let filePath = "";

    if (activeTab.rackElevation) {
      if (e.country_id !== "" && e.id !== "") {
        filePath = `${authContext.getAssetPath}/images/${e.country_id}/${e.id}/rackelevation/${c}.png`;
      } else {
        filePath = "Data Not Available";
      }
    }
    if (activeTab.fiberMap) {
      if (e.country_id !== "" && e.id !== "") {
        filePath = `${authContext.getAssetPath}/images/${e.country_id}/${e.id}/fibermap/${c}.png`;
      } else {
        filePath = "Data Not Available";
      }
    }
    if (activeTab.topology) {
      if (e.country_id !== "" && e.id !== "") {
        filePath = `${authContext.getAssetPath}/images/${e.country_id}/${e.id}/topology/${c}.png`;
      } else {
        filePath = "Data Not Available";
      }
    }

    return await fetch(filePath, { method: "HEAD" }).then((res) => {
      if (res.ok) {
        setImage(filePath);
        setCurrentDataCenter(e);
        setFileExists(true);
      } else {
        setCurrentDataCenter(e);
        setFileExists(false);
      }
    });
  };

  const nextImage = (count) => {
    if (count !== i) {
      setCounter(++count);
      selectDataCenterFloor(currentDataCenter, count);
    } else {
      setNextDisable(true);
      setPrevDisable(false);
    }
  };
  const prevImage = (count) => {
    if (count !== 1) {
      setCounter(--count);
      selectDataCenterFloor(currentDataCenter, count);
    } else {
      setPrevDisable(true);
      setNextDisable(false);
    }
  };

  return (
    <div className="row px-4" style={{ backgroundColor: "white" }}>
      <div className="col-xl-1 w_11">
        <div className="leftside">
          <p onClick={() => setActiveTab({ ...status, inventory: true })}>
            <a
              style={{ cursor: "pointer" }}
              className={activeTab.inventory ? "plink active" : "plink"}
            >
              Inventory
            </a>
          </p>
          <p onClick={() => setActiveTab({ ...status, rackElevation: true })}>
            <a
              style={{ cursor: "pointer" }}
              className={activeTab.rackElevation ? "plink active" : "plink"}
            >
              Rack Elevation
            </a>
          </p>
          <p onClick={() => setActiveTab({ ...status, topology: true })}>
            <a
              style={{ cursor: "pointer" }}
              className={activeTab.topology ? "plink active" : "plink"}
            >
              Topology
            </a>
          </p>
          <p onClick={() => setActiveTab({ ...status, fiberMap: true })}>
            <a
              style={{ cursor: "pointer" }}
              className={activeTab.fiberMap ? "plink active" : "plink"}
            >
              Fiber Map
            </a>
          </p>
        </div>
      </div>
      {activeTab.fiberMap ? (
        <div className="col-lg-11 w_89 ">
          <div className="row g-3">
            <div className="col-12 col-lg-9">
              <div className="map_pic h-100">
                <h6>{selectedDataCenter.name}</h6>
                {fileExists ? (
                  <img src={image} style={{ height: "100%", width: "100%" }} />
                ) : (
                  <h2 style={{ textAlign: "center" }}>Data Not Available</h2>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              {fileExists ? (
                <h5 className="text-center mt-5">
                  Page {counter} of {i}
                  {prevdisable ? (
                    <span style={{ marginRight: "5px" }}>
                      <i className="fas fa-angle-double-left"></i>
                    </span>
                  ) : (
                    <span
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() => prevImage(counter)}
                    >
                      <i className="fas fa-angle-double-left"></i>
                    </span>
                  )}
                  {nextdisable ? (
                    <span>
                      <i className="fas fa-angle-double-right"></i>
                    </span>
                  ) : (
                    <span>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={() => nextImage(counter)}
                        className="fas fa-angle-double-right"
                      ></i>
                    </span>
                  )}
                </h5>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {activeTab.inventory ? (
        <div className="col-lg-11 w_89">
          <div
            className="tblop-117"
            style={{ paddingBottom: 10, paddingTop: 10 }}
          >
            <div className="ttl_op-117">
              <h6 style={{ fontSize: "0.792rem", color: "grey" }}>
                Latest Updates : 2022-04-22 12:22 HKT
              </h6>
            </div>
          </div>
          <div className="op-117_table mt-1">
            <div
              className="table-responsive"
              style={{ overflow: "auto", height: "350px" }}
            >
              <table
                className="table table-responsive-md"
                style={{ border: "1px solid #eee" }}
              >
                <thead>
                  <tr style={{ border: "1px solid #eee" }}>
                    <th width="10%">
                      <strong>Function</strong>
                    </th>
                    <th width="10%">
                      <strong>Hostname</strong>
                    </th>
                    <th width="10%">
                      <strong>Vendor</strong>
                    </th>
                    <th width="10%">
                      <strong>Model</strong>
                    </th>
                    <th width="10%">
                      <strong>S/N</strong>
                    </th>
                    <th width="10%">
                      <strong>Status</strong>
                    </th>
                    <th width="10%">
                      <strong>Cabinet ID</strong>
                    </th>
                    <th width="10%">
                      <strong>Support</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allNetworkData &&
                    allNetworkData.map((data, id) => {
                      return (
                        <tr key={id}>
                          <td>
                            {data.network_function !== null
                              ? data.network_function.name
                              : "N/A"}{" "}
                          </td>
                          <td> {data.name} </td>
                          <td> {data.makes?.name}</td>
                          <td>{data.models?.name} </td>
                          <td> {data.sn}</td>
                          <td>
                            {" "}
                            {data.deviceStatus !== null
                              ? data.deviceStatus.status_name
                              : "N/A"}
                          </td>
                          <td>{data?.cabinets?.name} </td>
                          <td>{data.support_expiry} </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
      {activeTab.rackElevation ? (
        <div className="col-lg-11 w_89">
          <div className="tblop-117">
            <div className="ttl_op-117">
              <h6
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "600",
                  fontSize: "1.25rem",
                }}
              >
                {selectedDataCenter.name}
              </h6>

              {fileExists ? (
                <div style={{ float: "left" }}>
                  Page {counter} of {i}{" "}
                  {prevdisable ? (
                    <span style={{ fontSize: "1.25rem" }}>&laquo;</span>
                  ) : (
                    <span
                      onClick={() => prevImage(counter)}
                      style={{ fontSize: "1.25rem", cursor: "pointer" }}
                    >
                      &laquo;
                    </span>
                  )}
                  {nextdisable ? (
                    <span style={{ fontSize: "1.25rem" }}>&nbsp;&raquo;</span>
                  ) : (
                    <span
                      onClick={() => nextImage(counter)}
                      style={{ fontSize: "1.25rem", cursor: "pointer" }}
                    >
                      &nbsp;&raquo;
                    </span>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className="op-117_table mt-1">
            <div
              className="table-responsive"
              style={{ overflow: "auto", height: "500px" }}
            >
              {fileExists ? (
                <img src={image} style={{ width: "100%" }} />
              ) : (
                <h2 style={{ textAlign: "center" }}>Data Not Available</h2>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {activeTab.topology ? (
        <div className="col-lg-11 w_89">
          <div className="tblop-117">
            <div className="ttl_op-117">
              <h6
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "600",
                  fontSize: "1.25rem",
                }}
              >
                {selectedDataCenter.name}
              </h6>
              {fileExists ? (
                <div style={{ float: "left" }}>
                  Page {counter} of {i}{" "}
                  {prevdisable ? (
                    <span style={{ fontSize: "1.25rem" }}>&laquo;</span>
                  ) : (
                    <span
                      onClick={() => prevImage(counter)}
                      style={{ fontSize: "1.25rem", cursor: "pointer" }}
                    >
                      &laquo;
                    </span>
                  )}
                  {nextdisable ? (
                    <span style={{ fontSize: "1.25rem" }}>&nbsp;&raquo;</span>
                  ) : (
                    <span
                      onClick={() => nextImage(counter)}
                      style={{ fontSize: "1.25rem", cursor: "pointer" }}
                    >
                      &nbsp;&raquo;
                    </span>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className="op-117_table mt-1">
            <div
              className="table-responsive"
              style={{ overflow: "auto", height: "500px" }}
            >
              {fileExists ? (
                <img src={image} style={{ height: "450px", width: "100%" }} />
              ) : (
                <h2 style={{ textAlign: "center" }}>Data Not Available</h2>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(Network);
