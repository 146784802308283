import { XError } from "components/common"
import AuthContext from "context"
import moment from "moment"
import React, { useContext, useEffect, useRef, useState } from "react"
import Common from "services/commonService"
import networkServices from "services/networkServices"
import Swal from "sweetalert2"
import { LogoutOnSessionExpire } from "common/helpers"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

export default function NetworkModel(props) {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const modalRef = useRef(null)
  const authContext = useContext(AuthContext)
  const [hostName, setHostName] = useState("")
  const [make, setMake] = useState("")
  const [modelName, setModelName] = useState("")
  const [function_name, setFunctionName] = useState("")
  const [sn, setSN] = useState("")
  const [cabinet_id, setCabinetID] = useState("")
  const [device_status, setDeviceStatus] = useState("")
  const [top_u_position, setTopUPosition] = useState("")
  const [support_status, setSupportStatus] = useState("")
  const [support_expiry, setSupportExpiry] = useState("")
  const [makeData, setMakeData] = useState([])
  const [networkFunction, setNetworkFunction] = useState([])
  const [modelData, setModelData] = useState([])
  const [deviceStatusData, setDeviceStatusData] = useState([])
  const [cabinet, setCabinetData] = useState([])
  const { t } = useTranslation()
  const [error, setError] = useState({
    name: "",
    status: "",
  })

  useEffect(() => {
    networkServices.getMake(authContext.getToken).then((res) => {
      setMakeData(res.data.data)
    })

    networkServices
      .getNetworkFunction(authContext.getToken)
      .then((res) => setNetworkFunction(res.data.data))

    // networkServices.getDeviceStatus(authContext.getToken).then(res => setDeviceStatusData(res.data.data));

    // Common.status().then((res) => setDeviceStatusData(res.data.data))
    if (authContext.getStatusData) {
      setDeviceStatusData(authContext.getStatusData)
    }

    // CabinetService.getAllCabinetsDataCenter(authContext.getToken, props.data_center_id.id).then(res => setCabinetData(res.data.data));
    setCabinetData(props.cabinetData)
    setIsOpen(props.show)
  }, [props.show, props.cabinetData])

  const getModelById = async (id) => {
    networkServices
      .getModel(authContext.getToken, id)
      .then((res) => setModelData(res.data.data))
    if (id === "null") {
      setMake("")
    }
    setModelName("")
  }

  const checkSetValue = async (id) => {
    if (id === "null") {
      setModelName("")
    }
  }

  const onSubmit = async (data) => {
    if (checkValidation()) {
      await networkServices
        .addNetworkDevice(authContext.getToken, {
          name: hostName,
          desc: "",
          make: make,
          model: modelName,
          function_name: function_name,
          sn: sn,
          data_center: props.data_center_id.id,
          cabinet_id: cabinet_id,
          device_status: device_status,
          support_status: support_status,
          top_u_position: top_u_position,
          support_expiry: support_expiry,
        })
        .then(async (res) => {
          props.selectDataCenterFloor(props.data_center_id)
          closeModal()
          setIsLoading(false)
          Swal.fire(t("network.new_network_device_created"))
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  const checkValidation = () => {
    let error = {
      make: "",
      model: "",
      function: "",
    }

    let flag = true

    if (make === "" || make === null || make === undefined) {
      error.make = "The make field is required."
      flag = false
    }
    if (modelName === "" || modelName === null || modelName === undefined) {
      error.model = "The model field is required."
      flag = false
    }
    if (
      function_name === "" ||
      function_name === null ||
      function_name === undefined
    ) {
      error.function = "The function field is required."
      flag = false
    }

    setError({ ...error })

    return flag
  }

  const closeModal = () => {
    setHostName("")
    setMake("")
    setModelName("")
    setSN("")
    setFunctionName("")
    setCabinetID("")
    setDeviceStatus("")
    setSupportExpiry("")
    setTopUPosition("")
    setSupportStatus("")
    setModelData("")

    setError({
      make: "",
      model: "",
      function: "",
    })

    modalRef.current.click()
  }
  return (
    <React.Fragment>
      <div
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header mt-59"
              style={{ marginBottom: "1rem" }}
            >
              <h3 className="modal-title">{t("network.add_device_details")}</h3>
              <button
                style={{
                  cursor: "pointer",
                  background: "transparent",
                  border: "none",
                }}
                ref={modalRef}
                data-bs-dismiss="modal"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div
                  className="card-body"
                  style={{ padding: "0px", overflow: "hidden" }}
                >
                  <div className="basic-form">
                    <form>
                      <div className="row">
                        <div className="col-md-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("network.hostname")}
                          </label>
                          <input
                            type="text"
                            value={hostName}
                            className="form-control"
                            onChange={(event) =>
                              setHostName(event.target.value)
                            }
                            placeholder={t("network.hostname")}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("network.vendor")}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <select
                            className="default-select form-control wide"
                            value={make}
                            onChange={(event) => {
                              setMake(event.target.value)
                              getModelById(event.target.value)
                            }}
                          >
                            <option value="null">
                              {t("network.choose")}...
                            </option>
                            {makeData &&
                              makeData.map((m) => {
                                return (
                                  <option key={m.id} value={m.id}>
                                    {m.name}
                                  </option>
                                )
                              })}
                          </select>
                          <XError message={error.make} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("network.function")}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <select
                            className="default-select form-control wide"
                            value={function_name}
                            onChange={(event) => {
                              setFunctionName(event.target.value)
                            }}
                          >
                            <option value="">{t("network.choose")}...</option>
                            {networkFunction &&
                              networkFunction.map((nf) => {
                                return (
                                  <option key={nf.id} value={nf.id}>
                                    {nf.name}
                                  </option>
                                )
                              })}
                          </select>
                          <XError message={error.function} />
                        </div>
                        <div className="col-md-6 col-sm-6 mt-2313">
                          <label className="form-label">
                            {t("network.model")}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <select
                            className="default-select form-control wide"
                            value={modelName}
                            onChange={(event) => {
                              setModelName(event.target.value)
                              checkSetValue(event.target.value)
                            }}
                          >
                            <option value="null">
                              {t("network.choose")}...
                            </option>
                            {modelData &&
                              modelData.map((md) => {
                                return (
                                  <option key={md.id} value={md.id}>
                                    {md.name}
                                  </option>
                                )
                              })}
                          </select>
                          <XError message={error.model} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("network.serial_number")}
                          </label>
                          <input
                            type="text"
                            value={sn}
                            className="form-control"
                            onChange={(event) => setSN(event.target.value)}
                            placeholder={t("network.serial_number")}
                          />
                        </div>
                        <div className="col-md-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("network.cabinet_id")}
                          </label>
                          <select
                            className="default-select form-control wide"
                            value={cabinet_id}
                            onChange={(event) =>
                              setCabinetID(event.target.value)
                            }
                          >
                            <option>{t("network.choose")}...</option>
                            {cabinet &&
                              cabinet.map((md) => {
                                return (
                                  <option key={md.id} value={md.id}>
                                    {md.name}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("network.status")}
                          </label>
                          <select
                            className="default-select form-control wide"
                            value={device_status}
                            onChange={(event) =>
                              setDeviceStatus(event.target.value)
                            }
                          >
                            <option>{t("network.choose")}...</option>
                            {deviceStatusData &&
                              deviceStatusData.map((status) => {
                                if (status.status_type_id === 2) {
                                  return (
                                    <option value={status.id} key={status.id}>
                                      {status.status_name}
                                    </option>
                                  )
                                }
                              })}
                          </select>
                        </div>
                        <div className="col-md-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("network.top_u_position")}
                          </label>
                          <input
                            type="text"
                            value={top_u_position}
                            className="form-control"
                            placeholder={t("network.top_u_position")}
                            onChange={(event) =>
                              setTopUPosition(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <h5
                          className="mt-3"
                          style={{
                            color: "#147AD6",
                            fontWeight: "700",
                            marginBottom: "0px",
                          }}
                        >
                          {t("network.maintenance_and_support")}
                        </h5>
                        <div className="col-md-6 mt-2">
                          <label className="form-label">
                            {" "}
                            {t("network.status")}
                          </label>
                          <select
                            className="default-select form-control wide"
                            value={support_status}
                            onChange={(event) =>
                              setSupportStatus(event.target.value)
                            }
                          >
                            <option>{t("network.choose")}...</option>
                            <option value={1}>Active</option>
                            <option value={2}>Inactive</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-2">
                          <label className="form-label">
                            {t("network.expiry_date")}
                          </label>
                          <input
                            type="date"
                            value={support_expiry}
                            className="form-control"
                            placeholder={t("network.expiry_date")}
                            onChange={(event) =>
                              setSupportExpiry(event.target.value)
                            }
                            onFocus={() => "this.type='date'"}
                            min={moment(new Date()).format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>

                      <div
                        className="toolbar toolbar-bottom mt-51 d-flex justify-content-end gap-2"
                        role="toolbar"
                        style={{ padding: ".5rem 0rem" }}
                      >
                        <button
                          type="button"
                          onClick={closeModal}
                          className="btn btn-outline-primary mr_1"
                        >
                          {" "}
                          {t("common.cancel")}{" "}
                        </button>
                        {isLoading ? (
                          <button type="button" className="btn btn-primary">
                            {" "}
                            {t("common.loading")} ...{" "}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => onSubmit(props.data_center_id)}
                            className="btn btn-primary"
                          >
                            {t("common.save")}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
