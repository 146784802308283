import { axiosWithToken as axios } from 'utils/axios';

class CabinetBreakerRelation {
  async getBreakers(token, breakerId) {
    return await axios(token).get(`getCabinetBreakerRelation?breaker_id=${breakerId}`);
  }

  async getCabinet(token, cabinetId) {
    return await axios(token).get(`getCabinetBreakerRelation?cabinet_id=${cabinetId}`);
  }  
}

export default new CabinetBreakerRelation();