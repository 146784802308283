import { useTranslation } from "react-i18next";

const ReportsNavTab = ({ setStatus, setPageNumber, status }) => {
  const { t } = useTranslation();
  const activeClass = (selectedStatus) => {
    if (status === selectedStatus) {
      return "btn btn-secondary";
    }
    return "btn btn-light";
  };

  return (
    <div className="de_line_btn" style={{ width: "12rem" }}>
      <button
        type="button"
        onClick={() => {
          setStatus("");
          setPageNumber(1);
        }}
        className={activeClass("")}
      >
        All
        {/* {t("reports.general")} */}
      </button>

      <button
        type="button"
        onClick={() => {
          setStatus(1);
          setPageNumber(1);
        }}
        className={activeClass(1)}
      >
        Success
        {/* {t("reports.odoo")} */}
      </button>
      <button
        type="button"
        onClick={() => {
          setStatus("0");
          setPageNumber(1);
        }}
        className={activeClass("0")}
      >
        Failed
        {/* {t("reports.failed_records")} */}
      </button>
    </div>
  );
};

export default ReportsNavTab;
