const cardData = [
    {
        title: 'Install',
        graph: [],
        totalNumber:0,
        className:'',
        ticketno:1,
        types:[
            {
                title:"Pending Provisioning"
            },
            {
                title:"In Service"
            },
            {
                title:"Pending Change"
            },
            {
                title:"Customer Acceptance"
            },
            {
                title:"In Service (PP)"
            },
            {
                title:"New"
            },
            {
                title:"Work In Progress"
            } 
        ]
    },
    {
        title: 'Deinstall',
        graph: [],
        className:'',
        totalNumber:0,
        ticketno:2,
        types:[
            {
                title:"Pending Provisioning"
            },
            {
                title:"In Service"
            },
            {
                title:"Pending Change"
            },
            {
                title:"Customer Acceptance"
            },
            {
                title:"In Service (PP)"
            },
            {
                title:"New"
            },
            {
                title:"Work In Progress"
            }  
        ]
    },
    {
        title: 'Upgrade/Downgrade',
        graph: [],
        className:'',
        totalNumber:0,
        ticketno:4,
        types:[
            {
                title:"Pending Provisioning"
            },
            {
                title:"In Service"
            },
            {
                title:"Pending Change"
            },
            {
                title:"Customer Acceptance"
            },
            {
                title:"In Service (PP)"
            },
            {
                title:"New"
            },
            {
                title:"Work In Progress"
            }  
        ]
    },
    {
        title: 'Others',
        graph: [],
        className:'',
        totalNumber:0,
        ticketno:3,
        types:[
            {
                title:"Pending Provisioning"
            },
            {
                title:"In Service"
            },
            {
                title:"Pending Change"
            },
            {
                title:"Customer Acceptance"
            },
            {
                title:"In Service (PP)"
            },
            {
                title:"New"
            },
            {
                title:"Work In Progress"
            }  
        ]
    } 
];

export {
cardData
}