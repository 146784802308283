/* eslint-disable react-hooks/exhaustive-deps */
import { css } from 'glamor'
import { useMemo } from 'react'
import { Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import { TICKET_COMMON_STYLES } from 'views/super-admin/Tickets/shared/constants'

const styles = {
  operationSiteLabel: {
    fontSize: '16px !important',
    fontWeight: '400 !important'
  },
  operationSiteSelect: {
    fontSize: '16px !important'
  },
  selectColumn: {
    paddingRight: '0px'
  },
  labelColumn: {
    marginTop: '5px',
    paddingLeft: '12px !important',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  ...TICKET_COMMON_STYLES
}
const BoxSelect = ({ id, value, setFieldValue, options, label, placeholder, active }) => {
  const handleSelectChange = (selected) => {
    setFieldValue(id, selected.value)
  }
  return (
    <>
      <Col {...css(styles.labelColumn)}>
        <Form.Label {...css(styles.operationSiteLabel)}>
          {label}
          <span {...css(styles.span)} className='red_star'>
            *
          </span>
        </Form.Label>
      </Col>
      <Col {...css(styles.selectColumn)}>
        <Select
          id={id}
          name={id}
          isDisabled={!active}
          value={value !== '' ? options.find(item => item.value === value) : null}
          hideSelectedOptions={false}
          options={options}
          className='basic-multi-select'
          classNamePrefix='select'
          onChange={handleSelectChange}
          placeholder={active && placeholder}
        />
      </Col>
    </>
  )
}

const BoxSelectMemo = ({
  id,
  value,
  setFieldValue,
  options,
  label,
  placeholder,
  active
}) => {
  return useMemo(() => {
    return (
      <BoxSelect
        id={id}
        value={value}
        setFieldValue={setFieldValue}
        options={options}
        label={label}
        placeholder={placeholder}
        active={active}
      />
    )
  }, [
    id, value, options, label, placeholder, active
  ])
}

export default BoxSelectMemo
