import { css } from 'glamor';
import { useTranslation } from 'react-i18next';
import VisitorRow from './VisitorRow';

const styles = {
  container: {
    padding: '5px 10px 10px 10px !important',
    margin: '0 10px 0 10px !important',
    border: '1px solid #17a2b8',
    borderRadius: '5px',
    height: '260px',
    overflowY: 'auto'
  },
  empty: {
    fontSize: '14px',
    fontStyle: 'italic'
  }
};

const VisitorList = ({
  visitors,
  onDelete,
  onChange,
  onSave,
  onEdit,
  onNo,
  isEditMode,
  setIsEditMode
}) => {
  const { t } = useTranslation();
  return (
    <div {...css(styles.container)}>
      {visitors.length > 0 &&
        visitors.map(({ id, visitorName }) => (
          <VisitorRow
            key={id}
            id={id}
            name={visitorName}
            handleDelete={onDelete}
            handleChange={onChange}
            handleSave={onSave}
            handleEdit={onEdit}
            handleNo={onNo}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
          />
        ))}
      {visitors.length === 0 && (
        <div className="p-4 d-flex flex-column justify-content-around align-items-center">
          <div {...css(styles.empty)}>{t('create-ticket.site-access.visitor-list-empty')}</div>
        </div>
      )}
    </div>
  );
};

export default VisitorList;
