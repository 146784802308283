import React, { useEffect, useState, useContext } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AuthContext from "context";
import moment from "moment";

const DragAndDrop = ({
  isDraftEdit,
  data,
  handleDelete,
  handleUpdateOrder,
  ticketStatus,
}) => {
  const contextStore = useContext(AuthContext);
  const [items, setItems] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  useEffect(() => {
    setItems(data);
  }, [data]);

  // useEffect(() => {
  //   console.log(contextStore.getAuth);
  // }, [contextStore]);
  const convertDate = (date) => {
    if (
      contextStore?.getAuth &&
      contextStore?.getAuth?.timezone &&
      contextStore?.getAuth?.timezone?.timezone &&
      contextStore?.getAuth?.timezone?.timezone_prefix
    ) {
      const hongKongTime = moment.tz(date, "Asia/Hong_Kong");
      return (
        hongKongTime
          .clone()
          .tz(contextStore?.getAuth?.timezone?.timezone)
          .format("YYYY-MM-DD HH:mm:ss") +
        ` ${contextStore?.getAuth?.timezone?.timezone_prefix}`
      );
    } else {
      return (
        moment(data.close_date)
          .tz("Asia/Hong_Kong")
          .format("YYYY-MM-DD HH:mm:ss") + `HKT`
      );
    }
  };
  const handleDragStart = (e, item) => {
    if (isDraftEdit) {
      setDraggingItem(item);
      e.dataTransfer.setData("text/plain", "");
    }
  };

  const handleDragEnd = () => {
    if (isDraftEdit) {
      setDraggingItem(null);
    }
  };

  const handleDragOver = (e) => {
    if (isDraftEdit) {
      e.preventDefault();
    }
  };

  const handleDrop = (e, targetItem) => {
    if (isDraftEdit) {
      if (!draggingItem) return;

      const currentIndex = items.indexOf(draggingItem);
      const targetIndex = items.indexOf(targetItem);

      if (currentIndex !== -1 && targetIndex !== -1) {
        const newItems = [...items];
        newItems.splice(currentIndex, 1);
        newItems.splice(targetIndex, 0, draggingItem);
        setItems(newItems);
        handleUpdateOrder(
          newItems.map((item, index) => ({
            ...item,
            order: index + 1, // Set order based on index
          })),
          "isOrderUpdate=true"
        );
      }
    }
  };

  const checkPermission = (isAppended) => {
    if (isAppended == 1) {
      if (
        ticketStatus != "Completed" &&
        ticketStatus != "Closed" &&
        ticketStatus != "Solved"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <div className="sortable-list">
      {items.map((item, index) => (
        <div
          key={item.id}
          className={`item ${item === draggingItem ? "dragging" : ""}`}
          draggable="true"
          onDragStart={(e) => handleDragStart(e, item)}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, item)}
        >
          {isDraftEdit && (
            <div style={{ marginRight: "1rem" }}>
              <DragIndicatorIcon />
            </div>
          )}

          <div style={{ width: "100%", whiteSpace: "pre-line" }}>
            {item.description}

            <div className="d-flex justify-content-between mt-1">
              <div>
                <span style={{ color: "black" }}>
                  Created By: {JSON.parse(item.created_by).name}
                </span>
              </div>
              <div>
                <span style={{ color: "black" }}>
                  Created At: {convertDate(item.createdAt)}
                </span>
              </div>
            </div>
          </div>
          {isDraftEdit && checkPermission(item.is_appended) && (
            <IconButton
              edge="end"
              style={{ marginRight: "0.5rem" }}
              onClick={() => {
                handleDelete(item.id);
              }}
            >
              <DeleteIcon style={{ color: "black" }} />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};

export default DragAndDrop;
