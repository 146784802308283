import { XError } from "components/common";
import StorageContext from "context";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import RoleModel from "services/roleServices";
import Swal from "sweetalert2";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import ticketService from "services/ticketService";
import CloseIcon from "@mui/icons-material/Close";
const errorInit = {
  name: "",
  country: "",
  //"dataCenter":"",
  space: "",
  monitorAndEvalution: "",
  network: "",
  tickets: "",
  incident_mode: "",
  provisioning: "",
  accounts: "",
  esg: "",
  dcs: "",
  contact: "",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

function App({ token, data, show, setShow, retriveCurrentData, permission }) {
  const contextStore = React.useContext(StorageContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const modalRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [dataCenters, setDataCenters] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [placeHolder, setPlaceHolder] = useState("All");
  const [placeHolderTicket, setPlaceHolderTicket] = useState("All");
  const [state, setState] = useState({
    name: "",
    country: "",
    dataCenter: "",
    ticketType: "",
    incident_mode: 0,
    space: 1,
    monitorAndEvalution: 1,
    network: 1,
    tickets: 1,
    provisioning: 1,
    accounts: 0,
    esg: 0,
    dcs: 0,
    userManagement: false,
    contact: 1,
  });
  const [errors, setError] = useState(errorInit);
  const { t } = useTranslation();
  useEffect(() => {
    getCountries();
    getDataCenters(data.country_id);
    getTicketTypes();
    let data_Center = addDataCenter(data.role_datacenter, data.country);
    let ticket_type = addTicketType(data.role_ticket);
    setIsOpen(show);
    console.log(data);
    setState({
      id: data.id,
      name: data.name,
      country: data.country_id,
      dataCenter: data_Center,
      ticketType: !!ticket_type ? ticket_type : "",
      space: JSON.parse(data.space),
      monitorAndEvalution: JSON.parse(data.m_e),
      network: JSON.parse(data.network),
      tickets: JSON.parse(data.tickets),
      incident_mode: data.incident_mode,
      provisioning: JSON.parse(data.provisioning),
      accounts: data.accounts,
      esg: data.esg,
      dcs: data.dcs,
      userManagement: data.user_management ? true : false,
      status: data.is_active,
      contact: JSON.parse(data.contact),
    });

    if (ticket_type == null) {
      setPlaceHolderTicket("All");
    }
    //addDataCenter(data.role_datacenter)

    return () => {
      //setCountries([]);
      //setDataCenters([]);
      setState({});
    };
  }, [show, setState]);

  // function openModal() {
  //     setIsOpen(true);
  // }

  function closeModal() {
    setIsOpen(false);
    setShow(false);
  }

  const addDataCenter = (data_centers, country) => {
    if (data_centers.length > 0) {
      if (country.country_code === "global") {
        //setPlaceHolder("All")
        //return {"label": "All", "value":1,select_all:true}
        return null;
      } else {
        let dataCenter = [];
        for (const k of data_centers) {
          dataCenter.push({
            label: k.datacenter.name,
            value: k.data_center_id,
          });
        }

        return dataCenter;
      }

      //setState({...state, dataCenter:dataCenters})
    } else {
      return null;
    }
  };
  const addTicketType = (ticket_types) => {
    if (ticket_types.length > 0) {
      let ticketType = [];
      for (const k of ticket_types) {
        ticketType.push({
          label: k.ticketTypes.name,
          value: k.ticket_type_id,
        });
      }
      return ticketType;

      //setState({...state, dataCenter:dataCenters})
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (
      state.ticketType &&
      state.ticketType.length &&
      state.ticketType.length > 0
    ) {
      const checkIfIncident = state.ticketType.filter(
        (el) => el.label == "Incidents"
      );
      if (checkIfIncident.length == 0) {
        setState({ ...state, incident_mode: 0 });
      }
    }
  }, [state.ticketType]);
  const checkPermissionForIncidentsTicket = () => {
    if (state.tickets != "1") {
      if (
        state.ticketType == "" ||
        (!!state.ticketType &&
          state.ticketType.length &&
          state.ticketType.length == 0)
      ) {
        return true;
      } else if (
        !!state.ticketType &&
        state.ticketType.length &&
        state.ticketType.length > 0
      ) {
        const checkIfIncident = state.ticketType.filter(
          (el) => el.label == "Incidents"
        );
        if (checkIfIncident.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };
  const getCountries = async () => {
    await RoleModel.countryService(token, true).then(async ({ data }) => {
      await setCountries(data.data);
    });
  };

  const getDataCenters = async (id) => {
    await RoleModel.dataCenterByCountryId(token, { id }).then(
      async ({ data }) => {
        await setDataCenters(data.data);
      }
    );
  };

  const getTicketTypes = async (id) => {
    await ticketService.getTicketTypes(token).then(async ({ data }) => {
      await setTicketTypes(data.data);
    });
  };

  const onChangeCountry = async (id) => {
    //setState({...state,country:id})

    await getDataCenters(id);
    if (id) {
      setPlaceHolder("All");
    } else {
      setPlaceHolder("Select");
    }
    setState({ ...state, dataCenter: null, country: id });

    // let filterCountry = countries
    // console.log(filterCountry)
    // filterCountry = filterCountry.filter(k => k.id == id)
    // if(filterCountry.length > 0){
    // 	if(filterCountry[0].country_code == "global"){
    // 		setState({...state,dataCenter:{"label": "All", "value":1,select_all:true},country:id})
    // 	}else{
    // 		setState({...state,dataCenter:null,country:id})
    // 	}
    // }else{
    // 	setState({...state,country:id,dataCenter:null})
    // }
  };

  const renderCountryList = () => {
    return (
      countries &&
      countries.map((country) => {
        return (
          <option value={country.id} key={country.id}>
            {country.name}{" "}
          </option>
        );
      })
    );
  };

  // const renderDataCenterList = () => {

  // 	return dataCenters && dataCenters.map(dataCenter => {
  // 		return <option value={dataCenter.id} key={dataCenter.id}>{dataCenter.name} </option>
  // 	})
  // }
  const onChangeStatus = () => {
    const status = data.is_active ? 0 : 1;
    RoleModel.updateStatus(token, {
      status,
      id: data.id,
    })
      .then(async () => {
        retriveCurrentData();

        closeModal();
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        // 404 page
      });
  };

  const onSubmit = () => {
    console.log(state);
    if (checkValidation()) {
      RoleModel.updateRoleAndPermissions(token, state)
        .then(async (res) => {
          retriveCurrentData();

          closeModal();

          Swal.fire(t("rolepermission.role_updated"));
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          let error = {
            name: "",
            country: "",
            //"dataCenter":"",
            space: "",
            monitorAndEvalution: "",
            network: "",
            tickets: "",
            incident_mode: "",
            provisioning: "",
            accounts: "",
            esg: "",
            dcs: "",
            contact: "",
          };
          let serverError = err.response.data.errors;

          if (serverError?.name) {
            error.name = serverError.name;
          }
          if (serverError?.country) {
            error.country = serverError.country;
          }

          if (serverError?.space) {
            error.space = serverError.space;
          }
          if (serverError?.monitorAndEvalution) {
            error.monitorAndEvalution = serverError.monitorAndEvalution;
          }
          if (serverError?.network) {
            error.network = serverError.network;
          }
          if (serverError?.tickets) {
            error.tickets = serverError.tickets;
          }
          if (serverError?.provisioning) {
            error.provisioning = serverError.provisioning;
          }
          if (serverError?.accounts) {
            error.accounts = serverError.accounts;
          }
          if (serverError?.contact) {
            error.contact = serverError.contact;
          }
          if (serverError?.esg) {
            error.esg = serverError.esg;
          }
          if (serverError?.dcs) {
            error.dcs = serverError.dcs;
          }
          if (serverError?.incident_mode) {
            error.incident_mode = serverError.incident_mode;
          }
          setError({ ...error });
        });
    }
  };

  const checkValidation = () => {
    let error = {
      name: "",
      country: "",
      //"dataCenter":"",
      space: "",
      monitorAndEvalution: "",
      network: "",
      tickets: "",
      incident_mode: "",
      provisioning: "",
      accounts: "",
      esg: "",
      dcs: "",
      contact: "",
    };

    const {
      name,
      country,
      //	dataCenter,
      space,
      monitorAndEvalution,
      network,
      tickets,
      incident_mode,
      provisioning,
      accounts,
      esg,
      dcs,
      contact,
    } = state;

    let flag = true;

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required.";
      flag = false;
    }

    if (country === "" || country === null || country === undefined) {
      error.country = "The country field is required.";
      flag = false;
    }
    /*if (dataCenter === "" || dataCenter === null || dataCenter === undefined) {

			error.dataCenter = "The dataCenter field is required.";
			flag = false;
		}*/
    if (space.length === 0) {
      error.space = "The space field is required.";
      flag = false;
    }
    if (monitorAndEvalution.length === 0) {
      error.monitorAndEvalution = "The M&E field is required.";
      flag = false;
    }
    if (network.length === 0) {
      error.network = "The network field is required.";
      flag = false;
    }
    if (tickets.length === 0) {
      error.tickets = "The tickets field is required.";
      flag = false;
    }
    if (provisioning.length === 0) {
      error.provisioning = "The provisioning field is required.";
      flag = false;
    }
    if (accounts.length === 0) {
      error.accounts = "The accounts field is required.";
      flag = false;
    }
    if (contact.length === 0) {
      error.contact = "The contacts field is required.";
      flag = false;
    }
    if (esg.length === 0) {
      error.esg = "The esg field is required.";
      flag = false;
    }
    if (dcs.length === 0) {
      error.dcs = "The dcs field is required.";
      flag = false;
    }
    if (dcs.incident_mode === 0) {
      error.incident_mode = "The Incident Mode field is required.";
      flag = false;
    }

    setError({ ...error });
    return flag;
  };
  const onDelete = () => {
    closeModal();

    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        RoleModel.destroy(token, { id: data.id })
          .then(async () => {
            retriveCurrentData();
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            // redirect 404
          });
      }
    });
  };

  const renderDataCenterMulti = () => {
    let data_center = [];

    for (const dataCenter of dataCenters) {
      data_center.push({ value: dataCenter.id, label: dataCenter.name });
    }
    return data_center;
  };

  const renderTicketTypeMulti = () => {
    const uniqueData = Array.from(
      new Map(ticketTypes.map((item) => [item.ticket_types_id, item])).values()
    );
    let ticket_types = [];
    uniqueData.forEach((ticketType) => {
      if (ticketType.TicketType != "Others") {
        ticket_types.push({
          value: ticketType.ticket_types_id,
          label: ticketType.TicketType,
        });
      }
    });
    ticket_types.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    return ticket_types;
  };

  const onChangeDataCenter = async (value) => {
    setState({ ...state, dataCenter: value });
    setDisabled(false);
  };

  const onChangeTicketType = async (value) => {
    setState({ ...state, ticketType: value });
    setDisabled(false);
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        {/* <div className="modal-dialog modal-lg">
		        <div className="modal-content">*/}
        <div className="modal-header mt-59">
          <h3 className="modal-title"> {t("rolepermission.edit_role")}</h3>
          <CloseIcon
            type="button"
            onClick={() => closeModal()}
            data-bs-dismiss="modal"
          />
        </div>
        <div className="modal-body">
          <div className="card">
            <div
              className="card-body"
              style={{ padding: "0px", overflow: "hidden" }}
            >
              <div className="basic-form">
                <form>
                  <div className="row">
                    <div className="mb-3 col-md-12 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.name")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        value={state.name}
                        className="form-control"
                        onChange={(event) => {
                          setState({ ...state, name: event.target.value });
                          setDisabled(false);
                        }}
                        placeholder={t("rolepermission.name")}
                      />
                      <XError message={errors.name} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.country")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.country}
                        onChange={(event) => {
                          onChangeCountry(event.target.value);
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        {renderCountryList()}
                      </select>

                      <XError message={errors.country} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.data_centres")}
                      </label>

                      <Select
                        options={renderDataCenterMulti()}
                        className="default-select wide"
                        isMulti={true}
                        isClearable={true}
                        onChange={onChangeDataCenter}
                        value={state.dataCenter}
                        placeholder={placeHolder}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.tickets")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.tickets}
                        onChange={(event) => {
                          setState({ ...state, tickets: event.target.value });
                          setDisabled(false);
                          if (event.target.value == "1") {
                            setState({
                              ...state,
                              incident_mode: 0,
                              tickets: event.target.value,
                              ticketType: "",
                            });
                          }
                          setPlaceHolderTicket(
                            event.target.value == "1" ? "Select" : "All"
                          );
                        }}
                        className="default-select form-control wide"
                      >
                        {permission &&
                          permission.map((per) => {
                            return <option value={per.id}>{per.name}</option>;
                          })}
                      </select>

                      <XError message={errors.tickets} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.ticket_types")}
                      </label>

                      <Select
                        options={
                          state.tickets == "1" ? [] : renderTicketTypeMulti()
                        }
                        className="default-select wide"
                        isMulti={true}
                        isClearable={true}
                        onChange={onChangeTicketType}
                        value={state.ticketType}
                        placeholder={placeHolderTicket}
                      />
                      <div
                        class="form-check form-switch pt-2 d-flex align-items-center"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label className="form-label" style={{ float: "left" }}>
                          {t("rolepermission.incident_mode")}
                        </label>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          style={{ marginLeft: "1rem", width: "2.5rem" }}
                          disabled={!checkPermissionForIncidentsTicket()}
                          checked={state.incident_mode == 1 ? true : false}
                          onChange={(event) => {
                            setDisabled(false);
                            setState({
                              ...state,
                              incident_mode: event.target.checked ? 1 : 0,
                            });
                          }}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.provisioning")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.provisioning}
                        onChange={(event) => {
                          setState({
                            ...state,
                            provisioning: event.target.value,
                          });
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        {permission &&
                          permission.map((per) => {
                            return <option value={per.id}>{per.name}</option>;
                          })}
                      </select>

                      <XError message={errors.provisioning} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.space")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.space}
                        onChange={(event) => {
                          setState({ ...state, space: event.target.value });
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        {permission &&
                          permission.map((per) => {
                            return <option value={per.id}>{per.name}</option>;
                          })}
                      </select>

                      <XError message={errors.space} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.m_and_e")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.monitorAndEvalution}
                        onChange={(event) => {
                          setState({
                            ...state,
                            monitorAndEvalution: event.target.value,
                          });
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        {permission &&
                          permission.map((per) => {
                            return <option value={per.id}>{per.name}</option>;
                          })}
                      </select>

                      <XError message={errors.monitorAndEvalution} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.network")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.network}
                        onChange={(event) => {
                          setState({
                            ...state,
                            network: event.target.value,
                          });
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        {permission &&
                          permission.map((per) => {
                            return <option value={per.id}>{per.name}</option>;
                          })}
                      </select>

                      <XError message={errors.network} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.accounts")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.accounts}
                        onChange={(event) => {
                          setState({
                            ...state,
                            accounts: event.target.value,
                          });
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        <option key={0} value={0}>
                          No
                        </option>
                        <option key={1} value={1}>
                          Yes
                        </option>
                      </select>

                      <XError message={errors.accounts} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.esg")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.esg}
                        onChange={(event) => {
                          setState({
                            ...state,
                            esg: Number(event.target.value),
                          });
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        <option key={0} value={0}>
                          No
                        </option>
                        <option key={1} value={1}>
                          Yes
                        </option>
                      </select>

                      <XError message={errors.esg} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        Contacts <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.contact}
                        onChange={(event) => {
                          setState({
                            ...state,
                            contact: event.target.value,
                          });
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        {permission &&
                          permission.map((per) => {
                            return (
                              <option key={per.id} value={per.id}>
                                {per.name}
                              </option>
                            );
                          })}
                      </select>
                      <XError message={errors.contact} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {t("rolepermission.dcs")}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        value={state.dcs}
                        onChange={(event) => {
                          setState({
                            ...state,
                            dcs: Number(event.target.value),
                          });
                          setDisabled(false);
                        }}
                        className="default-select form-control wide"
                      >
                        <option key={0} value={0}>
                          No
                        </option>
                        <option key={1} value={1}>
                          Yes
                        </option>
                      </select>

                      <XError message={errors.dcs} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("rolepermission.user_management")}
                      </label>

                      <div className="row">
                        <div className="col-md-6 col-sm-6 mt_11">
                          <div className="form-check">
                            <input
                              type="radio"
                              checked={
                                state.userManagement === true ? true : false
                              }
                              onChange={(event) => {
                                setState({ ...state, userManagement: true });
                                setDisabled(false);
                              }}
                              name="optradio"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              {t("rolepermission.allow")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 mt_11">
                          <div className="form-check">
                            <input
                              type="radio"
                              checked={
                                state.userManagement === true ? false : true
                              }
                              onChange={(event) => {
                                setState({ ...state, userManagement: false });
                                setDisabled(false);
                              }}
                              name="optradio"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault2"
                            >
                              {t("rolepermission.disallow")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="toolbar toolbar-bottom mt-51 d-flex"
                    role="toolbar"
                  >
                    <button
                      type="button"
                      onClick={() => onChangeStatus()}
                      style={{ marginRight: "1rem" }}
                      className="btn btn-outline-danger mr_1"
                    >
                      {state.status ? "Archive" : "Active"}
                    </button>
                    <button
                      type="button"
                      style={{ marginRight: "1rem" }}
                      onClick={() => onDelete()}
                      className="btn btn-danger mr_1"
                    >
                      {t("common.delete")}
                    </button>
                    <button
                      type="button"
                      onClick={closeModal}
                      style={{ marginRight: "1rem" }}
                      className="btn btn-outline-primary mr_1"
                    >
                      {t("common.cancel")}
                    </button>
                    <button
                      type="button"
                      onClick={onSubmit}
                      disabled={disabled}
                      className="btn btn-primary"
                    >
                      {t("common.save")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/*</div>
		    </div>*/}
      </Modal>
    </div>
  );
}

export default App;
