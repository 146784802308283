import { LogoutOnSessionExpire } from "common/helpers"
import { XError } from "components/common"
import AuthContext from "context"
import React, { useContext, useEffect, useState } from "react"
import crossConnectService from "services/crossConnectService"
import Swal from "sweetalert2"
import useGetAddConnectData from "../shared/useGetAddConnectData"
import validation from "../shared/validation"
import "./UpdateCrossConnect.css"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
const UpdateCrossConnect = ({
  activeDataCenter,
  countryId,
  setIsValueChange,
  isValueChange,
  setShowUpdateModal,
  editData,
}) => {
  const authContext = useContext(AuthContext)
  const [state, setState] = useState({
    id: editData.id,
    installedbase_id: editData.installedbase_id,
    data_center_id: editData.data_center_id,
    accounts_id: editData.accounts_id,
    cc_media_type_id: editData.xc_media_id,
    a_side_space_id: editData.aEndSpaceID,
    pmi: editData.pmi,
    z_side_space_id: editData.zEndSpaceID,
    desc: editData.desc,
    status: editData.status_id,
  })
  const { t } = useTranslation()
  const [error, setError] = useState({
    installedbase_id: "",
    cc_media_type_id: "",
    accounts_id: "",
    a_side_space_id: "",
    z_side_space_id: "",
    status: "",
    pmi: "",
    desc: "",
  })

  const {
    mediaTypes,
    accounts,
    floors,
    statuses,
    aSideFloor,
    aSideCabinets,
    zSideCabinets,
    zSideFloor,
    setASideFloor,
    setZSideFloor,
  } = useGetAddConnectData({
    dcId: activeDataCenter,
    countryId,
    state,
  })

  const [pmis, setPmis] = useState([
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
  ])

  const submitCrossConnectData = async () => {
    try {
      if (validation.checkValidation({ state, setError })) {
        await crossConnectService.updateCrossConnect(authContext.token(), state)
        closeModal()
        Swal.fire(t("xconnect.cross_connect_has_been_updated"))
        setIsValueChange(!isValueChange)
      }
    } catch (e) {
      LogoutOnSessionExpire(e)
    }
  }

  const closeModal = () => {
    setShowUpdateModal(false)
  }

  const onDelete = async () => {
    const result = await Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    })
    if (result.isConfirmed) {
      try {
        await crossConnectService.deleteCrossConnect(authContext.token(), state)
        await Swal.fire("success", "Deleted!", "success")
        closeModal()
        setIsValueChange(!isValueChange)
      } catch (err) {
        LogoutOnSessionExpire(err)
        closeModal()
        await Swal.fire("error", "Something went wrong")
      }
    }
  }

  return (
    <div
      className="modal show bd-example-modal-lg"
      style={{ display: "block" }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title">
              {" "}
              {t("xconnect.update_cross_connect")}{" "}
            </h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              onClick={closeModal}
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.installbase_id")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("xconnect.installbase_id")}
                          value={state.installedbase_id}
                          onChange={(event) =>
                            setState({
                              ...state,
                              installedbase_id: event.target.value,
                            })
                          }
                        />
                        <XError message={error.installedbase_id} />
                      </div>
                      <div className="col-md-6 mt-2313"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.customer")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              accounts_id: event.target.value,
                            })
                          }
                          value={state.accounts_id}
                        >
                          <option value="">{t("xconnect.choose")}...</option>
                          {accounts &&
                            accounts.map((account, i) => {
                              return (
                                <option value={account.id} key={account.id}>
                                  {account.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.accounts_id} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.media_type")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              cc_media_type_id: event.target.value,
                            })
                          }
                          value={state.cc_media_type_id}
                        >
                          <option value="">{t("xconnect.choose")} ...</option>
                          {mediaTypes &&
                            mediaTypes.map((mediaType, i) => {
                              return (
                                <option value={mediaType.id} key={mediaType.id}>
                                  {mediaType.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.cc_media_type_id} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <p className="blue_txt_de settings-cross-connects-blue-label">
                          {t("xconnect.a_side")}
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <p className="blue_txt_de settings-cross-connects-blue-label">
                          {t("xconnect.z_side")}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.floor")}
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setASideFloor(event.target.value)
                          }
                          value={aSideFloor}
                        >
                          <option value="">{t("xconnect.choose")} ...</option>
                          {floors &&
                            floors.map((floor, i) => {
                              //if (status.status_type_id === 6) {
                              return (
                                <option value={floor.id} key={floor.id}>
                                  {floor.name}
                                </option>
                              )
                              //}
                            })}
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.floor")}
                        </label>
                        <select
                          disabled={validation.isEmpty(state.a_side_space_id)}
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setZSideFloor(event.target.value)
                          }
                          value={zSideFloor}
                        >
                          <option value="">{t("xconnect.choose")} ...</option>
                          {floors &&
                            floors.map((floor, i) => {
                              return (
                                <option value={floor.id} key={floor.id}>
                                  {floor.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.cabinet_id")}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              a_side_space_id: event.target.value,
                            })
                          }
                          value={state.a_side_space_id}
                        >
                          <option value="">{t("xconnect.choose")} ...</option>
                          {aSideCabinets &&
                            aSideCabinets.map((cabinet) => {
                              return (
                                <option value={cabinet.id} key={cabinet.id}>
                                  {cabinet.name}
                                </option>
                              )
                              //}
                            })}
                        </select>
                        <XError message={error.a_side_space_id} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.cabinet_id")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          disabled={validation.isEmpty(state.a_side_space_id)}
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              z_side_space_id: event.target.value,
                            })
                          }
                          value={state.z_side_space_id}
                        >
                          <option value="">{t("xconnect.choose")} ...</option>
                          {zSideCabinets &&
                            zSideCabinets
                              .filter((c) => [1, 8].includes(c.status))
                              .map((cabinet, i) => {
                                return (
                                  <option value={cabinet.id} key={cabinet.id}>
                                    {cabinet.name}
                                  </option>
                                )
                              })}
                        </select>
                        <XError message={error.z_side_space_id} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-2313">
                        <label className="form-label">
                          {t("xconnect.description")}
                        </label>
                        <div className="settings-cross-connects-div">
                          <textarea
                            name="desc"
                            className="form-control settings-cross-connects-desc"
                            placeholder="Description"
                            rows="4"
                            value={state.desc}
                            cols="50"
                            onChange={(event) =>
                              setState({
                                ...state,
                                desc: event.target.value,
                              })
                            }
                          />
                          <div>
                            <XError message={error.desc} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.pmi")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              pmi: event.target.value,
                            })
                          }
                          value={state.pmi}
                        >
                          <option value="">{t("xconnect.choose")} ...</option>
                          {pmis &&
                            pmis.map((p, i) => {
                              return (
                                <option value={p.id} key={p.id}>
                                  {p.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.pmi} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("xconnect.status")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              status: event.target.value,
                            })
                          }
                          value={state.status}
                        >
                          <option value="">{t("xconnect.choose")} ...</option>
                          {statuses &&
                            statuses.map((s, i) => {
                              return (
                                <option value={s.id} key={s.id}>
                                  {s.status_name}
                                </option>
                              )
                              //}
                            })}
                        </select>
                        <XError message={error.status} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                role="toolbar"
              >
                <button
                  type="button"
                  style={{ marginRight: 15 }}
                  className="btn btn-outline-primary mr_1 red_color"
                  onClick={onDelete}
                >
                  <img
                    src="/images/trash-2.svg"
                    alt=""
                    style={{ width: "11px", marginTop: "-0.188rem" }}
                  />{" "}
                  {t("common.delete")}
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  style={{ marginRight: 15 }}
                  className="btn btn-outline-primary mr_1"
                >
                  {" "}
                  {t("common.cancel")}{" "}
                </button>
                <button
                  type="button"
                  onClick={() => submitCrossConnectData()}
                  className="btn btn-primary"
                >
                  {t("common.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateCrossConnect
