import { LogoutOnSessionExpire } from 'common/helpers';
import StorageContext from 'context';
import React, { useContext, useEffect, useState } from 'react';
import accountService from 'services/accountService';
import CommonService from 'services/commonService';
import Loading from 'views/super-admin/pre-loader/loader';
import Layout from '../Layouts';
import CreateAccount from './createAccount';
import UpdateAccount from './updateAccount';
import { useTranslation } from 'react-i18next';
import Pagination from 'views/super-admin/Ticket/components/shared/usePagination';
import Table from './End Customer Tab/table';
import AccountDetailModal from './accountDetailModal';
import './style.scss';
const Account = () => {
  const contextStore = useContext(StorageContext);
  const [ascending, setAscending] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [ascendingCountry, setAscendingCountry] = useState(true);
  const [ascendingAccountCode, setAscendingAccountCode] = useState(true);
  const [ascendingType, setAscendingType] = useState(true);
  const [ascendingReseller, setAscendingReseller] = useState(true);
  const [state, setState] = useState([]);
  const [permission, setPermission] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();
  const [searchVal, setSearchVal] = useState('');
  const [drop, SetDrop] = useState(false);
  const [drop1Subtype, Setdrop1Subtype] = useState('');
  const [nexttvalue, setNextTValue] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [accountDetailData, setAccountDetailData] = useState({});

  const [query, setQuery] = useState('');
  const columns = [
    {
      name: 'customer_name',
      value: t(`account.company_name`)
    },
    {
      name: 'customer_code',
      value: t(`account.company_code`)
    },
    {
      name: 'customer_type',
      value: t(`account.type`)
    },
    {
      name: 'reseller_code',
      value: t(`account.reseller`)
    },
    {
      name: 'de_entity_country',
      value: t(`account.country`)
    },
    {
      name: 'sites',
      value: t(`account.sites`)
    },
    {
      name: 'total_ibs',
      value: t(`account.ibs`)
    },
    {
      name: 'customer_ops_contact',
      value: t(`account.contact`)
    },
    {
      name: 'customer_email',
      value: t(`account.email`)
    },
    {
      name: 'customer_phone',
      value: t(`account.phone`)
    }
  ];

  useEffect(() => {
    fetchAccount();
    // CommonService.permission()
    //   .then((res) => {
    //     setPermission(res.data.permission);
    //   })
    //   .catch((err) => {
    //     LogoutOnSessionExpire(err);
    //   });
  }, [pageNumber, itemsPerPage, query]);

  const getData = async () => {
    await accountService.getAllAccount(contextStore.token(), '').then((res) => {
      setState(res.data.data.account.sort((a, b) => (a.customer_name < b.customer_name ? 1 : -1)));
      setIsLoading(false);
    });
  };
  const openEditModel = (data) => {
    // console.log(data);
    setUpdateData(data);
    setShow(true);
    document.body.classList.add('modal-open');
  };

  const getAccountDetail = async (customer_code) => {
    setIsLoading(true);
    await accountService
      .getAccountDetail(contextStore.token(), customer_code)
      .then((res) => {
        setAccountDetailData(res.data.data);
        setShow(true);
      })
      .catch((err) => LogoutOnSessionExpire(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openAccountDetailModal = (data) => {
    getAccountDetail(data.customer_code);
  };

  const renderItem = () => {
    return (
      state &&
      state
        .filter(
          (data) =>
            data.customer_name.toLowerCase().includes(search) ||
            data.customer_code.toLowerCase().includes(search)
        )
        .map((data) => {
          return (
            <tr className="setting-table">
              <td
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  openAccountDetailModal(data);
                }}
              >
                {' '}
                {data?.customer_name}{' '}
              </td>
              <td className="center-col"> {data.customer_code ? data.customer_code : 'N/A'} </td>
              <td className="center-col">{data.customer_type ? data.customer_type : 'N/A'}</td>
              <td>
                {/* {data.reseller_id ? state.find(account => account.id === data.reseller_id && account.account_type === 2)?.name || "N/A" : data.reseller_id ===0 ? "NA" :"N/A"} */}
                {data.reseller_code ? data.reseller_code : 'N/A'}
              </td>
              <td>{data.de_entity_country ? data.de_entity_country : 'N/A'}</td>
              <td>{data.sites ? data.sites : 'N/A'}</td>
              <td>{data.total_ibs ? data.total_ibs : 0}</td>
              <td>{data.portal_code ? data.portal_code : 'N/A'}</td>
              <td>{data.customer_ops_contact ? data.customer_codeops_contact : 'N/A'}</td>
              <td className="center-col">{data.customer_email ? data.customer_email : 'N/A'}</td>
              <td className="center-col">{data.customer_phone ? data.customer_phone : 'N/A'}</td>
              {/* <td className="center-col">
                <p>
                  <a
                    href="#ssd"
                    className="edit"
                    onClick={() => openEditModel(data)}
                    style={{ cursor: 'pointer' }}
                    data-bs-toggle="modal"
                    data-bs-target=".update-model"
                  >
                    <i className="fas fa-edit"></i>{' '}
                  </a>{' '}
                </p>
              </td> */}
            </tr>
          );
        })
    );
  };
  const HandleSearch = (val) => {
    setSearchVal(() => val);
    if (val.length > 0) {
      SetDrop(true);
    } else {
      // fetchTicket(false, '', '', '', drop2Val);

      SetDrop(false);
      Setdrop1Subtype(() => '');
      setQuery('');
      setPageNumber(1);
      setItemsPerPage(50);
    }
  };
  const HandleDrop1 = (val) => {
    SetDrop(() => false);
    Setdrop1Subtype(() => val);
    setNextTValue(0);
    setQuery(`${val}=${searchVal}`);
    setPageNumber(1);
    setItemsPerPage(50);
  };

  const fetchAccount = async (val, searchVal) => {
    setIsLoading(true);
    setAscending(true);
    const paginationQuery = `page=${pageNumber}&pageSize=${itemsPerPage}&country=${contextStore.getAuth.country.name}`;
    // console.log(query);
    await accountService
      .getAllAccount(
        contextStore.token(),
        query != '' ? paginationQuery + '&' + query : paginationQuery
      )
      .then((res) => {
        if (res.data.data.account.length > 0) {
          setTotal(res.data.data.total);
          setState(
            res.data.data.account.sort((a, b) => (a.customer_name < b.customer_name ? 1 : -1))
          );
        } else {
          setState([]);
        }
      })
      .catch((err) => LogoutOnSessionExpire(err))
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <div className="content-body" style={{ minHeight: '20rem' }}>
          <hr className="main" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                {/* <div>
                  <span>{t('account.filter')}: </span>
                  <input
                    type="search"
                    value={searchQuery}
                    style={{ marginBottom: '1rem', border: '1px solid #92979A' }}
                    onChange={filterAccount}
                  />
                </div> */}
                <div className="tc_drop1-out">
                  <div class="tc_drop1">
                    <div className="inp-out">
                      <input
                        type="text"
                        onChange={(e) => HandleSearch(e.target.value)}
                        value={searchVal}
                      />
                      <div className="after"></div>
                    </div>
                    <div className="img-out" onClick={() => SetDrop(() => !drop)}>
                      {' '}
                      <img alt="" src="\images\arrow.png" className="arrow-img" />
                    </div>
                  </div>
                  {drop ? (
                    <div className="tc_drop1-opt">
                      <div className="tc_opt" onClick={() => HandleDrop1('customer_name')}>
                        Search 'Name' for {searchVal}
                      </div>
                      <div className="tc_opt" onClick={() => HandleDrop1('customer_code')}>
                        Search 'Code' for {searchVal}
                      </div>
                      <div className="tc_opt" onClick={() => HandleDrop1('customer_type')}>
                        Search 'Type' for {searchVal}
                      </div>
                      <div className="tc_opt" onClick={() => HandleDrop1('reseller_code')}>
                        Search 'Reseller' for {searchVal}
                      </div>
                      <div className="tc_opt" onClick={() => HandleDrop1('portal_code')}>
                        Search 'Portal Code' for {searchVal}
                      </div>
                      {contextStore.getAuth.country.name == 'Global' && (
                        <div className="tc_opt" onClick={() => HandleDrop1('de_entity_country')}>
                          Search 'Country' for {searchVal}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-sm-2">
                {/* <div className="add-new-button">
                  <a
                    href="#sd"
                    id="addnew"
                    className="btn btn-primary me-3 btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target=".bd-example-modal-lg"
                  >
                    <img src="/images/plus-circle.svg" alt="" />
                    &nbsp; {t('account.add_account')}
                  </a>

                  <CreateAccount
                    retriveCurrentData={getData}
                    accountData={state}
                    permission={permission}
                    token={contextStore.token()}
                  />
                </div> */}
              </div>
              {/* {show === true ? (
                <UpdateAccount
                  token={contextStore.token()}
                  data={updateData}
                  show={show}
                  accountData={state}
                  setShow={setShow}
                  permission={permission}
                  retriveCurrentData={getData}
                />
              ) : null} */}

              {show == true ? (
                <AccountDetailModal
                  setShow={setShow}
                  show={show}
                  data={accountDetailData}
                  setIsLoading={setIsLoading}
                  setAccountDetailData={setAccountDetailData}
                ></AccountDetailModal>
              ) : null}
            </div>
            <div className="col-lg-12 layout-card-div2">
              <div className="card">
                {/* <Table
                  columns={columns}
                  data={state}
                  setItemsPerPage={setItemsPerPage}
                  setPageNumber={setPageNumber}
                  total={total}
                  pageNumber={pageNumber}
                ></Table> */}
                <div className="card-body" style={{ overflowY: 'hidden' }}>
                  <div className="table-responsive table-scroll-set3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            onClick={() => {
                              setAscending(!ascending);
                              if (ascending === true) {
                                state.sort((a, b) => (a.customer_name > b.customer_name ? 1 : -1));
                              }
                              if (ascending === false) {
                                state.sort((a, b) => (a.customer_name < b.customer_name ? 1 : -1));
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <strong> {t('account.company_name')} </strong>
                            <i className={`fa fa-solid fa-sort-${ascending ? 'down' : 'up'}`}></i>
                          </th>
                          <th
                            className="center-col"
                            onClick={() => {
                              setAscendingAccountCode(!ascendingAccountCode);
                              if (ascendingAccountCode === true) {
                                state.sort((a, b) => (a.customer_code > b.customer_code ? 1 : -1));
                              }
                              if (ascendingAccountCode === false) {
                                state.sort((a, b) => (a.customer_code < b.customer_code ? 1 : -1));
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <strong> {t('account.company_code')} </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingAccountCode ? 'down' : 'up'
                              }`}
                            ></i>
                          </th>
                          <th
                            className="center-col"
                            onClick={() => {
                              setAscendingType(!ascendingType);
                              if (ascendingType === true) {
                                state.sort((a, b) =>
                                  a.accounttype?.name > b.accounttype?.name ? 1 : -1
                                );
                              }
                              if (ascendingType === false) {
                                state.sort((a, b) => (a.customer_type < b.customer_type ? 1 : -1));
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <strong> {t('account.type')} </strong>
                            <i
                              className={`fa fa-solid fa-sort-${ascendingType ? 'down' : 'up'}`}
                            ></i>
                          </th>
                          <th
                            onClick={() => {
                              setAscendingReseller(!ascendingReseller);
                              if (ascendingReseller === true) {
                                state.sort((a, b) => (a.reseller_code > b.reseller_code ? 1 : -1));
                              }
                              if (ascendingReseller === false) {
                                state.sort((a, b) => (a.reseller_code < b.reseller_code ? 1 : -1));
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <strong> {t('account.reseller')} </strong>
                            <i
                              className={`fa fa-solid fa-sort-${ascendingReseller ? 'down' : 'up'}`}
                            ></i>
                          </th>
                          <th
                            onClick={() => {
                              setAscendingCountry(!ascendingCountry);
                              if (ascendingCountry === true) {
                                state.sort((a, b) =>
                                  a.allcountry?.name > b.allcountry?.name ? 1 : -1
                                );
                              }
                              if (ascendingCountry === false) {
                                state.sort((a, b) =>
                                  a.allcountry?.name < b.allcountry?.name ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <strong> {t('account.country')} </strong>
                            <i
                              className={`fa fa-solid fa-sort-${ascendingCountry ? 'down' : 'up'}`}
                            ></i>
                          </th>
                          <th style={{ cursor: 'pointer' }}>
                            <strong> {t('account.sites')} </strong>
                          </th>{' '}
                          <th style={{ cursor: 'pointer' }}>
                            <strong> {t('account.ibs')} </strong>
                          </th>
                          <th style={{ cursor: 'pointer' }}>
                            <strong> {t('account.portal_code')} </strong>
                          </th>
                          <th style={{ cursor: 'pointer' }}>
                            <strong> {t('account.contact')} </strong>
                          </th>
                          <th className="center-col" style={{ cursor: 'pointer' }}>
                            <strong> {t('account.email')} </strong>
                          </th>
                          <th className="center-col" style={{ cursor: 'pointer' }}>
                            <strong> {t('account.phone')} </strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="py-5">{renderItem()}</tbody>
                    </table>
                    <div className="d-flex justify-content-end mt-3">
                      {state && state.length != 0 && (
                        <Pagination
                          total={total}
                          setPageNumber={setPageNumber}
                          setItemsPerPage={setItemsPerPage}
                          pageNumber={pageNumber}
                        ></Pagination>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Account;
