import React, { useEffect, useState, useContext } from "react";
import Loading from "views/super-admin/pre-loader/loader";
import Navigation from "../Navigation";
import SubNavigation from "./Navigation";
import Layout from "views/super-admin/Layouts";
import { useTranslation } from "react-i18next";
import MaintenenceModal from "./maintenanceModal";
import maintenanceService from "services/maintenanceService";
import Swal from "sweetalert2";
import AuthContext from "context";
import moment from "moment";
import ErrorModel from "views/super-admin/Ticket/components/errormodel";
import Pagination from "views/super-admin/Ticket/components/shared/usePagination";

const Maintenence = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState([]);
  const [maintenanceStatusType, setMaintenanceStatusType] = useState([]);
  const [maintenanceStatus, setMaintenanceStatus] = useState([]);
  const contextStore = useContext(AuthContext);
  const [maintenanceModeStatus, setMaintenanceModeStatus] = useState(null);
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const [errorpopup, setErrorPopup] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!isFirstTimeLoading) {
      getList();
    }
  }, [pageNumber]);

  useEffect(() => {
    if (maintenanceStatus.length > 0) {
      const maintenanceStatusBody = {
        currentStatus:
          maintenanceStatus[0].current_status_id == 2 ||
          maintenanceStatus[0].current_status_id == 4
            ? "On"
            : "Off",
        targetStatus: maintenanceStatus[0].target_status_id == 1 ? "On" : "Off",
        status_current: maintenanceStatus[0].current_status_id,
        isMaintenanceModeOn:
          (maintenanceStatus[0].current_status_id === 2 &&
            maintenanceStatus[0].target_status_id === 3) ||
          maintenanceStatus[0].current_status_id === 4,
      };
      contextStore.setMaintenanceModeStatus(maintenanceStatusBody);
    }
  }, [maintenanceStatus]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (localStorage.getItem("token")) {
        getList();
      }
    }, 30000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);
  const getList = async () => {
    setIsLoading(true);
    const body = {
      page: pageNumber,
      pageSize: itemsPerPage,
    };
    await maintenanceService
      .getMaintenance(localStorage.getItem("token"), body)
      .then((res) => {
        setState(res.data.data.maintenanceLogs);
        setMaintenanceStatusType(res.data.data.maintenanceStatusType);
        setMaintenanceStatus(res.data.data.maintenanceStatus);
        setTotal(res.data.data.totalRecords);
        setIsFirstTimeLoading(false);
      })
      .catch((err) => {
        if (
          err?.error?.response?.status !== 403 &&
          err?.error?.response?.status !== 401
        ) {
        } else {
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const refresh = () => {
    getList();
  };

  useEffect(() => {
    if (state.length > 0) {
      setMaintenanceModeStatus(state[0]);
    } else {
      setMaintenanceModeStatus(null);
    }
  }, [state]);

  const getMaintenanceModeStatus = () => {
    const status = maintenanceStatus[0];
    if (status.current_status_id == 1 && status.target_status_id == 3) {
      return "On";
    }
    if (status.current_status_id == 2 && status.target_status_id == 3) {
      return "Hourly Data Sync (On)";
    }
    if (status.current_status_id == 2 && status.target_status_id == 1) {
      return "Hourly Data Sync";
    }
    if (status.current_status_id == 1 && status.target_status_id == 1) {
      return "Off";
    }
    if (status.current_status_id == 3 && status.target_status_id == 3) {
      return "On";
    }
    if (status.current_status_id == 4 && status.target_status_id == 1) {
      return "Data Sync In Progress (Off)";
    }
    if (status.current_status_id == 2 && status.target_status_id == 2) {
      return "Hourly Data Sync";
    }
    return "Off / Hourly Data Sync";
  };
  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <div className="content-body reports-block">
          <Navigation />
          <hr className="main" />
          <div className="container-fluid pt-0">
            <div className="row">
              <SubNavigation />
              <div className="col-lg-11">
                <div className="mt-3" style={{ fontSize: "21px" }}>
                  <p>
                    <b>{t("maintenance.boss")}</b>
                  </p>
                </div>
                <div className="d-flex  align-items-center g-5">
                  <div className="btn_maintenance_mode">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      {t("maintenance.maintenance_mode")}
                    </button>
                  </div>
                  <div className="maintenance_mode_status">
                    <span>
                      Status :{" "}
                      <b>
                        {maintenanceStatus &&
                          maintenanceStatus.length > 0 &&
                          getMaintenanceModeStatus()}
                      </b>
                    </span>
                  </div>
                </div>
                <div className="table-responsive mt-3">
                  <table className="table table-responsive-md">
                    <thead>
                      <tr>
                        <th>
                          <strong>{t("maintenance.date")}</strong>
                        </th>
                        <th>
                          <strong>{t("maintenance.by")}</strong>
                        </th>
                        <th>
                          <strong>{t("maintenance.turn_on_off")}</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {state &&
                        state.length > 0 &&
                        state.map((data) => {
                          return (
                            <tr>
                              <td>
                                {moment
                                  .tz(data.createdAt, "Asia/Hong_Kong")
                                  .tz(moment.tz.guess())
                                  .format("YYYY-MM-DD hh:mm A")}
                              </td>
                              <td>{JSON.parse(data.user).name}</td>
                              <td>{data.status ? "On" : "Off"}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  {state && state.length > 0 && (
                    <Pagination
                      setItemsPerPage={setItemsPerPage}
                      setPageNumber={setPageNumber}
                      total={total}
                      pageNumber={pageNumber}
                    ></Pagination>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {errorpopup && (
          <ErrorModel
            show={errorpopup}
            setShow={setErrorPopup}
            errorMsg={errorMsg}
          />
        )}
        {showModal && (
          <MaintenenceModal
            setShowModal={setShowModal}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            maintenanceModeStatus={maintenanceStatus[0]}
            refresh={refresh}
            getMaintenanceModeStatus={getMaintenanceModeStatus}
          ></MaintenenceModal>
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Maintenence;
