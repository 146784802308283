import React, { useState, useRef, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Chip,
  IconButton,
  MenuItem,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear"; // Assuming you are using a clear icon
import { styled } from "@mui/system";
import Swal from "sweetalert2";
import { XError, XAlert } from "components/common";
const TagComponent = ({
  options,
  createServiceTags,
  tagIds,
  setTagIds,
  savedTags,
  isEdit,
}) => {
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (value.length == 0) {
      setTagIds([]);
    } else {
      const tags = [];
      value.forEach((el) => {
        if (el.id) {
          tags.push(el.id);
        }
      });
      setTagIds(tags);
    }
  }, [value]);
  useEffect(() => {
    if (isEdit) {
      setValue(savedTags);
    }
  }, [isEdit]);

  const handleCreate = (newOption) => {
    if (newOption.length < 2 || newOption.length > 30) {
      Swal.fire({
        text: "Please enter a value between 2 and 30 characters.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "confirmBtn",
        },
      }).then((result) => {});
    } else {
      Swal.fire({
        text: "Are you sure to add new tag?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          createServiceTags(newOption);
        }
      });
    }

    // // setOptions((prev) => [...prev, newOptionObj]);
    // setValue((prev) => [...prev, newOptionObj]);
  };
  const checkIfExist = (data, value) => {
    const checkIfExist = data.filter(
      (el) => el.name.toLowerCase() == value.toLowerCase()
    );
    if (checkIfExist && checkIfExist.length == 0) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      {isEdit ? (
        <Autocomplete
          multiple
          freeSolo
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={value}
          inputValue={inputValue}
          clearIcon={null}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            const data = [];

            newValue.forEach((el) => {
              if (el.id) {
                data.push(el);
              }
            });
            setValue(data);
          }}
          filterOptions={(options, params) => {
            const filtered = options.filter((option) =>
              option.name
                .toLowerCase()
                .includes(params.inputValue.toLowerCase())
            );

            // Suggest the creation of a new option
            if (
              params.inputValue !== "" &&
              (filtered.length == 0 ||
                !checkIfExist(filtered, params.inputValue))
            ) {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={options}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option) => {
            if (option.inputValue) {
              return (
                <MenuItem
                  {...props}
                  onClick={() => handleCreate(option.inputValue)}
                >
                  <AddIcon style={{ marginRight: 8 }} />
                  {option.title}
                </MenuItem>
              );
            }
            return <div {...props}>{option.name}</div>;
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                style={{
                  background: "#bdcded",
                  height: "25px",
                  fontWeight: "bold",
                }}
                key={option.id}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={inputRef}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  backgroundColor: "background.paper",
                  borderRadius: 1,
                  padding: 0,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #ff7a00",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #ff7a00",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #ff7a00",
                  },
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        inputRef.current.focus();
                        setOpen((prevOpen) => !prevOpen);
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </div>
                ),
              }}
            />
          )}
          PaperComponent={({ children }) => <Paper>{children}</Paper>}
        />
      ) : (
        <>
          {savedTags &&
            savedTags?.length > 0 &&
            savedTags.map((data) => {
              return (
                <Chip
                  style={{
                    background: "#bdcded",
                    marginLeft: "0.25rem",
                    height: "25px",
                    fontWeight: "bold",
                    marginBottom: "0.25rem",
                  }}
                  key={data.id}
                  label={data.name}
                />
              );
            })}
        </>
      )}
    </div>
  );
};

export default TagComponent;
