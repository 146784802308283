const { useEffect } = require('react')

const useSetParameters = ({ dataCenters, countries, setFieldValue }) => {
  useEffect(() => {
    if (dataCenters.length > 0) {
      setFieldValue('dataCenters', dataCenters)
    }
  }, [dataCenters])

  useEffect(() => {
    if (countries.length > 0) {
      setFieldValue('countries', countries)
    }
  }, [countries])
  return {}
}

export default useSetParameters
