import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class Maintenance {
  async maintenanceModeStatusUpdate(token, body) {
    return await axios(token).post(`maintenance`, body);
  }
  async getMaintenance(token, body) {
    return await axios(token).get(
      `maintenance?page=${body.page}&pageSize=${body.pageSize}`
    );
  }
}

export default new Maintenance();
