import React, { useEffect } from "react";
import moment from "moment";

const TicketHistory = ({
  notes,
  separateArrayDateWise,
  getTime,
  getAttachment,
  handleDelAttach,
}) => {
  // useEffect(() => {
  //   console.log(notes);
  // }, [notes]);
  return (
    <div className="pb-3">
      {notes?.length > 0 ? (
        <div class="row pt-2 pb-5">
          <h5 class="history-title">History</h5>
          {separateArrayDateWise(notes).map((history) => (
            <div>
              <h1 className="separator">
                {moment().format("MMMM DD, YYYY") === history.date
                  ? "Today"
                  : history.date}
              </h1>
              <div class="col-md-12">
                <div class="history-div">
                  {history.items.map((data, key) => (
                    <div
                      class="col-md-12"
                      key={key}
                      style={{
                        paddingBottom: "3px",
                      }}
                    >
                      <div class="history-out" id="history">
                        <div class="history">
                          {" "}
                          <div class="img-left">
                            <div class="img-title">
                              <img
                                src={
                                  data.type == "note"
                                    ? "images/Icon.svg"
                                    : "images/message-user.svg"
                                }
                                className="history-user-img"
                              />
                            </div>
                          </div>
                          <div class="description">
                            <div>
                              <h5 class="des-name">
                                {data?.author_id || data[key]?.author_id}
                                <span
                                  style={{
                                    opacity: 0.5,
                                  }}
                                >
                                  {" "}
                                  &nbsp; &nbsp;-
                                  {getTime(data.date)}
                                </span>
                              </h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data.body
                                    ? data.body.replace(/\n/g, "<br />")
                                    : data[key]?.body.replace(/\n/g, "<br />"),
                                }}
                              />
                              {!data.body &&
                                data.tracking_values &&
                                data.tracking_values.map((data, key) => (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: data,
                                    }}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          {data?.attachments?.length > 0 ? (
                            <div>
                              {" "}
                              <div className="attach-outer">Attachments</div>
                              <div className="file-name-box">
                                {data.attachments.map((val) => {
                                  return (
                                    <div className="down-file">
                                      <div>{val.name}</div>{" "}
                                      <div className="down-file-inner">
                                        <div
                                          onClick={() =>
                                            getAttachment(val.id, val.name)
                                          }
                                        >
                                          <img
                                            src="images/fdown.svg"
                                            width={13}
                                            alt=""
                                          />
                                        </div>

                                        <div>
                                          <img
                                            src="images/fclose.svg"
                                            width={8}
                                            alt=""
                                            style={{
                                              paddingTop: "2px",
                                            }}
                                            onClick={() =>
                                              handleDelAttach(val.id)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TicketHistory;
