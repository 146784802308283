import ticketDetails from "services/ticketDetails";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const AttachmentTable = (props) => {
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    console.log(props.attachments);
    setAttachments(props.attachments);
  }, [props.attachments]);
  const getAttachment = async (attachmentId, fileName) => {
    props.setIsLoading(true);
    await ticketDetails
      .getAttachment(
        localStorage.getItem("token"),
        props.ticketId,
        attachmentId
      )
      .then((res) => {
        if (res?.data.data === undefined) {
          // console.log("here")
          props.setErrorPopup(true);
          props.setErrorMsg(res.data);
          //setStatusChange(true)
        } else {
          // setToggleEdit(false);
          // updateTicketDetail(false);
          props.handleDownload(res.data.data.data, fileName);
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };
  return (
    <div class="site-table" style={{ width: "90%" }}>
      {attachments.length > 0 ? (
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="site-tr">
                File Name
              </th>
              <th scope="col" class="site-tr">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {attachments.length > 0 &&
              attachments.map((data, key) => {
                return (
                  <tr>
                    <td class="site-tr">{data.name}</td>
                    <td>
                      <span
                        class="site-tr"
                        onClick={() => {
                          getAttachment(data.id, data.name);
                        }}
                      >
                        {" "}
                        <img
                          src="images/download.svg"
                          alt=""
                          style={{ paddingTop: "2px", width: "12px" }}
                        />
                      </span>
                      <span
                        class="site-tr"
                        onClick={() => props.handleDelAttach(data.id)}
                      >
                        {" "}
                        <img
                          src="images/fclose.svg"
                          alt=""
                          style={{
                            paddingTop: "2px",
                            width: "12px",
                            marginLeft: "8px",
                          }}
                        />
                      </span>

                      {props.checkPermission && (
                        <span
                          class="site-tr"
                          onClick={() => props.handleDelAttach(data.id)}
                        >
                          {" "}
                          <img
                            src="images/fclose.svg"
                            alt=""
                            style={{
                              paddingTop: "2px",
                              width: "12px",
                              marginLeft: "8px",
                            }}
                          />
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="mb-3"></div>
      )}
      {props.permission("Add Attachment") && (
        <div>
          <Button
            variant="text"
            className="add_file"
            onClick={props.handleImageClick}
          >
            + Add new file(s)
          </Button>
          <div style={{ textAlign: "center" }}>
            <input
              type="file"
              style={{ display: "none" }}
              ref={props.fileInputRef}
              onChange={props.handleFileChange}
              multiple
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default AttachmentTable;
