import moment from "moment";
import { useEffect } from "react";

const useStartEndTime = ({
  startDateTime,
  endDateTime,
  startDateTimeName,
  endDateTimeName,
  setFieldValue,
}) => {
  useEffect(() => {
    const start = startDateTime;
    const end = endDateTime;
    const now = moment(new Date());
    const nowHours = moment(new Date()).hour();
    if (moment(start) > moment(end)) {
      setFieldValue(endDateTimeName, start);
    }
    const diff = now.startOf("day").diff(moment(start).startOf("day"), "days");
    // if (diff === 0 && nowHours > moment(start).hour()) {
    //   setFieldValue(
    //     startDateTimeName,
    //     now.set({ hours: nowHours, minutes: 0, seconds: 0 }).format()
    //   )
    // }
  }, [startDateTime, endDateTime]);

  return {};
};

export default useStartEndTime;
