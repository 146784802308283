import StorageContext from "context";
import React, { useEffect } from "react";
import { auth } from "services/auth";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { LogoutOnSessionExpire } from "common/helpers";

const Index = () => {
  const contextData = React.useContext(StorageContext);
  const TenantId = process.env.REACT_APP_TENANT_ID;
  const domain = window.location.origin;

  useEffect(() => {
    const token = localStorage.getItem("token");
    localStorage.setItem("isRefreshed", true);

    auth(token)
      .then((res) => {
        contextData.setMaintenanceModeStatus(res.data.maintenanceModeStatus);
        contextData.setAuth(res.data.user);
        contextData.setMonthYear(res.data.currentMonth, res.data.currentYear);
        contextData.login(res.data.user.access_token);
        contextData.setDataCenter(res.data.dataCenter);
        contextData.setCountry(res.data.country);
      })
      .catch((err) => {
        if (token) {
          localStorage.removeItem("token");
          let url = `https://login.microsoftonline.com/${TenantId}/oauth2/logout?post_logout_redirect_uri=${domain}`;

          window.location.href = url;
        }
      });
  }, []);

  return contextData.getToken ? (
    <AuthenticatedRoutes rolePermission={contextData.getAuth} />
  ) : (
    <AuthenticationRoutes />
  );
};

export default Index;
