import { useEffect } from "react";
import TicketHistory from "./ticketHistory";
const LogNoteAndMessage = ({
  permission,
  setMessage,
  message,
  handleImageClick,
  fileInputRef,
  handleFileChange,
  sendMessage,
  history,
  separateArrayDateWise,
  getTime,
  getAttachment,
  handleDelAttach,
  active,
}) => {
  // useEffect(() => {
  //   console.log(history);
  // }, [history]);
  return (
    <>
      <div class="inputstyle">
        <div class="msg_input">
          <div class="media-left">
            <div class="field">
              <p class="control">
                <textarea
                  class="input input-style"
                  type="text"
                  placeholder="  New message..."
                  value={message}
                  onChange={(event) => {
                    if (permission) {
                      setMessage(event.target.value);
                    }
                  }}
                ></textarea>

                {permission && (
                  <span class="icon btnsmall cst-btnsmall">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="img/attach.svg"
                        alt="Click to select file"
                        onClick={handleImageClick}
                      />
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </div>
                    <button class="button send_button" onClick={sendMessage}>
                      <span class="send-btn">
                        {active == "message" ? "SEND" : "LOG"}
                      </span>
                    </button>
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <TicketHistory
        notes={history}
        separateArrayDateWise={separateArrayDateWise}
        getTime={getTime}
        getAttachment={getAttachment}
        handleDelAttach={handleDelAttach}
      ></TicketHistory>
    </>
  );
};

export default LogNoteAndMessage;
