import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class PatchPanelService {

	/*
	|-------------------------------------------------------------------------------
	| 	get patch panel
	|-------------------------------------------------------------------------------
	*/
	async index (token,{dataCenterId}){

		let url =`patchPanel?data_center=${dataCenterId}`;		

		return await axios(token).get(url);
	}
	/*
	|-------------------------------------------------------------------------------
	| 	store patch panel
	|-------------------------------------------------------------------------------
	*/
	async store (token,data){

		let url =`createPatchPanel`;		

		return await axios(token).post(url,data);
	}
	/*
	|-------------------------------------------------------------------------------
	| 	update patch panel
	|-------------------------------------------------------------------------------
	*/
	async update (token,data){

		let url =`updatePatchPanel`;		

		return await axios(token).post(url,data);
	}
	/*
	|-------------------------------------------------------------------------------
	| 	destroy patch panel
	|-------------------------------------------------------------------------------
	*/
	async destroy (token,data){

		let url =`destroyPatchPanel`;		

		return await axios(token).post(url,data);
	}
}

export default new PatchPanelService()