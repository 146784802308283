import { XError } from "components/common";
import StorageContext from "context";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import RoleModel from "services/roleServices";
import Swal from "sweetalert2";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import ticketService from "services/ticketService";
import CloseIcon from "@mui/icons-material/Close";
import ErrorModel from "views/super-admin/Ticket/components/errormodel";

const General = ({
  retriveCurrentData,
  token,
  permission,
  setShow,
  isEdit,
  setIsEdit,
  data,
  getRoleDetail,
  setIsLoading,
  setRoleDetail,
}) => {
  const { t } = useTranslation();
  const contextStore = React.useContext(StorageContext);
  const modalRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [dataCenters, setDataCenters] = useState([]);
  const [placeHolder, setPlaceHolder] = useState("Choose...");
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const [errorpopup, setErrorPopup] = useState(false);
  
  const [state, setState] = useState({
    name: "",
    country: "",
    dataCenter: "",
    dcs: "",
    monitorAndEvalution: "",
    space: "",
    network: "",
    esg: "",
    accounts: "",
    contact: "",
    moderator: "",
    userManagement: "",
  });
  const [errors, setError] = useState({
    name: "",
    country: "",
    dataCenter: "",
    dcs: "",
    monitorAndEvalution: "",
    space: "",
    network: "",
    esg: "",
    accounts: "",
    contact: "",
    moderator: "",
    userManagement: "",
  });
  const permissionNew = [
    { label: "Allow", value: 1 },
    { label: "Disallow", value: 0 },
  ];
  const customStylesForSelect = {
    control: (provided, state) => ({
      ...provided,
      // Change border color based on focus state
      border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change colors as needed
      "&:hover": {
        border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change hover color if needed
      },
      boxShadow: null,
      minHeight: "3rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
      color: "#8392A5",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
      color: "#8392A5",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "18rem",
    }),
  };

  useEffect(() => {
    const countriesWithGlobal = setCountries([
      ...contextStore.getPermittedCountry,
      ...(contextStore.getPermittedCountry.length === 5
        ? [{ country_code: "GLO", id: 6, name: "Global" }]
        : []),
    ]);
  }, []);
  useEffect(() => {
    setState({ ...state, dataCenter: "" });
    if (state.country == "") {
      setDataCenters([]);
    } else {
      if (state.country == 6) {
        setDataCenters(contextStore.getPermittedDataCenter);
      } else {
        const dataCenterByCountryId = contextStore.getPermittedDataCenter.filter(
          (el) => el.country_id == state.country
        );
        setDataCenters(dataCenterByCountryId);
      }
    }

    if ((isEdit || data) && state.country == data.country_id) {
      setState({ ...state, dataCenter: addDataCenter(data?.role_datacenter) });
    }
  }, [state.country]);

  useEffect(() => {
    if (isEdit) {
      setState({
        id: data.id,
        name: data.name,
        country: data.country_id,
        dataCenter: addDataCenter(data.role_datacenter),
        space: data.space,
        monitorAndEvalution: data.m_e,
        network: data.network,
        accounts: data.accounts,
        esg: data.esg,
        dcs: data.dcs,
        status: data.is_active,
        contact: data.contact,
        userManagement: data.user_management,
        moderator: data.moderator,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (data) {
      setState({
        id: data.id,
        name: data.name,
        country: data.country_id,
        dataCenter: addDataCenter(data.role_datacenter),
        space: data.space,
        monitorAndEvalution: data.m_e,
        network: data.network,
        accounts: data.accounts,
        esg: data.esg,
        dcs: data.dcs,
        status: data.is_active,
        contact: data.contact,
        userManagement: data.user_management,
        moderator: data.moderator,
      });
    }
  }, [data]);

  const addDataCenter = (data_centers) => {
    if (data_centers.length > 0) {
      let dataCenter = [];
      for (const k of data_centers) {
        dataCenter.push({
          label: k.datacenter.name,
          value: k.data_center_id,
        });
      }
      return dataCenter;
    } else {
      return null;
    }
  };

  // useEffect(() => {
  //   console.log(state);
  // }, [state]);

  const formatOptions = (data) => {
    return data.map((item) => ({ value: item.id, label: item.name }));
  };

  const onSubmit = () => {
    if (checkValidation()) {
      setIsLoading(true);
      if (isEdit || data) {
        RoleModel.updateRoleAndPermissions(token, "general", state)
          .then(async (res) => {
            retriveCurrentData();
            getRoleDetail(res.data.data.roleId);
            setIsLoading(false);
            Swal.fire({
              title: "Role updated successfully.",
              html: "",
              icon: "success",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "confirmBtn",
              },
            });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              title: "",
              html: err.error.response.data.message,
              icon: "warning",
              confirmButtonText: "Ok",
              customClass: {
                cancelButton: "cancelBtn",
                confirmButton: "confirmBtn",
              },
            });
          });
      } else {
        RoleModel.createRoleAndPermissions(token, "general", state)
          .then(async (res) => {
            retriveCurrentData();
            getRoleDetail(res.data.data.roleId);
            setIsLoading(false);
            Swal.fire({
              title: "Role created successfully.",
              html: "",
              icon: "success",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "confirmBtn",
              },
            });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              title: "",
              html: err.error.response.data.message,
              icon: "warning",
              confirmButtonText: "Ok",
              customClass: {
                cancelButton: "cancelBtn",
                confirmButton: "confirmBtn",
              },
            });
          });
      }
    }
  };

  const checkValidation = () => {
    let error = {
      name: "",
      country: "",
      dataCenter: "",
      dcs: "",
      monitorAndEvalution: "",
      space: "",
      network: "",
      esg: "",
      accounts: "",
      contact: "",
      moderator: "",
      userManagement: "",
    };

    const {
      name,
      country,
      dataCenter,
      dcs,
      monitorAndEvalution,
      space,
      network,
      esg,
      accounts,
      contact,
      moderator,
      userManagement,
    } = state;

    let flag = true;

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required.";
      flag = false;
    }

    if (country === "" || country === null || country === undefined) {
      error.country = "The country field is required.";
      flag = false;
    }
    if (
      dataCenter === "" ||
      dataCenter === null ||
      dataCenter === undefined ||
      dataCenter?.length == 0
    ) {
      error.dataCenter = "The data center field is required.";
      flag = false;
    }
    if (dcs === "" || dcs === null || dcs === undefined) {
      error.dcs = "The dcs field is required.";
      flag = false;
    }

    if (
      monitorAndEvalution === "" ||
      monitorAndEvalution === null ||
      monitorAndEvalution === undefined
    ) {
      error.monitorAndEvalution = "The M&E field is required.";
      flag = false;
    }
    if (space === "" || space === null || space === undefined) {
      error.space = "The space field is required.";
      flag = false;
    }
    if (network === "" || network === null || network === undefined) {
      error.network = "The network field is required.";
      flag = false;
    }

    if (esg === "" || esg === null || esg === undefined) {
      error.esg = "The esg field is required.";
      flag = false;
    }
    if (accounts === "" || accounts === null || accounts === undefined) {
      error.accounts = "The accounts field is required.";
      flag = false;
    }
    if (contact === "" || contact === null || contact === undefined) {
      error.contact = "The contact field is required.";
      flag = false;
    }
    if (moderator === "" || moderator === null || moderator === undefined) {
      error.moderator = "The moderator field is required.";
      flag = false;
    }
    if (
      userManagement === "" ||
      userManagement === null ||
      userManagement === undefined
    ) {
      error.userManagement = "The user management field is required.";
      flag = false;
    }
    setError({ ...error });
    return flag;
  };

  const onChangeStatus = () => {
    setIsLoading(true);
    const status = data.is_active ? 0 : 1;
    RoleModel.updateStatus(token, {
      status,
      id: data.id,
    })
      .then(async () => {
        getRoleDetail(data.id);
        retriveCurrentData();
        setIsLoading(false);
        Swal.fire({
          title: "Status updated successfully.",
          html: "",
          icon: "success",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
        });
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        setIsLoading(false);
        Swal.fire({
          title: "",
          html: err.error.response.data.message,
          icon: "warning",
          confirmButtonText: "Ok",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        });
      });
  };

  const onDelete = () => {
    setIsLoading(true);
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        RoleModel.destroy(token, { id: data.id })
          .then(async () => {
            retriveCurrentData();
            setIsLoading(false);
            setShow(false);
            setIsEdit(false);
            setRoleDetail(null);
            Swal.fire({
              title: "Role deleted successfully.",
              html: "",
              icon: "success",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "confirmBtn",
              },
            });
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            setIsLoading(false);
            Swal.fire({
              title: "",
              html: err.error.response.data.message,
              icon: "warning",
              confirmButtonText: "Ok",
              customClass: {
                cancelButton: "cancelBtn",
                confirmButton: "confirmBtn",
              },
            });
          });
      }
    });
  };

  return (
    <div className="basic-form">
      <form>
        <div className="row">
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.name")}{" "}
              <small className="text-danger">*</small>
            </label>
            <input
              type="text"
              value={state.name}
              className="form-control"
              onChange={(event) =>
                setState({ ...state, name: event.target.value })
              }
              placeholder={t("rolepermission.name")}
            />
            {state.name === "" && <XError message={errors.name} />}
          </div>
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.country")}{" "}
              <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={formatOptions(countries)}
              className="default-select wide select-s"
              onChange={(option) =>
                setState({ ...state, country: option.value })
              }
              value={formatOptions(countries).filter(
                (el) => el.value === state.country
              )}
              placeholder={placeHolder}
            />
            {state.country === "" && <XError message={errors.country} />}
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.data_centres")}{" "}
              <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={(() => {
                const availableOptions = formatOptions(dataCenters);
                if (
                  availableOptions.length < 2 ||
                  state?.dataCenter?.length === availableOptions.length
                ) {
                  return availableOptions;
                }
                return [
                  { value: "select_all", label: "Select All" },
                  ...availableOptions,
                ];
              })()}
              className="default-select wide select-s"
              isMulti={true}
              isClearable={true}
              onChange={(selectedOptions) => {
                if (
                  selectedOptions.some(
                    (option) => option.value === "select_all"
                  )
                ) {
                  setState({
                    ...state,
                    dataCenter: formatOptions(dataCenters),
                  });
                } else {
                  setState({
                    ...state,
                    dataCenter: selectedOptions,
                  });
                }
              }}
              value={state.dataCenter}
              placeholder={placeHolder}
            />
            {(state.dataCenter === "" ||
              state?.dataCenter?.length === 0 ||
              state?.dataCenter == null) && (
              <XError message={errors.dataCenter} />
            )}
          </div>
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.dcs")} <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={permissionNew}
              className="default-select wide select-s"
              onChange={(option) => {
                setState({ ...state, dcs: option.value });
              }}
              value={permissionNew.filter((el) => el.value === state.dcs)}
              placeholder={placeHolder}
              menuPlacement="auto"
            />
            {state.dcs === "" && <XError message={errors.dcs} />}
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.m_and_e")}{" "}
              <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={formatOptions(permission)}
              className="default-select wide select-s"
              onChange={(option) => {
                setState({ ...state, monitorAndEvalution: option.value });
              }}
              value={formatOptions(permission).filter(
                (el) => el.value === state.monitorAndEvalution
              )}
              placeholder={placeHolder}
            />
            {state.monitorAndEvalution === "" && (
              <XError message={errors.monitorAndEvalution} />
            )}
          </div>
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.space")}{" "}
              <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={permissionNew}
              className="default-select wide select-s"
              onChange={(option) => {
                setState({ ...state, space: option.value });
              }}
              value={permissionNew.filter((el) => el.value === state.space)}
              placeholder={placeHolder}
            />
            {state.space === "" && <XError message={errors.space} />}
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.network")}{" "}
              <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={formatOptions(permission)}
              className="default-select wide select-s"
              onChange={(option) => {
                setState({ ...state, network: option.value });
              }}
              value={formatOptions(permission).filter(
                (el) => el.value === state.network
              )}
              placeholder={placeHolder}
            />
            {state.network === "" && <XError message={errors.network} />}
          </div>
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.esg")} <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={permissionNew}
              className="default-select wide select-s"
              onChange={(option) => {
                setState({ ...state, esg: option.value });
              }}
              value={permissionNew.filter((el) => el.value === state.esg)}
              placeholder={placeHolder}
            />
            {state.esg === "" && <XError message={errors.esg} />}
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {t("rolepermission.accounts")}{" "}
              <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={permissionNew}
              className="default-select wide select-s"
              onChange={(option) => {
                setState({ ...state, accounts: option.value });
              }}
              value={permissionNew.filter((el) => el.value === state.accounts)}
              placeholder={placeHolder}
            />
            {state.accounts === "" && <XError message={errors.accounts} />}
          </div>
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              Contacts <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={permissionNew}
              className="default-select wide select-s"
              onChange={(option) => {
                setState({ ...state, contact: option.value });
              }}
              value={permissionNew.filter((el) => el.value === state.contact)}
              placeholder={placeHolder}
            />
            {state.contact === "" && <XError message={errors.contact} />}
          </div>
        </div>
        { contextStore.getAuth.role.name === "Super Admin" && <div className="row">
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
            User Moderator <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={permissionNew}
              className="default-select wide select-s"
              onChange={(option) => {
                setState((prevState) => {
                  const newState = { ...prevState, moderator: option.value };
                  // Automatically allow user management if moderator is allowed
                  if (option.value == 1 && prevState.userManagement !== 1) {
                    newState.userManagement = 1;
                  }
                  return newState;
                });
              }}
              value={permissionNew.filter((el) => el.value === state.moderator)}
              placeholder={placeHolder}
              menuPlacement="auto"
            />
            {state.moderator === "" && <XError message={errors.moderator} />}
          </div>
          <div className="mb-3 col-md-6 mt-2313">
            <label className="form-label">
              {" "}
              {t("rolepermission.user_management")}{" "}
              <small className="text-danger">*</small>
            </label>
            <Select
              styles={customStylesForSelect}
              options={permissionNew}
              className="default-select wide select-s"
              onChange={(option) => {
                setState({ ...state, userManagement: option.value });
              }}
              value={permissionNew.filter(
                (el) => el.value === state.userManagement
              )}
              placeholder={placeHolder}
              menuPlacement="auto"
            />
            {state.userManagement === "" && (
              <XError message={errors.userManagement} />
            )}
          </div>
        </div>
}
      </form>
      <div
        className="toolbar toolbar-bottom mt-51"
        style={{ textAlign: "right", padding: ".5rem 0rem" }}
        role="toolbar"
      >
        {isEdit && (
          <button
            type="button"
            onClick={() => onChangeStatus()}
            style={{
              marginRight: "1rem",
              backgroundColor: "white",
              color: "#E03138",
              borderRadius: "0.25rem",
            }}
            className="btn btn-outline-danger mr_1"
          >
            {state.status ? "Archive" : "Active"}
          </button>
        )}
        {isEdit && contextStore.getAuth.role.name === "Super Admin" && (
          <button
            type="button"
            style={{ marginRight: "1rem", borderRadius: "0.25rem" }}
            onClick={() => onDelete()}
            className="btn btn-danger mr_1"
          >
            Delete
          </button>
        )}

        <button
          type="button"
          className="btn btn-primary"
          style={{ borderRadius: "0.25rem" }}
          onClick={onSubmit}
        >
          Confirm
        </button>
      </div>
      {errorpopup && (
        <ErrorModel
          show={errorpopup}
          setShow={setErrorPopup}
          errorMsg={errorMsg}
        />
      )}
    </div>
  );
};

export default General;
