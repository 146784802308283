import { LogoutOnSessionExpire } from 'common/helpers';
import React, { useEffect, useState } from 'react';
import provisioningService from 'services/provisioningService';
import Pagination from 'views/super-admin/Ticket/components/shared/usePagination';
import SerachBox from '../searchBox';
import { useTranslation } from 'react-i18next';
import ProvisioningPop from 'views/super-admin/Provisioning/components/provisioningPop';
import ErrorModel from '../../Ticket/components/errormodel';
import Swal from 'sweetalert2';

const AssociatedService = (props) => {
  const [errorpopup, setErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something went Wrong !');
  // const [isLoading, setIsLoading] = useState(false);
  const [associate, setAssociate] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState('');
  const [provision, setProvision] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [ticketData, setticketData] = useState({});
  const [currentServiceId, setCurrentServiceId] = useState('');
  const [category, setCategory] = useState('installed_base');

  const { t } = useTranslation();

  useEffect(() => {
    props.setIsLoading(true);
    fetchServices();
  }, [pageNumber, itemsPerPage, query]);

  const fetchServices = async () => {
    let filters = [
      {
        name: 'customer_id',
        value: props.data
      },
      {
        name: 'status',
        value: 'is_open'
      },
      {
        name: 'category',
        value: 'installed_base'
      },
      {
        name: 'ib_category',
        value: 'space,back_office'
      }
    ];
    if (props.endData) {
      filters.push({
        name: 'end_customer_id',
        value: props.endData
      });
    }
    if (query) {
      filters.push(query);
    }
    const body = {
      page: pageNumber,
      limit: itemsPerPage,
      filters: filters
    };
    console.log(filters);
    await provisioningService
      .getServices(localStorage.getItem('token'), body)
      .then((res) => {
        let servicesData = res.data.data;
        console.log('hello assoiated ', servicesData);
        if (servicesData) {
          setAssociate(servicesData.rows);
          setTotal(servicesData.total);
        } else {
          setErrorMsg(res.data);
          setErrorPopup(true);
        }
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };
  const searchCategory = [
    { name: t(`contacts.associated_service_id_table.title`), field: 'name' },
    { name: t(`contacts.associated_service_id_table.space_id`), field: 'space' },
    { name: t(`contacts.associated_service_id_table.other_space_name`), field: 'other_space_name' },
    {
      name: t(`contacts.associated_service_id_table.customer_service_reference`),
      field: 'customer_service_reference'
    },
    { name: t(`contacts.associated_service_id_table.sales_order_item`), field: 'sale_order_item' },
    { name: t(`contacts.associated_service_id_table.status`), field: 'status' }
  ];
  const handlePop = async (data) => {
    props.setIsLoading(true);
    getPopupDetail(data.id);
  };

  const getPopupDetail = async (tid, isCurrentView) => {
    try {
      let data = await provisioningService.getPopupDetail(localStorage.getItem('token'), tid);
      if (isCurrentView) {
        setCurrentServiceId('');
      }
      getStatus(data?.data?.data?.project_id);
      setticketData(data?.data?.data);
      setProvision(true);
      props.setIsLoading(false);
    } catch (e) {
      props.setIsLoading(false);
    }
  };

  const getStatus = async (projectId) => {
    try {
      await provisioningService.getStatus(localStorage.getItem('token'), projectId).then((res) => {
        setStatusList(res?.data?.data);
      });
    } catch (e) {
      props.setIsLoading(false);
    }
  };
  const closeModal = () => {
    setProvision(false);
  };
  const handleExitbutton = async () => {
    await Swal.fire({
      title: 'Are you sure?',
      text: 'Any changes made will not be saved.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        closeModal();
      }
    });
  };

  return (
    <React.Fragment>
      <div className="card-body" style={{ overflow: 'auto' }}>
        <div className="row">
          <div>
            <SerachBox
              searchCategory={searchCategory}
              query={query}
              setQuery={setQuery}
              setIsLoading={props.setIsLoading}
            ></SerachBox>
          </div>
        </div>
        <div className="table-responsive tckect_tt">
          <table className="table header-border table-borderless table-hover verticle-middle">
            <thead>
              <tr>
                <th>Title</th>
                <th>Space ID</th>
                <th>Other Space Name</th>
                <th>Customer Service Reference</th>
                <th>Sales Order Item</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {' '}
              {associate &&
                associate.map((data, i) => {
                  return (
                    <tr>
                      <td
                        className="pd-l bold-txt cus-search"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handlePop(data);
                        }}
                      >
                        {' '}
                        {data.name ? data.name : 'N/A'}
                      </td>
                      <td className="pd-l bold-txt cus-search">
                        {data.space ? data.space : 'N/A'}
                      </td>
                      <td className="pd-l bold-txt cus-search">
                        {data.other_space_name ? data.other_space_name : 'N/A'}
                      </td>
                      <td className="pd-l bold-txt cus-search">
                        {data?.customer_service_reference ? data.customer_service_reference : 'N/A'}
                      </td>
                      <td className="pd-l bold-txt cus-search">
                        {data.sale_order_item ? data.sale_order_item : 'N/A'}
                      </td>

                      <td> {data.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mt-3">
          {associate && associate.length > 0 && (
            <Pagination
              setItemsPerPage={setItemsPerPage}
              setPageNumber={setPageNumber}
              total={total}
              pageNumber={pageNumber}
            ></Pagination>
          )}
        </div>
      </div>
      {provision && (
        <ProvisioningPop
          ticketData={ticketData}
          handleExitbutton={handleExitbutton}
          provision={provision}
          statusList={statusList}
          handlePop={handlePop}
          refreshData={getPopupDetail}
          setIsLoading={props.setIsLoading}
          isIB={ticketData.project.includes('Installed Base') ? true : false}
          currentServiceId={currentServiceId}
          setCurrentServiceId={setCurrentServiceId}
        />
      )}
      {errorpopup && <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />}
    </React.Fragment>
  );
};

export default AssociatedService;
