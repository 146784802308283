import { useTranslation } from "react-i18next";
import moment from "moment";

const CabinetTable = ({
  cabinets,
  cabinetAscending,
  setCabinetAscending,
  getEditCabinetPopup,
  isReadOnly,
  getStatus,
  layoutRef,
  lastRefreshTime,
}) => {
  const { t } = useTranslation();

  return (
    <div className="cabint-breaker">
      <span style={{ fontWeight: "600", color: "red" }}>
        Last Updated :{" "}
        {moment(lastRefreshTime?.last_refresh).format("DD/MM/YYYY hh:mm")} SGT
      </span>
      <table
        className="table header-border verticle-middle"
        style={{ whiteSpace: "nowrap" }}
      >
        <thead>
          <tr>
            <th
              scope="col"
              className="pd-l"
              width="25%"
              onClick={() => {
                setCabinetAscending(!cabinetAscending);
                if (cabinetAscending === true) {
                  cabinets.sort((a, b) => (a.name > b.name ? 1 : -1));
                }
                if (cabinetAscending === false) {
                  cabinets.sort((a, b) => (a.name < b.name ? 1 : -1));
                }
              }}
              style={{ cursor: "pointer" }}
            >
              {t("cabinet.name")}{" "}
              <i
                className={`fa fa-solid fa-sort-${
                  cabinetAscending ? "down" : "up"
                }`}
              ></i>
            </th>
            <th scope="col"> {t("cabinet.reseller")} </th>
            <th
              scope="col"
              className="pd-l"
              onClick={() => {
                setCabinetAscending(!cabinetAscending);
                if (cabinetAscending === true) {
                  cabinets.sort((a, b) => (a.customer > b.customer ? 1 : -1));
                }
                if (cabinetAscending === false) {
                  cabinets.sort((a, b) => (a.customer < b.customer ? 1 : -1));
                }
              }}
            >
              {" "}
              {t("cabinet.customer")}{" "}
              <i
                className={`fa fa-solid fa-sort-${
                  cabinetAscending ? "down" : "up"
                }`}
              ></i>
            </th>
            <th
              scope="col"
              className="pd-l"
              onClick={() => {
                setCabinetAscending(!cabinetAscending);
                if (cabinetAscending === true) {
                  cabinets.sort((a, b) => (a.status > b.status ? 1 : -1));
                }
                if (cabinetAscending === false) {
                  cabinets.sort((a, b) => (a.status < b.status ? 1 : -1));
                }
              }}
            >
              {" "}
              {t("cabinet.status")}{" "}
              <i
                className={`fa fa-solid fa-sort-${
                  cabinetAscending ? "down" : "up"
                }`}
              ></i>
            </th>
            {/* <th scope="col"> Max kWs </th>
                      <th scope="col"> Sold kWs </th> */}
            <th scope="col"> # {t("cabinet.breakers")} </th>
            <th scope="col"> # {t("cabinet.xconnects")} </th>
            {/* {isReadOnly?(
              <th scope="col" >  </th>
              ):null} */}
          </tr>
        </thead>
        <tbody id="cardnew">
          {cabinets &&
            cabinets.length > 0 &&
            cabinets.map((res) => {
              if (res.deletedAt != null) {
                return null;
              }
              return (
                <tr
                  key={res.id}
                  className={res?.sync_status === 2 ? "newCab" : ""}
                >
                  <th className="pd-l bold-txt">{res.name}</th>
                  <td>
                    {" "}
                    {res.reseller_name != null ? res.reseller_name : "-"}
                  </td>
                  <th className="pd-l bold-txt">
                    {res.customer_name != null ? res.customer_name : "-"}
                  </th>
                  <td>{getStatus(res.status)}</td>
                  <td> {res.num_breakers != null ? res.num_breakers : "-"} </td>
                  <td>{res.num_xconnects != null ? res.num_xconnects : "-"}</td>
                  {isReadOnly === false ? (
                    <td>
                      {" "}
                      <a href="#sd" onClick={() => getEditCabinetPopup(res)}>
                        {" "}
                        <i className="fas fa-edit"></i>
                      </a>{" "}
                    </td>
                  ) : null}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CabinetTable;
