import { css } from 'glamor'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { TICKET_COMMON_STYLES } from '../../shared/constants'
import TicketAccordion from '../../shared/TicketAccordion'
import TicketDateTime from '../../shared/TicketDateTime'
import * as utils from '../../shared/utils'

const Schedule = ({
  setFieldValue,
  active,
  scheduleStartTime,
  scheduleEndTime
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    // if (scheduleStartTime === '') {
    //   setFieldValue(
    //     'scheduleStartTime',
    //     moment().add(1, 'day')
    //     // .utc()
    //   )
    //   setFieldValue(
    //     'scheduleEndTime',
    //     moment().add(1, 'day')
    //     // .utc()
    //   )
    // }
  }, [active])

  const styles = {
    container: {
      marginTop: '10px',
      marginBottom: '10px'
    },
    label: {
      fontSize: '15px'
    },
    ...TICKET_COMMON_STYLES
  }

  return (
    <TicketAccordion
      label={t('create-ticket.remote-hands.schedule-title')}
      active={active}
      valid={active && scheduleStartTime !== ''}
    >
      <Row {...css(styles.container)}>
        <Col>
          <div {...css(styles.label)}>{t('create-ticket.remote-hands.schedule-start-date')}
            <span {...css(styles.span)} className='red_star'>
              *
            </span>
          </div>
          <div>
            <TicketDateTime
              id='scheduleStartTime'
              isTopPlacement
              value={scheduleStartTime}
              setFieldValue={setFieldValue}
              active={active}
              placeholder={`${t('create-ticket.site-access.start-time').toUpperCase()} *`}
              // hoursConstraint={utils.getHoursConstraintStartDate()}
            />
          </div>
        </Col>
        <Col>
          <div {...css(styles.label)}>{t('create-ticket.remote-hands.schedule-end-date')}
            <span {...css(styles.span)} className='red_star'>
              *
            </span>
          </div>
          <div>
            <TicketDateTime
              id='scheduleEndTime'
              isTopPlacement
              value={scheduleEndTime}
              setFieldValue={setFieldValue}
              active={active}
              placeholder={`${t('create-ticket.site-access.end-time').toUpperCase()} *`}
              // hoursConstraint={utils.getHoursConstraintEndDate(scheduleStartTime, scheduleEndTime)}
              compareDate={scheduleStartTime}
            />
          </div>
        </Col>
      </Row>
    </TicketAccordion>

  )
}

const SchedulesMemo = ({ scheduleStartTime, scheduleEndTime, active, setFieldValue }) => {
  return useMemo(() => {
    return (
      <Schedule
        scheduleStartTime={scheduleStartTime}
        scheduleEndTime={scheduleEndTime}
        active={active}
        setFieldValue={setFieldValue}
      />
    )
  }, [scheduleStartTime, scheduleEndTime, active])
}

export default SchedulesMemo
