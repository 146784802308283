import AuthContext from 'context';
import { useContext, useEffect, useState } from 'react';
import createTicketService from 'services/createTicketService';

const Impacted = (siteId) => {
  const authContext = useContext(AuthContext);
  const [devices, setDevices] = useState([]);
  const getDevice = async () => {
    try {
      const result = await createTicketService.getDevices(authContext.token(), siteId);
      let finalResult = result.data.map((data) => {
        const { id, name } = data;
        return { label: name, value: id };
      });
      setDevices(finalResult);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (siteId) {
      getDevice();
    }
  }, [siteId]);

  return devices;
};

export default Impacted;
