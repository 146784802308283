import { remove } from 'lodash';
import React, { useState, useEffect } from 'react';

const SelectBoxFilter = ({
  statusCategory,
  selectedStatus,
  setSelectedStatus,
  setIsSelectedGroup
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const handle = (data) => {
    setIsSelectedGroup('');
    setSelectedStatus(data !== 'All' ? data : '');
    setShowDropdown(false);
  };

  return (
    <React.Fragment>
      <div className="tc_drop1-out">
        <div className="tc_drop2">
          <div className="inp-out">
            <div className="val">{selectedStatus !== '' ? selectedStatus : 'All'}</div>
            <div className="after"></div>
          </div>
          <div className="img-out" onClick={() => setShowDropdown(() => !showDropdown)}>
            {' '}
            <img alt="" src="\images\arrow.png" className="arrow-img" />
          </div>
        </div>
        {showDropdown && statusCategory && statusCategory.length > 0 && (
          <div className="tc_drop2-opt">
            {statusCategory.map((data) => {
              return (
                <div className="tc_opt" onClick={() => handle(data.name)}>
                  {data.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectBoxFilter;
