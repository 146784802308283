export const TICKET_MODAL_BACKGROUND_COLOR = 'rgba(240,240,240,1)'
export const TICKET_MODAL_ORANGE_COLOR = 'rgb(254, 134, 0)'
export const TICKET_BLUE_COLOR = '#0069d9'
export const TICKET_DARK_GREY_COLOR = '#707070'

export const TICKET_COMMON_STYLES = {
  borderBottom: {
    borderBottom: '1px solid rgba(112,112,112,1)'
  },
  selectPadding: {
    paddingLeft: '0px',
    paddingRight: '0px'
  },
  span: {
    position: 'absolute',
    marginLeft: '5px',
    fontSize: '1.3rem'
  },
  icon: {
    paddingBottom: '5px',
    marginLeft: '10px'
  }
}
// export const TICKET_TYPE = {
//   SITE_ACCESS: {
//     ID: 4
//   },
//   FAULT_REPORT: {
//     ID: 2
//   }
// }
