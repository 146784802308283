import { useEffect } from 'react'

const useResetFields = (formik) => {
  const {
    country,
    companyName,
    operatingSite,
    serviceId
  } = formik.values

  const { setFieldValue } = formik

  const clearServiceId = () => {
    if (typeof serviceId === 'object') {
      setFieldValue('serviceId', [])
    } else {
      setFieldValue('serviceId', '')
    }
  }

  const resetFields = (fields) => {
    fields.forEach(fields => {
      setFieldValue(fields, '')
    })
  }

  useEffect(() => {
    resetFields(['companyName', 'operatingSite', 'customer'])
    clearServiceId()
  }, [country])

  useEffect(() => {
    resetFields(['operatingSite', 'customer'])
    clearServiceId()
  }, [companyName])

  useEffect(() => {
    clearServiceId()
  }, [operatingSite])

  return {
  }
}

export default useResetFields
