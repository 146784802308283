import React, { useEffect, useState } from 'react';
import Pagination from 'views/super-admin/Ticket/components/shared/usePagination';
import '../../../../Ticket/faultpopup.css';
import '../../../../Ticket/style.css';
const Table = ({
  columns,
  data,
  setItemsPerPage,
  pageNumber,
  setPageNumber,
  total,
  setSortDirection,
  sortDirection,
  sort,
  setSort
}) => {
  const [isAscending, setIsAscending] = useState(true);
  const [sortedColumn, setSortedColumn] = useState(null);

  const sortTable = (columnName) => {
    const checkIfItIsAllreadySelected = columnName === sortedColumn ? !isAscending : true;
    // const sortedState = [...ticketList].sort((a, b) => {
    //   if (a[columnName] < b[columnName]) {
    //     return checkIfItIsAllreadySelected ? -1 : 1;
    //   }
    //   if (a[columnName] > b[columnName]) {
    //     return checkIfItIsAllreadySelected ? 1 : -1;
    //   }
    //   return 0;
    // });
    // setTicketList(sortedState);
    setSortedColumn(columnName);
    setIsAscending(checkIfItIsAllreadySelected);
    if (columnName == sort) {
      setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc');
      setPageNumber(1);
    } else {
      setSort(columnName);
      setSortDirection('asc');
      setPageNumber(1);
    }
  };

  return (
    <React.Fragment>
      <div className="card-body">
        <div className="table-responsive tckect_tt table-scroll-set2">
          <table className="table header-border table-borderless table-hover verticle-middle">
            <thead style={{ position: 'relative' }}>
              <tr>
                {columns &&
                  columns.map((column, i) => {
                    return (
                      <th
                        key={i}
                        scope="col"
                        style={{ cursor: 'pointer' }}
                        onClick={() => sortTable(column.name)}
                      >
                        {column.value}
                        <i
                          className={`fa fa-solid fa-sort-${
                            column.name === sortedColumn ? (!isAscending ? 'down' : 'up') : 'down'
                          }`}
                          style={{ fontSize: '1.3rem', paddingLeft: '6px' }}
                        ></i>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {' '}
              {data &&
                data.map((data, i) => {
                  return (
                    <tr>
                      {columns &&
                        columns.map((column, i) => {
                          return (
                            <td
                              className="pd-l bold-txt cus-search"
                              key={i}
                              style={{ cursor: 'pointer' }}
                              //   onClick={() => props.handlePop(data)}
                            >
                              {data[column.name] ? data[column.name] : 'N/A'}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="d-flex justify-content-end mt-3">
            {data && data.length && (
              <Pagination
                setItemsPerPage={setItemsPerPage}
                setPageNumber={setPageNumber}
                total={total}
                pageNumber={pageNumber}
              ></Pagination>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Table;
