import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BoxSelect from "../shared/BoxSelect";
import { TICKET_MODAL_BACKGROUND_COLOR } from "../shared/constants";
import TicketSelectField from "../shared/TicketSelectField";
import useFaultTicketForm from "./useFaultTicketForm";
import * as utils from "../shared/utils";
import TicketInputField from "../shared/TicketInputField";
import Description from "./Description";
import { css } from "glamor";
import TicketButton from "../shared/TicketButton";
import SpaceField from "../shared/TicketInputField/spaceField";
import { useState, useEffect } from "react";
import TicketCheckBox from "../shared/TicketCheckBox";
const FaultTicketForm = ({ closeModal, setLoading, id }) => {
  const { t } = useTranslation();
  const form = useFaultTicketForm({ closeModal, setLoading, id });
  const [isEndCustomer, setEndCustomer] = useState(false);

  const globalOption = {
    label: t("global"),
    value: "global",
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "500px",
    },
    icon: {
      marginLeft: "10px",
    },
  };

  const severity = [
    {
      label: "S-4",
      value: 0,
      id: 0,
    },
    {
      label: "S-3",
      value: 1,
      id: 1,
    },
    {
      label: "S-2",
      value: 2,
      id: 2,
    },
    {
      label: "S-1",
      value: 3,
      id: 3,
    },
  ];

  // const handleSubmit = () => {
  //   if (form.isActive('submit')) {
  //     form.formik.setFieldValue('buttonBeingClicked', 'submit')
  //   }
  // }
  const handelCheckboxToggle = () => {
    form.formik.setFieldValue(
      "isShowResellerContacts",
      !form.formik.values.isShowResellerContacts
    );
    form.formik.setFieldValue("customer", "");
  };

  useEffect(() => {
    if (form.formik.values.companyName != "") {
      const isEndCustomer = utils.isSelectedCompanyEndCustomer(
        form.formik.values.companyName,
        form.companies
      );
      setEndCustomer(isEndCustomer);
    }
    form.formik.setFieldValue("isShowResellerContacts", false);
  }, [form.formik.values.companyName]);

  return (
    <form onSubmit={form.formik.handleSubmit} className="fault">
      <div {...css(styles.container)}>
        <div>
          <Row>
            <BoxSelect
              id="country"
              setFieldValue={form.formik.setFieldValue}
              value={form.formik.values.country}
              options={[...form.countries]}
              label={t("create-ticket.site-access.country").toUpperCase()}
              placeholder={t(
                "create-ticket.site-access.operating-site-placeholder"
              )}
              active
            />
          </Row>
          <Row>
            <TicketSelectField
              id="companyName"
              label={t("create-ticket.site-access.company-name")}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive("companyName")}
              options={form.companies}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t(
                "create-ticket.site-access.company-name-placeholder"
              )}
              value={form.formik.values.companyName}
              isCompanyLoading={form.isCompanyLoading}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <BoxSelect
              id="operatingSite"
              setFieldValue={form.formik.setFieldValue}
              value={form.formik.values.operatingSite}
              options={form.operatingSites}
              label={t(
                "create-ticket.site-access.operating-site"
              ).toUpperCase()}
              placeholder={t(
                "create-ticket.site-access.operating-site-placeholder"
              )}
              active={form.isActive("operatingSite")}
            />
          </Row>

          <Row className="p_relative">
            {isEndCustomer &&
              form.formik.values.companyName &&
              form.isActive("customer") && (
                <div className="show_reseller_contacts">
                  <TicketCheckBox
                    label="Show Reseller Contacts"
                    checked={form.formik.values.isShowResellerContacts}
                    active={true}
                    handelCheckboxToggle={() => handelCheckboxToggle()}
                  />{" "}
                </div>
              )}
            <TicketSelectField
              id="customer"
              label={t("create-ticket.site-access.customer")}
              setFieldValue={form.formik.setFieldValue}
              active={form.isActive("customer")}
              options={form.customers}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t("create-ticket.site-access.customer-placeholder")}
              value={form.formik.values.customer}
            />
          </Row>
          <Row>
            <TicketSelectField
              id="serviceId"
              label={t("create-ticket.site-access.service-id")}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive("serviceId")}
              options={form.services}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t(
                "create-ticket.site-access.service-id-placeholder"
              )}
              value={form.formik.values.serviceId}
              isServiceLoading={form.isServiceLoading}
            />
          </Row>
          <Row>
            <SpaceField
              SpaceValue={form.formik.values.serviceId}
              services={form.services}
            />
          </Row>
          <Row>
            <TicketSelectField
              id="requestType"
              label={t("create-ticket.fault-ticket.request-type")}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive("requestType")}
              options={form.ticketTypes}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t(
                "create-ticket.fault-ticket.request-type-placeholder"
              )}
              value={form.formik.values.requestType}
            />
          </Row>
          <Row>
            <TicketInputField
              id="ticketSubject"
              value={form.formik.ticketSubject}
              setFieldValue={form.formik.setFieldValue}
              label={t("create-ticket.site-access.ticket-subject")}
              required
              active={form.isActive("ticketSubject")}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            />
          </Row>
          <Row>
            <TicketSelectField
              id="severity"
              setFieldValue={form.formik.setFieldValue}
              value={form.formik.values.severity}
              options={severity}
              label={t("create-ticket.fault-ticket.severity")}
              placeholder={t("create-ticket.fault-ticket.severity-placeholder")}
              active={form.isActive("severity")}
              required
              backgroundColor={"rgba(240,240,240,1)"}
            />
          </Row>
          <Row>
            <Description
              active={form.isActive("description")}
              value={form.formik.values.description}
              setFieldValue={form.formik.setFieldValue}
            />
          </Row>
        </div>
        <div>
          <Row className="mt-4 d-flex justify-content-end gap-3">
            <TicketButton
              label={t("cancel")}
              variant="secondary"
              width="150px"
              active={true}
              closeModal={closeModal}
            />
            <TicketButton
              label={t("create-ticket.label")}
              variant="primary"
              icon={
                <i
                  {...css(styles.icon)}
                  className="fa-regular fa-pen-to-square"
                />
              }
              // handleClick={handleSubmit}
              active={form.isActive("submit")}
              buttonType="submit"
              width="200px"
            />
          </Row>
        </div>
      </div>
    </form>
  );
};

export default FaultTicketForm;
