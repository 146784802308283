import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TICKET_MODAL_BACKGROUND_COLOR } from "../../shared/constants";
import TicketInputField from "../../shared/TicketInputField";
import TicketTextAreaField from "../../shared/TicketTextAreaField";
import TicketMultiSelectField from "../../shared/TicketMultiSelectField";
import TicketSelectField from "../../shared/TicketSelectField";
import BoxSelect from "../../shared/BoxSelect";
import TicketCheckBox from "../../shared/TicketCheckBox";
import "./LeftPart.scss";
import VisitDetails from "./VisitDetails";
import * as utils from "../../shared/utils";
import TicketSwitchButton from "../../shared/TicketSwitchButton";
import SpaceField from "../../shared/TicketInputField/spaceField";
import { useEffect, useState } from "react";

const LeftPart = ({ form }) => {
  // console.log('hello--', form);
  const { t } = useTranslation();
  const [isEndCustomer, setEndCustomer] = useState(false);
  const [isShowResellerContacts, setShowResellerContacts] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const allOption = {
    label: t("select-all").toUpperCase(),
    value: "all",
  };

  const globalOption = {
    label: t("global"),
    value: "global",
  };

  const handleVisitTypeButtonClick = (id, controlId) => {
    const { setFieldValue } = form.formik;
    if (controlId === "work-visit") {
      setFieldValue(id, "work-visit");
    } else if (controlId === "first-time-visit") {
      setFieldValue(id, "first-time-visit");
    } else {
      setFieldValue(id, "site-tour");
    }
  };

  const handelCheckboxToggle = () => {
    setShowResellerContacts(!isShowResellerContacts);
    form.formik.setFieldValue(
      "isShowResellerContacts",
      !isShowResellerContacts
    );
    form.formik.setFieldValue("customer", "");
  };

  useEffect(() => {
    if (form.formik.values.companyName != "") {
      const isEndCustomer = utils.isSelectedCompanyEndCustomer(
        form.formik.values.companyName,
        form.companies
      );
      setEndCustomer(isEndCustomer);
    }
    form.formik.setFieldValue("isShowResellerContacts", false);
  }, [form.formik.values.companyName]);

  useEffect(() => {
    if (
      form.formik.values.serviceId &&
      form.formik.values.serviceId.length == 0
    ) {
      setIsSelectAll(false);
    }
  }, [form.formik.values.serviceId]);
  const visitTypeButtons = [
    {
      controlId: "work-visit",
      label: t("create-ticket.site-access.work-visit").toUpperCase(),
      selected: form.formik.values.visitType === "work-visit",
    },
    {
      controlId: "first-time-visit",
      label: t("create-ticket.site-access.first-time-visit").toUpperCase(),
      selected: form.formik.values.visitType === "first-time-visit",
    },
    {
      controlId: "site-tour",
      label: t("create-ticket.site-access.site-tour").toUpperCase(),
      selected: form.formik.values.visitType === "site-tour",
    },
  ];

  return (
    <>
      <div className="create-ticket-left-part-upper-section">
        <Row>
          <BoxSelect
            id="country"
            setFieldValue={form.formik.setFieldValue}
            value={form.formik.values.country}
            options={[...form.countries]}
            label={t("create-ticket.site-access.country").toUpperCase()}
            placeholder={t(
              "create-ticket.site-access.operating-site-placeholder"
            )}
            active
          />
        </Row>
        <Row>
          <TicketSelectField
            id="companyName"
            label={t("create-ticket.site-access.company-name")}
            setFieldValue={form.formik.setFieldValue}
            required
            active={form.isActive("companyName")}
            options={form.companies}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            placeholder={t(
              "create-ticket.site-access.company-name-placeholder"
            )}
            value={form.formik.values.companyName}
            isCompanyLoading={form.isCompanyLoading}
          />
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <BoxSelect
            id="operatingSite"
            setFieldValue={form.formik.setFieldValue}
            value={form.formik.values.operatingSite}
            options={form.operatingSites}
            label={t("create-ticket.site-access.operating-site").toUpperCase()}
            placeholder={t(
              "create-ticket.site-access.operating-site-placeholder"
            )}
            active={form.isActive("operatingSite")}
          />
        </Row>

        <Row className="p_relative">
          {isEndCustomer &&
            form.formik.values.companyName &&
            form.isActive("customer") && (
              <div className="show_reseller_contacts">
                <TicketCheckBox
                  label="Show Reseller Contacts"
                  checked={form.formik.values.isShowResellerContacts}
                  active={true}
                  handelCheckboxToggle={() => handelCheckboxToggle()}
                />{" "}
              </div>
            )}
          <TicketSelectField
            id="customer"
            label={t("create-ticket.site-access.customer")}
            setFieldValue={form.formik.setFieldValue}
            active={form.isActive("customer")}
            options={form.customers}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            placeholder={t("create-ticket.site-access.customer-placeholder")}
            value={form.formik.values.customer}
            isClearable
          />
        </Row>
        <Row className="p_relative">
          {form.isActive("serviceId") &&
            form.services &&
            form.services.length > 0 && (
              <div className="show_select_all">
                <TicketCheckBox
                  label="Select All"
                  checked={isSelectAll}
                  active={true}
                  handelCheckboxToggle={() => setIsSelectAll(!isSelectAll)}
                />{" "}
              </div>
            )}
          <TicketMultiSelectField
            id="serviceId"
            label={t("create-ticket.site-access.service-id")}
            placeholder={t("create-ticket.site-access.service-id-placeholder")}
            setFieldValue={form.formik.setFieldValue}
            active={form.isActive("serviceId")}
            options={form.services}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            value={form.formik.values.serviceId}
            isSelectAll={isSelectAll}
            isServiceLoading={form.isServiceLoading}
          />
        </Row>
        <Row>
          {" "}
          <SpaceField
            SpaceValue={form.formik.values.serviceId}
            services={[allOption, ...form.services]}
          />
        </Row>
        <Row>
          <TicketInputField
            id="ticketSubject"
            value={form.formik.ticketSubject}
            setFieldValue={form.formik.setFieldValue}
            label={t("create-ticket.site-access.ticket-subject")}
            required
            active={form.isActive("ticketSubject")}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
          />
        </Row>
        <Row>
          <TicketTextAreaField
            id="description"
            setFieldValue={form.formik.setFieldValue}
            label={t("create-ticket.site-access.description")}
            value={form.formik.values.description}
            active={form.isActive("ticketSubject")}
            required={false}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
          />
        </Row>
        <Row>
          <TicketInputField
            id="specialVisitArea"
            setFieldValue={form.formik.setFieldValue}
            label={t("create-ticket.site-access.special-area-placeholder")}
            value={form.formik.specialVisitArea}
            active={form.isActive("ticketSubject")}
            required={false}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            placeholder={t("create-ticket.site-access.special-area")}
          />
        </Row>
        <div className="mt-3 mb-3">
          <Row>
            {visitTypeButtons.map((button) => (
              <TicketSwitchButton
                key={button.controlId}
                id="visitType"
                controlId={button.controlId}
                label={button.label}
                handleClick={handleVisitTypeButtonClick}
                active={form.isActive("ticketSubject")}
                selected={button.selected}
              />
            ))}
          </Row>
        </div>
        <div className="mt-3 mb-3">
          <Row>
            <TicketCheckBox
              label={t("create-ticket.site-access.need_assistance")}
              checked={form.formik.values.need_assistance}
              active={true}
              handelCheckboxToggle={() =>
                form.formik.setFieldValue(
                  "need_assistance",
                  !form.formik.values["need_assistance"]
                )
              }
            />{" "}
          </Row>
        </div>
      </div>

      {/* <div>
        <VisitDetails
          setFieldValue={form.formik.setFieldValue}
          startDateTime={form.formik.values.startDateTime}
          endDateTime={form.formik.values.endDateTime}
          active={form.isActive('ticketSubject')}
        />
      </div> */}
    </>
  );
};

export default LeftPart;
