import React from 'react';
import Layout from 'views/super-admin/Layouts';
import Navigation from '../Component/Navigation';
import Network from './Network';
import './Op.css';
export default function Op() {
    return (
        <Layout>
            <Network/> 
        </Layout>
    )
}
