import { Col, Row } from 'react-bootstrap';
import LeftPart from './LeftPart';
import RightPart from './RightPart';
import ShipmentTicketFormContextProvider from './context/ShipmentTicketFormContextProvider';

const ShipmentTicketForm = ({ closeModal, setLoading, id }) => {
  // const form = useShipmentTicketForm({ closeModal, setLoading })
  return (
    <ShipmentTicketFormContextProvider closeModal={closeModal} setLoading={setLoading} id={id}>
      <Row className="shipment">
        <Col>
          <LeftPart />
        </Col>
        <Col>
          <RightPart closeModal={closeModal} />
        </Col>
      </Row>
    </ShipmentTicketFormContextProvider>
  );
};

export default ShipmentTicketForm;
