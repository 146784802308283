import { XError } from "components/common"
import { useEffect, useState } from "react"
import Common from "services/commonService"
import NetworkServices from "services/networkServices"
import PatchPanelService from "services/patchPanelService"
import RoleService from "services/roleServices"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"
import { LogoutOnSessionExpire } from "common/helpers"
import CloseIcon from "@mui/icons-material/Close"

const AddPatchPanel = ({
  closeModal,
  activeTab,
  contextStore,
  refresh,
  setRefresh,
  editableObj,
  setEditableObj,
  floorsData,
  getFloorData,
}) => {
  const [activeDataCenter, setActiveDataCenter] = useState({})
  // const [activeFloor,setActiveFloor] = useState(null);
  const [roomList, setRoomList] = useState([])
  const { t } = useTranslation()
  const [cabinetList, setCabinetList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [modelList, setModelList] = useState([])
  const [status, setStatus] = useState([])
  const [state, setState] = useState({
    name: editableObj?.name || "",
    cabinet: editableObj?.cabinet_id || "",
    topu: editableObj?.top_u || "",
    vendor: editableObj?.vendor || "",
    model: editableObj?.model || "",
    num_ports: editableObj?.num_ports || "",
    status: editableObj?.status_id || "",
    description: editableObj?.description || "",
    data_center_id: activeTab,
    patch_id: editableObj.id,
    floor_id: editableObj.floor_id,
    room_id: editableObj.room_id,
  })
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  // const [dataCenter,setDataCenter] = useState([]);

  useEffect(() => {
    getDataCenter()

    onChangeFloor(editableObj.floor_id)
    onChangeRoom(editableObj.room_id)

    NetworkServices.getMake(contextStore.getToken)
      .then((res) => {
        setVendorList(res.data.data)
      })
      .catch((err) => {
        LogoutOnSessionExpire(err)
        /*500 error page*/
      })

    // usin context for status api
    if (contextStore.getStatusData) {
      setStatus(
        contextStore.getStatusData.filter((st) => st.status_type_id === 5)
      )
    } else {
      LogoutOnSessionExpire()
    }

    // Common.status()
    //   .then((res) => {
    //     setStatus(res.data.data.filter((st) => st.status_type_id === 5))
    //   })
    //   .catch((err) => {
    //     LogoutOnSessionExpire(err)
    //     /*500 error page*/
    //   })

    if (editableObj.vendor) {
      onChangeVendor(editableObj?.vendor)
      //setState({...state,model:editableObj?.model})
    }

    return () => {
      setState({})
      setActiveDataCenter({})
      setCabinetList([])
      setVendorList([])
      setModelList([])
      setStatus([])
    }
  }, [activeTab])

  const getDataCenter = async () => {
    await RoleService.dataCenter(contextStore.token()).then((res) => {
      // setDataCenter(res.data.data);

      let current = res.data.data.filter((center) => center.id === activeTab)
      setActiveDataCenter(current[0])
    })
  }

  const onChangeVendor = (id) => {
    setState({ ...state, vendor: id })
    if (id != "") {
      NetworkServices.getModel(contextStore.getToken, id)
        .then((res) => {
          setModelList(res.data.data)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          /*500 error page*/
        })
    } else {
      setModelList([])
    }
  }

  const onSubmit = () => {
    setIsLoading(true)
    if (checkValidation()) {
      PatchPanelService.update(contextStore.getToken, state)
        .then((res) => {
          setRefresh(!refresh)
          closeModal(false)
          Swal.fire(t("cabling.patch_panel_updated"))
          clearAll()
          setIsLoading(false)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }
  const clearAll = () => {
    setState({
      name: "",
      cabinet: "",
      topu: "",
      vendor: "",
      model: "",
      num_ports: "",
      status: "",
      description: "",
      data_center_id: activeTab,
    })
  }

  const checkValidation = () => {
    let error = {
      name: "",
      cabinet: "",
      topu: "",
      num_ports: "",
      status: "",
      floor: "",
      room: "",
    }

    const { name, cabinet, topu, num_ports, status, floor_id, room_id } = state

    // let flag = true;

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required."
      // flag = false;
    }

    if (cabinet === "" || cabinet === null || cabinet === undefined) {
      error.cabinet = "The cabinet field is required."
      // flag = false;
    }
    if (floor_id === "" || floor_id === null || floor_id === undefined) {
      error.floor = "The floor field is required."
      // flag = false;
    }
    if (room_id === "" || room_id === null || room_id === undefined) {
      error.room = "The room field is required."
      // flag = false;
    }

    if (topu === "" || topu === null || topu === undefined) {
      error.topu = "The top u position field is required."
      // flag = false;
    }

    if (num_ports === "" || num_ports === null || num_ports === undefined) {
      error.num_ports = "The num of ports field is required."
      // flag = false;
    }
    if (status === "" || status === null || status === undefined) {
      error.status = "The status field is required."
      // flag = false;
    }

    setError({ ...error })
    return true
  }
  const onDelete = () => {
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        PatchPanelService.destroy(contextStore.getToken, state)
          .then((res) => {
            setRefresh(!refresh)
            closeModal(false)
            Swal.fire("success", t("cabling.deleted"), "success")
          })
          .catch((err) => {
            LogoutOnSessionExpire(err)

            closeModal(false)
            Swal.fire("error", t("cabling.something_went_wrong"))
          })
      }
    })
  }

  const onChangeFloor = (floor_id) => {
    setState({
      ...state,
      floor_id: floor_id,
      room_id: "",
      cabinet: "",
    })
    getFloorData()
    const tempFloorData = floorsData
    const floor = tempFloorData.filter(
      (floor) => floor.id === parseInt(floor_id)
    )

    if (floor.length) {
      setRoomList(floor[0].rooms)
    }
  }

  const onChangeRoom = (room_id) => {
    setState({ ...state, room_id: room_id, cabinet: "" })
    getFloorData()
    const floor = floorsData.filter(
      (floor) => floor.id === parseInt(state.floor_id)
    )
    if (floor.length) {
      const room = floor[0].rooms.filter(
        (room) => room.id === parseInt(room_id)
      )
      if (room.length) {
        setCabinetList(room[0].cabinets)
      }
    }
  }

  return (
    <div className="modal show" style={{ display: "block" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title">{t("cabling.update_patch_panel")}</h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                closeModal(false)
                clearAll()
              }}
              data-bs-dismiss="modal"
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <p>{activeDataCenter?.name || ""}</p>
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="col-md-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.name")}:
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          defaultValue={state.name}
                          className="form-control"
                          onChange={(event) =>
                            setState({ ...state, name: event.target.value })
                          }
                          placeholder=""
                        />
                        <XError message={error.name} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.floor")}:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.floor_id}
                          onChange={(event) =>
                            onChangeFloor(event.target.value)
                          }
                        >
                          <option value="">floor</option>
                          {floorsData &&
                            floorsData.map((list, index) => {
                              if (list.data_center_id === activeDataCenter.id) {
                                return (
                                  <option value={list.id} key={index}>
                                    {list.name}
                                  </option>
                                )
                              }
                            })}
                        </select>
                        <XError message={error.floor} />
                      </div>
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.room")}:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.room_id}
                          onChange={(event) => onChangeRoom(event.target.value)}
                        >
                          <option value="">room</option>
                          {roomList &&
                            roomList.map((list, index) => {
                              return (
                                <option value={list.id} key={index}>
                                  {list.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.room} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.cabinet_rack")}:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.cabinet}
                          onChange={(event) =>
                            setState({ ...state, cabinet: event.target.value })
                          }
                        >
                          <option value="">cabinet/rack</option>
                          {cabinetList &&
                            cabinetList.map((list, index) => {
                              return (
                                <option value={list.id} key={index}>
                                  {list.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.cabinet} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.top_u_position")}:
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          defaultValue={state.topu}
                          className="form-control"
                          onChange={(event) =>
                            setState({ ...state, topu: event.target.value })
                          }
                          placeholder=""
                        />
                        <XError message={error.topu} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.vendor")}:
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.vendor}
                          onChange={(event) =>
                            onChangeVendor(event.target.value)
                          }
                        >
                          <option value="">vendor</option>
                          {vendorList &&
                            vendorList.map((list, index) => {
                              return (
                                <option value={list.id} key={index}>
                                  {list.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.model")}:
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.model}
                          onChange={(event) =>
                            setState({ ...state, model: event.target.value })
                          }
                        >
                          <option value="">model</option>
                          {modelList &&
                            modelList.map((list, index) => {
                              return (
                                <option value={list.id} key={index}>
                                  {list.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          # {t("cabling.of_ports")}:
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          defaultValue={state.num_ports}
                          className="form-control"
                          onChange={(event) =>
                            setState({
                              ...state,
                              num_ports: event.target.value,
                            })
                          }
                          placeholder=""
                        />
                        <XError message={error.num_ports} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.status")}:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.status}
                          onChange={(event) =>
                            setState({ ...state, status: event.target.value })
                          }
                        >
                          <option value="">status</option>
                          {status &&
                            status.map((list, index) => {
                              return (
                                <option value={list.id} key={index}>
                                  {list.status_name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.status} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.description")}:
                        </label>
                        <textarea
                          className="form-control"
                          style={{ height: "70px" }}
                          defaultValue={state.description}
                          onChange={(event) =>
                            setState({
                              ...state,
                              description: event.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div
                      class="toolbar toolbar-bottom mt-51 d-flex"
                      role="toolbar"
                      style={{ float: "right" }}
                    >
                      <div class="delt" style={{ marginRight: "25px" }}>
                        <button
                          type="button"
                          onClick={() => onDelete()}
                          class="btn btn-outline-primary mr_1 red_color"
                        >
                          <img
                            src="/images/trash-2.svg"
                            alt=""
                            style={{
                              width: "11px",
                              marginTop: "-0.188rem",
                            }}
                          />{" "}
                          {t("common.delete")}
                        </button>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          closeModal(false)
                          clearAll()
                        }}
                        style={{ marginRight: "10px" }}
                        class="btn btn-outline-primary mr_1"
                      >
                        {" "}
                        {t("common.cancel")}{" "}
                      </button>
                      {isLoading ? (
                        <button type="button" class="btn btn-primary">
                          {t("common.loading")} ...
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => onSubmit()}
                          class="btn btn-primary"
                        >
                          {t("common.save")}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPatchPanel
