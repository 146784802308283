import React, { useEffect, useState } from 'react';
import Pagination from 'views/super-admin/Ticket/components/shared/usePagination';
import '../../Ticket/style.css';
import '../../Ticket/faultpopup.css';
import SidePop from '../sidePop';
const Table = (props) => {
  const [ascendingTicketNo, setAscendingTicketNo] = useState(true);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(props.data.rows);
  const [SidePopVal, setSidePopVal] = useState({
    name: true,
    customer: true,
    end_customer: false,
    ticketType: false,
    sale_order_item: true,
    product: false,
    service_request_date: true,
    service_commencement_date: false,
    site: true,
    space: true,
    related_space_id: false,
    remarks: false,
    description: false,
    ban: false,
    third_party_circuit: false,
    parent_task: false,
    customer_service_reference: false,
    a_side_space_id: false,
    z_side_space_id: false,
    assignee: true,
    status: true,
    task_type: true
  });
  const [sidePop, setSidePop] = useState(false);

  useEffect(() => {}, [props]);
  const sortTable = (columnName) => {
    setSortedColumn(columnName);
    const isAscending = columnName === sortedColumn ? !ascendingTicketNo : true;
    const sortedState = [...props.data.rows].sort((a, b) => {
      if (a[columnName] < b[columnName]) {
        return isAscending ? -1 : 1;
      }
      if (a[columnName] > b[columnName]) {
        return isAscending ? 1 : -1;
      }
      return 0;
    });
    props.data.rows = sortedState;
    setSortedColumn(columnName);
    setAscendingTicketNo(isAscending);
  };

  return (
    <React.Fragment>
      <div className="card-body">
        <div className="table-responsive tckect_tt table-scroll-set2">
          <table className="table header-border table-borderless table-hover verticle-middle">
            <thead style={{ position: 'relative' }}>
              <tr>
                {props.columns &&
                  props.columns.map((column, i) => {
                    if (SidePopVal[column.name]) {
                      return (
                        <th
                          key={i}
                          scope="col"
                          style={{ cursor: 'pointer' }}
                          onClick={() => sortTable(column.name)}
                        >
                          {column.value}
                          <i
                            className={`fa fa-solid fa-sort-${
                              column.name === sortedColumn
                                ? !ascendingTicketNo
                                  ? 'down'
                                  : 'up'
                                : 'down'
                            }`}
                            style={{ fontSize: '1.3rem', paddingLeft: '6px' }}
                          ></i>
                        </th>
                      );
                    } else {
                      return null; // Don't render the column if SidePopVal[column.name] is false
                    }
                  })}
                <th>
                  <img
                    src="\images\3dots.svg"
                    alt=""
                    onClick={() => setSidePop(!sidePop)}
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '12px',
                      top: '4px',
                      width: '20px',
                      transform: 'rotate(90deg)'
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {' '}
              {props.data.rows &&
                props.data.rows.map((data, i) => {
                  return (
                    <tr>
                      {props.columns &&
                        props.columns.map((column, i) => {
                          if (SidePopVal[column.name]) {
                            return (
                              <td
                                className="pd-l bold-txt cus-search"
                                key={i}
                                style={{ cursor: 'pointer' }}
                                onClick={() => props.handlePop(data)}
                              >
                                {column.name == 'description' ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: data[column.name] || ''
                                    }}
                                  ></span>
                                ) : data[column.name] ? (
                                  data[column.name]
                                ) : (
                                  'N/A'
                                )}
                              </td>
                            );
                          } else {
                            return null; // Don't render the column if SidePopVal[column.name] is false
                          }
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="d-flex justify-content-end mt-3">
            {props.data.rows && props.data.rows.length && (
              <Pagination
                setItemsPerPage={props.setItemsPerPage}
                setPageNumber={props.setPageNumber}
                total={props.total}
                pageNumber={props.pageNumber}
              ></Pagination>
            )}
          </div>
        </div>

        <div>
          {sidePop && (
            <SidePop
              setSidePopVal={setSidePopVal}
              SidePopVal={SidePopVal}
              setSidePop={setSidePop}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Table;
