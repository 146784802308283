import StorageContext from "context";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navigation = () => {
  const location = useLocation();
  const contextData = React.useContext(StorageContext);
  const { t } = useTranslation();
  const activeClass = (route) => {
    if (typeof route === "string") {
      if (location.pathname === route) {
        return "nav-link active";
      }
    }

    if (typeof route === "object") {
      let classType = "nav-link";
      route.map((r) => {
        if (location.pathname === r) {
          classType = "nav-link active";
        }
      });

      return classType;
    }

    return "nav-link";
  };
  const getMaintenaceModeStatus = () => {
    const status = contextData.getMaintenanceModeStatus;
    if (status && status?.isMaintenanceModeOn) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="container-fluid">
      <div id="title">
        <h4 className="card-title"> {t("setting.settings")} </h4>
        <p>
          {" "}
          {t(
            "setting.manage_users_roles_and_permissions_and_data_centres"
          )}{" "}
        </p>
      </div>

      <div className="card-header" id="header">
        <div className="d-sm-flex d-block justify-content-between align-items-center">
          <div className="card-action coin-tabs mt-3 mt-sm-0">
            <ul className="nav nav-tabs" role="tablist">
              {!getMaintenaceModeStatus() &&
              (contextData.getAuth?.role?.user_management || contextData.getAuth?.role?.moderator) ? (
                <li className="nav-item gap_s">
                  <Link
                    className={activeClass("/setting-user")}
                    id="tab1"
                    to="/setting-user"
                  >
                    {t("setting.user_management")}{" "}
                  </Link>
                </li>
              ) : null}

              {!getMaintenaceModeStatus() &&
              (contextData.getAuth?.role?.user_management || contextData.getAuth?.role?.moderator) ? (
                <li className="nav-item gap_s">
                  <Link
                    className={activeClass("/setting-role-and-permissions")}
                    id="tab2"
                    to="/setting-role-and-permissions"
                  >
                    {t("setting.roles_and_permissions")}{" "}
                  </Link>
                </li>
              ) : null}
              {/* {contextData.getAuth?.role?.accounts != 1 ? (
                <li className="nav-item gap_s">
                  <Link
                    className={activeClass('/setting-accounts')}
                    id="tab2"
                    to="/setting-accounts"
                  >
                    {t('setting.accounts')}{' '}
                  </Link>
                </li>
              ) : null} */}
              {!getMaintenaceModeStatus() &&
              contextData?.getAuth?.role?.dcs !== 0 ? (
                <li className="nav-item gap_s">
                  <Link
                    className={activeClass([
                      "/setting-data-center",
                      "/setting-capacity",
                      "/capacity-layout",
                      "/setting-power-distibution",
                      "/setting-bcm",
                    ])}
                    id="tab3"
                    to="/setting-data-center"
                  >
                    {t("setting.data_centres")}{" "}
                  </Link>
                </li>
              ) : null}
              {!getMaintenaceModeStatus() && (
                <li className="nav-item gap_s">
                  <Link
                    className={activeClass([
                      "/setting-cabling-and-patch-nw-rooms",
                      "/setting-cabling-and-patch-cabling",
                      "/setting-cabling-and-patch-patch-panels",
                    ])}
                    id="tab3"
                    to="/setting-cabling-and-patch-nw-rooms"
                  >
                    {t("setting.cabling_and_patch")}{" "}
                  </Link>
                </li>
              )}
              {!getMaintenaceModeStatus() && (
                <li className="nav-item gap_s">
                  <Link
                    className={activeClass([
                      "/setting-network",
                      "/setting-rackelevation",
                      "/setting-topology",
                      "/setting-fibermap",
                    ])}
                    id="tab4"
                    to="/setting-network"
                  >
                    {t("setting.network_svcs")}{" "}
                  </Link>
                </li>
              )}
              {!getMaintenaceModeStatus() &&
              contextData.getAuth?.role?.user_management ? (
                <li className="nav-item gap_s">
                  <Link
                    className={activeClass("/setting-activity")}
                    id="tab4"
                    to="/setting-activity"
                  >
                    {t("setting.activity_log")}{" "}
                  </Link>
                </li>
              ) : null}
              {contextData?.getAuth?.role?.name == "Super Admin" && (
                <li className="nav-item gap_s">
                  <Link
                    className={
                      location.pathname == "/setting-maintenance-ops-portal" ||
                      location.pathname == "/setting-maintenance-boss"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="tab4"
                    to="/setting-maintenance-boss"
                  >
                    {t("setting.maintenance")}{" "}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
