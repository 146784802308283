import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useGetCompaniesAndCountries from "../shared/hooks/useGetCompaniesAndCountries";
import useHandleVisitors from "./hooks/useHandleVisitors";
import useGetOperatingSitesAndCustomers from "../shared/hooks/useGetOperatingSitesAndCustomers";
import useGetServiceIds from "../shared/hooks/useGetServiceIds";
import useResetFields from "../shared/hooks/useResetFields";
import useCreateSiteAccessTicket from "./hooks/useCreateSiteAccessTicket";
import useStartEndTime from "../shared/hooks/useStartEndTime";
import useGetTeams from "../shared/hooks/useGetTeams";
import * as utils from "../shared/utils";
import useSetParameters from "../shared/hooks/useSetParameters";

const useSiteAccessTicketForm = ({ closeModal, setLoading, id }) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState(null);
  const schema = yup.object().shape({
    operatingSite: yup.string().required(),
    companyName: yup.string().min(1).required(),
    // serviceId: yup.array().min(1).required(),
    ticketSubject: yup
      .string()
      .required(t("create-ticket.site-access.subject-non-valid")),
    startDateTime: yup.string().min(1).required(),
    endDateTime: yup.string().min(1).required(),
    visitorList: yup.array().min(1).required(),
  });

  const returnValuesIfValid = async (values) => {
    const result = await schema
      .validate(values, { abortEarly: false })
      .catch((err) => {
        setValidationMessage(err?.errors[0]);
      });
    return result;
  };

  const { teams } = useGetTeams();

  const { postNewTicket, requestOnFlight } = useCreateSiteAccessTicket({
    teams,
    closeModal,
  });

  const formik = useFormik({
    initialValues: {
      country: "",
      companyName: "",
      operatingSite: "",
      customer: "",
      serviceId: [],
      ticketSubject: "",
      description: "",
      specialVisitArea: "",
      visitType: "work-visit",
      startDateTime: "",
      endDateTime: "",
      visitorName: "",
      visitorCompanyName: "",
      visitorContactNumber: "",
      visitorVehicleInformation: "",
      visitorIdNumber: "",
      visitorList: [],
      allowOpenRack: true,
      cargoUse: false,
      agreeCollection: true,
      buttonBeingClicked: "",
      validationMessage: "",
      dataCenters: [],
      countries: [],
      isShowResellerContacts: false,
      need_assistance: false,
    },
    onSubmit: async (values) => {
      if (values.buttonBeingClicked === "cancel") {
        closeModal();
      }
      if (values.buttonBeingClicked === "submit") {
        const result = await returnValuesIfValid(values);
        // console.log(result);
        if (result && values.agreeCollection === true) {
          // console.log(result);
          postNewTicket(result);
        }
      }
    },
  });

  const {
    companies,
    countries,
    companiesSite,
    isCompanyLoading,
  } = useGetCompaniesAndCountries(
    setLoading,
    formik.setFieldValue,
    formik.values.country
  );

  const {
    operatingSites,
    customers,
    dataCenters,
  } = useGetOperatingSitesAndCustomers({
    countryId: utils.getCountryIdByCode(formik.values.country, countries),
    companyId: formik.values.companyName,
    fetchCustomers: true,
    setLoading,
    country: formik.values.country,
    countries: countries,
    isShowResellerContacts: formik.values.isShowResellerContacts,
  });

  useSetParameters({
    dataCenters,
    countries,
    setFieldValue: formik.setFieldValue,
  });

  const { services, isServiceLoading } = useGetServiceIds(
    formik.values.companyName,
    formik.values.operatingSite,
    setLoading,
    id
  );

  const {
    handleAddVisitor,
    handleDeleteVisitor,
    handleChangeVisitor,
    handleCsvUpload,
    removeAllVisitors,
    handleSaveUser,
    handleOldUser,
    isEditMode,
    setIsEditMode,
  } = useHandleVisitors({ formik });

  useResetFields(formik);

  useStartEndTime({
    startDateTime: formik.values.startDateTime,
    endDateTime: formik.values.endDateTime,
    startDateTimeName: "startDateTime",
    endDateTimeName: "endDateTime",
    setFieldValue: formik.setFieldValue,
  });

  const isActive = (field) => {
    switch (field) {
      case "companyName":
        return formik.values.country !== "";
      case "operatingSite":
        return formik.values.companyName !== "";
      case "customer":
        return formik.values.operatingSite !== "";
      case "serviceId":
        return formik.values.operatingSite !== "";
      case "ticketSubject":
        return formik.values.operatingSite !== "";
      case "visitorForm":
        return (
          isActive("ticketSubject") &&
          formik.values.startDateTime !== "" &&
          formik.values.endDateTime !== ""
        );
      case "submit":
        return (
          isActive("visitorForm") &&
          formik.values.visitorList.length > 0 &&
          formik.values.ticketSubject !== "" &&
          !requestOnFlight &&
          formik.values.agreeCollection
        );

      default:
        return false;
    }
  };

  const checkIfVisitorFormNotEmpty = () => {
    // console.log("checkIfVisitorFormNotEmpty")
    const {
      visitorName,
      visitorCompanyName,
      visitorContactNumber,
      visitorVehicleInformation,
      visitorIdNumber,
    } = formik.values;
    return (
      visitorName !== "" ||
      visitorCompanyName !== "" ||
      visitorContactNumber !== "" ||
      visitorVehicleInformation !== "" ||
      visitorIdNumber !== ""
    );
  };

  return {
    formik,
    isActive,
    handleSaveUser,
    handleOldUser,
    handleAddVisitor,
    handleDeleteVisitor,
    handleChangeVisitor,
    handleCsvUpload,
    removeAllVisitors,
    checkIfVisitorFormNotEmpty,
    validationMessage,
    setValidationMessage,
    companies,
    countries,
    operatingSites,
    customers,
    services,
    companiesSite,
    isEditMode,
    setIsEditMode,
    isCompanyLoading,
    isServiceLoading,
  };
};

export default useSiteAccessTicketForm;
