export const BinIcon = ({ onClick }) => {
  return (
    <div
      id='Delete_Button_1'
      style={{ width: '15px', height: '20px', display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
      onClick={onClick}
    >
      <svg viewBox='5 2 16.467 5.489' style={{ paddingBottom: '1px' }}>
        <path id='Path_4' d='M 20.87902069091797 5.1365966796875 L 15.97808837890625 5.1365966796875 L 15.97808837890625 3.56829833984375 C 15.97808837890625 2.705734252929688 15.27235412597656 2 14.4097900390625 2 L 12.05734252929688 2 C 11.19477844238281 2 10.48904418945312 2.705734252929688 10.48904418945312 3.56829833984375 L 10.48904418945312 5.1365966796875 L 5.588111877441406 5.1365966796875 C 5.274452209472656 5.1365966796875 5 5.411048889160156 5 5.724708557128906 L 5 6.900932312011719 C 5 7.214591979980469 5.274452209472656 7.489044189453125 5.588111877441406 7.489044189453125 L 20.87902069091797 7.489044189453125 C 21.19268035888672 7.489044189453125 21.46713256835938 7.214591979980469 21.46713256835938 6.900932312011719 L 21.46713256835938 5.724708557128906 C 21.46713256835938 5.411048889160156 21.19268035888672 5.1365966796875 20.87902069091797 5.1365966796875 Z M 12.05734252929688 3.960372924804688 C 12.05734252929688 3.725128173828125 12.21417236328125 3.56829833984375 12.44941711425781 3.56829833984375 L 14.01771545410156 3.56829833984375 C 14.25296020507812 3.56829833984375 14.4097900390625 3.725128173828125 14.4097900390625 3.960372924804688 L 14.4097900390625 5.1365966796875 L 12.05734252929688 5.1365966796875 L 12.05734252929688 3.960372924804688 Z' />
      </svg>
      <svg class='Path_5' viewBox='9 20 13.331 11.762'>
        <path id='Path_5' d='M 21.74242401123047 20 L 9.588111877441406 20 C 9.274452209472656 20 9 20.27445220947266 9 20.58811187744141 L 9 29.80186462402344 C 9 30.89967346191406 9.862564086914062 31.76223754882812 10.96037292480469 31.76223754882812 L 20.37016296386719 31.76223754882812 C 21.46797180175781 31.76223754882812 22.33053588867188 30.89967346191406 22.33053588867188 29.80186462402344 L 22.33053588867188 20.58811187744141 C 22.33053588867188 20.27445220947266 22.05608367919922 20 21.74242401123047 20 Z M 14.48904418945312 28.62564086914062 C 14.48904418945312 28.86088562011719 14.33221435546875 29.01771545410156 14.09696960449219 29.01771545410156 L 13.31282043457031 29.01771545410156 C 13.07757568359375 29.01771545410156 12.92074584960938 28.86088562011719 12.92074584960938 28.62564086914062 L 12.92074584960938 23.1365966796875 C 12.92074584960938 22.90135192871094 13.07757568359375 22.74452209472656 13.31282043457031 22.74452209472656 L 14.09696960449219 22.74452209472656 C 14.33221435546875 22.74452209472656 14.48904418945312 22.90135192871094 14.48904418945312 23.1365966796875 L 14.48904418945312 28.62564086914062 Z M 18.4097900390625 28.62564086914062 C 18.4097900390625 28.86088562011719 18.25296020507812 29.01771545410156 18.01771545410156 29.01771545410156 L 17.23356628417969 29.01771545410156 C 16.99832153320312 29.01771545410156 16.84149169921875 28.86088562011719 16.84149169921875 28.62564086914062 L 16.84149169921875 23.1365966796875 C 16.84149169921875 22.90135192871094 16.99832153320312 22.74452209472656 17.23356628417969 22.74452209472656 L 18.01771545410156 22.74452209472656 C 18.25296020507812 22.74452209472656 18.4097900390625 22.90135192871094 18.4097900390625 23.1365966796875 L 18.4097900390625 28.62564086914062 Z' />
      </svg>
    </div>
  )
}
