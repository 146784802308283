import { useFormik } from "formik";
import { useEffect } from "react";
import * as yup from "yup";
import useGetCompaniesAndCountries from "../shared/hooks/useGetCompaniesAndCountries";
import useGetOperatingSitesAndCustomers from "../shared/hooks/useGetOperatingSitesAndCustomers";
import useGetServiceIds from "../shared/hooks/useGetServiceIds";
import useGetTeams from "../shared/hooks/useGetTeams";
import useCreateFaultTicket from "./hooks/useCreateFaultTicket";
import useGetTicketTypes from "./hooks/useGetTicketTypes";
import useResetFields from "./hooks/useResetFields";
import * as utils from "../shared/utils";
import useSetParameters from "../shared/hooks/useSetParameters";

const useFaultTicketForm = ({ closeModal, setLoading, id }) => {
  const schema = yup.object().shape({
    companyName: yup.string().required(),
    operatingSite: yup.string().required(),
    // customer: yup.string().required(),
    serviceId: yup.string().required(),
    requestType: yup.string().required(),
    ticketSubject: yup.string().required(),
  });

  const returnValuesIfValid = async (values) => {
    const result = await schema
      .validate(values, { abortEarly: false })
      .catch((err) => {
        console.log(err?.errors[0]);
      });
    return result;
  };

  const { teams } = useGetTeams();

  const { postNewTicket, requestOnFlight } = useCreateFaultTicket({
    closeModal,
    teams,
  });

  const formik = useFormik({
    initialValues: {
      country: "",
      companyName: "",
      operatingSite: "",
      customer: "",
      requestType: "",
      serviceId: "",
      ticketSubject: "",
      description: "",
      services: [],
      dataCenters: [],
      countries: [],
      severity: 0,
      isShowResellerContacts: false,
    },
    onSubmit: async (values) => {
      const result = await returnValuesIfValid(values);
      if (result) {
        postNewTicket(result);
      }
    },
  });

  const {
    companies,
    countries,
    companiesSite,
    isCompanyLoading,
  } = useGetCompaniesAndCountries(
    setLoading,
    formik.setFieldValue,
    formik.values.country
  );

  const {
    operatingSites,
    customers,
    dataCenters,
  } = useGetOperatingSitesAndCustomers({
    countryId: utils.getCountryIdByCode(formik.values.country, countries),
    companyId: formik.values.companyName,
    fetchCustomers: true,
    setLoading,
    country: formik.values.country,
    countries: countries,
    isShowResellerContacts: formik.values.isShowResellerContacts,
  });

  useSetParameters({
    dataCenters,
    countries,
    setFieldValue: formik.setFieldValue,
  });

  const { services, isServiceLoading } = useGetServiceIds(
    formik.values.companyName,
    formik.values.operatingSite,
    setLoading,
    id
  );

  useEffect(() => {
    if (services && services.length > 0) {
      formik.setFieldValue("services", services);
    }
  }, [services]);

  const { ticketTypes } = useGetTicketTypes();

  useResetFields(formik);

  const addCondition = (func, field) => {
    return func(field) && formik.values[field] !== "";
  };

  const isActive = (field) => {
    // console.log("heyyy",formik.values.country)
    switch (field) {
      case "companyName":
        return formik.values.country !== "";
      case "operatingSite":
        return addCondition(isActive, "companyName");
      case "customer":
        return addCondition(isActive, "operatingSite");
      case "serviceId":
        return addCondition(isActive, "operatingSite");
      case "requestType":
        return addCondition(isActive, "serviceId");
      case "ticketSubject":
        return addCondition(isActive, "requestType");
      case "severity":
        return addCondition(isActive, "ticketSubject");
      case "description":
        return addCondition(isActive, "severity");
      case "submit":
        return addCondition(isActive, "severity") && !requestOnFlight;
      default:
        return false;
    }
  };

  return {
    formik,
    isActive,
    companies,
    countries,
    teams,
    operatingSites,
    customers,
    services,
    ticketTypes,
    companiesSite,
    isCompanyLoading,
    isServiceLoading,
  };
};

export default useFaultTicketForm;
