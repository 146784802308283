import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Table from './table';
import SerachBox from '../../Setting/Component/searchBox';
import accountService from 'services/accountService';
import StorageContext from 'context';

const EndCustomerTab = (props) => {
  const contextStore = useContext(StorageContext);
  console.log(props.data);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [endCustomerList, setEndCustomerList] = useState([]);
  const [errorpopup, setErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something went Wrong !');
  const [query, setQuery] = useState('');

  useEffect(() => {
    props.setIsLoading(true);
    getEndCustomerList();
  }, [props.data, pageNumber, itemsPerPage, query]);

  const columns = [
    {
      name: 'customer_name',
      value: t(`account.endcustomer_table.name`)
    },
    {
      name: 'customer_code',
      value: t(`account.endcustomer_table.company_code`)
    },
    {
      name: 'de_entity_country',
      value: t(`account.endcustomer_table.country`)
    },
    {
      name: 'sites',
      value: t(`account.endcustomer_table.sites`)
    },
    {
      name: 'total_ibs',
      value: t(`account.endcustomer_table.ibs`)
    }
  ];

  const searchCategory = [
    { name: 'Name', field: 'customer_name' },
    { name: 'Company Code', field: 'customer_code' },
    { name: 'Country', field: 'de_entity_country' },
    { name: 'Sites', field: 'sites' }
  ];

  //Get end customer list API call
  const getEndCustomerList = async () => {
    const paginationQuery = `page=${pageNumber}&pageSize=${itemsPerPage}&reseller_code=${props.data.customer_code}`;

    await accountService
      .getAllAccount(
        contextStore.token(),
        query != '' ? paginationQuery + `&${query.name}=${query.value}` : paginationQuery
      )
      .then((res) => {
        if (res.data.data.account.length > 0) {
          setTotal(res.data.data.total);
          setEndCustomerList(
            res.data.data.account.sort((a, b) => (a.customer_name < b.customer_name ? 1 : -1))
          );
        } else {
          setEndCustomerList([]);
        }
      })
      .catch((err) => {})
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="row">
        <div>
          <SerachBox
            searchCategory={searchCategory}
            query={query}
            setQuery={setQuery}
            setIsLoading={props.setIsLoading}
          ></SerachBox>
        </div>
      </div>
      <Table
        columns={columns}
        data={endCustomerList}
        setItemsPerPage={setItemsPerPage}
        setPageNumber={setPageNumber}
        total={total}
        pageNumber={pageNumber}
        setIsLoading={props.setIsLoading}
      ></Table>
    </div>
  );
};

export default EndCustomerTab;
