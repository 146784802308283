/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import AuthContext from "context"
import React, { useContext, useEffect, useRef, useState } from "react"
import CabinetServices from "services/CabinetService"
import Profile from "services/profileService"
import Common from "services/commonService"
import Floors from "services/floorServices"
import cabinetBreakerRelationService from "services/cabinetBreakerRelationService"
import moment from "moment"
import CloseIcon from "@mui/icons-material/Close"
import FitScreenIcon from "@mui/icons-material/FitScreen"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"
// import ChartComponent from 'common/sciChart';

import "./cabinet-popup.css"
import "./Layoutcss/142osahall.css"
import "./Layoutcss/167osahall.css"
import "./Layoutcss/147hall.css"
import "./Layoutcss/57osahall.css"
import "./Layoutcss/58osahall.css"
import "./Layoutcss/59osahall.css"
import "./Layoutcss/60osahall.css"
import "./Layoutcss/61osahall.css"
import "./Layoutcss/62osahall.css"
import "./Layoutcss/64osahall.css"
import "./Layoutcss/65hall.css"
import "./Layoutcss/66hall.css"
import "./Layoutcss/67hall.css"
import "./Layoutcss/osahall.css"
import "./Layoutcss/63oshall.css"
import "./Layoutcss/135tyohall.css"
import "./Layoutcss/134tyohall.css"
import "./Layoutcss/89tyohall.css"
import "./Layoutcss/90tyohall.css"
import "./Layoutcss/133hall.css"
import "./Layoutcss/op-351.css"
import "./Layoutcss/132hall.css"
import "./Layoutcss/45hall.css"
import "./Layoutcss/46hall.css"
import "./Layoutcss/3hall.css"
import "./Layoutcss/op-357.css"
import "./Layoutcss/47hall.css"
import "./Layoutcss/48hall.css"
import "./Layoutcss/49hall.css"
import "./Layoutcss/50hall.css"
import "./Layoutcss/51hall.css"
import "./Layoutcss/52hall.css"
import "./Layoutcss/53hall.css"
import "./Layoutcss/54hall.css"
import "./Layoutcss/42hall.css"
import "./Layoutcss/92hall.css"
import "./Layoutcss/39hall.css"
import "./Layoutcss/113hall.css"
import "./Layoutcss/114hall.css"
import "./Layoutcss/115hall.css"
import "./Layoutcss/116hall.css"
import "./Layoutcss/117hall.css"
import "./Layoutcss/93hall.css"
import "./Layoutcss/94hall.css"
import "./Layoutcss/95hall.css"
import "./Layoutcss/96hall.css"
import "./Layoutcss/97hall.css"
import "./Layoutcss/98hall.css"
import "./Layoutcss/99hall.css"
import "./Layoutcss/100hall.css"
import "./Layoutcss/101hall.css"
import "./Layoutcss/104hall.css"
import "./Layoutcss/105hall.css"
import "./Layoutcss/106hall.css"
import "./Layoutcss/103hall.css"
import "./Layoutcss/102hall.css"
import "./Layoutcss/107hall.css"
import "./Layoutcss/108hall.css"
import "./Layoutcss/109hall.css"
import "./Layoutcss/110hall.css"
import "./Layoutcss/211hall.css"
import "./Layoutcss/215hall.css"
import "./Layoutcss/216hall.css"
import "./Layoutcss/217hall.css"
import "./Layoutcss/218hall.css"
import "./Layoutcss/219hall.css"
import "./Layoutcss/220hall.css"
import "./Layoutcss/221hall.css"
import "./Layoutcss/222hall.css"
import "./Layoutcss/223hall.css"
import "./Layoutcss/224hall.css"
import "./Layoutcss/225hall.css"
import "./Layoutcss/226hall.css"
import "./Layoutcss/227hall.css"
import "./Layoutcss/op-405.css"
import "./Layoutcss/op-406.css"
import "./Layoutcss/169hall.css"
import "./Layoutcss/170hall.css"
import "./Layoutcss/172hall.css"
import "./Layoutcss/173hall.css"
import "./Layoutcss/143hall.css"
import "./Layoutcss/145hall.css"
import "./Layoutcss/146hall.css"
import "./Layoutcss/148hall.css"
import { LogoutOnSessionExpire } from "common/helpers"
import cabinectXconnectService from "services/cabinectXconnectService"
import { useTranslation } from "react-i18next"
import Charts from "common/charts"
import SensorCharts from "common/sensorChart"
import Loading from "views/super-admin/pre-loader/loader"
import h337 from "heatmap.js"
import dataHallServices from "services/dataHallServices"
import { lastOfType, style } from "glamor"
import Header from "views/super-admin/Layouts/Header"
import ReactPanZoom from "@ajainarayanan/react-pan-zoom"

const CabinetPopup = (props) => {
  const contextStore = useContext(AuthContext)
  const { t } = useTranslation()
  const [status, setStatus] = useState([])
  const [color, setColor] = useState([])
  const [brkpopup, setBrkPopup] = useState()
  const [popName, setPopName] = useState("")
  const [brkrdata, setBrkrData] = useState([])
  const [xconnectpopup, setXConnectPopup] = useState()
  const [connectdata, setConnectData] = useState([])
  const [xconnectcount, setXconnectCount] = useState(0)
  const [showBreakerPopup, setShowBreakerPopup] = useState(false)
  const [crahpop, setCrahpop] = useState(0)
  const [crahUrl, setCrahUrl] = useState()
  const [tempPop, setTempPop] = useState()
  const [dctime, setDctime] = useState()
  const [timeZonePrefix, setTimeZonePrefix] = useState(null)
  const [scroll, setScroll] = useState("hidden")
  const [chartVal, setChartVal] = useState({
    title: "Tempreature (in C)",
    data: [],
  })
  const [sensorVal, setSensorVal] = useState({
    title: "Tempreature (in C)",
    data: [],
  })

  const [radius, setRadius] = useState(30)

  const [zoom, setZoom] = useState(0.25)
  const [dx, setDx] = useState(-400)
  const [dy, setDy] = useState(-250)

  const [chartData, setChartData] = useState({})
  const [chartName, setchartName] = useState("")
  const [sensorData, setSensorData] = useState({})
  const [sensorName, setSensorName] = useState("")
  // const [modalIsOpen, setIsOpen] = useState(false);
  // const [floorIndex, setFloorIndex] = useState(0);
  // const [selectedRoom, setselectedRoom] = useState({});
  // const [editCabinets, setEditCabinets] = useState();
  // const [currentDataCenter, setCurrentDataCenter] = useState([]);
  // const [allFloorData, setAllFloorData] = useState([]);
  // const [showCabinetsEdit, setShowCabinetsEdit] = useState();
  // const [cabinets, setCabinets] = useState([]);
  // const [ascending, setAscending] = useState(true);
  // const [activeTab, setActiveTab] = useState();
  // const [cabinetAscending, setCabinetAscending] = useState(true);
  // const [dataCenter, setDataCenter] = useState([]);
  const authContext = useContext(AuthContext)
  // const [room, setRoom] = useState([]);
  const [breakerData, setBreakerData] = useState("")
  const [xconnectData, setXconnectData] = useState("")
  const [cvChart, setCvchart] = useState(false)
  const [sensorChart, setSensorchart] = useState(false)
  const [sciChart, setsciechart] = useState(false)
  const [cvCat, setcvCat] = useState("temperature")
  const [sensorCat, setSensorCat] = useState("temperature")
  const [cvPeriod, setcvPeriod] = useState(1)
  const [sensorPeriod, setSensorPeriod] = useState(1)
  const modalRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [layoutHtml, setLayoutHtml] = useState("")
  const [sensorId, setSensorId] = useState("")
  const [popup, setPopup] = useState(null)
  const [active, setActive] = useState("lay")
  const [initialScale, setInitialScale] = useState(1)
  const [initialPoints, setInitialPoints] = useState({ x: 0, y: 0 })
  const layoutRef = useRef()
  const buttonPlusRef = useRef()
  const buttonminusRef = useRef()
  const buttonResetRef = useRef()
  const prismaZoom = useRef()
  const chartHead = useRef("")
  const popupRef = useRef(null)
  const mapRef = useRef(null)
  const sensorPopupRef = useRef(null)
  const request = useRef(true)

  useEffect(async () => {
    if (props.activateTab == "heat") {
      setActive("heat")
      setIsLoading(true)
      await props.getHeatMapData(props.roomName)
      setIsLoading(false)
      setZoom(1)
      setDx(0)
      setDy(0)
    }
    if (props.activateTab == "lay") {
      setActive("lay")
      setZoom(0.25)
      setDx(-400)
      setDy(-250)
    }
  }, [props.activateTab])
  useEffect(async () => {
    // setIsOpen(props.show);
    let statusData = []
    let colorData = []

    const updateMousePosition = (ev) => {
      layoutRef.current?.scroll({ behavior: "auto" })
    }

    if (props.data?.roomHtml?.room_id) {
      layoutRef.current?.addEventListener("mousemove", updateMousePosition)
    }

    // new when we use context for status api
    if (contextStore.getStatusData) {
      contextStore.getStatusData.forEach((data) => {
        statusData.push(data.status_name)
        colorData.push(data.color_code)
      })
    }
    setColor(colorData)
    setStatus(statusData)

    // console.log(contextStore.getStatusData)

    // earlier when we called status api
    // await Common.status()
    //   .then((res) => {
    //     res.data.data.forEach((data) => {
    //       statusData.push(data.status_name)
    //       colorData.push(data.color_code)
    //     })
    //   })
    //   .catch((err) => LogoutOnSessionExpire(err))
    // setColor(colorData)
    // setStatus(statusData)

    if (document.getElementById("html_layout")) {
      setLayoutHtml(document.getElementById("html_layout").innerHTML)
    }
    return () => {
      layoutRef.current?.removeEventListener("mousemove", updateMousePosition)
    }
  }, [props.show])

  useEffect(() => {
    // console.log(props.selectedFloor);
    // console.log(props.selectedFloor.datacenter.country_id);
    let country = ""
    if (props.selectedFloor.datacenter.country_id === 1) {
      country = "Japan"
    }
    if (props.selectedFloor.datacenter.country_id === 2) {
      country = "Korea"
    }
    if (props.selectedFloor.datacenter.country_id === 3) {
      country = "China"
    }
    if (props.selectedFloor.datacenter.country_id === 4) {
      country = "Indonesia"
    }
    if (props.selectedFloor.datacenter.country_id === 5) {
      country = "Philippines"
    }
    Profile.getTimezone(localStorage.getItem("token")).then((res) => {
      res.data.data.forEach((val) => {
        // console.log(val);
        // console.log(contextStore);
        // console.log(val.country_name)
        if (val.country_name === country) {
          // console.log(val)
          // console.log(val.country_name);
          // console.log(val.timezone);
          // console.log(val);
          // console.log(val.timezone_prefix);
          setTimeZonePrefix(val.timezone_prefix)
          setDctime(val.timezone)
        }
      })
    })
  }, [])

  // useEffect(() => {
  //   if (cvChart) {
  //     document.addEventListener('click', );
  //   }
  // }, [cvChart]);

  // useEffect(() => {
  //   getChartval();
  // }, [cvPeriod]);

  const handleClick = (val) => {
    setcvPeriod(() => val)
    getChartval(val, cvCat)
  }
  const handleCateo = (val) => {
    setcvCat(() => val)
    getChartval(cvPeriod, val)
  }

  const handleSensorClick = (val) => {
    setSensorPeriod(() => val)
    getSensorval(val, sensorCat)
  }
  const handleSensorCateo = (val) => {
    setSensorCat(() => val)
    getSensorval(sensorPeriod, val)
  }
  // const closeModal = () => {
  //   modalRef.current.click();
  // };
  // const getEditCabinetPopup = (res) => {
  //   // props.setEditCabinets(res);
  //   // props.setShowCabinetsEdit(true);
  //   props.setShow(false);
  // };

  const getCabinetsData = async (e) => {
    // setCabinetAscending(true);

    // setselectedRoom(e);
    await CabinetServices.selectByHallId(authContext.token(), {
      room_id: e.id,
    })
      .then((res) => {
        //console.log(res.data.data)
        //  if(res.data.data.length > 0){
        // setCabinets(res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)));
        //  }
      })
      .catch((err) => LogoutOnSessionExpire(err))
  }

  // const selectDataCenterFloor = async (e, floor_id = 0) => {
  //     authContext.setNetworkDataCenter(e);
  //     // setAscending(true);
  //     // setCurrentDataCenter(e);

  //     await Floors.floorByDataCenterId(authContext.token(), e).then((res) => {
  //         if (res.data.data.length > 0) {

  //             // setAllFloorData(res.data.data);
  //             // setRoom(res.data.data[floor_id].rooms);
  //             if (res.data.data[floor_id].rooms.length > 0) {
  //                 getCabinetsData(res.data.data[floor_id].rooms[0]);
  //             }

  //             // setFloorIndex(floor_id);
  //             // setActiveTab(floor_id);
  //         } else {
  //             // setFloorIndex(null);
  //         }
  //     });
  // }

  //<div class="content"> <i class="fa-solid fa-square-pen bg-light"></i> </div>
  const getPopupInnerNA = (val) => {
    let temp = "N/A"
    let id = 1
    return `
    <div class="content-title"><p>${val}</p></div>
    <div class="content" style="padding-top:2px"><p>${t(
      "layout.status"
    )}:</p><p>${temp}</p>
  </div>
    <div class="content"><p>${t("dashboard.customer")}: </p>
          <p style="padding-left: 5px">${temp}</p>
     </div>
     <div class="content">
        <p>${t("dashboard.reseller")}: </p>
        <p style="padding-left: 5px"> ${temp}</p>
     </div>
     <div class="content">
        <p>${t("layout.sold_kw")}:</p>
        <p style="padding-left: 5px">${temp}</p>
     </div>
     <div class="content">
        <p>${t("layout.max_kw")}:</p>
        <p>${temp}</p>
     </div>
     <div class="content power-block">
      <p class="powerbre invs">#${t("layout.power")}</p>
      <p class="invs">${temp}</p>
      </div>
     <div class="content power-block xconnect-hover" >
        <p class="connectx invs">#${t("layout.xconnects")}</p>
        <p class="invs">${temp}</p>
     </div>
     </div>
      <div class="spinner-border spinnerLoader"  style="${
        props.isCabinetInfoDataLoading ? "display:block" : "display:none"
      }"role="status">
  <span class="sr-only">Loading...</span>
</div>
   `
  }

  const getPopupInnerContentUpdated = (val, sysref) => {
    let temp = "NA"
    let humid = "NA"
    let id = sysref

    // code for JKT2 when cabinet have CF in name
    let popupId = val.cabinet_name.replaceAll("-", "")
    let finalCabinetName = val.cabinet_name
    if (
      document.getElementById(`title-${popupId}`).classList.contains("cfDiv")
    ) {
      var num = document
        .getElementById(`title-${popupId}`)
        .getAttribute("data-value")
      finalCabinetName = "CF" + num + "-" + val.cabinet_name
    }
    return `
    <div class="content-title"><p>${finalCabinetName}</p></div>
    <div class="content" style="padding-top:2px"><p>${t(
      "layout.status"
    )}:</p><p>${status[val.status - 1]}</p></div><div class="content"><p>${t(
      "dashboard.customer"
    )}: </p>
          <p style="padding-left: 5px">${
            val.customer_name === null
              ? val.customer || "NA"
              : val.customer_name || "NA"
          }</p>
     </div>
     <div class="content">
        <p>${t("dashboard.reseller")}: </p>
        <p style="padding-left: 5px"> ${
          val.reseller_name !== null ? val.reseller_name : "NA"
        }</p>
     </div>
     <div class="content">
        <p>${t("layout.sold_kw")}:</p>
        <p style="padding-left: 5px">${
          val.sold_kw !== null ? val.sold_kw : 0
        }</p>
     </div>
     <div class="content">
        <p>${t("layout.max_kw")}:</p>
        <p>${val.max_kw !== null ? val.max_kw : 0}</p>
     </div>
     <div class="content power-block">
      <p class="powerbre invs">#${t("layout.power")}</p>
      <p class="${val.num_power !== null ? "breaker-color invs" : "invs"}">${
      val.num_power !== null ? val.num_power : 0
    }</p>

        <ul>
          <li><p class="powerbre">#${t("layout.power")}</p></li>
          <li><p class=${val.num_power !== null ? "" : " "}>${
      val.num_power !== null ? val.num_power : 0
    }</p>
          <div class="breaker-popup" >
            <div class="brk-pop">
              <div class="content-title">
                <div> ${popName}</div>
              </div>
              <div class="brkr-card">${breakerData}</div>
            </div>
          </div>
          </li>
        </ul>
      </div>
     <div class="content power-block ${
       val.num_xc !== 0 ? "xconnect-hover" : " "
     }" >
        <p class="connectx invs">#${t("layout.xconnects")}</p>
        <p class="${val.num_xc !== 0 ? "breaker-color invs" : "invs"}">${
      val.num_xc !== 0 ? val.num_xc : 0
    }</p>
        <ul>
          <li><p class="powerbre">#${t("layout.xconnects")}</p></li>
          <li><p class=${val.num_xc !== 0 ? "breaker-color" : " "}>${
      val.num_xc !== 0 ? val.num_xc : 0
    }</p>
            <div class="xconnect-popup" >
            <div class="brk-pop">
              <div class="content-title">
                <div> ${popName}</div>
              </div>
              <div class="brkr-card">${xconnectData}</div>
            </div>
          </div>
          </li>
        </ul>
     </div>

     <div  id='${id}'>
     <div class="temp-pop">
     <div class="temp-time" id='date-${id}'>As of 08-jun-2023 12:00</div>
     <div class="dh-title">Data Hall Environment</div>
     <div class="temp-pop-in1" >
       <div>
         <div>Temperature</div>
         <div>Humidity</div>
       </div>
       <div id='temp-${id}'>
         <div>N/A</div>
         <div>N/A</div>
       </div>
     </div>
     <div class="power-pop">
       <div class="power-txt">Power</div>
     <div class="power-flex" id='power-${id}'>
     <h1 class='power-na'>N/A</h1>


     </div>

     </div>
   </div>
     </div>
      <div class="spinner-border spinnerLoader"  style="${
        props.isCabinetInfoDataLoading ? "display:block" : "display:none"
      }"role="status">
  <span class="sr-only">Loading...</span>
</div>
   `
  }

  const getPopupInnerContent = (val) => {
    let id = val.ref_systemref
    return `

    <div class="content-title"><p>${val.name}</p></div>
    <div class="content" style="padding-top:2px"><p>${t(
      "layout.status"
    )}:</p><p>${status[val.status - 1]}</p></div><div class="content"><p>${t(
      "dashboard.customer"
    )}: </p>
          <p style="padding-left: 5px">${
            val.customer_name === null
              ? val.customer || "NA"
              : val.customer_name || "NA"
          }</p>
     </div>
     <div class="content">
        <p>${t("dashboard.reseller")}: </p>
        <p style="padding-left: 5px"> ${
          val.reseller_name !== null ? val.reseller_name : "NA"
        }</p>
     </div>
     <div class="content">
        <p>${t("layout.sold_kw")}:</p>
        <p style="padding-left: 5px">${
          val.sold_kw !== null ? parseFloat(val.sold_kw).toFixed(3) : 0
        }</p>
     </div>
     <div class="content">
        <p>${t("layout.max_kw")}:</p>
        <p>${val.max_kw !== null ? parseFloat(val.max_kw).toFixed(3) : 0}</p>
     </div>
     <div class="content power-block ${
       val.num_breakers !== null ? "breaker-hover" : " "
     }">
      <p class="powerbre invs">#${t("layout.power")}</p>
      <p class="${val.num_breakers !== null ? "breaker-color invs" : "invs"}">${
      val.num_breakers !== null ? val.num_breakers : 0
    }</p>

        <ul>
          <li><p class="powerbre">#${t("layout.power")}</p></li>
          <li><p class=${val.num_breakers !== null ? "breaker-color" : " "}>${
      val.num_breakers !== null ? val.num_breakers : 0
    }</p>
          <div class="breaker-popup" >
            <div class="brk-pop">
              <div class="content-title">
                <div> ${popName}</div>
              </div>
              <div class="brkr-card">${breakerData}</div>
            </div>
          </div>
          </li>
        </ul>
      </div>
     <div class="content power-block ${
       val.num_xconnects !== 0 ? "xconnect-hover" : " "
     }" >
        <p class="connectx invs">#${t("layout.xconnects")}</p>
        <p class="${val.num_xconnects !== 0 ? "breaker-color invs" : "invs"}">${
      val.num_xconnects !== 0 ? val.num_xconnects : 0
    }</p>
        <ul>
          <li><p class="powerbre">#${t("layout.xconnects")}</p></li>
          <li><p class=${val.num_xconnects !== 0 ? "breaker-color" : " "}>${
      val.num_xconnects !== 0 ? val.num_xconnects : 0
    }</p>
            <div class="xconnect-popup" >
            <div class="brk-pop">
              <div class="content-title">
                <div> ${popName}</div>
              </div>
              <div class="brkr-card">${xconnectData}</div>
            </div>
          </div>
          </li>
        </ul>
     </div>

     <div  id='${id}'>
     <div class="temp-pop">
     <div class="temp-time">As of 08-jun-2023 12:00</div>
     <div class="temp-pop-in1" >
       <div>
         <div>Temperature</div>
         <div>Humidity</div>
       </div>
       <div>
         <div>N/A</div>
         <div>N/A</div>
       </div>
     </div>
     <div class="power-pop">
       <div class="power-txt">Power</div>
     <div class="power-flex">
     <h1 class='power-na'>N/A</h1>

     </div>
   </div>
     </div>
      <div class="spinner-border spinnerLoader"  style="${
        props.isCabinetInfoDataLoading ? "display:block" : "display:none"
      }"role="status">
  <span class="sr-only">Loading...</span>
</div>
   `
  }

  const getChartval = (time, type) => {
    request.current = true

    let headers = {
      cabinet_name: chartHead.current,
      table: type,
      interval: time,
    }
    setIsLoading(true)
    CabinetServices.getChartData(headers)
      .then((res) => {
        // console.log(res.data.data)
        let val = {}
        setChartData(() => res.data)
        if (type == "temperature") {
          val.title = "Temperature"
          val.yAxis = "Temperature (in °C)"
        } else if (type == "humidity") {
          val.title = "Humidity"
          val.yAxis = "Humidity (in %)"
        } else {
          val.title = "power"
          val.yAxis = "Power (in A)"
        }
        val.time = time
        val.data = res.data.data
        setChartVal(() => val)

        if (request.current) {
          setCvchart(true)
        }
        setIsLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const getSensorval = (time, type) => {
    request.current = true

    let headers = {
      equipment_id: sensorId,
      table: type,
      interval: time,
    }
    setIsLoading(true)
    // console.log(dctime)
    // setIsLoading(false)
    CabinetServices.getSensorData(headers)
      .then((res) => {
        // console.log(res.data.data)
        // res.data.data.forEach((val) => {
        //   const eventTimestampUTC = new Date(val.event_timestamp_hk)
        //   const eventTimestampLocal = eventTimestampUTC.toLocaleString()
        // console.log(val.event_timestamp_hk, "||", eventTimestampLocal)
        // })

        // * need to add this new key bcz of the Charts Component and this api didnt have this key which was causing errors while plotting points
        const newKey = "ts_utc"
        const oldArr = res.data.data
        const newArr = oldArr.map((obj) => ({
          ...obj,
          [newKey]: new Date(obj.event_timestamp_utc),
        }))

        // console.log(newArr)

        let val = {}
        setSensorData(() => newArr)
        if (type == "temperature") {
          val.title = "Temperature"
          val.yAxis = "Temperature (in °C)"
        } else if (type == "humidity") {
          val.title = "Humidity"
          val.yAxis = "Humidity (in %)"
        }
        val.time = time
        val.data = newArr

        // const eventTimestampUTC = new Date(res.data.event_timestamp_hk)
        // const eventTimestampLocal = eventTimestampUTC.toLocaleString()

        // console.log("-----------")
        // console.log(eventTimestampLocal)
        // console.log("-----------")
        setSensorVal(() => val)

        if (request.current) {
          setSensorchart(true)
        }
        setIsLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  // getXConnect
  const getXconncet = async (pid, pname) => {
    setXconnectCount(0)
    await cabinectXconnectService
      .getConnect(authContext.token(), pid)
      .then((res) => {
        //  console.log("xconnect count...",res.data.data.length);
        setXconnectCount(res.data.data.length)
        setConnectData(res.data.data)
        let html = ""
        res.data.data &&
          res.data.data.forEach((data, i) => {
            return (html += `<div class="brkr-no">XConncet #${i + 1} : ${
              data?.installedbase_id
            }</div>
              <div class="breaker-name">${
                data?.aEndSpaceName === pname
                  ? data?.zEndSpaceName + " by " + data?.xcmediatypes
                  : data?.aEndSpaceName + " by " + data?.xcmediatypes
              }</div>`)
          })
        setXconnectData(html)
      })
      .catch((err) => LogoutOnSessionExpire(err))
  }

  const mapXconncet = () => {
    if (connectdata.length !== 0) {
      return (
        connectdata &&
        connectdata.map((data, i) => {
          return (
            <React.Fragment>
              <div className="brkr-no">
                XConncet #{i + 1} : {data?.installedbase_id}
              </div>
              <div className="breaker-name">
                {data?.aEndSpaceName === popName
                  ? data?.zEndSpaceName + " by " + data?.xcmediatypes
                  : data?.aEndSpaceName + " by " + data?.xcmediatypes}
              </div>
            </React.Fragment>
          )
        })
      )
    } else {
      return <div style={{ fontSize: "15px" }}>No Data Found</div>
    }
  }

  // End Xconnect
  const getBreaker = async (pid) => {
    await cabinetBreakerRelationService
      .getCabinet(authContext.token(), pid)
      .then((res) => {
        setBrkrData(res.data.data)
        let html = ""
        res.data.data &&
          res.data.data.forEach((data, i) => {
            return (html += `<div class="power-detail"><div class="brkr-no">Pwr Breaker ${
              i + 1
            }</div>
              <div class="breaker-name">${data?.breaker?.name}</div></div>`)
          })
        setBreakerData(html)
      })
      .catch((err) => LogoutOnSessionExpire(err))
  }

  const mapbrkr = () => {
    if (brkrdata.length !== 0) {
      return (
        brkrdata &&
        brkrdata.map((data, i) => {
          return (
            <React.Fragment>
              <div className="brkr-no">Pwr Breaker {i + 1}</div>
              <div className="breaker-name">{data?.breaker?.name}</div>
            </React.Fragment>
          )
        })
      )
    } else {
      return <div style={{ fontSize: "15px" }}>No Data Found</div>
    }
  }
  const test = (val) => {
    setCrahpop(val)
  }

  // const handleCvCharts = () => {
  //   setCvchart(!cvChart);
  // };
  const clickPlusbutton = () => {
    setZoom(zoom + 0.2)
    // setScale(scale * 1.2)
    // prismaZoom.current?.zoomIn(0.2)
    // panzoom.zoomIn()
    // setInitialScale((prev) => prev + 0.1)
  }
  const clickMinusbutton = () => {
    if (zoom <= 0.4) {
      setZoom(0.2)
    } else {
      setZoom(zoom - 0.2)
    }
    // panzoom.zoomOut()
    // setScale(scale / 1.2)
    // prismaZoom.current?.zoomOut(0.2)
    // setInitialScale((prev) => prev - 0.1)
  }
  const clickResetbutton = () => {
    setZoom(0.25)
    setDx(-400)
    setDy(-250)
  }

  const handleCharCat = (cateo) => {
    if (cateo === "temp") {
      setcvCat(1)

      let x = chartVal
      x.title = "Temperature"
      x.yAxis = "Temperature (in °C)"
      x.data = chartData.tempreature
      setChartVal(() => x)
    } else if (cateo === "humid") {
      setcvCat(2)
      let x = chartVal
      x.title = "Humidity"
      x.yAxis = "Humidity (in %)"
      x.data = chartData.humidity
      setChartVal(() => x)
    } else {
      setcvCat(3)
      let x = chartVal
      x.title = "Power"
      x.yAxis = "Power (in A)"
      x.data = chartData.power
      setChartVal(() => x)
    }
  }

  const handleSensorCat = (cateo) => {
    if (cateo === "temp") {
      setSensorCat(1)

      let x = sensorVal
      x.title = "Temperature"
      x.yAxis = "Temperature (in °C)"
      x.data = chartData.tempreature
      setSensorVal(() => x)
    } else if (cateo === "humid") {
      setSensorCat(2)
      let x = chartVal
      x.title = "Humidity"
      x.yAxis = "Humidity (in %)"
      x.data = chartData.humidity
      setSensorVal(() => x)
    }
  }

  function handleClickOutside(event) {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setCvchart(false)
      setcvCat("temperature")
      setcvPeriod(1)
      setIsLoading(false)
      request.current = false
    }
  }

  const handleChartPeriod = (val) => {
    setcvPeriod(val)
  }
  const returnEditedtwo = (data) => {
    var newdata = data.replace(
      /images/g,
      "images/" +
        props.data?.roomHtml.room.floor.datacenter.country_id +
        "/" +
        props.data?.roomHtml.room.floor.data_center_id +
        "/" +
        props.data?.roomHtml.room.floor_id +
        "/" +
        props.data?.roomHtml.room.id
    )
    var xmlString = newdata

    // Create a temporary element to parse the HTML
    var tempElement = document.createElement("div")
    tempElement.innerHTML = xmlString

    // Get the div by class name
    var targetDiv = tempElement.getElementsByClassName("shape-5")[0]
    var imgTag = targetDiv.getElementsByTagName("img")[0]
    imgTag.setAttribute("width", "1200") // Set your desired width
    imgTag.setAttribute("height", "500")

    return targetDiv.innerHTML
  }
  // useEffect(() =>{
  //   if(active === 'heat' && props.roomName === 'TYO3-07-02'){
  //     // Get all elements with class "shape-5"
  //     const elementsWithShape5Class = document.querySelectorAll('.shape-5');

  //     // Loop through each element
  //     elementsWithShape5Class.forEach(element => {
  //       // Get the parent element
  //       const parentElement = element.parentElement;

  //       // Add a new class to the parent element

  //       // Get the height and width of the parent element
  //       const parentHeight = parentElement.offsetHeight;
  //       const parentWidth = parentElement.offsetWidth;

  //       if(parentHeight > 500 && parentWidth > 1500){
  //         if(parentHeight > 500 && parentWidth > 1700 && parentWidth < 1800){
  //           parentElement.classList.add('new-class3');
  //         }else if(parentHeight > 500 && parentWidth > 2250 && parentWidth < 2800){
  //           parentElement.classList.add('new-class4');
  //         }else if(parentWidth > 1550 && parentWidth < 1600){
  //           parentElement.classList.add('new-class6');
  //         }
  //         else{
  //           parentElement.classList.add('new-class');
  //         }

  //       }else{
  //         if(parentHeight > 500 && parentWidth > 1000){
  //           parentElement.classList.add('new-class5');

  //         }else{
  //           parentElement.classList.add('new-class2');

  //         }
  //       }
  //       calculateData(active);
  //       // Log or use the height and width as needed
  //       console.log('Parent Height:', parentHeight);
  //       console.log('Parent Width:', parentWidth);
  //     });
  //   } else{
  //     calculateData(active);
  //   }
  // },[active])

  const returnEdited = (
    data,
    room_id,
    is_by_name,
    data_center,
    selectedFloor,
    jsondata
  ) => {
    // data = data.replace(/PEK1-3-3-C-001/g, props.roomName)

    var newdata = data.replace(
      /images/g,
      "images/" +
        props.data?.roomHtml.room.floor.datacenter.country_id +
        "/" +
        props.data?.roomHtml.room.floor.data_center_id +
        "/" +
        props.data?.roomHtml.room.floor_id +
        "/" +
        props.data?.roomHtml.room.id
    )
    var xmlString = newdata

    // var doc = new DOMParser().parseFromString(xmlString, "text/html");
    // var hoverelements = document.querySelectorAll("#popup-");
    // var heatMapData = [];
    setTimeout(function () {
      //var abcElements = document.querySelectorAll('.num_value');
      // var title = document.querySelectorAll('#title-' + attribute);
      // console.log("jsondata",jsondata);
      // Create a container element for the heatmap

      jsondata.forEach((val, index) => {
        let attribute = val.id
        //abcElements[index].id = 'title-' + attribute;
        // hoverelements[index].id = 'popup-' + attribute;

        if (is_by_name) {
          attribute = val.name
          if (val?.installedBase != null && val?.installedBase != undefined) {
            attribute = val.installedBase.ref_systemref
          }
        }

        // console.log(attribute);

        //set style for cab which are not available
        if (val.sync_status === 3) {
          document.getElementById("title-" + attribute).style.border =
            "3px solid red"
        }

        if (document.getElementById("title-" + attribute) !== null) {
          // code for JKT2 when cabinet have CF infront

            if (
            document
              .getElementById("title-" + attribute)
              .classList.contains("cfDiv")
          ) {
            var num = document
              .getElementById("title-" + attribute)
              .getAttribute("data-value")
            document.getElementById("title-" + attribute).innerHTML =
              "CF" + num + val.name.slice(-5)
          } else {
            document.getElementById(
              "title-" + attribute
            ).innerHTML = val.name.slice(-8)
          }
          document.getElementById("title-" + attribute).style.background =
            color[val.status - 1]

          // breker popup code
          // breker popup code
          // const colorchange=(breaker)=>{

          //   if(breaker !== "" && breaker > 0  ){
          //     document.getElementById("popup-" + attribute).firstElementChild.children[6].childNodes[3].classList.add("breaker-color");
          //   }else{
          //     document.getElementById("popup-" + attribute).firstElementChild.children[6].childNodes[3].classList.remove("breaker-color");
          //   }
          // }

          // const element = document.getElementById('title-' + attribute);

          // if (element) {
          //   const rect = element.getBoundingClientRect();
          //   const xdiv = rect.left;
          //   const ydiv = rect.top;
          //   // Example data
          //   heatMapData.push(
          //     { x: xdiv, y: ydiv, value: 15 }
          //   )

          //   console.log(`Div - X: ${xdiv}, Y: ${ydiv}`);
          // }

          document.getElementById(
            "title-" + attribute
          ).onmouseover = function () {
            setPopName("")
            let nodeList = document.querySelectorAll(".info_ctl")
            for (let i = 0; i < nodeList.length; i++) {
              nodeList[i].classList.remove("blk", "blk2")
            }

            let nodeList2 = document.querySelectorAll(".info_ct")
            for (let i = 0; i < nodeList2.length; i++) {
              nodeList2[i].classList.remove("blk", "blk2")
            }

            // * Positioning the tooltip dynamically
            let nodeListtest = document.querySelectorAll(".innr_ctl")

            for (let i = 0; i < nodeListtest.length; i++) {
              var tooltips = document.querySelectorAll(".card-body")
              var parentRect = tooltips[0].getBoundingClientRect()
              const x = document.getElementById("title-" + attribute)
              const y = document.getElementById("popup-" + attribute)
              var tooltipRect2 = x.getBoundingClientRect()
              var tooltipStyle = nodeListtest[i].style
              var triangle = y.children[1]

              if (window.innerWidth <= 1600) {
                // * Check if the tooltip will go out of the top boundary of the parent
                if (tooltipRect2.top - 90 < parentRect.top) {
                  // 307 === 17.5rem (height of tooltip as we dont hv a fixed height of tooltip)
                  tooltipStyle.bottom = "auto"
                  tooltipStyle.top = "-10px"
                } // * Check if the tooltip will go out of the bottom boundary of the parent
                else if (tooltipRect2.bottom + 307 > parentRect.bottom) {
                  tooltipStyle.top = "auto"
                  tooltipStyle.bottom = "-30px"
                }
              } else {
                // * Check if the tooltip will go out of the top boundary of the parent
                if (tooltipRect2.top - 307 < parentRect.top) {
                  // 307 === 17.5rem (height of tooltip as we dont hv a fixed height of tooltip)
                  tooltipStyle.bottom = "auto"
                  tooltipStyle.top = "-10px"
                } // * Check if the tooltip will go out of the bottom boundary of the parent
                else if (tooltipRect2.bottom + 307 > parentRect.bottom) {
                  tooltipStyle.top = "auto"
                  tooltipStyle.bottom = "-30px"
                }
              }

              // * Check if the tooltip will go out of the left boundary of the parent
              if (
                tooltipRect2.right - tooltipStyle.width / 2 <
                parentRect.left
              ) {
                tooltipStyle.left = "0"
                tooltipStyle.right = "auto"
                tooltipStyle.transform = `translateX(0%)`
                nodeListtest[i].parentElement.classList.remove("info_ctl")
                nodeListtest[i].parentElement.classList.add("info_ctl2")
                triangle.classList.remove("triangle-right.gap_lft")
                triangle.classList.remove("triangle-right")
                triangle.classList.add("triangle-lft")
                triangle.style.zIndex = 20
              } // * Check if the tooltip will go out of the right boundary of the parent
              else if (
                tooltipRect2.left - tooltipStyle.width / 2 <
                parentRect.right
              ) {
                tooltipStyle.left = "auto"
                tooltipStyle.right = "0"
                tooltipStyle.transform = `translateX(-48%)`
                nodeListtest[i].parentElement.classList.add("info_ctl")
                nodeListtest[i].parentElement.classList.remove("info_ctl2")
                triangle.classList.remove("triangle-right.gap_lft")
                triangle.classList.remove("triangle-lft")
                triangle.classList.add("triangle-right")
                triangle.style.zIndex = 20
              }
            }
            //  colorchange(val.num_breakers);

            setPopName(val.name)
            // getXconncet(attribute, val.name);
            // getBreaker(attribute);
            document.getElementById("popup-" + attribute).classList.add("blk")
          }

          document.getElementById(
            "title-" + attribute
          ).onmouseleave = function () {
            // setTimeout(function () {
            // }, 1000)
            if (document.getElementById("popup-" + attribute) !== null) {
              document
                .getElementById("popup-" + attribute)
                .classList.remove("blk")
            }
          }

          if (document.getElementById("popup-" + attribute) !== null) {
            document.getElementById(
              "popup-" + attribute
            ).onmouseenter = function () {
              document
                .getElementById("popup-" + attribute)
                .classList.add("blk2")
            }

            document
              .getElementById("popup-" + attribute)
              .addEventListener("mouseleave", () => {
                document
                  .getElementById("popup-" + attribute)
                  .classList.remove("blk2")
              })
            // onmouseleave = function () {

            // }
          }

          if (document.getElementById("popup-" + attribute) !== null) {
            document.querySelectorAll(".temp-pop-in1").click = function () {
              document
                .querySelectorAll(".power-pop")
                .classList.remove("invs", "blk2")
            }
          }

          // open canvas chart

          // canvas chart ends here

          // open sci chart

          // if (document.getElementById('popup-' + attribute) !== null) {
          //   var elements = document.querySelectorAll('.power-pop');
          //   elements.forEach(function (element) {
          //     element.addEventListener('click', function (name) {
          //       chartHead.current = popName;
          //       handleClick(cvPeriod);
          //     });
          //   });
          // }

          // canvas scie ends here
        }

        function invisible() {
          document.getElementById("popup-" + attribute).classList.remove("blk")
          setBrkPopup(0)
          setXConnectPopup(0)
        }

        // breker popup code

        // let allChildren = document.querySelectorAll("#popup-" + attribute + " .content > p");
        // console.log(allChildren.length)
        // if(allChildren.length > 0){
        //     allChildren[1].textContent = status[val.status-1]
        // allChildren[3].textContent = val.customer
        // allChildren[5].textContent = val.max_kw !==null ? parseFloat(val.max_kw).toFixed(3) : 0
        // allChildren[7].textContent = val.num_breakers !==null ? val.num_breakers : 0
        // allChildren[9].textContent = val.num_xconnents !==null ? val.num_xconnents : 0
        if (document.getElementById("popup-" + attribute) !== null) {
          document.getElementById("popup-" + attribute).children[0].innerHTML =
            val.sync_status === 3
              ? getPopupInnerNA(val.name)
              : getPopupInnerContent(val)
          // breker popup code
          document.getElementById(
            "popup-" + attribute
          ).firstElementChild.children[6].style.cursor = "pointer"

          document.getElementById(
            "popup-" + attribute
          ).firstElementChild.children[7].style.cursor = "pointer"

          // console.log(document.getElementById(
          //   "popup-" + attribute
          // ).firstElementChild.children[6].childNodes[5].children[1])
          // document.getElementById(
          //   "popup-" + attribute
          // ).firstElementChild.children[6].childNodes[5].children[1].onmouseenter = function () {
          //  //  document.getElementById("popup-" + attribute).insertAdjacentHTML("afterend",
          //  //      "<h3>This is the text which has been inserted by JS</h3>");
          //   console.log(attribute)
          //   if(val.num_breakers !== "" && val.num_breakers > 0 ){
          //     setPopName(val.name);
          //     getBreaker(attribute);
          //     console.log(1)
          //     setShowBreakerPopup(true)

          //   }
          //   // }else{
          //   //   setBrkPopup(0);
          //   // }

          //   //document.body.addEventListener("click", invisible, true);
          // };

          // document.getElementById(
          //   "popup-" + attribute
          // ).firstElementChild.children[6].childNodes[5].children[1].onmouseleave = function () {

          //  setShowBreakerPopup(false)
          //  console.log(showBreakerPopup)
          // }

          // breker popup code

          document.getElementById(
            "popup-" + attribute
          ).firstElementChild.children[7].childNodes[3].onmouseover = function () {
            setPopName(val.name)
            // getXconncet(attribute);
            if (xconnectcount !== "" && xconnectcount > 0) {
              setXConnectPopup(1)
            } else {
              setXConnectPopup(0)
            }

            document.body.addEventListener("click", invisible, true)
          }
        }

        // if (document.getElementById('popup-' + attribute) !== null) {
        //   // console.log(document.getElementById(`${val.ref_systemref}`));
        //   let id = val.ref_systemref;
        //   document.getElementById(id).addEventListener('click', function (name) {
        //     handleClick(cvPeriod);
        //     chartHead.current = popName;
        //   });
        //   // var elements = document.querySelectorAll('.temp-pop-in1');
        //   // elements.forEach(function (element) {
        //   //   element.addEventListener('click', function (name) {
        //   //     handleClick(cvPeriod);
        //   //     chartHead.current = popName;
        //   //   });
        //   // });
        // }

        // function invDisplay() {
        //   document.getElementsByClassName("card-body").classList.add("invs");
        // }

        //  }

        // old code
        // // console.log(document.querySelector('.num_data'))
        // // console.log(document.querySelector('#title-55'))
        //  let article = document.querySelector('#title-'+ attribute);
        // //article.textContent = val.name.split("-")[5]
        // let allChildren = document.querySelectorAll("#popup-" + attribute + " .content > p");
        // allChildren[1].textContent = status[val.status-1]
        // allChildren[3].textContent = val.customer
        // allChildren[5].textContent = val.max_kw !==null ? parseFloat(val.max_kw).toFixed(3) : 0
        // allChildren[7].textContent = val.num_breakers !==null ? val.num_breakers : 0
        // allChildren[9].textContent = val.num_xconnents !==null ? val.num_xconnents : 0
      })

      function crahFunction() {
        setCrahpop(1)
        let NodeVal = document.getElementById("crah-pop")

        setCrahUrl(NodeVal.value)
      }
      if (document.getElementById("crah-pop") !== null) {
        document
          .getElementById("crah-pop")
          .addEventListener("mouseover", crahFunction)
      }
      if (props.cabinetData.length > 0) {
        props.cabinetData.forEach((data) => {
          let attribute = data.cabinet_name.replace(/-/g, "")
          if (document.getElementById("title-" + attribute) !== null) {
            document.getElementById("title-" + attribute).style.background =
              color[data.status - 1]
          }
          if (document.getElementById("popup-" + attribute) !== null) {
            document.getElementById(
              "popup-" + attribute
            ).children[0].innerHTML = getPopupInnerContentUpdated(
              data,
              attribute
            )
            document
              .getElementById(attribute)
              .addEventListener("click", function () {
                chartHead.current = popName

                handleClick(cvPeriod, cvCat)
              })
          }
        })
      }
      createPower()
      createTemp()
      /*  if (Object.keys(props.powerBreakerData).length > 0) {
        for (const cabinetName in props.powerBreakerData) {
          if (props.powerBreakerData.hasOwnProperty(cabinetName)) {
            let attribute = cabinetName.replace(/-/g, '');
            const cabinetData = props.powerBreakerData[cabinetName];
            document.getElementById('power-' + attribute).innerHTML = `${cabinetData
              .map((data) => {
                return ` <div >
                <div class="breaker-name">${data.breaker_name ? data.breaker_name : 0}</div>
                <div class="pwr-title-actual">Actual: ${data.value != null ? data.value : 'N/A'}</div>
                <div class="pwr-title-subs">Subscribed: ${data.subscribed_amps ? ' ' + data.subscribed_amps : 0}</div>
                </div>`;
              })
              .join('')}`;
          }
        }
      }

      if (Object.keys(props.temperatureData).length > 0) {
        for (const cabinetName in props.temperatureData) {
          if (props.temperatureData.hasOwnProperty(cabinetName)) {
            let attribute = cabinetName.replace(/-/g, '');

            const cabinetData = props.temperatureData[cabinetName];
            const parsedDate = moment(cabinetData[0].ts_utc, 'YYYY-MM-DD HH:mm:ss');
            const formattedDate = parsedDate.format('D-MMMM-YYYY HH:mm');
            document.getElementById('temp-' + attribute).innerHTML = ` <div>${
              cabinetData[1].value ? cabinetData[1].value : 0
            }</div>
          <div>${cabinetData[0].value ? cabinetData[0].value : 0}</div>`;
            document.getElementById('date-' + attribute).innerHTML = `${'As of ' + formattedDate}`;
          }
        }
      }*/
    }, 100)

    return newdata
  }

  const [isScrolling, setIsScrolling] = useState(false)
  const [scaleValue, setScaleValue] = useState(0.45)
  const [scaleValueW, setScaleValueW] = useState(0.6)
  const [scaleValueStatus, setScaleValueStatus] = useState(false)
  const [scaleWidth, setScaleWidth] = useState(0)
  const decayRate = 0.01 // Adjust the decay rate as needed
  // const [mapData, setMapData] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false)
  const handleFullScreenToggle = () => {
    if (!isFullScreen) {
      // Enter full-screen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen()
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen()
      }
    } else {
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }

    // Toggle the full-screen state
    setIsFullScreen(!isFullScreen)
  }

  const isScrollableCheck = () => {
    if (layoutRef.current.scrollWidth === scaleWidth) {
      // Matching with scaleWidth
      // console.log("hhhhh")
      return false // or do something else
    } else if (layoutRef.current.scrollWidth > layoutRef.current.clientWidth) {
      // If not matching, check for horizontal scrolling
      // console.log("hellll")
      return true // Indicates horizontal scrolling
    } else {
      // If neither condition is met, there is no horizontal scrolling
      return false
    }
  }

  useEffect(() => {
    // Call handleScroll on component load
    if (active === "heat") {
      //console.log(document.getElementById('html_layout').innerHTML)
      if (document.getElementById("html_layout")) {
        setLayoutHtml(document.getElementById("html_layout").innerHTML)
        document.getElementById("html_layout").innerHTML = ""
      }
    }
  }, [active])

  const handleScroll = () => {
    const isScrollable =
      layoutRef.current.scrollWidth > layoutRef.current.clientWidth
    const isScrollableh =
      layoutRef.current.scrollHeight > layoutRef.current.clientHeight
    // console.log("width--",layoutRef.current.scrollWidth, layoutRef.current.clientWidth, scaleWidth)
    if (isScrollableh || isScrollable) {
      // Set a flag to indicate that scrolling is happening
      setIsLoading(true)
      setScaleValue((prevScale) => prevScale - decayRate)
    } else {
      // Scrolling is finished
      setIsLoading(false)
      setIsScrolling(false)
      heatMapData(active)
    }
  }

  useEffect(() => {
    if (active === "heat") {
      // Get all elements with class "shape-5"
      setScaleValueStatus(true)
      const elementsWithShape5Class = document.querySelectorAll(".shape-5")
      // console.log(elementsWithShape5Class)
      // Loop through each element
      elementsWithShape5Class.forEach((element) => {
        // Get the parent element
        const parentElement = element.parentElement
        // Add a new class to the parent element
        parentElement.classList.add("new-class8")
        // Apply styles
        const parentWidth = parentElement.offsetWidth
        const parentHeight = parentElement.offsetHeight
        setScaleWidth(parentWidth)
        // console.log("heloo---", parentWidth, parentHeight)
        // if((parentWidth > 2200 && parentWidth < 2300) || (parentWidth > 2550 && parentWidth < 2800)){
        //   parentElement.style.transform = `scale(0.3, 0.6)`;
        //   parentElement.style.transformOrigin = 'left top';
        // }else{
        parentElement.style.transform = `scale(${scaleValue})`
        parentElement.style.transformOrigin = "left top"
        // }
      })
    } else {
      // setScaleValue(0.6);
      setScaleValueStatus(false)
      // removeNewClass()
    }
  }, [active, scaleValue])

  const onPan = (dx, dy) => {
    setDx(dx)
    setDy(dy)
  }

  const removeNewClass = () => {
    const elementsWithNewClass = document.querySelectorAll(".new-class8")
    elementsWithNewClass.forEach((element) => {
      // element.style.transform = '';  // Reset the transform style
      // element.style.transformOrigin = '';
      // element.style.transition = '';layoutRef.current.clientWidth
      element.classList.remove("new-class8")
    })
  }

  //   setting radius of heat sensors
  useEffect(() => {
    window.innerWidth <= 1600 ? setRadius(20) : setRadius(30)
  }, [])

  useEffect(() => {
    if (layoutHtml && isScrolling) {
      console.log("Working")
      heatMapData(active)
    }
  }, [props.mapData])

  useEffect(() => {
    // Call handleScroll on component load
    if (active === "heat" && !props.isMapDataLoading) {
      handleScroll()
    } else {
      let heatmapContainer = document.getElementById("div-containersd")
      let heatmapInstance = null

      removeHeatmapCanvas(heatmapContainer, heatmapInstance)
    }
  }, [active, scaleValue, props.isMapDataLoading])

  const handleResize = () => {
    heatMapData(active)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [active])

  const returnColorValue = (value) => {
    let updatedVal
    if (value >= 0 && value <= 22) {
      updatedVal = 10
    } else if (value >= 23 && value <= 27) {
      updatedVal = 22
    } else if (value >= 28 && value <= 30) {
      updatedVal = 30
    } else {
      updatedVal = 50
    }
    return updatedVal
  }

  const handleHover = (event, value, id, humidity) => {
    setPopup({ x: event.clientX, y: event.clientY, value, id, humidity })
  }

  const handleMouseOut = () => {
    setPopup(null)
  }

  const heatMapData = async (active) => {
    if (layoutHtml) {
      document.getElementById("html_layout").innerHTML = layoutHtml
    }
    let heatmapContainer = document.getElementById("div-containersd")
    let heatmapInstance = null
    let heatMapData = []
    removeHeatmapCanvas(heatmapContainer, heatmapInstance)

    if (active === "heat") {
      let heatmapDivs = heatmapContainer.querySelectorAll(".heatmap-div")

      let allSensorId = [] // stores the id of all the sensors in the html

      heatmapDivs.forEach((container) => {
        let pTagSensors = container.querySelector("p")
        const pTagId = pTagSensors.id
        allSensorId.push(pTagId)
      })

      let sensorsReceived = [] // stores the id of the sensors data received
      let missingSensors = [] // stores the id of the missing sensors
      let missingSensorPos = []

      // sensorOff Icon
      const svgMarkupSM = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" style="background-color: #FFE0E0;  width: 80px; height: 100px; position: absolute;
      top: -35px; left: -50px;">
      <rect fill="none" height="24" width="24"/>
      <path d="M8.14,10.96C8.05,11.29,8,11.64,8,12c0,1.1,0.45,2.1,1.17,2.83l-1.42,1.42C6.67,15.16,6,13.66,6,12 c0-0.93,0.21-1.8,0.58-2.59L5.11,7.94C4.4,9.13,4,10.52,4,12c0,2.21,0.9,4.21,2.35,5.65l-1.42,1.42C3.12,17.26,2,14.76,2,12 c0-2.04,0.61-3.93,1.66-5.51L1.39,4.22l1.41-1.41l18.38,18.38l-1.41,1.41L8.14,10.96z M17.42,14.59C17.79,13.8,18,12.93,18,12 c0-1.66-0.67-3.16-1.76-4.24l-1.42,1.42C15.55,9.9,16,10.9,16,12c0,0.36-0.05,0.71-0.14,1.04L17.42,14.59z M20,12 c0,1.48-0.4,2.87-1.11,4.06l1.45,1.45C21.39,15.93,22,14.04,22,12c0-2.76-1.12-5.26-2.93-7.07l-1.42,1.42C19.1,7.79,20,9.79,20,12z"/>
      </svg>`

      const svgMarkupLG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" style="background-color: #FFE0E0;  width: 120px; height: 130px; position: absolute;
      top: -35px; left: -50px;">
      <rect fill="none" height="24" width="24"/>
      <path d="M8.14,10.96C8.05,11.29,8,11.64,8,12c0,1.1,0.45,2.1,1.17,2.83l-1.42,1.42C6.67,15.16,6,13.66,6,12 c0-0.93,0.21-1.8,0.58-2.59L5.11,7.94C4.4,9.13,4,10.52,4,12c0,2.21,0.9,4.21,2.35,5.65l-1.42,1.42C3.12,17.26,2,14.76,2,12 c0-2.04,0.61-3.93,1.66-5.51L1.39,4.22l1.41-1.41l18.38,18.38l-1.41,1.41L8.14,10.96z M17.42,14.59C17.79,13.8,18,12.93,18,12 c0-1.66-0.67-3.16-1.76-4.24l-1.42,1.42C15.55,9.9,16,10.9,16,12c0,0.36-0.05,0.71-0.14,1.04L17.42,14.59z M20,12 c0,1.48-0.4,2.87-1.11,4.06l1.45,1.45C21.39,15.93,22,14.04,22,12c0-2.76-1.12-5.26-2.93-7.07l-1.42,1.42C19.1,7.79,20,9.79,20,12z"/>
      </svg>`

      const sensorOffDiv = document.createElement("div")
      sensorOffDiv.classList.add("no-data")
      sensorOffDiv.style.display = "none"
      sensorOffDiv.style.position = "relative"
      if (window.innerWidth <= 1600) {
        sensorOffDiv.innerHTML = svgMarkupSM
      } else {
        sensorOffDiv.innerHTML = svgMarkupLG
      }

      heatmapDivs.forEach((div) => {
        let clone = sensorOffDiv.cloneNode(true)
        div.appendChild(clone)
      })

      // Iterate through each div and calculate x, y values for the p tag relative to the container
      props.mapData.data.forEach(function (eqId) {
        // var pTag = div.querySelector('p');
        // console.log("heat map data", eqId.equipment_id, eqId.value)
        var pTagElement = document.getElementById(eqId.equipment_id) // Use the id to select the p tag
        // pTagElement.insertBefore(sensorOffDiv)
        if (pTagElement) {
          var rect = pTagElement.getBoundingClientRect()
          var containerRect = heatmapContainer.getBoundingClientRect()

          // console.log("containerRect",containerRect,"rect",rect)
          var xValue = parseInt(rect.left - containerRect.left)
          var yValue = parseInt(rect.top - containerRect.top)
          // let val = Math.floor(Math.random() * 30) + 1
          // console.log(xValue, yValue, val)

          // Push the values into the heatMapData array
          heatMapData.push({
            x: xValue,
            y: yValue,
            value: returnColorValue(Math.round(eqId.temperature)),
            temp: eqId.temperature.toFixed(2),
            humidity: eqId.humidity.toFixed(2),
            id: eqId.equipment_id,
          })
        }
      })

      // checking which sensors did we receive
      props.mapData.data.map((sensor) => {
        sensorsReceived.push(sensor.equipment_id)
      })

      // pushing the missing sensors into array
      allSensorId.map((id) => {
        if (!sensorsReceived.includes(id)) {
          missingSensors.push(id)
        }
      })

      // accessing the sensorOff icon and making it visible
      missingSensors.map((sensor) => {
        let pElement = document.getElementById(sensor)
        let sensorOff = pElement.parentElement.children[1]

        // getting dimension of the sensor icon parent
        var rect2 = sensorOff.parentElement.getBoundingClientRect()
        var containerRect = heatmapContainer.getBoundingClientRect()

        pElement.style.display = "none"
        sensorOff.style.display = "block"
        sensorOff.style.zIndex = 999
        sensorOff.parentElement.style.visibility = "visible"

        // finding the x and y value of the sensor off icon in respect to the whole container
        var xValue2 = parseInt(rect2.left - containerRect.left)
        var yValue2 = parseInt(rect2.top - containerRect.top)

        // pushing the dimensions in array
        missingSensorPos.push({
          x: xValue2,
          y: yValue2,
          id: sensor,
          temp: "No Data",
          humidity: "No Data",
        })
      })

      heatmapInstance = h337.create({
        container: heatmapContainer,
        radius: radius, // set your desired radius
        maxOpacity: 0.6, // set your desired maxOpacity
        minOpacity: 0.1, // set your desired minOpacity
        blur: 0.5, // set your desired blur
        // width: 1000, // set your desired width
        // height: 1000 // set your desired height
      })
      console.log(radius)
      // Set data for the heatmap
      heatmapInstance.setData({
        max: 32,
        data: heatMapData,
      })

      // heatmap sensor hover popup
      var heatmapCanvas = heatmapContainer.querySelector(".heatmap-canvas")

      heatmapCanvas.addEventListener("mousemove", function (event) {
        var x = event.offsetX
        var y = event.offsetY

        // Find the bubble that the mouse is inside
        var hoveredBubble = heatMapData.find((point) => {
          var distance = Math.sqrt((point.x - x) ** 2 + (point.y - y) ** 2)

          return distance <= radius // Adjust the radius based on your bubble size
        })

        // checking if the user is hovering the sensor off icon
        var missingSensor = missingSensorPos.find((point) => {
          var distance = Math.sqrt((point.x - x) ** 2 + (point.y - y) ** 2)
          // Adjust the radius based on your bubble and screen size

          if (window.innerWidth <= 1600) {
            return distance <= 15
          } else {
            return distance <= 20
          }
        })

        if (hoveredBubble) {
          setPopup({
            x: x,
            y: y,
            value: hoveredBubble.value,
            id: hoveredBubble.id,
            temp: hoveredBubble.temp,
            humidity: hoveredBubble.humidity,
          })
        } else if (missingSensor) {
          // if user hovers over the sensor off icon
          setPopup({
            x: x,
            y: y,
            value: missingSensor.value,
            id: missingSensor.id,
            temp: missingSensor.temp,
            humidity: missingSensor.humidity,
          })
        } else {
          setPopup(null)
        }
      })
    }
  }

  // console.log("-----------")
  // console.log(sensorId)
  const removeHeatmapCanvas = (heatmapContainer, heatmapInstance) => {
    if (heatmapInstance) {
      // Destroy the heatmap instance
      heatmapInstance.destroy()
      heatmapInstance = null
    }

    // Remove the existing canvas element from its parent
    if (heatmapContainer !== null) {
      const existingCanvas = heatmapContainer.querySelector(".heatmap-canvas")
      // console.log("existingCanvas--", existingCanvas)
      if (existingCanvas) {
        existingCanvas.remove()
      }
    }
  }

  const createPower = () => {
    try {
      if (Object.keys(props.powerBreakerData).length > 0) {
        for (const cabinetName in props.powerBreakerData) {
          if (props.powerBreakerData.hasOwnProperty(cabinetName)) {
            let attribute = cabinetName.replace(/-/g, "")
            const cabinetData = props.powerBreakerData[cabinetName]
            const elementPowerId = "power-" + attribute
            const elementPower = document.getElementById(elementPowerId)
            if (elementPower) {
              elementPower.innerHTML = `${cabinetData
                .map((data) => {
                  return ` <div >
                <div class="breaker-name">${
                  data.breaker_name ? data.breaker_name : 0
                }</div>
                <div>Actual: <span class="pwr-amp-big"> ${
                  data.value || data.value === 0 ? data.value : "N/A"
                }</span></div>
                <div>Subscribed: <span class="pwr-amp" > ${
                  data.subscribed_amps ? " " + data.subscribed_amps : 0
                }</span> </div>
                </div>`
                })
                .join("")}`
            }
          }
        }
      }
    } catch (e) {
      console.log(e.message)
    }
  }
  const createTemp = () => {
    try {
      if (Object.keys(props.temperatureData).length > 0) {
        for (const cabinetName in props.temperatureData) {
          if (props.temperatureData.hasOwnProperty(cabinetName)) {
            let attribute = cabinetName.replace(/-/g, "")

            const cabinetData = props.temperatureData[cabinetName]
            const parsedDate = moment(
              cabinetData[0].ts_utc,
              "YYYY-MM-DD HH:mm:ss"
            )
            const formattedDate = parsedDate.format("D-MMMM-YYYY HH:mm")
            let elementId = "temp-" + attribute

            let element = document.getElementById(elementId)

            if (element) {
              // If the element exists, set its innerHTML
              element.innerHTML = `<div>${
                cabinetData[1].value ? cabinetData[1].value : 0
              }</div><div>${
                cabinetData[0].value ? cabinetData[0].value : 0
              }</div>`
            } else {
              console.log("Element not found: " + elementId)
            }
            let dateElementId = "date-" + attribute
            let dateElement = document.getElementById(dateElementId)
            if (dateElement) {
              dateElement.innerHTML = `${"As of " + formattedDate}`
            }
          }
        }
      }
    } catch (e) {
      console.log(e.message)
    }
  }
  return (
    <div onClick={handleClickOutside}>
      {props.data?.roomHtml === null && (
        <div
          className="not-found-popup modal show bd-example-modal-lg"
          style={{ display: "block" }}
          id="exampleModalCenter"
        >
          <div className="modal-dialog modal-lg">
            <div className={`modal-content model-content-not-found`}>
              <div className="modal-header mt-24">
                <h3 className="modal-title notfound">Info not found</h3>
                <CloseIcon
                  style={{ cursor: "pointer", stroke: "black" }}
                  ref={modalRef}
                  onClick={() => props.setShow(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.data?.roomHtml !== null && (
        <div
          className="cabinet-popup modal show bd-example-modal-lg"
          style={{ display: "block" }}
          id="exampleModalCenter"
        >
          {!cvChart && (
            <Loading
              loading={isLoading} // the previous condition for the loader was causing probs in the historcial data section
              background="rgba(0, 0, 0, 0.40)"
              loaderColor="rgb(248, 153, 3)"
            />
          )}
          {!sensorChart && (
            <Loading
              loading={isLoading}
              background="rgba(0, 0, 0, 0.40)"
              loaderColor="rgb(248, 153, 3)"
            />
          )}
          <div
            className={`modal-dialog modal-lg model-${
              props.data.roomHtml.room.id
            } ${active === "heat" ? "heat_popup" : ""}`}
          >
            <div
              className={`modal-content model-content-layout heat_popup_scroll`}
            >
              <div className="modal-header mt-24">
                {props.data?.roomHtml !== null && (
                  <h3 className="modal-title">
                    {"Layout - " + props.roomName}
                  </h3>
                )}
                {active !== "heat" ? (
                  <div>
                    <CloseIcon
                      style={{ cursor: "pointer", stroke: "black" }}
                      fontSize="large"
                      fontWeight="medium"
                      data-bs-dismiss="modal"
                      ref={modalRef}
                      onClick={() => props.setShow(false)}
                    />
                  </div>
                ) : (
                  <div>
                    <CloseIcon
                      style={{ cursor: "pointer", stroke: "black" }}
                      fontSize="large"
                      fontWeight="bold"
                      data-bs-dismiss="modal"
                      ref={modalRef}
                      onClick={() => props.setShow(false)}
                    />
                  </div>
                )}
              </div>
              <div class="row pt-2 ps-5">
                <div class="col-md-12">
                  <div style={{ position: "relative" }} className="test">
                    <div class="btnstyle2 pb-3">
                      <div class="btn-group2">
                        <a
                          href="#"
                          className={`msg-button2 ${
                            active === "lay" ? "bactive" : ""
                          }`}
                          onClick={() => {
                            setActive("lay")
                            setZoom(0.25)
                            setDx(-400)
                            setDy(-250)
                          }}
                        >
                          {active === "lay" && (
                            <img
                              className="down"
                              src="images/downward-arrow.png"
                              alt="Down Arrow"
                            />
                          )}
                          Layout
                        </a>

                        <a
                          id="heatmapDiv"
                          href="#"
                          className={`msg-button2 ${
                            active === "heat" ? "bactive" : ""
                          }`}
                          onClick={async () => {
                            setActive("heat")
                            setIsLoading(true)
                            await props.getHeatMapData(props.roomName)
                            setIsLoading(false)
                            setZoom(1)
                            setDx(0)
                            setDy(0)
                          }}
                        >
                          {active === "heat" && (
                            <img
                              className="down"
                              src="images/downward-arrow.png"
                              alt="Down Arrow"
                            />
                          )}
                          Heatmap
                        </a>
                        <a
                          href="#"
                          className={`msg-button2 ${
                            active === "robot" ? "bactive" : ""
                          }`}
                          onClick={() => {
                            setActive("robot")
                          }}
                        >
                          {active === "robot" && (
                            <img
                              className="down"
                              src="images/downward-arrow.png"
                              alt="Down Arrow"
                            />
                          )}
                          Robot
                        </a>
                        {/* <button onClick={handleFullScreenToggle}>
                          {isFullScreen ? 'Exit Full Screen' : 'Go Full Screen'}
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                {(active === "heat" || active === "lay") && (
                  <div
                    style={{
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* Refresh Div */}
                    <div>
                      {dctime && !props.isCabinetInfoDataLoading && (
                        <div>
                          Last Update:{" "}
                          {moment(props.mapData?.lastRefreshTime?.refresh_date)
                            .tz(dctime)
                            .format("MMMM D, YYYY HH:mm")}{" "}
                          {timeZonePrefix}
                          <input
                            class="btn btn-primary btn-sm"
                            type="button"
                            value="Refresh"
                            style={{
                              borderRadius: "15px",
                              paddingLeft: "0px",
                              paddingBottom: "5px",
                              paddingRight: "0px",
                              paddingTop: "5px",
                              width: "102px",
                              marginLeft: "1rem",
                            }}
                            onClick={async () => {
                              setIsLoading(true)
                              await props.getHeatMapData(props.roomName)
                              setIsLoading(false)
                            }}
                          />
                        </div>
                      )}
                      {props.isCabinetInfoDataLoading && (
                        <div className="loading">
                          Loading cabinet status<span class="dots">..</span>
                        </div>
                      )}
                    </div>

                    {/* Icons Div */}
                    {active === "lay" ? (
                      <div
                        style={{
                          display: "flex",
                          gap: ".5rem",
                          paddingRight: "4rem",
                        }}
                      >
                        <ZoomInIcon
                          style={{ cursor: "pointer", stroke: "black" }}
                          id="zoomInBtn"
                          onClick={clickPlusbutton}
                        />

                        <ZoomOutIcon
                          style={{
                            cursor: "pointer",
                            stroke: "black",
                          }}
                          id="zoomOutBtn"
                          onClick={clickMinusbutton}
                        />

                        <FitScreenIcon
                          style={{
                            cursor: "pointer",
                          }}
                          fontWeight=""
                          id="resetBtn"
                          onClick={clickResetbutton}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              {/* breker popup code */}
              {brkpopup === 1 ? (
                <div className="brk-pop">
                  <div className="content-title">
                    <div> {popName}</div>{" "}
                    <div
                      onclick={() => setBrkPopup(0)}
                      className="close-brk-pop"
                    >
                      {cvPeriod} x
                    </div>
                  </div>
                  <div className="brkr-card">{mapbrkr()}</div>
                </div>
              ) : null}

              {/* // breker popup code */}
              {/* xconnect popup code */}
              {xconnectpopup === 1 ? (
                <div className="brk-pop">
                  <div className="content-title">
                    <div> {popName}</div>{" "}
                    <div
                      onclick={() => setXConnectPopup(0)}
                      className="close-brk-pop"
                    >
                      x
                    </div>
                  </div>
                  <div className="brkr-card">{mapXconncet()}</div>
                </div>
              ) : null}

              {/* // xconnect popup code */}

              {/* {/ crah-pop-code /} */}
              {crahpop === 1 ? (
                <div className="crah-popup">
                  <div className="crah-popup-head">
                    {" "}
                    <div className="close-crah-popup" onClick={() => test(0)}>
                      x
                    </div>
                  </div>
                  <div className="crah-popup-body">
                    <embed width={1024} height={768} src={crahUrl} type="" />
                  </div>
                </div>
              ) : null}

              {/* {/ crah-pop-code /} style={{position: "absolute", top: "23px", right: "158px"}}

              */}

              {cvChart ? (
                <div>
                  <div className="cv-chart-pop" ref={popupRef}>
                    {/* <Loading
                      loading={isLoading}
                      background="rgba(0, 0, 0, 0.40)"
                      loaderColor="rgb(248, 153, 3)"
                    /> */}
                    <div className="cv-chart-pop-head">
                      <div style={{ zIndex: "9999999" }}>
                        {chartHead.current}
                      </div>
                    </div>
                    <div style={{ background: "white" }}>
                      <div>
                        <div className="cv-top1">
                          <div
                            className={`cv-top1-temp +' ' + ${
                              cvCat === "temperature" && "cvActive"
                            }`}
                            onClick={() => handleCateo("temperature")}
                          >
                            Temperature
                          </div>
                          <div
                            className={`cv-top1-temp +' ' + ${
                              cvCat === "humidity" && "cvActive"
                            }`}
                            onClick={() => handleCateo("humidity")}
                          >
                            Humidity
                          </div>
                          <div
                            className={`cv-top1-temp +' ' + ${
                              cvCat === "power" && "cvActive"
                            }`}
                            onClick={() => handleCateo("power")}
                          >
                            Power
                          </div>
                        </div>
                        <div className="cv-top2">
                          <div style={{ fontWeight: 600 }}>Period:</div>
                          <div
                            className={` ${cvPeriod === 1 && "cvActive"}`}
                            onClick={() => handleClick(1)}
                            style={{ cursor: "pointer" }}
                          >
                            Last Hour
                          </div>
                          <div
                            className={` ${cvPeriod === 4 && "cvActive"}`}
                            onClick={() => handleClick(4)}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            4-Hour
                          </div>
                          <div
                            className={` ${cvPeriod === 8 && "cvActive"}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(8)}
                          >
                            {" "}
                            8-Hour
                          </div>
                        </div>
                        <div className="cv-top2">
                          <div style={{ fontWeight: 600 }}>Timezone:</div>
                          <div>{timeZonePrefix}</div>
                        </div>
                      </div>
                      <div>
                        <Charts chartVal={chartVal} dctime={dctime} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* sensor Chart */}
              {sensorChart ? (
                <div>
                  <div className="cv-chart-pop" ref={sensorPopupRef}>
                    {/* <Loading
                      loading={isLoading}
                      background="rgba(0, 0, 0, 0.40)"
                      loaderColor="rgb(248, 153, 3)"
                    /> */}
                    <div className="cv-chart-pop-head">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          zIndex: "9999999",
                        }}
                      >
                        {sensorId}
                        <p
                          onClick={() => {
                            setSensorPeriod(() => 1)
                            setSensorchart(false)
                            setSensorData(null)
                          }}
                        >
                          X
                        </p>
                      </div>
                    </div>
                    <div style={{ background: "white" }}>
                      <div>
                        <div className="cv-top1">
                          <div
                            className={`cv-top1-temp +' ' + ${
                              sensorCat === "temperature" && "sensorActive"
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSensorCateo("temperature")}
                          >
                            Temperature
                          </div>
                          <div
                            className={`cv-top1-temp +' ' + ${
                              sensorCat === "humidity" && "sensorActive"
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSensorCateo("humidity")}
                          >
                            Humidity
                          </div>
                        </div>
                        <div className="cv-top2">
                          <div style={{ fontWeight: 600 }}>Period:</div>
                          <div
                            className={` ${
                              sensorPeriod === 1 && "sensorActive"
                            }`}
                            onClick={() => handleSensorClick(1)}
                            style={{ cursor: "pointer" }}
                          >
                            Last Hour
                          </div>
                          <div
                            className={` ${
                              sensorPeriod === 4 && "sensorActive"
                            }`}
                            onClick={() => handleSensorClick(4)}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            4-Hour
                          </div>
                          <div
                            className={` ${
                              sensorPeriod === 8 && "sensorActive"
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSensorClick(8)}
                          >
                            {" "}
                            8-Hour
                          </div>
                        </div>
                      </div>
                      <div className="cv-top2">
                        <div style={{ fontWeight: 600 }}>Timezone:</div>
                        <div>{timeZonePrefix}</div>
                      </div>
                      <div>
                        <SensorCharts chartVal={sensorVal} dctime={dctime} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                class="modal-body"
                style={{
                  paddingBottom: "1.5rem",
                }}
                id="div-containersd"
              >
                <div class="card">
                  <div
                    class={`card-body ${active === "heat" ? "heatsize" : ""}`}
                    style={{
                      marginRight: "-1.5rem",
                      position: "relative",
                    }}
                    ref={layoutRef}
                  >
                    {active === "lay" ? (
                      <ReactPanZoom
                        zoom={zoom}
                        onPan={onPan}
                        pandx={dx}
                        pandy={dy}
                      >
                        <div
                          id="layoutDiv"
                          className="react-transform-component"
                          dangerouslySetInnerHTML={{
                            __html: returnEdited(
                              props.data?.roomHtml.html,
                              props.data?.roomHtml?.room_id,
                              props.data?.roomHtml?.is_by_name,
                              props.selectedDataCenter,
                              props.selectedFloor,
                              props.data.data
                            ),
                          }}
                        />
                      </ReactPanZoom>
                    ) : (
                      <div
                        id="layoutDiv"
                        className="react-transform-component"
                        dangerouslySetInnerHTML={{
                          __html: returnEdited(
                            props.data?.roomHtml.html,
                            props.data?.roomHtml?.room_id,
                            props.data?.roomHtml?.is_by_name,
                            props.selectedDataCenter,
                            props.selectedFloor,
                            props.data.data
                          ),
                        }}
                      />
                    )}
                    {popup && (
                      <div
                        onMouseOver={() => setSensorId(popup.id)}
                        class="sensor-popup"
                        style={{
                          position: "absolute",
                          top: popup.y - 5, // Adjust the position to avoid covering the heatmap
                          left: popup.x - 40,
                          background: "rgb(130, 130, 130)",
                          color: "white",
                          borderRadius: "5px",
                          boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                          zIndex: 1,
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            padding: "2px",
                            background: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <p>{popup.id}</p>
                        </div>
                        <div>
                          <div style={{ padding: "5px" }}>
                            <div
                              style={{
                                textAlign: "center",
                                paddingTop: "5px",
                              }}
                            >
                              <p style={{ fontWeight: "bold" }}>Sensor Data</p>
                            </div>
                            <div style={{ cursor: "pointer" }}>
                              <div
                                class="content"
                                onClick={() => handleSensorCateo("temperature")}
                              >
                                <p>Temperature:</p>
                                <p>{popup.temp || NaN}&deg;C</p>
                              </div>
                              <div
                                class="content"
                                onClick={() => handleSensorCateo("humidity")}
                              >
                                <p>Humidity:</p>
                                <p>{popup.humidity || NaN}%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CabinetPopup
