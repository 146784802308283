/* eslint-disable react-hooks/exhaustive-deps */
import { css } from "glamor";
import { useEffect, useMemo, useState } from "react";
import Select, { components } from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import { TICKET_COMMON_STYLES } from "../constants";
import TicketInputField from "../TicketInputField";
import "./TicketMultiSelect.scss";

const Option = (props) => {
  return (
    <div>
      <components.Option
        {...props}
        className="ticket-multi-select-field-multi-select-option"
      >
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue
    {...props}
    className="ticket-multi-select-field-multi-select-input"
  >
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const CustomMenuList = (props) => {
  if (props.isServiceLoading) {
    return (
      <components.MenuList {...props}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <CircularProgress />
        </div>
      </components.MenuList>
    );
  }

  if (
    !props.isServiceLoading &&
    props.options.length === 0 &&
    !props.hasValue
  ) {
    return (
      <components.MenuList {...props}>
        <div style={{ padding: "10px", textAlign: "center" }}>
          No data found
        </div>
      </components.MenuList>
    );
  }
  return <components.MenuList {...props} />;
};

const styles = {
  ...TICKET_COMMON_STYLES,
};

const TicketMultiSelectField = ({
  id,
  label,
  value,
  active,
  required,
  options,
  backgroundColor,
  setFieldValue,
  placeholder,
  isSelectAll,
  isServiceLoading,
}) => {
  const [localOptions, setLocalOptions] = useState([]);

  useEffect(() => {
    if (value.length === 0) {
      setLocalOptions([]);
    }
  }, [value]);

  useEffect(() => {
    if (isSelectAll) {
      setLocalOptions(options);
      setFieldValue(id, options);
    } else {
      setLocalOptions([]);
      setFieldValue(id, "");
    }
  }, [isSelectAll, options, setFieldValue, id]);

  const onChange = (selected) => {
    const allOption = selected.find((option) => option.value === "all");
    if (allOption) {
      setLocalOptions(options.filter((option) => option.value !== "all"));
      setFieldValue(
        id,
        options.filter((option) => option.value !== "all")
      );
    } else {
      setLocalOptions(selected);
      setFieldValue(id, selected);
    }
  };

  const getOptions = (options) => {
    return localOptions.length === options.length ? [] : options;
  };

  return (
    <>
      {active ? (
        <div
          className="form-floating mb-3"
          {...css(styles.borderBottom, styles.selectPadding)}
        >
          <label htmlFor="floatingInput">
            {label}
            {required && (
              <span {...css(styles.span)} className="red_star">
                *
              </span>
            )}
          </label>
          <Select
            isMulti
            hideSelectedOptions
            options={getOptions(options)}
            value={localOptions}
            className="basic-multi-select ticket-multi-select-field-multi-select"
            classNamePrefix="select"
            placeholder={placeholder}
            allowSelectAll
            components={{
              Option,
              MultiValue,
              MenuList: (props) => (
                <CustomMenuList
                  {...props}
                  isServiceLoading={isServiceLoading}
                />
              ),
            }}
            onChange={onChange}
            isSelectAll={isSelectAll}
          />
        </div>
      ) : (
        <TicketInputField
          label={label}
          required={required}
          active={false}
          backgroundColor={backgroundColor}
        />
      )}
    </>
  );
};

const TicketMultiSelectFieldMemo = ({
  id,
  label,
  active,
  required,
  options,
  backgroundColor,
  setFieldValue,
  placeholder,
  value,
  isSelectAll,
  isServiceLoading,
}) => {
  return useMemo(() => {
    return (
      <TicketMultiSelectField
        id={id}
        label={label}
        active={active}
        required={required}
        options={options}
        backgroundColor={backgroundColor}
        setFieldValue={setFieldValue}
        placeholder={placeholder}
        value={value}
        isSelectAll={isSelectAll}
        isServiceLoading={isServiceLoading}
      />
    );
  }, [
    id,
    label,
    active,
    required,
    options,
    backgroundColor,
    placeholder,
    value,
    isSelectAll,
    isServiceLoading,
    setFieldValue,
  ]);
};

export default TicketMultiSelectFieldMemo;
