/* eslint-disable react-hooks/exhaustive-deps */
import { css } from "glamor";
import { useEffect, useMemo, useState } from "react";
import Select, { components } from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import { TICKET_COMMON_STYLES } from "../constants";
import TicketInputField from "../TicketInputField";
import "./TextSelectField.scss";

const TicketSelectField = ({
  id,
  label,
  required,
  active,
  options,
  backgroundColor,
  setFieldValue,
  placeholder,
  value,
  isClearable,
  defaultValue,
  noLabel,
  handleClear,
  menuPlacement,
  isCompanyLoading,
  isServiceLoading,
}) => {
  useEffect(() => {
    // console.log(isCompanyLoading);
  }, [isCompanyLoading]);
  const styles = {
    ...TICKET_COMMON_STYLES,
    noMarginTop: {
      "& > *": {
        marginTop: "18px !important",
      },
    },
  };
  const [val, setValue] = useState("");
  useEffect(() => {
    try {
      const found = options.find((option) => option.id === value);
      if (value === "") {
        setValue("");
      } else if (found) {
        setValue(found);
      }
    } catch (err) {}
  }, [value]);

  useEffect(() => {
    if (defaultValue && value === "") {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleSelectChange = (selected, action) => {
    if (action.action !== "clear") {
      setValue(selected);
      setFieldValue(id, selected.value);
    } else if (action.action === "clear" && handleClear) {
      setValue("");
      handleClear();
    }
  };

  const customNoOptionsMessage = () => {
    return id === "companyName" || id === "serviceId"
      ? "No data found"
      : "No options";
  };

  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {isCompanyLoading || isServiceLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          props.children
        )}
      </components.MenuList>
    );
  };

  return (
    <>
      {active ? (
        <div
          className="form-floating"
          {...css(
            styles.borderBottom,
            styles.selectPadding,
            noLabel && styles.noMarginTop
          )}
        >
          {!noLabel && (
            <label htmlFor="floatingSelect">
              {label}
              {required && (
                <span {...css(styles.span)} className="red_star">
                  *
                </span>
              )}
            </label>
          )}
          <Select
            hideSelectedOptions={false}
            options={options}
            value={val}
            className={
              noLabel
                ? "ticket-multi-select-field-multi-select-nolabel"
                : "ticket-multi-select-field-multi-select"
            }
            classNamePrefix="select"
            placeholder={placeholder}
            onChange={handleSelectChange}
            isClearable={isClearable || false}
            menuPlacement={menuPlacement || "bottom"}
            noOptionsMessage={customNoOptionsMessage}
            components={{ MenuList: CustomMenuList }}
          />
        </div>
      ) : (
        <TicketInputField
          label={label}
          required={required}
          backgroundColor={backgroundColor}
        />
      )}
    </>
  );
};

const TicketSelectFieldMemo = ({
  id,
  label,
  value,
  required,
  active,
  options,
  backgroundColor,
  setFieldValue,
  placeholder,
  isClearable,
  defaultValue,
  noLabel,
  handleClear,
  menuPlacement,
  isCompanyLoading,
  isServiceLoading,
}) => {
  return useMemo(() => {
    return (
      <TicketSelectField
        id={id}
        label={label}
        active={active}
        required={required}
        options={options}
        backgroundColor={backgroundColor}
        setFieldValue={setFieldValue}
        placeholder={placeholder}
        value={value}
        isClearable={isClearable}
        defaultValue={defaultValue}
        noLabel={noLabel}
        handleClear={handleClear}
        menuPlacement={menuPlacement}
        isCompanyLoading={isCompanyLoading}
        isServiceLoading={isServiceLoading}
      />
    );
  }, [
    id,
    label,
    active,
    required,
    options,
    backgroundColor,
    placeholder,
    value,
    isClearable,
    defaultValue,
    noLabel,
    handleClear,
    menuPlacement,
    isCompanyLoading,
    isServiceLoading,
  ]);
};

export default TicketSelectFieldMemo;
