import { LogoutOnSessionExpire } from "common/helpers"
import AuthContext from "context"
import React, { useContext, useEffect, useRef, useState } from "react"
import CabinetService from "services/CabinetService"
import Common from "services/commonService"
import networkServices from "services/networkServices"
import RoleModel from "services/roleServices"
import Loading from "views/super-admin/pre-loader/loader"
import EditNetwork from "./editNetwork"
import NetworkModel from "./NetworkModel"
import NetworkNavigation from "./networkNavigation"
import Navigation from "../Component/Navigation"
import { useTranslation } from "react-i18next"
export default function Network(props) {
  const authContext = useContext(AuthContext)
  const [dataCenter, setDataCenter] = useState([])
  const [allNetworkData, setAllNetworkData] = useState([])
  const [countryName, setCountryName] = useState("Country")
  const initialMount = useRef(true)
  const [isLoading, setIsLoading] = useState(true)
  const [ascending, setAscending] = useState(true)
  const [isReadOnly, setIsReadOnly] = useState(true)
  const [statusData, setStatusData] = useState([])
  const [editNetworkData, setEditNetworkData] = useState()
  const [showNetworkEdit, setShowNetworkEdit] = useState(false)
  const [cabinetData, setCabinetData] = useState([])
  const [countries, setCountries] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    if (authContext.getStatusData) {
      setStatusData(authContext.getStatusData)
    }
    // Common.status().then((res) => setStatusData(res.data.data))
    getData()

    if (
      authContext?.getAuth?.role?.space === 3 ||
      authContext?.getAuth?.role?.m_e === 3 ||
      authContext?.getAuth?.role?.network === 3
    ) {
      setIsReadOnly(false)
    }

    if (initialMount.current === false) {
      selectDataCenterFloor(authContext.getNetworkDataCenter)
    }
    getAllDataCenter()
  }, [])

  const getData = async () => {
    await RoleModel.countryService(authContext.token())
      .then((res) => {
        setCountries(res.data.data)
      })
      .catch((err) => {
        LogoutOnSessionExpire(err)
      })
      .finally(() => setIsLoading(false))
  }

  const getAllDataCenter = async () => {
    setCountryName("Country")

    await RoleModel.dataCenter(authContext.token()).then((res) => {
      setDataCenter(res.data.data.filter((data) => data.dc_type_id === 1))
      if (
        authContext.getNetworkDataCenter === undefined ||
        authContext.getNetworkDataCenter.length === 0
      ) {
        authContext.setNetworkDataCenter(
          res.data.data.filter((data) => data.dc_type_id === 1)[0]
        )
      }
      if (res.data.data.length > 0) {
        if (initialMount.current) {
          selectDataCenterFloor(
            authContext.getNetworkDataCenter.length !== 0
              ? authContext.getNetworkDataCenter
              : res.data.data.filter((data) => data.dc_type_id === 1)[0]
          )

          initialMount.current = false
        }
      }
    })
  }

  const getDataCenterById = async (e) => {
    setCountryName(e.name)
    setIsLoading(true)

    await RoleModel.dataCenterByCountryId(authContext.token(), e).then(
      (res) => {
        //  await networkServices.getNetworkDevices(authContext.token(), e).then((res) => {
        setDataCenter(res.data.data)
        setIsLoading(false)
        if (res.data.data.length > 0) {
          selectDataCenterFloor(res.data.data[0])
        }
      }
    )
  }

  const selectDataCenterFloor = async (e, floor_id = 0) => {
    authContext.setNetworkDataCenter(e)
    setAscending(true)
    CabinetService.getAllCabinetsDataCenter(
      authContext.getToken,
      e.id
    ).then((res) => setCabinetData(res.data.data))
    setIsLoading(true)
    await networkServices
      .networkByDataCenterId(authContext.token(), e)
      .then((res) => {
        setAllNetworkData(res.data.data)
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 1000);
        // return () => clearTimeout(timer);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err)
      })
      .finally(() => setIsLoading(false))
  }

  const renderCountry = () => {
    return (
      countries.length &&
      countries.map((data, i) => {
        return (
          <a
            href="#cont"
            className="dropdown-item"
            key={i}
            onClick={() => {
              getDataCenterById(data)
            }}
          >
            {data.name}{" "}
          </a>
        )
      })
    )
  }
  const getEditNetworkPopup = (data) => {
    setEditNetworkData(data)
    setShowNetworkEdit(true)
  }

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.4)"
        loaderColor="rgb(248, 153, 3)"
      />
      <div className="content-body">
        <Navigation />
        <div className="container-fluid">
          <hr className="main" />
          <div className="row">
            <div className="col-xl-1" style={{ width: "11%" }}>
              <div className="leftside">
                <NetworkNavigation />
              </div>
            </div>
            <div className="col-lg-11" style={{ width: "89%" }}>
              <div id="pro">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <div className="main_scrll">
                      <div className="btn_ul">
                        <ul className="nav nav-tabs mb-3">
                          <li className="nav-item">
                            {" "}
                            <button
                              className="btn btn-secondary"
                              style={{ width: "100%" }}
                              onClick={getAllDataCenter}
                            >
                              {" "}
                              {t("network.global")}{" "}
                            </button>
                          </li>
                          <li className="nav-item">
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className="btn btn-light dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ width: "100%" }}
                              >
                                {" "}
                                {countryName}{" "}
                              </button>
                              <div
                                className="dropdown-menu"
                                style={{ margin: "0px" }}
                              >
                                {renderCountry()}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="outer-div-setting">
                        <div className="ul_scrll">
                          <ul className="nav nav-tabs mb-3">
                            {dataCenter &&
                              dataCenter.map((data, index) => {
                                if (
                                  authContext.getNetworkDataCenter &&
                                  authContext.getNetworkDataCenter.id ===
                                    data.id
                                ) {
                                  return (
                                    <li
                                      className={
                                        index === 0 ? "nav-item" : "nav-item"
                                      }
                                      key={index}
                                    >
                                      <a
                                        href="#ds"
                                        onClick={() =>
                                          selectDataCenterFloor(data)
                                        }
                                        style={{ cursor: "pointer" }}
                                        className="nav-link active show"
                                      >
                                        <img
                                          alt=""
                                          className="down setting_down"
                                          src="\images\downward-arrow.png"
                                        />
                                        {data.name}
                                      </a>
                                    </li>
                                  )
                                } else {
                                  return (
                                    <li
                                      className={
                                        index === 0 ? "nav-item" : "nav-item"
                                      }
                                      key={index}
                                    >
                                      <a
                                        href="#sds"
                                        onClick={() =>
                                          selectDataCenterFloor(data)
                                        }
                                        style={{ cursor: "pointer" }}
                                        className="nav-link"
                                      >
                                        {" "}
                                        {data.name}{" "}
                                      </a>
                                    </li>
                                  )
                                }
                              })}
                          </ul>
                        </div>
                        <div>
                          <span>
                            <i>
                              {t(
                                "network.select_a_country_to_view_partner_data_center"
                              )}
                            </i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tblop-117">
                  <div className="ttl_op-117">
                    <h6 style={{ fontSize: "0.792rem", color: "grey" }}>
                      {t("network.latest_updates")} : 2022-04-22 12:22 HKT
                    </h6>
                    <a
                      href="#ds"
                      style={{
                        color: "#147AD6",
                        fontWeight: "600",
                        fontSize: "0.813rem",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target=".bd-example-modal-lg"
                    >
                      <img
                        alt=""
                        src="\images\plus-circle-blue.svg"
                        style={{ width: "1rem" }}
                      />{" "}
                      &nbsp;{t("network.add_device")}
                    </a>
                  </div>
                </div>
                <div className="op-117_table mt-1">
                  <div
                    className="table-responsive"
                    style={{ overflow: "auto", height: "350px" }}
                  >
                    <table
                      className="table table-responsive-md"
                      style={{ border: "1px solid #eee" }}
                    >
                      <thead>
                        <tr style={{ border: "1px solid #eee" }}>
                          <th width="10%">
                            <strong>{t("network.function")}</strong>
                          </th>
                          <th width="10%">
                            <strong>{t("network.hostname")}</strong>
                          </th>
                          <th width="10%">
                            <strong>{t("network.vendor")}</strong>
                          </th>
                          <th width="10%">
                            <strong>{t("network.model")}</strong>
                          </th>
                          <th width="10%">
                            <strong>{t("network.s_n")}</strong>
                          </th>
                          <th width="10%">
                            <strong>{t("network.status")}</strong>
                          </th>
                          <th width="10%">
                            <strong>{t("network.cabinet")}</strong>
                          </th>
                          <th width="10%">
                            <strong>{t("network.support")}</strong>
                          </th>
                          <th width="1%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {allNetworkData &&
                          allNetworkData.map((data, id) => {
                            return (
                              <tr key={id}>
                                <td>
                                  {data.network_function !== null
                                    ? data.network_function.name
                                    : "N/A"}{" "}
                                </td>
                                <td> {data.name} </td>
                                <td> {data.makes?.name}</td>
                                <td>{data.models?.name} </td>
                                <td> {data.sn}</td>
                                <td>
                                  {" "}
                                  {data.deviceStatus !== null
                                    ? data.deviceStatus.status_name
                                    : "N/A"}
                                </td>
                                <td>{data?.cabinets?.name} </td>
                                <td>
                                  {data.support_expiry === null ||
                                  data.support_expiry === "0000-00-00"
                                    ? "N/A"
                                    : data.support_expiry}{" "}
                                </td>
                                <td>
                                  {isReadOnly === false ? (
                                    <span>
                                      <a
                                        href="#dsd"
                                        onClick={() =>
                                          getEditNetworkPopup(data)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textAlign: "right",
                                        }}
                                      >
                                        <i className="fas fa-edit"></i>
                                      </a>
                                    </span>
                                  ) : null}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <NetworkModel
              show={true}
              data_center_id={authContext.getNetworkDataCenter}
              selectDataCenterFloor={selectDataCenterFloor}
              cabinetData={cabinetData}
            />

            {showNetworkEdit && (
              <EditNetwork
                network_data={editNetworkData}
                show={showNetworkEdit}
                setShow={setShowNetworkEdit}
                data_center_id={authContext.getNetworkDataCenter}
                selectDataCenterFloor={selectDataCenterFloor}
                cabinetData={cabinetData}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
