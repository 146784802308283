import { css } from 'glamor';
import { t } from 'i18next';
import { useMemo, useRef, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import CsvIcon from './CsvIcon';

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between !important',
    marginTop: '15px',
    alignItems: 'center'
  },
  title: {
    fontSize: '24px'
  },
  icon: {
    paddingBottom: '5px',
    cursor: 'pointer',
    width: '30px !important'
  },
  button: {
    width: '30px !important'
  },
  tooltipText: {
    fontSize: '14px'
  },
  link: {
    color: '#3A7EFF'
  }
};

const VisitorDetailsTitle = ({ text, csvButton, onCsvUpload, active }) => {
  const inputFileRef = useRef();
  const tooltipRef = useRef(null);
  const [target, setTarget] = useState(null);
  const [showCsvTooltip, setShowCsvTooltip] = useState(false);

  const onMouseEnter = (event) => {
    setShowCsvTooltip(true);
    setTarget(event.target);
  };

  const onMouseLeave = (event) => {
    setShowCsvTooltip(false);
    setTarget(null);
  };

  return (
    <>
      <div {...css(styles.row)} onMouseLeave={onMouseLeave}>
        <div {...css(styles.title)}>{text}</div>
        {csvButton && (
          <div {...css(styles.title, styles.icon)}>
            {active && (
              <OverlayTrigger
                show={showCsvTooltip}
                trigger={['hover', 'focus']}
                placement="left"
                container={tooltipRef}
                target={target}
                overlay={
                  <Popover id="csv-popover-positioned" onMouseLeave={onMouseLeave}>
                    <Popover.Body {...css(styles.tooltipText)}>
                      <div>{t('create-ticket.site-access.visitor-csv-text')}</div>
                      <div className="mt-2">
                        {/* site_access_visitor_template.csv */}
                        <a
                          href="/files/site_access_visitor_template.csv"
                          download
                          {...css(styles.link)}
                        >
                          {t('create-ticket.site-access.visitor-csv-download')}
                        </a>
                        <br></br>
                        <a
                          href="/files/site_access_visitor_template.xlsx"
                          download
                          {...css(styles.link)}
                        >
                          {t('create-ticket.site-access.visitor-excel-download')}
                        </a>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button
                  {...css(styles.button)}
                  variant="link"
                  ref={tooltipRef}
                  className="p-0 d-flex justify-content-end"
                  as="div"
                  onMouseEnter={onMouseEnter}
                >
                  <CsvIcon
                    onClick={() => {
                      setShowCsvTooltip(false);
                      inputFileRef.current.click();
                    }}
                  />
                </Button>
              </OverlayTrigger>
            )}
            <input
              type="file"
              ref={inputFileRef}
              name="file"
              accept=".xlsx, .csv"
              hidden
              onChange={onCsvUpload}
              onClick={() => {
                inputFileRef.current.value = '';
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const VisitorDetailsTitleMemo = ({ text, csvButton, onCsvUpload, active }) => {
  return useMemo(() => {
    return (
      <VisitorDetailsTitle
        text={text}
        csvButton={csvButton}
        onCsvUpload={onCsvUpload}
        active={active}
      />
    );
  }, [text, active]);
};

export default VisitorDetailsTitleMemo;
