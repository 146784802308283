const SiteAccessLogs = ({
  logs,
  toggleEdit,
  istypetrue,
  setToggleLine,
  permission,
}) => {
  return (
    <div class="site-table" style={{ width: "90%" }}>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="site-tr">
              Activity Date Time
            </th>
            <th scope="col" class="site-tr">
              Activity Type
            </th>
            <th scope="col" class="site-tr">
              Visitor Name
            </th>
            <th scope="col" class="site-tr">
              Access Card ID
            </th>
            <th scope="col" class="site-tr">
              Remarks
            </th>
          </tr>
        </thead>
        <tbody>
          {logs &&
            logs.map((data, key) => {
              return (
                <tr key={key}>
                  <td class="site-tr">{data?.activity_date_time}</td>
                  <td class="site-tr">{data?.activity_type}</td>
                  <td class="site-tr">{data?.visitor_name}</td>
                  <td class="site-tr">{data?.access_card_id}</td>
                  <td class="site-tr">{data?.remarks}</td>
                </tr>
              );
            })}
          {toggleEdit && istypetrue && permission && (
            <tr>
              <div
                style={{
                  color: "#14d6d3",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={() => setToggleLine(true)}
              >
                Add a line
              </div>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SiteAccessLogs;
