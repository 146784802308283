/* eslint-disable no-undef */
import { useState, useContext, useEffect } from 'react';
import uuid from 'react-uuid';
import AuthContext from 'context';
import { LANGUAGES } from 'global-definitions';
import { use } from 'i18next';

// Visitor Name|Visitor Company Name|Contact Number|Id Number|Vehicle info

const CSV_FILE_MAPPING = {
  'Visitor Name': 'visitorName',
  'Visitor Email': 'visitorEmail',
  'Visitor Company Name': 'visitorCompanyName',
  'Contact Number': 'visitorContactNumber',
  'Id Number': 'visitorIdNumber',
  'Vehicle info': 'visitorVehicleInformation'
};

const useHandleVisitors = ({ formik }) => {
  const [editing, setEditing] = useState(false); // OP-919..previous users gets deleted if edit btn clicked on other user without saving previous one
  const [err, setErr] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  const [tempUser, setTempUser] = useState([{}]);
  const [newUserForEditing, setNewUserForEditing] = useState([{}]);
  // const [updatedVisitor, setUpdatedVisitor] = useState([{}]);
  const contextStore = useContext(AuthContext);

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const nameRegex = /^[A-Za-z]+([ A-Za-z]+)*/;
  const contactRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

  const validateInput = ({ visitorName, visitorContactNumber, visitorEmail }) => {
    const currentLanguage = contextStore.getAuth.language_id;

    const nonJpRules =
      currentLanguage !== LANGUAGES.JP && visitorName !== '' && visitorContactNumber !== '';

    const jpRules = currentLanguage === LANGUAGES.JP && visitorName !== '';

    const emailCheck = visitorEmail !== '';
    const nameCheck = visitorName !== '';
    const contactCheck = visitorContactNumber !== '';

    return (nonJpRules && emailCheck && contactCheck && nameCheck) || jpRules;
  };

  // console.log('lang:', contextStore.getAuth.language_id)
  const cleanFormValues = () => {
    formik.setFieldValue('visitorName', '');
    formik.setFieldValue('visitorEmail', '');
    formik.setFieldValue('visitorCompanyName', '');
    formik.setFieldValue('visitorContactNumber', '');
    formik.setFieldValue('visitorVehicleInformation', '');
    formik.setFieldValue('visitorIdNumber', '');
  };

  // handling when the user is getting added
  const handleAddVisitor = () => {
    setEditing(false);
    const {
      visitorName,
      visitorEmail,
      visitorCompanyName,
      visitorContactNumber,
      visitorVehicleInformation,
      visitorIdNumber,
      visitorList
    } = formik.values;

    if (validateInput({ visitorName, visitorContactNumber, visitorEmail })) {
      formik.setFieldValue('visitorList', [
        ...visitorList,
        {
          id: uuid(),
          visitorName,
          visitorEmail,
          visitorCompanyName,
          visitorContactNumber,
          visitorVehicleInformation,
          visitorIdNumber
        }
      ]);
      cleanFormValues();
    } else {
      setErr(true);
      return err;
    }
  };

  // handling when a user is getting edited and then saving
  const handleSaveUser = () => {
    const {
      visitorName,
      visitorEmail,
      visitorCompanyName,
      visitorContactNumber,
      visitorVehicleInformation,
      visitorIdNumber,
      visitorList
    } = formik.values;

    if (validateInput({ visitorName, visitorContactNumber, visitorEmail })) {
      let updatedVisitor = {
        id: uuid(),
        visitorName,
        visitorEmail,
        visitorCompanyName,
        visitorContactNumber,
        visitorVehicleInformation,
        visitorIdNumber
      };
      formik.setFieldValue('visitorList', [...visitorList, updatedVisitor]);
      cleanFormValues();
      setTimeout(() => {
        if (newUserForEditing) {
          const {
            visitorName,
            visitorEmail,
            visitorCompanyName,
            visitorContactNumber,
            visitorVehicleInformation,
            visitorIdNumber
          } = newUserForEditing;

          formik.setFieldValue('visitorName', visitorName);
          formik.setFieldValue('visitorEmail', visitorEmail);
          formik.setFieldValue('visitorCompanyName', visitorCompanyName);
          formik.setFieldValue('visitorContactNumber', visitorContactNumber);
          formik.setFieldValue('visitorVehicleInformation', visitorVehicleInformation);
          formik.setFieldValue('visitorIdNumber', visitorIdNumber);
          setTempUser({ ...newUserForEditing });
          // console.log(newUserForEditing.id);
          formik.setFieldValue(
            'visitorList',
            [...visitorList, updatedVisitor].filter(
              (visitor) => visitor.id !== newUserForEditing.id
            )
          );
        }
      }, 10);
    } else {
      setErr(true);
      return err;
    }
  };

  // handling if user clicks "NO" while saving an old user
  const handleOldUser = () => {
    const { visitorList } = formik.values;
    formik.setFieldValue('visitorList', [...visitorList, tempUser]);
    cleanFormValues();
    // console.log('handleOldUser');
    if (newUserForEditing) {
      const {
        visitorName,
        visitorEmail,
        visitorCompanyName,
        visitorContactNumber,
        visitorVehicleInformation,
        visitorIdNumber
      } = newUserForEditing;

      formik.setFieldValue('visitorName', visitorName);
      formik.setFieldValue('visitorEmail', visitorEmail);
      formik.setFieldValue('visitorCompanyName', visitorCompanyName);
      formik.setFieldValue('visitorContactNumber', visitorContactNumber);
      formik.setFieldValue('visitorVehicleInformation', visitorVehicleInformation);
      formik.setFieldValue('visitorIdNumber', visitorIdNumber);
      setTempUser({ ...newUserForEditing });
      // console.log(newUserForEditing.id);
      formik.setFieldValue(
        'visitorList',
        [...visitorList, tempUser].filter((visitor) => visitor.id !== newUserForEditing.id)
      );
    }
  };

  const handleDeleteVisitor = (id) => {
    const { visitorList } = formik.values;
    const listCopy = visitorList.slice();
    formik.setFieldValue(
      'visitorList',
      listCopy.filter((visitor) => visitor.id !== id)
    );
  };

  const handleChangeVisitor = (id) => {
    // console.log('handleChangeVisitor');
    const { visitorList } = formik.values;
    const visitor = visitorList.find((visitor) => visitor.id === id);
    setNewUserForEditing(visitor);
    if (editing) {
      // console.log('handleChangeVisitor IF');
      setIsEditMode(true);
    } else {
      // console.log('handleChangeVisitor ELSE');
      setEditing(true);
      const { visitorList } = formik.values;
      const visitor = visitorList.find((visitor) => visitor.id === id);

      if (visitor) {
        const {
          visitorName,
          visitorEmail,
          visitorCompanyName,
          visitorContactNumber,
          visitorVehicleInformation,
          visitorIdNumber
        } = visitor;

        formik.setFieldValue('visitorName', visitorName);
        formik.setFieldValue('visitorEmail', visitorEmail);
        formik.setFieldValue('visitorCompanyName', visitorCompanyName);
        formik.setFieldValue('visitorContactNumber', visitorContactNumber);
        formik.setFieldValue('visitorVehicleInformation', visitorVehicleInformation);
        formik.setFieldValue('visitorIdNumber', visitorIdNumber);
        setTempUser({ ...visitor });

        handleDeleteVisitor(id);
      }
    }
  };
  // console.log(tempUser)
  // console.log(editing)

  const handleCsvUpload = (rows) => {
    const { visitorList } = formik.values;
    const visitors = rows
      .map((row) => {
        const mapped = {};
        const entries = Object.entries(row);
        entries.forEach(([key, value]) => {
          mapped[CSV_FILE_MAPPING[key.trim()]] = value;
        });
        return { id: uuid(), ...mapped };
      })
      .filter((row) =>
        validateInput({
          visitorName: row.visitorName,
          visitorContactNumber: row.visitorContactNumber,
          visitorEmail: row.visitorEmail
        })
      );

    formik.setFieldValue('visitorList', [...visitorList, ...visitors]);
  };

  const removeAllVisitors = () => {
    formik.setFieldValue('visitorList', []);
  };

  return {
    handleAddVisitor,
    handleDeleteVisitor,
    handleChangeVisitor,
    handleCsvUpload,
    removeAllVisitors,
    handleSaveUser,
    handleOldUser,
    isEditMode,
    setIsEditMode
  };
};

export default useHandleVisitors;
