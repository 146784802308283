import _ from 'lodash';
import moment from 'moment';
import roleServices from 'services/roleServices';

const DEFAULT_TEAM_ID = 20;

export const filterCompanies = (companies, selectedCountry) => {
  return _.orderBy(
    companies.filter((company) => {
      if (selectedCountry === 'global') {
        return company;
      } else {
        return (
          company.company_code_country === selectedCountry || company.company_code_country === null
        );
      }
    }),
    ['name'],
    ['asc']
  );
};

export const filterCompaniesSite = (companies, selectedCountry) => {
  return _.orderBy(
    companies.filter((company) => {
      if (selectedCountry === 'global') {
        return company.de_entity_country;
      }
      // else if(selectedCountry === 'JP'){
      //   return company.de_entity_country === "Japan" || company.de_entity_country === null
      // }else if(selectedCountry === 'KR'){
      //   return company.de_entity_country === "Korea" || company.de_entity_country === null
      // }else if(selectedCountry === 'CN'){
      //   return company.de_entity_country === "China" || company.de_entity_country === null
      // }else if(selectedCountry === 'ID'){
      //   return company.de_entity_country === "Indonesia" || company.de_entity_country === null
      // }else if(selectedCountry === 'PH'){
      //   return company.de_entity_country === "Philippines" || company.de_entity_country === null
      // }
      else {
        return company.de_entity_country === selectedCountry || company.de_entity_country === null;
      }
    }),
    ['customer_name'],
    ['asc']
  );
};

export const getTeamIdByCountry = (teams, country, prefix, dataCenterCountry, countries) => {
  // console.log("hello---",{ country, dataCenterCountry, teams,countries })
  // const mapped = teams
  //   .filter(team => team.name.includes(prefix))
  //   .map(team => {
  //     const { name } = team
  //     const splitted = name.split(prefix)

  //     return { ...team, country: splitted[1].trim() }
  //   })
  //   console.log("mapped",mapped,countries)
  // if (country !== 'global') {
  //   const code = countries.find(team => team.name === country === "South Korea"?"Korea":country)
  //   console.log("code----",code.country_code)

  //   const found = mapped.find(team => team.country === code.country_code)
  //   return found ? found.id : DEFAULT_TEAM_ID
  // } else {
  //   const found = mapped.find(team => team.country === dataCenterCountry)
  //   return found ? found.id : DEFAULT_TEAM_ID
  // }
  const mapped = teams
    .filter((team) => team.name.includes(prefix))
    .map((team) => {
      const { name } = team;
      const splitted = name.split(prefix);

      return { ...team, country : splitted[1].trim() };
    });
    console.log(teams, country, prefix, dataCenterCountry, countries, mapped)
  if (country !== 'global') {
    console.log("here")
    const found = mapped.find((team) => team.country.includes(country));
    return found ? found.id : DEFAULT_TEAM_ID;
  } else {
    const found = mapped.find((team) => team.country.includes(dataCenterCountry));
    return found ? found.id : DEFAULT_TEAM_ID;
  }
};

export const getHoursConstraintEndDate = (start, end) => {
  const diff = moment(start).startOf('day').diff(moment(end).startOf('day'), 'days');
  if (diff === 0) {
    const hours = moment(start).hour();

    return { min: hours };
  }

  return {};
};

export const getHoursConstraintStartDate = () => {
  return { min: moment(new Date()).hour() };
};

export const getCountryIdByCode = (code, countries) => {
  try {
    return countries?.find((c) => c.country_code === code).id;
  } catch {
    return null;
  }
};

export const isSelectedCompanyEndCustomer = (companyId, companies) => {
  const selectedCompany = companies.filter((company) => company.id == companyId);
  return selectedCompany[0].ns_is_end_customer;
};

export const getDataCenterCountry = (siteId, dataCenters, countries) => {
  // console.log({ siteId, dataCenters, countries })
  try {
    const country = dataCenters.find((dc) => dc.id === parseInt(siteId, 10)).country_id;
    return countries.find((c) => c.id === country).country_code;
  } catch {
    return null;
  }
};
