import StorageContext from "context";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Loading from "views/super-admin/pre-loader/loader";
import UserModal from "services/userServices";
import Layout from "../../Layouts";
import Navigation from "../Component/Navigation";
import CreateUser from "./CreateUser";
import UpdateUser from "./UpdateUser";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import SerachBox from "../Component/searchBox";
import Pagination from "views/super-admin/Ticket/components/shared/usePagination";
const User = () => {
  const contextStore = useContext(StorageContext);
  const [state, setState] = useState();
  const [filteredItems, setFilteredItems] = useState();
  const [updateData, setUpdateData] = useState({});
  const [ascending, setAscending] = useState(true);
  const [ascendingName, setAscendingName] = useState(true);
  const [ascendingPrimary, setAscendingPrimary] = useState(true);
  const [ascendingSecondary, setAscendingSecondary] = useState(true);
  const [ascendingRoles, setAscendingRoles] = useState(true);
  const [ascendingCountry, setAscendingCountry] = useState(true);
  const [ascendingActivity, setAscendingActivity] = useState(true);
  const [ascendingStatus, setAscendingStatus] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    pending: 0,
    active: 0,
    inactive: 0,
    total: 0,
    live: 0,
  });
  const [statusQuery, setStatusQuery] = useState("");

  const [query, setQuery] = useState("");

  const { t } = useTranslation();
  const searchCategory = [
    { name: t(`userm.username_email`), field: "email" },
    { name: t(`userm.name`), field: "name" },
    { name: t(`userm.primary_number`), field: "primary_num" },
    { name: t(`userm.secondary_number`), field: "secondry_num" },
    { name: t(`userm.roles`), field: "roleName" },
    { name: t(`userm.country`), field: "countryName" },
  ];
  useEffect(() => {
    getAllUsers();
  }, [pageNumber, itemsPerPage, query, statusQuery]);
  const handleFilterByStatus = (status) => {
    setStatusQuery(status);
    setPageNumber(1);
  };
  const getAllUsers = async () => {
    setIsLoading(true);
    let body = {
      page: pageNumber ? pageNumber : 1,
      pageSize: itemsPerPage,
    };
    if (query !== "") {
      body[query.name] = query["value"];
    }
    // console.log(statusQuery);
    if (statusQuery !== "") {
      body["status"] = statusQuery == 3 ? 1 : statusQuery;
      body["live"] = statusQuery == 3 ? true : false;
    }

    const data = await UserModal.index(contextStore.token(), body);
    setState(data?.data?.data);
    setTotal(data?.data?.total);
    setFilteredItems(
      data?.data?.data.sort((a, b) => (a.email < b.email ? 1 : -1))
    );
    setStats({
      active: data?.data?.activeUsersCount,
      pending: data?.data?.pendingUsersCount,
      inactive: data?.data?.inactiveUsersCount,
      total: data?.data?.totalUsersCount,
      live: data?.data?.liveUserCount,
    });

    setIsLoading(false);
  };

  const getStatus = (status) => {
    let stringStatus = "";

    switch (status) {
      case 0:
        stringStatus = (
          <span
            className="badge badge-warning badge-lg light"
            //style={{width:"50%"}}
          >
            Pending
          </span>
        );
        break;
      case 1:
        stringStatus = (
          <span
            className="badge badge-success badge-lg light"
            //style={{width:"50%"}}
          >
            Active
          </span>
        );
        break;
      case 2:
        stringStatus = (
          <span className="badge badge-danger badge-lg light">Inactive</span>
        );
        break;
      default:
        stringStatus = (
          <span
            className="badge badge-success badge-lg light"
            //style={{width:"50%"}}
          >
            Active
          </span>
        );
        break;
    }

    return stringStatus;
  };

  const renderHtml = () => {
    return (
      filteredItems &&
      filteredItems.length > 0 &&
      filteredItems.map((user) => {
        return (
          <tr key={user.uuid} className="setting-table">
            <td> {user.email} </td>
            <td> {user.name} </td>
            <td> {user.primary_num} </td>
            <td> {user.secondry_num ? user.secondry_num : "N/A"} </td>
            <td> {user.role?.name || ""} </td>
            <td> {user.country?.name || ""} </td>
            <td>
              {" "}
              {user?.last_activity
                ? moment(user?.last_activity).format("YYYY-MM-DD hh:mm A")
                : "N/A"}
              {""}
            </td>
            <td> {getStatus(user.status)} </td>
            <td>
              <p>
                <a
                  href="#sd"
                  className="edit"
                  onClick={() => openEditModel(user)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-edit"></i>{" "}
                </a>{" "}
              </p>
            </td>
          </tr>
        );
      })
    );
  };
  const openEditModel = (data) => {
    setUpdateData(data);
    setShow(true);
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />

      <Layout>
        <div className="content-body">
          <Navigation />
          <hr className="main" />

          <div className="container-fluid">
            <div className="user_cards mt-2" style={{ marginBottom: "15px" }}>
              <div
                className="d-flex justify-content-between"
                style={{ gap: "1.5rem" }}
              >
                <div className="w-100">
                  <div
                    className={
                      statusQuery === 3 ? "activeCard box_active" : "box_active"
                    }
                    onClick={() => {
                      handleFilterByStatus(3);
                    }}
                  >
                    <h4>{t("userm.activeLast30Min")}</h4>
                    <p>{stats.live}</p>
                  </div>
                </div>
                <div className="w-100">
                  <div
                    className={
                      statusQuery === "" ? "activeCard box_total" : "box_total"
                    }
                    onClick={() => {
                      handleFilterByStatus("");
                    }}
                  >
                    <h4>{t("userm.total")}</h4>
                    <p>{stats.total}</p>
                  </div>
                </div>
                <div className="w-100">
                  <div
                    className={
                      statusQuery === 1
                        ? "activeCard box_activated"
                        : "box_activated"
                    }
                    onClick={() => {
                      handleFilterByStatus(1);
                    }}
                  >
                    <h4>{t("userm.activated")}</h4>
                    <p>{stats.active}</p>
                  </div>
                </div>
                <div className="w-100">
                  <div
                    className={
                      statusQuery === 0
                        ? "activeCard box_Pending"
                        : "box_Pending"
                    }
                    onClick={() => {
                      handleFilterByStatus(0);
                    }}
                  >
                    <h4>{t("userm.pending")}</h4>
                    <p>{stats.pending}</p>
                  </div>
                </div>
                <div className="w-100">
                  <div
                    className={
                      statusQuery === 2
                        ? "activeCard box_inactive"
                        : "box_inactive"
                    }
                    onClick={() => {
                      handleFilterByStatus(2);
                    }}
                  >
                    <h4>{t("userm.inactive")}</h4>
                    <p>{stats.inactive}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10">
                <div className="d-flex align-items-center">
                  <div>Filter : &nbsp;</div>
                  <SerachBox
                    searchCategory={searchCategory}
                    query={query}
                    setQuery={setQuery}
                    setIsLoading={setIsLoading}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  ></SerachBox>
                </div>
              </div>
              <div className="col-2 col-sm-2">
                <a
                  href="#sd"
                  id="addnew"
                  className="btn btn-primary me-3 btn-sm"
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                >
                  <img src="/images/plus-circle.svg" alt="" />
                  &nbsp; {t("userm.add_new_user")}{" "}
                </a>
              </div>

              {showCreateModal && (
                <CreateUser
                  retriveCurrentData={getAllUsers}
                  token={contextStore.token()}
                  show={showCreateModal}
                  setShow={setShowCreateModal}
                  isEdit={false}
                />
              )}
              {show === true ? (
                <CreateUser
                  token={contextStore.token()}
                  data={updateData}
                  show={show}
                  setShow={setShow}
                  retriveCurrentData={getAllUsers}
                  isEdit={true}
                />
              ) : null}

              <div className="col-lg-12  layout-card-div2">
                <div className="card">
                  <div className="card-body" style={{ overflowY: "auto" }}>
                    <div
                      className="table-responsive"
                      style={{
                        minHeight:
                          filteredItems && filteredItems.length == 0
                            ? "200px"
                            : "0px",
                      }}
                    >
                      <table className="table table-responsive-md">
                        <thead>
                          <tr>
                            <th
                              onClick={() => {
                                setAscending(!ascending);
                                if (ascending === true) {
                                  filteredItems.sort((a, b) =>
                                    a.email > b.email ? 1 : -1
                                  );
                                }
                                if (ascending === false) {
                                  filteredItems.sort((a, b) =>
                                    a.email < b.email ? 1 : -1
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <strong> {t("userm.username_email")} </strong>
                              <i
                                className={`fa fa-solid fa-sort-${
                                  ascending ? "down" : "up"
                                }`}
                              ></i>
                            </th>
                            <th
                              onClick={() => {
                                setAscendingName(!ascendingName);
                                if (ascendingName === true) {
                                  filteredItems.sort((a, b) =>
                                    a.name > b.name ? 1 : -1
                                  );
                                }
                                if (ascendingName === false) {
                                  filteredItems.sort((a, b) =>
                                    a.name < b.name ? 1 : -1
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <strong> {t("userm.name")} </strong>
                              <i
                                className={`fa fa-solid fa-sort-${
                                  ascendingName ? "down" : "up"
                                }`}
                              ></i>
                            </th>
                            <th
                              onClick={() => {
                                setAscendingPrimary(!ascendingPrimary);
                                if (ascendingPrimary === true) {
                                  filteredItems.sort((a, b) =>
                                    a.primary_num > b.primary_num ? 1 : -1
                                  );
                                }
                                if (ascendingPrimary === false) {
                                  filteredItems.sort((a, b) =>
                                    a.primary_num < b.primary_num ? 1 : -1
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <strong> {t("userm.primary_number")} </strong>
                              <i
                                className={`fa fa-solid fa-sort-${
                                  ascendingPrimary ? "down" : "up"
                                }`}
                              ></i>
                            </th>
                            <th
                              onClick={() => {
                                setAscendingSecondary(!ascendingSecondary);
                                if (ascendingSecondary === true) {
                                  filteredItems.sort((a, b) =>
                                    a.secondry_num > b.secondry_num ? 1 : -1
                                  );
                                }
                                if (ascendingSecondary === false) {
                                  filteredItems.sort((a, b) =>
                                    a.secondry_num < b.secondry_num ? 1 : -1
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <strong> {t("userm.secondary_number")} </strong>
                              <i
                                className={`fa fa-solid fa-sort-${
                                  ascendingSecondary ? "down" : "up"
                                }`}
                              ></i>
                            </th>
                            <th
                              onClick={() => {
                                setAscendingRoles(!ascendingRoles);
                                if (ascendingRoles === true) {
                                  filteredItems.sort((a, b) =>
                                    a.role?.name > b.role?.name ? 1 : -1
                                  );
                                }
                                if (ascendingRoles === false) {
                                  filteredItems.sort((a, b) =>
                                    a.role?.name < b.role?.name ? 1 : -1
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <strong> {t("userm.roles")} </strong>
                              <i
                                className={`fa fa-solid fa-sort-${
                                  ascendingRoles ? "down" : "up"
                                }`}
                              ></i>
                            </th>
                            <th
                              onClick={() => {
                                setAscendingCountry(!ascendingCountry);
                                if (ascendingCountry === true) {
                                  filteredItems.sort((a, b) =>
                                    a.country?.name > b.country?.name ? 1 : -1
                                  );
                                }
                                if (ascendingCountry === false) {
                                  filteredItems.sort((a, b) =>
                                    a.country?.name < b.country?.name ? 1 : -1
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <strong> {t("userm.country")} </strong>
                              <i
                                className={`fa fa-solid fa-sort-${
                                  ascendingCountry ? "down" : "up"
                                }`}
                              ></i>
                            </th>
                            <th
                              onClick={() => {
                                setAscendingActivity(!ascendingActivity);
                                if (ascendingActivity === true) {
                                  filteredItems.sort((a, b) =>
                                    moment(a?.last_activity).format(
                                      "YYYY-MM-DD hh:mm A"
                                    ) >
                                    moment(b?.last_activity).format(
                                      "YYYY-MM-DD hh:mm A"
                                    )
                                      ? 1
                                      : -1
                                  );
                                }
                                if (ascendingActivity === false) {
                                  filteredItems.sort((a, b) =>
                                    moment(a?.last_activity).format(
                                      "YYYY-MM-DD hh:mm A"
                                    ) <
                                    moment(b?.last_activity).format(
                                      "YYYY-MM-DD hh:mm A"
                                    )
                                      ? 1
                                      : -1
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <strong> {t("userm.last_activity")} </strong>
                              <i
                                className={`fa fa-solid fa-sort-${
                                  ascendingActivity ? "down" : "up"
                                }`}
                              ></i>
                            </th>
                            <th
                              onClick={() => {
                                setAscendingStatus(!ascendingStatus);
                                if (ascendingStatus === true) {
                                  filteredItems.sort((a, b) =>
                                    a.status > b.status ? 1 : -1
                                  );
                                }
                                if (ascendingStatus === false) {
                                  filteredItems.sort((a, b) =>
                                    a.status < b.status ? 1 : -1
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <strong> {t("userm.status")} </strong>
                              <i
                                className={`fa fa-solid fa-sort-${
                                  ascendingStatus ? "down" : "up"
                                }`}
                              ></i>
                            </th>
                            <th>
                              <strong> {t("userm.actions")} </strong>
                            </th>

                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{renderHtml()}</tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      {filteredItems && filteredItems.length > 0 && (
                        <Pagination
                          setItemsPerPage={setItemsPerPage}
                          setPageNumber={setPageNumber}
                          total={total}
                          pageNumber={pageNumber}
                        ></Pagination>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default User;
