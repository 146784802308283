import axios from "axios";
import { validateEmail } from "common/helpers";
import { XError } from "components/common";
import StorageContext from "context";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import RoleModel from "services/roleServices";
import UserModal from "services/userServices";
import Swal from "sweetalert2";
import "./userStyle.css";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};
const customStylesForSelect = {
  control: (provided, state) => ({
    ...provided,
    // Change border color based on focus state
    border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change colors as needed
    "&:hover": {
      border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change hover color if needed
    },
    boxShadow: null,
    height: "3rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "1.063rem",
    fontWeight: 400,
    color: "#8392A5",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "1.063rem",
    fontWeight: 400,
    color: "#8392A5",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "1.063rem",
    fontWeight: 400,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "18rem",
  }),
};
const CreateUser = ({
  retriveCurrentData,
  token,
  show,
  setShow,
  isEdit,
  data,
}) => {
  const contextStore = React.useContext(StorageContext);
  const modalRef = useRef(null);
  const [roles, setRoles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [positions, setPositions] = useState([]);
  const [deparments, setDepartments] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [state, setState] = useState(
    isEdit
      ? {
          name: "",
          email: "",
          country: "",
          role: "",
          uuid: "",
          status: "",
          primary_num: "",
          secondry_num: "",
          dept: "",
          position: "",
        }
      : {
          name: "",
          email: "",
          country: "",
          role: "",
          primary_num: "",
          secondry_num: "",
          dept: "",
          position: "",
        }
  );

  const [error, setError] = useState({
    name: "",
    email: "",
    country: "",
    role: "",
    primary_num: "",
    secondry_num: "",
    dept: "",
    position: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    setIsOpen(show);
    getRoles();
    getCountries();

    UserModal.getPosition(token).then((res) => setPositions(res.data.data));

    UserModal.getDepartment(token).then((res) => setDepartments(res.data.data));

    if (contextStore.getAuth?.accessToken && !isEdit) {
      const AuthHeader = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${contextStore.getAuth?.accessToken}`,
      };

      axios
        .get("https://graph.microsoft.com/v1.0/users?$format=json", {
          headers: AuthHeader,
        })
        .then(async (res) => {
          //
          let userData = [];

          await res.data.value.map((user) => {
            userData.push(user.userPrincipalName);
          });

          setEmails(userData);
        });
    }
    if (isEdit) {
      setState({
        name: data.name,
        email: data.email,
        country: data.country.id,
        role: data.role.id,
        uuid: data.uuid,
        status: data.status,
        primary_num: data.primary_num,
        secondry_num: data.secondry_num,
        dept: data.dept,
        position: data.position,
      });
    }
  }, []);

  // useEffect(() => {
  //   console.log(state);
  // }, [state]);
  const formatOptions = (data) => {
    return data.map((item) => ({ value: item.id, label: item.name }));
  };

  const getCountries = () => {
    RoleModel.countryService(token, true)
      .then((payload) => {
        setCountries(payload.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        /*redirect to 500 page */
      });
  };

  const getRoles = async () => {
    await RoleModel.roleAndPermission(token)
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        /*redirect to 500 page */
      });
  };
  const onDelete = async () => {
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserModal.destroy(token, { uuid: state.uuid })
          .then(async (res) => {
            closeModal();
            retriveCurrentData();
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            // redirect to 500 page
          });
      }
    });
  };
  const onChangeStatus = async () => {
    const status = state.status === 1 ? 2 : 1;

    await UserModal.changeStatus(token, { status, uuid: state.uuid })
      .then(async (res) => {
        closeModal();

        retriveCurrentData();
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        // redirect to 500 page
      });
  };
  const getStatus = () => {
    let button = "";
    switch (state.status) {
      case 1:
        button = (
          <button
            type="button"
            style={{ marginRight: "1rem", borderRadius: "0.25rem" }}
            onClick={() => onChangeStatus()}
            className="btn btn-outline-primary1 mr_1"
          >
            {" "}
            {t("userm.inactive")}
          </button>
        );
        break;
      case 2:
        button = (
          <button
            type="button"
            style={{ marginRight: "1rem", borderRadius: "0.25rem" }}
            onClick={() => onChangeStatus()}
            className="btn btn-outline-primary1 mr_1"
          >
            {" "}
            {t("userm.active")}
          </button>
        );
        break;
      default:
        button = null;
    }

    return button;
  };
  const resendEmail = async () => {
    setIsLoading(true);

    await UserModal.resend(token, state)
      .then(async (res) => {
        setIsLoading(false);
        closeModal();
        Swal.fire(t("email_sent"));
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        setIsLoading(false);
      });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (isEdit) {
      updateUser();
    } else {
      createUser();
    }
  };

  const createUser = async () => {
    if (checkValidation()) {
      await UserModal.store(token, state)
        .then(async (res) => {
          setIsLoading(false);
          closeModal();

          retriveCurrentData();

          Swal.fire(t("userm.new_user_created"));
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          setIsLoading(false);

          let error = {
            name: "",
            email: "",
            country: "",
            role: "",
            primary_num: "",
            dept: "",
            position: "",
          };

          const errors = err?.response?.data?.errors;

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors.name;
          }
          if (
            errors?.email !== undefined ||
            errors?.email !== "" ||
            errors?.email !== null
          ) {
            error.email = errors.email;
          }
          if (
            errors?.country !== undefined ||
            errors?.country !== "" ||
            errors?.country !== null
          ) {
            error.country = errors.country;
          }
          if (
            errors?.role !== undefined ||
            errors?.role !== "" ||
            errors?.role !== null
          ) {
            error.role = errors.role;
          }
          if (
            errors?.primary_num !== undefined ||
            errors?.primary_num !== "" ||
            errors?.primary_num !== null
          ) {
            error.primary_num = errors.primary_num;
          }
          if (
            errors?.dept !== undefined ||
            errors?.dept !== "" ||
            errors?.dept !== null
          ) {
            error.dept = errors.dept;
          }

          if (
            errors?.position !== undefined ||
            errors?.position !== "" ||
            errors?.position !== null
          ) {
            error.position = errors.position;
          }
          setError({ ...error });
        });
    } else {
      setIsLoading(false);
    }
  };
  const updateUser = async () => {
    if (checkValidation()) {
      await UserModal.update(token, state)
        .then(async (res) => {
          setIsLoading(false);
          closeModal();
          retriveCurrentData();
          Swal.fire(t("userm.user_updated"));
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          setIsLoading(false);
          let error = {
            name: "",
            email: "",
            country: "",
            role: "",
            primary_num: "",
            dept: "",
            position: "",
          };
          const errors = err?.response?.data?.errors;

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors.name;
          }
          if (
            errors?.email !== undefined ||
            errors?.email !== "" ||
            errors?.email !== null
          ) {
            error.email = errors.email;
          }
          if (
            errors?.country !== undefined ||
            errors?.country !== "" ||
            errors?.country !== null
          ) {
            error.country = errors.country;
          }
          if (
            errors?.role !== undefined ||
            errors?.role !== "" ||
            errors?.role !== null
          ) {
            error.role = errors.role;
          }
          if (
            errors?.primary_num !== undefined ||
            errors?.primary_num !== "" ||
            errors?.primary_num !== null
          ) {
            error.primary_num = errors.primary_num;
          }
          if (
            errors?.dept !== undefined ||
            errors?.dept !== "" ||
            errors?.dept !== null
          ) {
            error.dept = errors.dept;
          }
          if (
            errors?.position !== undefined ||
            errors?.position !== "" ||
            errors?.position !== null
          ) {
            error.position = errors.position;
          }

          setError({ ...error });
        });
    } else {
      setIsLoading(false);
    }
  };

  const checkValidation = () => {
    let error = {
      name: "",
      email: "",
      country: "",
      role: "",
      primary_num: "",
      secondry_num: "",
      dept: "",
      position: "",
    };

    const { name, email, country, role, primary_num, dept, position } = state;

    let flag = true;

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required.";
      flag = false;
    }

    if (email === "" || email === null || email === undefined) {
      error.email = "The email field is required.";
      flag = false;
    } else {
      if (!validateEmail(email)) {
        error.email = "The email must be a valid email address.";
        flag = false;
      }
    }

    if (country === "" || country === null || country === undefined) {
      error.country = "The country field is required.";
      flag = false;
    }

    if (role === "" || role === null || role === undefined) {
      error.role = "The role field is required.";
      flag = false;
    }

    if (
      primary_num === "" ||
      primary_num === null ||
      primary_num === undefined
    ) {
      error.primary_num = "The primary number field is required.";
      flag = false;
    }

    if (dept === "" || dept === null || dept === undefined || dept == 0) {
      error.dept = "The department field is required.";
      flag = false;
    }
    if (position === "" || position === null || position === undefined) {
      error.position = "The position field is required.";
      flag = false;
    }

    setError({ ...error });
    return flag;
  };
  const closeModal = () => {
    setState({
      name: "",
      email: "",
      country: "",
      role: "",
      primary_num: "",
      secondry_num: "",
      dept: "",
      position: "",
    });

    setError({
      name: "",
      email: "",
      country: "",
      role: "",
      primary_num: "",
      secondry_num: "",
      dept: "",
      position: "",
    });
    setIsOpen(false);
    setShow(false);
  };

  // const handleRequestOptions = part => {

  // 	console.log(part);
  // 	//setEmails({ options: SOME_NEW_OPTION_ARRAY });
  // }

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <div className="modal-header mt-4">
        <h3 className="modal-title">
          {!isEdit ? t("userm.new_user") : t("userm.update_user")}
        </h3>
        <div
          className="close_icon"
          ref={modalRef}
          data-bs-dismiss="modal"
          onClick={() => closeModal()}
        >
          <CloseIcon style={{ cursor: "pointer" }} fontSize="large" />
        </div>
      </div>
      <div className="modal-body" style={{ paddingBottom: 0 }}>
        <div className="card">
          <div
            className="card-body"
            style={{ padding: "0px", overflow: "hidden" }}
          >
            <div className="basic-form">
              <form className="pt-5 pb-5">
                <div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.name")}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={state.name}
                        onChange={(event) =>
                          setState({ ...state, name: event.target.value })
                        }
                        placeholder={t("userm.name")}
                      />
                      <XError message={error.name} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.username_email")}
                        <span className="text-danger"> *</span>
                      </label>

                      <TextInput
                        Component="input"
                        placeholder={t("userm.username_email")}
                        value={state.email}
                        maxOptions={200}
                        onChange={(value) =>
                          setState({ ...state, email: value.trim() })
                        }
                        onSelect={(value) => {
                          if (value[0] === "@") {
                            setState({
                              ...state,
                              email: value.slice(1).trim(),
                            });
                          } else {
                            setState({ ...state, email: value.trim() });
                          }
                        }}
                        className="form-control"
                        trigger={["", "@"]}
                        options={{ "": emails, "@": emails }}
                      />

                      <XError message={error.email} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.primary_num")}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={state.primary_num}
                        onChange={(event) =>
                          setState({
                            ...state,
                            primary_num: event.target.value,
                          })
                        }
                        placeholder={t("userm.primary_num")}
                      />
                      <XError message={error.primary_num} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.secondry_num")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={state.secondry_num}
                        onChange={(event) =>
                          setState({
                            ...state,
                            secondry_num: event.target.value,
                          })
                        }
                        placeholder={t("userm.secondry_num")}
                      />
                      <XError message={error.secondry_num} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.department")}
                        <span className="text-danger"> *</span>
                      </label>
                      <Select
                        styles={customStylesForSelect}
                        options={formatOptions(deparments)}
                        value={formatOptions(deparments).filter(
                          (el) => el.value == state.dept
                        )}
                        onChange={(option) =>
                          setState({ ...state, dept: option.value })
                        }
                        placeholder={t("userm.choose") + "..."}
                        menuPlacement="auto"
                      />
                      <XError message={error.dept} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.position")}{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <Select
                        styles={customStylesForSelect}
                        options={formatOptions(positions)}
                        value={formatOptions(positions).filter(
                          (el) => el.value == state.position
                        )}
                        onChange={(option) =>
                          setState({ ...state, position: option.value })
                        }
                        placeholder={t("userm.choose") + "..."}
                        menuPlacement="auto"
                      />
                      <XError message={error.position} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.country")}
                        <span className="text-danger"> *</span>
                      </label>

                      <Select
                        styles={customStylesForSelect}
                        options={formatOptions(countries)}
                        value={formatOptions(countries).filter(
                          (el) => el.value == state.country
                        )}
                        onChange={(option) =>
                          setState({ ...state, country: option.value })
                        }
                        placeholder={t("userm.choose") + "..."}
                        menuPlacement="auto"
                      />
                      <XError message={error.country} />
                    </div>
                    <div className="col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.role")}
                        <span className="text-danger"> *</span>
                      </label>
                      <Select
                        styles={customStylesForSelect}
                        options={formatOptions(roles)}
                        value={formatOptions(roles).filter(
                          (el) => el.value == state.role
                        )}
                        onChange={(option) =>
                          setState({ ...state, role: option.value })
                        }
                        placeholder={t("userm.choose") + "..."}
                        menuPlacement="auto"
                      />
                      <XError message={error.role} />
                    </div>
                  </div>
                </div>

                <div
                  className="toolbar toolbar-bottom d-flex justify-content-end mt-5"
                  role="toolbar"
                  style={{ textAlign: "right" }}
                >
                  {isEdit && getStatus()}
                  {isEdit && contextStore.getAuth.role.name === "Super Admin" && (
                    <button
                      type="button"
                      onClick={() => onDelete()}
                      style={{
                        marginRight: "1rem",
                        marginBottom: "0.25rem",
                        borderRadius: "0.25rem",
                      }}
                      className="btn btn-danger mr_1"
                    >
                      {" "}
                      {t("common.delete")}
                    </button>
                  )}
                  {isEdit && data?.status === 0 && (
                    <button
                      onClick={() => resendEmail()}
                      style={{ marginRight: "1rem", marginBottom: "0.25rem" }}
                      type="button"
                      className="btn btn-outline-primary mr_1"
                    >
                      {" "}
                      {t("userm.resend")}{" "}
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    style={{ marginRight: "1rem", marginBottom: "0.25rem" }}
                    className="btn btn-outline-primary mr_1"
                  >
                    {" "}
                    {t("common.cancel")}{" "}
                  </button>

                  {isLoading ? (
                    <button type="button" className="btn btn-primary mr_1">
                      {" "}
                      {t("common.loading")}...{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => onSubmit()}
                      style={{
                        marginBottom: "0.25rem",
                        borderRadius: "0.25rem",
                      }}
                      className="btn btn-primary mr_1"
                    >
                      {" "}
                      {t("common.save")}{" "}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUser;
