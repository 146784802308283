import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class Profile {

	async get(token){
		
		return await axios(token).get(`getProfile`);
	}

	async update(token,data){
		return await axios(token).post('profileUpdate',data);
	}
	//timezone
	async getTimezone(token){
		
		return await axios(token).get(`Timezone`);
	}
}

export default new Profile();
