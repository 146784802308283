/* eslint-disable jsx-a11y/alt-text */
import { Line2 } from "components/graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import React from "react";
import { numberFormat, numberFormatToDecimalPlace } from "common/helpers";
import BoltIcon from "@mui/icons-material/Bolt";
import ThermostatIcon from "@mui/icons-material/Thermostat";
const RoomTable = ({
  selectedFloor,
  getCabinetData,
  hallSort,
  setHallSort,
  getCabinetsData,
  htmldata,
  setActivateTab,
}) => {
  const [state, setState] = useState([]);
  const { t } = useTranslation();
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      placement="top"
      classes={{ popper: className }}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 300 }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(0, 0, 0, 0.87)",
      minWidth: 150,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid black",
    },

    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
      marginBottom: "0px",
      marginLeft: "0rem",
    },
  }));

  const HtmlTooltipForIndicator = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      placement="top"
      classes={{ popper: className }}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 300 }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 200,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid black",
      textAlign: "center",
    },

    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
      marginBottom: "0.5rem",
      marginLeft: "0rem",
    },
  }));

  useEffect(() => {
    if (Object.keys(selectedFloor).length && selectedFloor.rooms.length) {
      selectedFloor.rooms.sort((a, b) => (a.name < b.name ? 1 : -1));
      getCabinetsData(0, selectedFloor.rooms[0]);
    }
    setState(selectedFloor);
    // console.log("Selecd----",htmldata)
    setHallSort(true);
  }, [selectedFloor]);

  const getCabsStatus = (data) => {
    let reservedPer = 0;
    let rofrPer = 0;
    let blockedPer = 0;
    let reserved = 0;
    let rofr = 0;
    let blocked = 0;

    let totalComplete = 0;
    if (data.cabinets.length > 0) {
      data.cabinets.forEach((data) => {
        if (data.status === 2) {
          totalComplete += 1;
        }
      });
    }

    let available =
      Number(data.design_cabs) -
      Number(data.sold_cabs) -
      Number(data.reserved_cabs) -
      Number(data.rofr_cabs) -
      Number(data.blocked_cabs) -
      Number(totalComplete);
    let soldPer = (data.sold_cabs * 100) / data.design_cabs;
    let availPer = (available * 100) / data.design_cabs;
    reservedPer = (data.reserved_cabs * 100) / data.design_cabs;
    rofrPer = (data.rofr_cabs * 100) / data.design_cabs;
    blockedPer = (data.blocked_cabs * 100) / data.design_cabs;
    let completePer = (totalComplete * 100) / data.design_cabs;
    return (
      <HtmlTooltip
        title={
          <>
            <div style={{ padding: "0.5rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  marginBottom: "0.5rem",
                }}
              >
                Cabinets
              </div>
              <div
                className="room_count"
                style={{
                  background: "#FE8600",
                }}
              >
                <div>In Services</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.sold_cabs)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#3feb7b",
                }}
              >
                <div>Available</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(available)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#1b70c0",
                }}
              >
                <div>Reserved</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.reserved_cabs)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#595959",
                }}
              >
                <div>ROFR</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.rofr_cabs)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#000000",
                  border: "1px solid white",
                }}
              >
                <div>Blocked</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.blocked_cabs)}</div>
              </div>
            </div>
          </>
        }
      >
        <Button style={{ width: "11ch" }}>
          <Line2
            totalText={data.design_cabs}
            inServiceText={data.sold_cabs}
            availableText={available}
            inServicePercent={soldPer}
            availablePercent={availPer}
            totalPercent={0}
            reserved={data.reserved_cabs}
            rofr={data.rofr_cabs}
            blocked={data.blocked_cabs}
            complete={totalComplete}
            reservedPer={reservedPer}
            rofrPer={rofrPer}
            blockedPer={blockedPer}
            completePer={completePer}
          />
        </Button>
      </HtmlTooltip>
    );
  };

  const getCagesStatus = (data) => {
    let available = 0;
    let soldPer = 0;
    let availPer = 0;
    let reservedPer = 0;
    let rofrPer = 0;
    let blockedPer = 0;
    let totalComplete = 0;
    if (
      data.design_cages &&
      data.design_cages !== null &&
      data.design_cages !== ""
    ) {
      available =
        Number(data.design_cages) -
        Number(data.sold_cages) -
        Number(data.reserved_cages) -
        Number(data.rofr_cages) -
        Number(data.blocked_cages);

      soldPer = (data.sold_cages * 100) / data.design_cages;
      availPer = (available * 100) / data.design_cages;
      reservedPer = (data.reserved_cages * 100) / data.design_cages;
      rofrPer = (data.rofr_cages * 100) / data.design_cages;
      blockedPer = (data.blocked_cages * 100) / data.design_cages;
    }

    return (
      <HtmlTooltip
        title={
          <>
            <div style={{ padding: "0.5rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  marginBottom: "0.5rem",
                }}
              >
                Cages
              </div>
              <div
                className="room_count"
                style={{
                  background: "#FE8600",
                }}
              >
                <div>In Services</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.sold_cages)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#3feb7b",
                }}
              >
                <div>Available</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(available)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#1b70c0",
                }}
              >
                <div>Reserved</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.reserved_cages)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#595959",
                }}
              >
                <div>ROFR</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.rofr_cages)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#000000",
                  border: "1px solid white",
                }}
              >
                <div>Blocked</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.blocked_cages)}</div>
              </div>
            </div>
          </>
        }
      >
        <Button style={{ width: "11ch" }}>
          <Line2
            totalText={data.design_cages}
            inServiceText={data.sold_cages}
            availableText={available}
            inServicePercent={soldPer}
            availablePercent={availPer}
            totalPercent={0}
            reserved={data.reserved_cages}
            rofr={data.rofr_cages}
            blocked={data.blocked_cages}
            reservedPer={reservedPer}
            rofrPer={rofrPer}
            blockedPer={blockedPer}
          />
        </Button>
      </HtmlTooltip>
    );
  };
  const getPowerStatus = (data) => {
    let reservedPer = 0;
    let rofrPer = 0;
    let blockedPer = 0;

    let totalComplete = 0;
    if (data.cabinets.length > 0) {
      data.cabinets.forEach((data) => {
        if (data.status === 2) {
          totalComplete += data.actual_kw !== null ? data.actual_kw : 0;
        }
      });
    }

    // console.log(
    //   Number(data.design_power),
    //   Number(data.sold_power),
    //   Number(data.reserved_power),
    //   Number(data.rofr_power),
    //   Number(data.blocked_power),
    //   totalComplete
    // );
    let available =
      Number(data.design_power) -
      Number(data.sold_power) -
      Number(data.reserved_power) -
      Number(data.rofr_power) -
      Number(data.blocked_power) -
      Number(totalComplete);

    let soldPer = (data.sold_power * 100) / data.design_power;
    let availPer = (available * 100) / data.design_power;
    reservedPer = (data.reserved_power * 100) / data.design_power;
    rofrPer = (data.rofr_power * 100) / data.design_power;
    blockedPer = (data.blocked_power * 100) / data.design_power;
    let completePer = (totalComplete * 100) / data.design_power;
    return (
      <HtmlTooltip
        title={
          <>
            <div style={{ padding: "0.5rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  marginBottom: "0.5rem",
                }}
              >
                Power(KW)
              </div>
              <div
                className="room_count"
                style={{
                  background: "#FE8600",
                }}
              >
                <div>In Services</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.sold_power)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#3feb7b",
                }}
              >
                <div>Available</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(available)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#1b70c0",
                }}
              >
                <div>Reserved</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.reserved_power)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#595959",
                }}
              >
                <div>ROFR</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.rofr_power)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#000000",
                  border: "1px solid white",
                }}
              >
                <div>Blocked</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(data.blocked_power)}</div>
              </div>
            </div>
          </>
        }
      >
        <Button style={{ width: "11ch" }}>
          <Line2
            totalText={data.design_power}
            inServiceText={data.sold_power}
            availableText={available}
            inServicePercent={soldPer}
            availablePercent={availPer}
            totalPercent={0}
            reserved={data.reserved_power}
            rofr={data.rofr_power}
            blocked={data.blocked_power}
            reservedPer={reservedPer}
            rofrPer={rofrPer}
            blockedPer={blockedPer}
            completePer={completePer}
          />
        </Button>
      </HtmlTooltip>
    );
  };
  const checkIfExixt = (id, type) => {
    if (type == "power") {
      const results = htmldata.filter(
        (data) => data.room_id == id && data.isPowerDataAvailable == 1
      );
      if (results.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    if (type == "temp") {
      const results = htmldata.filter(
        (data) => data.room_id == id && data.isTemperatureDataAvailable == 1
      );
      if (results.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <table className="table header-border table-borderless table-hover verticle-middle">
      <thead>
        <tr>
          <th
            scope="col"
            width="10%"
            onClick={() => {
              setHallSort(!hallSort);
              if (hallSort === true) {
                state.rooms.sort((a, b) => (a.name > b.name ? 1 : -1));
              }
              if (hallSort === false) {
                state.rooms.sort((a, b) => (a.name < b.name ? 1 : -1));
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            {t("floor.name")}{" "}
            <i
              className={`fa fa-solid fa-sort-${hallSort ? "down" : "up"}`}
            ></i>
          </th>
          <th scope="col" width="5%">
            {" "}
            {t("floor.status")}{" "}
          </th>
          <th scope="col" width="10%">
            {" "}
            {t("floor.cabinets")}{" "}
          </th>
          <th scope="col" width="10%">
            {" "}
            {t("floor.cages")}
          </th>
          <th scope="col" width="10%">
            {" "}
            {t("floor.power_kw")}{" "}
          </th>
          <th scope="col" width="5%">
            {t("floor.layout")}
          </th>
        </tr>
      </thead>
      <tbody id="cardnew">
        {state.rooms &&
          state.rooms.map((data, index) => {
            return (
              <tr key={index} style={{ cursor: "pointer" }}>
                <td
                  onClick={() => getCabinetsData(0, data)}
                  className="pd-l bold-txt"
                >
                  {" "}
                  {data.name}{" "}
                </td>
                <td onClick={() => getCabinetsData(0, data)}>
                  <div
                    style={{
                      height: "6px",
                      width: "5em",
                      overflow: "hidden",
                      backgroundColor:
                        data.status === 1 ? "#3FEB7B" : "#E0E2E5",
                    }}
                  >
                    <div className="progress-bar bg-success" role="progressbar">
                      {" "}
                    </div>
                  </div>
                </td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                    getCabinetsData(0, data);
                  }}
                >
                  {getCabsStatus(data)}
                </td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                    getCabinetsData(0, data);
                  }}
                >
                  {getCagesStatus(data)}
                </td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                    getCabinetsData(0, data);
                  }}
                >
                  {getPowerStatus(data)}
                </td>
                <td>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "33.33%" }}>
                      {htmldata.map((html, idx) => {
                        if (html.room_id === data.id) {
                          return (
                            <HtmlTooltipForIndicator
                              title={
                                <>
                                  <div style={{ padding: "0rem 0px 0px 0px" }}>
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      Room layout available
                                    </div>
                                  </div>
                                </>
                              }
                            >
                              <img
                                key={idx}
                                src="/images/Group 3647 orange.svg"
                                onClick={() => {
                                  setActivateTab("lay");
                                  getCabinetData(data.id, data.name, true);
                                }}
                              />
                            </HtmlTooltipForIndicator>
                          );
                        }
                      })}
                    </div>
                    <div style={{ width: "33.33%" }}>
                      {checkIfExixt(data.id, "power") ? (
                        <span>
                          <HtmlTooltipForIndicator
                            title={
                              <>
                                <div style={{ padding: "0rem 0px 0px 0px" }}>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    Power data available
                                  </div>
                                </div>
                              </>
                            }
                          >
                            <BoltIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "2rem",
                                color: "#fe8600",
                              }}
                              onClick={() => {
                                setActivateTab("lay");
                                getCabinetData(data.id, data.name, true);
                              }}
                            />
                          </HtmlTooltipForIndicator>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div style={{ width: "33.33%" }}>
                      {checkIfExixt(data.id, "temp") ? (
                        <span>
                          <HtmlTooltipForIndicator
                            title={
                              <>
                                <div style={{ padding: "0rem 0px 0px 0px" }}>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    Temperature and humidity data available
                                  </div>
                                </div>
                              </>
                            }
                          >
                            <ThermostatIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "2rem",
                                color: "#fe8600",
                              }}
                              onClick={() => {
                                setActivateTab("heat");
                                getCabinetData(data.id, data.name, true);
                              }}
                            />
                          </HtmlTooltipForIndicator>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </span>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default RoomTable;
