import { useEffect, useContext } from 'react'
import StorageContext from 'context'

const usePreselectCountry = ({ countries, setFieldValue }) => {
  const { getAuth } = useContext(StorageContext)
  useEffect(() => {
    if (countries && countries.length > 0 && setFieldValue) {
    //   console.log({ id: getAuth.country?.id })
      setFieldValue('country', getAuth.country?.country_code || '')
    }
  }, [countries])
  return {}
}

export default usePreselectCountry
