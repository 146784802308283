import AuthContext from 'context';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateTicketService from 'services/createTicketService';
import Swal from 'sweetalert2';
import * as utils from '../../shared/utils';

const SITE_ACCESS_PREFIX = 'Site Access ';

const useCreateSiteAccessTicket = ({ closeModal, teams }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [requestOnFlight, setRequestOnFlight] = useState(false);

  const postNewTicket = async (values) => {
    // console.log({ teams })
    try {
      const {
        country,
        operatingSite,
        companyName,
        specialVisitArea,
        visitType,
        startDateTime,
        endDateTime,
        allowOpenRack,
        serviceId,
        ticketSubject,
        description,
        visitorList,
        cargoUse,
        customer,
        dataCenters,
        countries,
        need_assistance
      } = values;

      const dataCenterCountry = utils.getDataCenterCountry(operatingSite, dataCenters, countries);

      const payload = {
        team_id: utils.getTeamIdByCountry(
          teams,
          country,
          SITE_ACCESS_PREFIX,
          dataCenterCountry,
          countries
        ),
        prority_id: 0,
        operating_site_id: parseInt(operatingSite, 10),
        designated_company_id: parseInt(companyName, 10),
        customer_id: parseInt(customer, 10),
        is_special_visit_area: specialVisitArea !== '',
        special_visit_area: specialVisitArea === '' ? null : specialVisitArea,
        first_time_access: visitType === 'first-time-visit',
        is_site_tour: visitType === 'site-tour',
        start_date: moment(startDateTime).utc(),
        end_date: moment(endDateTime).utc(),
        allow_open_selected_rack_door: allowOpenRack,
        service_id: serviceId.length > 0 ? serviceId.map((service) => service.id) : [],
        space_id: serviceId.length > 0 ? serviceId.map((service) => service.space_id) : [],
        subject: ticketSubject,

        description,
        need_assistance: need_assistance,
        site_access_details: visitorList.map((visitor) => {
          const {
            visitorName,
            visitorEmail,
            visitorCompanyName,
            visitorContactNumber,
            visitorVehicleInformation,
            visitorIdNumber
          } = visitor;
          return {
            name: visitorName,
            email: visitorEmail,
            identification_number: visitorIdNumber,
            contact_number: visitorContactNumber,
            cargo_use: cargoUse,
            visitor_company_name: visitorCompanyName,
            vehicle_info: visitorVehicleInformation
          };
        })
      };
      if (requestOnFlight === false) {
        setRequestOnFlight(true);
        // console.log(payload)
        const result = await CreateTicketService.createTicket(
          authContext.token(),
          payload,
          'site-access'
        );
        // console.log(result.data.data)
        const ticketId = result.id;
        setRequestOnFlight(false);
        closeModal();
        await Swal.fire({
          icon: "success",
          title: `${t("done")}!`,
          text: t("create-ticket.site-access.success", {
            ticket: `${ticketSubject} (#${ticketId})`,
          }),
          confirmButtonText: "Ok",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        })
      }
    } catch (err) {
      setRequestOnFlight(false);
      await Swal.fire(t('error'), err.message);
    }
  };

  return {
    postNewTicket,
    requestOnFlight
  };
};

export default useCreateSiteAccessTicket;
