import { XError } from "components/common";
import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import UserModal from "services/userServices";
import Swal from "sweetalert2";
import ProfileService from "../../services/profileService";
import Common from "services/commonService";
import Layout from "../super-admin/Layouts";
import "./profile-style.css";
import { LogoutOnSessionExpire } from "common/helpers";
import Loading from "views/super-admin/pre-loader/loader";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const contextStore = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    name: "",
    email: "",
    profile_img: "",
    role: "",
    country: "",
    status: "",
    image: "",
    password: "",
    confirm_password: "",
    primary_num: "",
    secondry_num: "",
    dept: "",
    position: "",
    timezone_id: "",
    language_id: "",
    refresh_time: "",
    display_duration: "",
  });
  const [positions, setPositions] = useState([]);
  const [deparments, setDepartments] = useState([]);
  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    primary_num: "",
    secondry_num: "",
    timezone_id: "",
  });
  const [timezone, setTimezone] = useState([]);
  const [timezonecountry, setTimezoneCountry] = useState([]);
  const [countrytimezone, setCountryTimezone] = useState("");
  const [languages, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getUserData();
    UserModal.getPosition(contextStore.token()).then((res) =>
      setPositions(res.data.data)
    );
    Common.language().then((res) => setLanguages(res.data.data));
    // set timezone country if country is selected
    console.log("timezone---", state.timezone_id);
    setCountryTimezone(contextStore?.getAuth?.timezone?.country_name);

    getTimeZone();
    UserModal.getDepartment(contextStore.token()).then((res) => {
      setDepartments(res.data.data);
    });
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const getUserData = async () => {
    await ProfileService.get(contextStore.token()).then((res) => {
      setState({
        name: res.data.data.name,
        email: res.data.data.email,
        profile_img: res.data.data.profile_img,
        role: res.data.data.role.name,
        country: res.data.data.country.name,
        status: res.data.data.status,
        image: "",
        password: "",
        confirm_password: "",
        primary_num: res.data.data.primary_num,
        secondry_num: res.data.data.secondry_num,
        dept: res.data.data.dept,
        position: res.data.data.position,
        timezone_id: res.data.data.timezone_id,
        language_id: res.data.data.language_id,
        refresh_time: res.data.data.bms_dashboardrefreshtime,
        display_duration: res.data.data.bms_dashboarddisplayduration,
      });
    });
  };

  const onSubmit = async () => {
    console.log("timezone---submit", countrytimezone);
    setIsLoading(true);
    if (checkValidation()) {
      await ProfileService.update(contextStore.token(), state)
        .then(async (res) => {
          console.log("ProfileService", res);
          contextStore.setAuth(res.data.data);
          setIsLoading(false);
          setError({
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            primary_num: "",
            secondry_num: "",
          });
          Swal.fire(t("profile.profile_updated"));
          //props.selectDataCenterFloor(props.dataCenterId)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          setIsLoading(false);
          let error = {
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            primary_num: "",
            secondry_num: "",
          };
          const errors = err?.response?.data?.errors;

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors.name;
          }

          if (
            errors?.email !== undefined ||
            errors?.email !== "" ||
            errors?.email !== null
          ) {
            error.email = errors.email;
          }

          if (
            errors?.password !== undefined ||
            errors?.password !== "" ||
            errors?.password !== null
          ) {
            error.password = errors.password;
          }

          if (
            errors?.primary_num !== undefined ||
            errors?.primary_num !== "" ||
            errors?.primary_num !== null
          ) {
            error.primary_num = errors.primary_num;
          }

          if (
            errors?.confirm_password !== undefined ||
            errors?.confirm_password !== "" ||
            errors?.confirm_password !== null
          ) {
            error.confirm_password = errors.confirm_password;
          }

          setError({ ...error });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };
  const checkValidation = () => {
    let error = {
      timezone_id: "",
    };

    const { timezone_id } = state;
    let flag = true;
    console.log("hiiiii--", countrytimezone);
    if (countrytimezone) {
      console.log("heyyyyyy--", timezone_id);
      if (
        timezone_id === "" ||
        timezone_id === null ||
        timezone_id === undefined ||
        timezone_id === 0
      ) {
        error.timezone_id = "The timezone field is required.";
        flag = false;
      }
    }
    setError({ ...error });
    return flag;
  };
  const renderProfileImage = () => {
    if (
      state?.profile_img !== "" &&
      state?.profile_img !== null &&
      state?.profile_img !== undefined
    ) {
      return (
        <div className="p-image-2">
          <img src={state?.profile_img} />
          <label
            className="edit_profile_dg"
            style={{ cursor: "pointer" }}
            htmlFor="actual-file2"
          >
            <img src="images/edit1.png" />
          </label>
          <input
            className="file-upload"
            type="file"
            id="actual-file2"
            accept="image/*"
            onChange={onChangePicture}
          />
        </div>
      );
    } else {
      return (
        <div className="p-image">
          <label
            style={{
              background: "#F3F4F9",
              padding: "36px 35px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            htmlFor="actual-file"
          >
            <img src="images/upload icon.svg" />
          </label>
          <input
            className="file-upload"
            type="file"
            id="actual-file"
            accept="image/*"
            onChange={onChangePicture}
          />
        </div>
      );
    }
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      let FR = new FileReader();

      FR.addEventListener("load", function (e) {
        setState({
          ...state,
          profile_img: e.target.result,
          image: e.target.result,
        });
      });
      FR.readAsDataURL(e.target.files[0]);
    }
  };

  const getStatus = (status) => {
    let stringStatus = "";

    switch (status) {
      case 0:
        stringStatus = (
          <span
            className="badge badge-warning badge-lg light"
            // style={{width:"50%"}}
          >
            Pending
          </span>
        );
        break;
      case 1:
        stringStatus = (
          <span
            className="badge badge-success badge-lg light"
            // style={{width:"50%"}}
          >
            Active
          </span>
        );
        break;
      case 2:
        stringStatus = (
          <span
            className="badge badge-danger badge-lg light"
            style={{ width: "50%" }}
          >
            Inactive
          </span>
        );
        break;
      default:
        stringStatus = (
          <span
            className="badge badge-success badge-lg light"
            // style={{width:"50%"}}
          >
            Active
          </span>
        );
        break;
    }

    return stringStatus;
  };
  const getTimeZone = async () => {
    // setIsLoading(true)
    await ProfileService.getTimezone(contextStore.token(), true).then(
      async ({ data }) => {
        setTimezone(data?.data);
        const cdata = data?.data;
        const tzobj = [
          ...new Map(
            cdata.map((item) => [JSON.stringify(item?.country_name), item])
          ).values(),
        ];
        setTimezoneCountry(tzobj);
      }
    );
  };
  const countryZone = (e) => {
    setCountryTimezone(e.target.value);
    setState({ ...state, timezone_id: "" });
  };
  return (
    <>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="main_profile">
              <div className="profile_head">
                <h3>
                  {t("profile.profile")} {getStatus(state.status)}{" "}
                </h3>
              </div>
              <div className="profile_info_start mt-4">
                <h3>{t("profile.general_information")}</h3>
              </div>
              <div className="upload_profile">{renderProfileImage()}</div>
              <div className="form_start">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label className="form-label">
                      {" "}
                      {t("profile.name")}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={state?.name}
                      onChange={(event) =>
                        setState({ ...state, name: event.target.value })
                      }
                    />
                    <XError message={error.name} />
                    <div className="country_role_info">
                      <p>
                        {t("profile.country")}: <strong>{state.country}</strong>
                      </p>
                      <p>
                        {t("profile.role")}: <strong>{state.role}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label className="form-label">
                      {t("profile.email_username")}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email/Username"
                      value={state?.email}
                      onChange={(event) =>
                        setState({ ...state, email: event.target.value })
                      }
                    />
                    <XError message={error.email} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label className="form-label">
                      {" "}
                      {t("profile.primary_number")}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Primary Number"
                      value={state?.primary_num}
                      onChange={(event) =>
                        setState({ ...state, primary_num: event.target.value })
                      }
                    />
                    <XError message={error.primary_num} />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label className="form-label">
                      {t("profile.secondary_number")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Secondary Number"
                      value={state?.secondry_num}
                      onChange={(event) =>
                        setState({ ...state, secondry_num: event.target.value })
                      }
                    />
                    <XError message={error.secondry_num} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2313">
                    <label className="form-label">
                      {" "}
                      {t("profile.department")}
                      <span className="text-danger"> *</span>
                    </label>
                    <select
                      disabled={state?.role === "Super Admin" ? false : true}
                      value={state.dept}
                      className="nice-select  default- form-control wide"
                    >
                      <option value="">Choose...</option>
                      {deparments &&
                        deparments.map((dept) => {
                          return (
                            <option value={dept.id} key={dept.id}>
                              {dept.name}{" "}
                            </option>
                          );
                        })}
                    </select>
                    <XError message={error.dept} />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2313">
                    <label className="form-label">
                      {t("profile.position")}
                    </label>
                    <select
                      value={state.position}
                      disabled={state?.role === "Super Admin" ? false : true}
                      className="nice-select  default- form-control wide"
                    >
                      <option value="">Choose...</option>
                      {positions &&
                        positions.map((position) => {
                          return (
                            <option value={position.id} key={position.id}>
                              {position.name}{" "}
                            </option>
                          );
                        })}
                    </select>
                    <XError message={error.position} />
                  </div>
                </div>
                <div className="profile_info_start mt-2">
                  <h3>{t("profile.change_password")}</h3>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label className="form-label">
                      {t("profile.new_password")}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      autoComplete="new-password"
                      onChange={(event) =>
                        setState({ ...state, password: event.target.value })
                      }
                    />
                    <XError message={error.password} />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label className="form-label">
                      {t("profile.confirm_new_password")}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={(event) =>
                        setState({
                          ...state,
                          confirm_password: event.target.value,
                        })
                      }
                    />
                    <XError message={error.confirm_password} />
                  </div>
                </div>
                <div className="profile_info_start mt-2">
                  <h3>{t("profile.language_and_timezone")}</h3>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label">
                      {t("profile.language")}
                    </label>
                    <select
                      value={state?.language_id || ""}
                      className="default-select form-control wide"
                      onChange={(event) =>
                        setState({ ...state, language_id: event.target.value })
                      }
                    >
                      <option value="">Choose...</option>
                      {languages &&
                        languages.map((l) => {
                          return (
                            <option value={l.id} key={l.code}>
                              {l.name}{" "}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label">{t("profile.country")}</label>
                    <select
                      value={countrytimezone ? countrytimezone : ""}
                      className="default-select form-control wide"
                      onChange={(event) => countryZone(event)}
                    >
                      <option value="">Choose...</option>
                      {timezonecountry &&
                        timezonecountry.map((c) => {
                          return (
                            <option value={c.country_name} key={c.id}>
                              {c.country_name}{" "}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label">
                      {t("profile.timezone")}
                    </label>
                    <select
                      value={state.timezone_id ? state.timezone_id : ""}
                      onChange={(event) =>
                        setState({ ...state, timezone_id: event.target.value })
                      }
                      className="default-select form-control wide"
                      required={
                        countrytimezone && state.timezone_id === 0
                          ? "required"
                          : ""
                      }
                    >
                      {" "}
                      <option value="">Choose...</option>
                      {timezone &&
                        timezone.map((tz) => {
                          if (tz.country_name == countrytimezone) {
                            return (
                              <option value={tz.id} key={tz.id}>
                                {tz.timezone}{" "}
                              </option>
                            );
                          } else {
                            if (!countrytimezone && tz.id == state.timezone_id)
                              return (
                                <option value={tz.id} key={tz.id}>
                                  {tz.timezone}{" "}
                                </option>
                              );
                          }
                        })}
                    </select>
                    <XError message={error.timezone_id} />
                  </div>
                </div>
                <div className="profile_info_start mt-2">
                  <h3>{t("profile.bmstitle")}</h3>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label">
                      {t("profile.dashboard_refresh_time")}
                    </label>
                    <select
                      disabled={state?.role === "Super Admin" ? false : true}
                      value={state?.refresh_time || ""}
                      className="default-select form-control wide"
                      onChange={(event) =>
                        setState({ ...state, refresh_time: event.target.value })
                      }
                    >
                      <option value="">Off</option>
                      <option value="5s">5s</option>
                      <option value="10s">10s</option>
                      <option value="30s">30s</option>
                      <option value="1m">1m</option>
                      <option value="5m">5m</option>
                      <option value="15m">15m</option>
                      <option value="30m">30m</option>
                      <option value="1h">1h</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label">
                      {t("profile.dashboard_display_duration")}
                    </label>
                    <select
                      disabled={state?.role === "Super Admin" ? false : true}
                      value={state?.display_duration || ""}
                      className="default-select form-control wide"
                      onChange={(event) =>
                        setState({
                          ...state,
                          display_duration: event.target.value,
                        })
                      }
                    >
                      <option value="">Choose...</option>
                      <option value="5m">5 mins</option>
                      <option value="15m">15 mins</option>
                      <option value="30m">30 mins</option>
                      <option value="1h">1 hr</option>
                      <option value="3h">3 hrs</option>
                      <option value="6h">6 hrs</option>
                      <option value="12h">12 hrs</option>
                      <option value="24h">24 hrs</option>
                    </select>
                  </div>
                </div>
                <div className="cnfrm_change mt-4">
                  {isLoading ? (
                    <button type="submit" className="btn btn-primary">
                      {t("common.saving_changes")} ...{" "}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => onSubmit()}
                    >
                      {t("common.confirm_changes")}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={{ borderRadius: "5px" }}
                    onClick={() => window.history.back()}
                  >
                    {" "}
                    {t("common.cancel")}{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
