import { Col, Row } from 'react-bootstrap';
import LeftPart from './LeftPart';
import RightPart from './RightPart';
import useSiteAccessTicketForm from './useSiteAccessTicketForm';

const SiteAccessTicketForm = ({ closeModal, setLoading, id }) => {
  const form = useSiteAccessTicketForm({ closeModal, setLoading, id });
  return (
    <form onSubmit={form.formik.handleSubmit} className="site_access">
      <Row>
        <Col>
          <LeftPart form={form} />
        </Col>
        <Col>
          <RightPart form={form} closeModal={closeModal} />
        </Col>
      </Row>
    </form>
  );
};

export default SiteAccessTicketForm;
