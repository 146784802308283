import { css } from 'glamor'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'

const styles = {
  text: {
    fontSize: '16px'
  },
  button: {
    marginRight: '10px !important'
  }
}

const CloseConfirm = ({ show, setShow, handleCloseModal }) => {
  const { t } = useTranslation()
  const handleClose = () => {
    setShow(false)
  }

  const handleClickOk = () => {
    setShow(false)
    handleCloseModal()
  }

  return (
    <Modal show={show} centered onHide={handleClose} backdrop='static' keyboard={false}>
      <Modal.Header className='mt-3 mb-1' closeButton>
        <Modal.Title>{'   '}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='mt-4' {...css(styles.text)}>
        {t('create-ticket.close-confirm-text')}
      </Modal.Body>
      <Modal.Footer>
        <Button {...css(styles.button)} variant='primary' onClick={handleClickOk}>
          {t('ok')}
        </Button>
        <Button variant='secondary' onClick={handleClose}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CloseConfirm
