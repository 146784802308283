import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "context";
import ticketDetails from "services/ticketDetails";
import moment from "moment";
import ErrorModel from "../errormodel";
import Swal from "sweetalert2";
import Loading from "../../../pre-loader/loader";
import { useTranslation } from "react-i18next";
import FaultParentPopup from "./fault-parent";
import AttachmentTable from "../attachmentTable";
import createTicketService from "services/createTicketService";
import _ from "lodash";
import { priority } from "../shared/data";
import SingleSelect from "../shared/singleSelect";
import { ticketType } from "../shared/data";
import AddFollowers from "../addfollowers";
import ticketService from "services/ticketService";
import provisioningService from "services/provisioningService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DescriptionAndAssignSection from "./shared/descriptionAndAssignSection";
import FirstRow from "./shared/firstRow";
import SecondRow from "./shared/secondeRow";
import DraftDescription from "./shared/draftDescription";
import TicketDetailTab from "../shared/ticketDetailTabs";
import LogNoteAndMessage from "../shared/logNote&Message";
import TicketHistory from "../shared/ticketHistory";
import CircularProgress from "@mui/material/CircularProgress";

const FaultPopup = (props) => {
  const { t } = useTranslation();
  const contextStore = useContext(AuthContext);
  const [ticketstatusname, setTicketStatusName] = useState([]);
  const [displaytitle, setDisplayTitle] = useState("");
  const [errorpopup, setErrorPopup] = useState(false);
  const [messagenotes, setMessageNotes] = useState([]);
  const [active, setActive] = useState("log");
  const [statusChange, setStatusChange] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [faultpaprentpopup, setFaultParentPopup] = useState(false);
  const [ticketdata, setTicketData] = useState([]);
  const [sitename, setSiteName] = useState("");
  const [refreshstatus, setRefreshStatus] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [updateTicketDetails, setUpdateTicketDetails] = useState({
    description: "",
  });

  const [istypetrue, setistypetrue] = useState(true);

  const [services, setServices] = useState([]);
  const [allSpace, setAllSpace] = useState([]);
  const [DesignatedId, setDesignatedId] = useState("");
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const [showAttachment, setShowAttachment] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [payload, setPayload] = useState();
  const fileInputRef = useRef(null);
  const [devices, setDevices] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [filteredAssignees, setFilteredAssignees] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [state, setState] = useState({
    ticket_id: "",
    company: "",
    type: "",
    service_id: "",
    priority: "",
    description: "",
    subject: "",
    attachments: [],
    notes: [],
    ticket_status_id: "",
    ticket_status: "",
    messages: [],
    tickettypeid: "",
    team_id: "",
    assignto: "",
    designatedcompany: "",
    related_incident_ticket_id: "",
    impacted_device: "",
    customer_email: "",
    service_ids: "",
    space_ids: "",
    service: "",
    space: "",
    services_id: "",
    impacted_ids: "",
    ticket_type: "",
    logs: [],
    readiness_id: "",
    assignee: "",
    assignee_id: "",
    assignee_is_current_user: false,
    portal_code: "",
  });
  const [follower, setFollower] = useState([]);
  const [follows, setFollows] = useState(false);
  const [userFollow, setUserFollows] = useState("");
  const [addfollower, setAddFollower] = useState(false);
  const [isUpdateDescriptionList, setIsUpdateDescriptionList] = useState(false);
  const [draftDescription, setDraftDescription] = useState("");
  const [isTicketStatusLoading, setIsTicketStatusLoading] = useState(false);

  const handlePrint = () => {
    let followerDiv = document.querySelector("#follwerDropdown");
    followerDiv.classList.remove("dropdown-menu");
    followerDiv.classList.add("follow-dropdown-menu");
    let descriptionDiv = document.querySelector("#descriptionBox");
    descriptionDiv.setAttribute("style", "height:100%;word-break: break-all;");
    html2canvas(document.querySelector("#capture"), {
      windowHeight: document.querySelector("#content-area").scrollHeight + 500,
    }).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      window.open(
        doc.output("bloburl", { filename: "new-file.pdf" }),
        "_blank"
      );
      followerDiv.classList.add("dropdown-menu");
      descriptionDiv.setAttribute(
        "style",
        "height:150px;word-break: break-all;"
      );

      // doc.save('file.pdf');
    });
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#ff7a00",
      boxShadow: "0 0 0 1px #ff7a00",
      "&:hover": {
        borderColor: "#ff7a00", // Maintain the same border color on hover
        boxShadow: "0 0 0 1px #ff7a00", // Maintain the same box shadow on hover
      },
    }),
  };
  const handleChangeDescription = (e) => {
    let strippedString = e.target.value.replace(/(<([^>]+)>)/gi, "");
    setUpdateTicketDetails((prev) => ({
      ...prev,
      description: strippedString,
    }));
  };
  const getServiceIds = async (companyId, siteId, token) => {
    const getServiceIdsDto = await createTicketService.getServiceIds({
      token: token,
      companyId,
      siteId,
    });
    setServices(
      _.orderBy(getServiceIdsDto.data?.data, ["name"], ["asc"])
        .filter((service) => service.ns_is_remote_hands === false)
        .map((service) => {
          const { id, name } = service;
          return { ...service, label: name, value: id };
        })
    );
  };
  const getDevice = async (siteId, token) => {
    try {
      const result = await createTicketService.getDevices(token, siteId);
      let finalResult = result.data.map((data) => {
        const { id, name } = data;
        return { label: name, value: id };
      });
      setDevices(finalResult);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFollowList();
    getServiceIds(
      parseInt(props.ticketdata.designated_company_id),
      parseInt(props.ticketdata.operatingsiteid),
      localStorage.getItem("token")
    );
    getDevice(
      parseInt(props.ticketdata.operatingsiteid),
      localStorage.getItem("token")
    );
  }, []);

  useEffect(() => {
    if (props.show) {
      document
        .getElementById("main-model")
        .addEventListener("click", async function (e) {
          if (
            document.getElementById("content-area") != null &&
            document.getElementById("content-area").contains(e.target)
          ) {
            // Clicked in box
            // console.log(1);
          } else {
            // await Swal.fire({
            //   title: 'Are you sure?',
            //   text: 'Any changes made will not be saved.',
            //   icon: 'warning',
            //   showCancelButton: true,
            //   confirmButtonColor: '#3085d6',
            //   cancelButtonColor: '#d33',
            //   confirmButtonText: 'Yes'
            // }).then((res) => {
            //   if (res.isConfirmed) {
            //     closeModal();
            //   }
            // });
            // Clicked outside the box
          }
        });
    }
    if (props.ticketdata.messages === null) {
      props.ticketdata.messages = "";
    } else {
      props.ticketdata.messages.map((val) => {
        val.type = "message";
        return val;
      });
    }
    if (props.ticketdata.notes === null) {
      props.ticketdata.notes = "";
    } else {
      props.ticketdata.notes.map((val) => {
        val.type = "note";
        return val;
      });
    }
    if (props.ticketdata.logs === null) {
      props.ticketdata.logs = "";
    } else {
      props.ticketdata.logs.map((val) => {
        val.type = "logs";
        return val;
      });
    }
    let service_id = [];
    let space_id = [];
    if (
      props.ticketdata.site_access_services !== null &&
      props.ticketdata.site_access_services.length > 0
    ) {
      props.ticketdata.site_access_services.map((data) => {
        service_id.push(data.name);
      });
    }
    if (
      props.ticketdata.site_access_spaces !== null &&
      props.ticketdata.site_access_spaces.length > 0
    ) {
      props.ticketdata.site_access_spaces.map((data) => {
        space_id.push(data.name);
      });
    }
    setState({
      ticket_id: props.ticketdata.id,
      company: props.ticketdata.company,
      type: props.ticketdata.tickettype,
      service_id: props.ticketdata.serviceid,
      priority: props.ticketdata.priority,
      description: props.ticketdata.description,
      subject: props.ticketdata.subject,
      attachments: props.ticketdata.attachments,
      notes: [
        ...props.ticketdata.notes,
        ...props.ticketdata.messages,
        ...props.ticketdata.logs,
      ],
      ticket_status_id: props.ticketdata.ticket_statusid,
      ticket_status: props.ticketdata.ticket_status,
      messages: props.ticketdata.messages,
      tickettypeid: props.ticketdata.tickettypeid,
      team_id: props.ticketdata.teamid,
      assignto: state.assignto ? state.assignto : props.ticketdata.assignto,
      designatedcompany: props.ticketdata.designatedcompany,
      related_incident_ticket_id:
        props?.ticketdata?.details?.related_incident_ticket_id != null
          ? props?.ticketdata?.details?.related_incident_ticket_id
          : "-",
      impacted_device: props?.ticketdata?.details?.impacted_device,
      customer_email: props.ticketdata.partner_email,
      service_ids: service_id.length > 0 ? service_id.toString() : "-",
      space_ids: space_id.length > 0 ? space_id.toString() : "-",
      service: props.ticketdata.details.service,
      space: props.ticketdata.details.space,
      services_id: {
        value: props.ticketdata?.details?.service_id,

        label: props.ticketdata?.details?.service,
        space: props.ticketdata?.details?.space,
      },
      impacted_ids: props.ticketdata?.details?.impacted_device_id,

      ticket_type: props.ticketdata?.tickettypeid,
      priorityid: parseInt(props.ticketdata.priorityid),
      readiness_id: props.ticketdata.readiness_id,
      assignee: props.ticketdata.assignto,
      assignee_id: props.ticketdata.assignto_id,
      assignee_is_current_user: state.assignee_is_current_user
        ? state.assignee_is_current_user
        : props.ticketdata.assignee_is_current_user,
      portal_code: props.ticketdata.portal_code,
    });
    setUpdateTicketDetails({
      description: props.ticketdata.description,
      service_id: props.ticketdata.details.service_id
        ? props.ticketdata.details.service_id
        : undefined,
      priority_id: parseInt(props.ticketdata.priorityid),
    });

    setStatusChange(false);
  }, [props.show, statusChange, toggleEdit, props.ticketdata]);
  useEffect(() => {
    ticketStatus(props?.ticketdata?.teamid, props?.ticketdata?.tickettypeid);
  }, [props.show]);
  const updateTicketDetail = async (status) => {
    setIsLoading(true);
    await ticketDetails
      .getTicketDetail(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        //setTicketData(res?.data?.data);
        if (!res?.data?.data) {
          setErrorPopup(true);
        }

        let ticket_Detail = res?.data?.data;
        if (ticket_Detail.messages === null) {
          ticket_Detail.messages = "";
        } else {
          ticket_Detail.messages.map((val) => {
            val.type = "message";
            return val;
          });
        }
        if (ticket_Detail.attachments === null) {
          ticket_Detail.attachments = [];
        } else {
          ticket_Detail.attachments.map((val) => {
            val.type = "attachments";
            return val;
          });
        }
        if (ticket_Detail.notes === null) {
          ticket_Detail.notes = "";
        } else {
          ticket_Detail.notes.map((val) => {
            val.type = "note";
            return val;
          });
        }
        if (ticket_Detail.logs === null) {
          ticket_Detail.logs = "";
        } else {
          ticket_Detail.logs.map((val) => {
            val.type = "logs";
            return val;
          });
        }
        let service_id = [];
        let space_id = [];
        if (
          ticket_Detail.site_access_services !== null &&
          ticket_Detail.site_access_services.length > 0
        ) {
          ticket_Detail.site_access_services.map((data) => {
            service_id.push(data.name);
          });
        }
        if (
          ticket_Detail.site_access_spaces !== null &&
          ticket_Detail.site_access_spaces.length > 0
        ) {
          ticket_Detail.site_access_spaces.map((data) => {
            space_id.push(data.name);
          });
        }
        setState({
          ticket_id: ticket_Detail.id,
          company: ticket_Detail.company,
          type: ticket_Detail.tickettype,
          service_id: ticket_Detail.serviceid,
          priority: ticket_Detail.priority,
          description: ticket_Detail.description,
          subject: ticket_Detail.subject,
          attachments: ticket_Detail.attachments,
          notes: [
            ...ticket_Detail.notes,
            ...ticket_Detail.messages,
            ...ticket_Detail.logs,
          ],
          ticket_status_id: ticket_Detail.ticket_statusid,
          ticket_status: ticket_Detail.ticket_status,
          messages: ticket_Detail.messages,
          tickettypeid: ticket_Detail.tickettypeid,
          team_id: ticket_Detail.teamid,
          assignto: ticket_Detail.assignto,
          designatedcompany: ticket_Detail.designatedcompany,
          related_incident_ticket_id:
            ticket_Detail?.details?.related_incident_ticket_id != null
              ? ticket_Detail?.details?.related_incident_ticket_id
              : "-",
          impacted_device: ticket_Detail?.details?.impacted_device,
          customer_email: ticket_Detail.partner_email,
          service_ids: service_id.length > 0 ? service_id.toString() : "-",
          space_ids: space_id.length > 0 ? space_id.toString() : "-",
          service: ticket_Detail.details.service,
          space: ticket_Detail.details.space,
          services_id: {
            value: ticket_Detail?.details?.service_id,

            label: ticket_Detail?.details?.service,
            space: ticket_Detail?.details?.space,
          },
          impacted_ids: ticket_Detail?.details?.impacted_device_id,
          ticket_type: ticket_Detail?.tickettypeid,
          priorityid: parseInt(ticket_Detail.priorityid),
          readiness_id: ticket_Detail.readiness_id,
          assignee: ticket_Detail.assignto,
          assignee_id: ticket_Detail.assignto_id,
          assignee_is_current_user: ticket_Detail.assignee_is_current_user,
          portal_code: ticket_Detail.portal_code,
        });
        setUpdateTicketDetails({
          description: ticket_Detail.description,
          service_id: ticket_Detail.details.service_id
            ? ticket_Detail.details.service_ids
            : undefined,
          priority_id: parseInt(ticket_Detail.priorityid),
        });
        if (status) {
        } else {
          Swal.fire({
            title: "Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "confirmBtn",
            },
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateStage = async (stageId, selectedkey) => {
    let currentStatus;
    ticketstatusname &&
      ticketstatusname.map((data, key, elements) => {
        if (state.ticket_status_id == data?.stage_id) {
          currentStatus = key;
        }
      });

    if (selectedkey <= currentStatus) {
      await Swal.fire("Error", "You can't select the previous stage.");
    } else {
      Swal.fire({
        text: t("common.confirm_status_update"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          await ticketDetails
            .updateStage(
              localStorage.getItem("token"),
              props.ticketdata.id,
              stageId
            )
            .then((res) => {
              if (
                res?.data?.code !== undefined ||
                res?.data?.error !== undefined
              ) {
                // console.log("here")
                setErrorPopup(true);

                //setStatusChange(true)
              } else {
                updateTicketDetail(false);
                // Swal.fire("Updated Successfully.");
                // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
              }
            })

            .catch((err) => {
              console.log(err.error.response);
              setErrorMsg(err.error.response.data.error);
              setErrorPopup(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    }
  };

  useEffect(() => {
    getAssignees();
  }, []);
  const getAssignees = async () => {
    setIsLoading(true);
    await provisioningService
      .getAssignees(localStorage.getItem("token"))
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);

          //setStatusChange(true)
        } else {
          setAssignees(res.data.data);
          setFilteredAssignees(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const HandleSearch = (val) => {
    setSearchValue(val);
    setFilteredAssignees(filterByValue(assignees, val));
  };
  const handleDiscard = () => {
    setToggleEdit(false);
    setUpdateTicketDetails((prev) => ({
      ...prev,
      description: props.ticketdata.description,
    }));
  };
  const filterByValue = (array, value) => {
    return array.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  const assignTaskTo = async (email) => {
    setIsLoading(true);
    await ticketDetails
      .assignToMe(localStorage.getItem("token"), props.ticketdata.id, {
        email: email,
      })
      .then((res) => {
        console.log("test", res.data);
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
        } else {
          updateTicketDetail(false);
          // Swal.fire("Updated Successfully.");
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const unassignTaskTo = async () => {
    setIsLoading(true);
    const assignee = assignees.filter(
      (assignee) => assignee.id === state.assignee_id
    );
    const email = assignee[0].email;
    await ticketDetails
      .unassignFromMe(localStorage.getItem("token"), props.ticketdata.id, {
        email: email,
      })
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
        } else {
          updateTicketDetail(false);
          // Swal.fire("Updated Successfully.");
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const [buttonText, setButtonText] = useState("Follow");
  const [icon, setIcon] = useState(follows ? "fas fa-check" : "black");
  const [buttonStyle, setButtonStyle] = useState({
    color: follows ? "#2cc970" : "black",
  });

  const follow = async () => {
    setIsLoading(true);
    await ticketService
      .subscribe(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          setFollows(true);
          getFollowList();
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const unfollow = async () => {
    setIsLoading(true);
    await ticketService
      .unsubscribe(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          setFollows(false);
          getFollowList();
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFollowList = async () => {
    setIsLoading(true);
    await ticketService
      .getFollower(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        const userfollow = res.data.data.find(
          (team) => team.name === contextStore.getAuth?.name
        );
        const userExists = Boolean(userfollow);
        if (userExists) {
          setFollows(true);
          setButtonText("Following");
          setIcon("fas fa-check");
          setButtonStyle({ color: "#2cc970" });
        } else {
          setFollows(false);
          setButtonText("Follow");
          setIcon("");
          setButtonStyle({ color: "black" });
        }
        setFollower(res.data.data);
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const delfollower = async (contact_id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "you want to remove follower.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        ticketService
          .removeFollower(
            localStorage.getItem("token"),
            props.ticketdata.id,
            contact_id
          )
          .then((res) => {
            getFollowList();
          })
          .catch((err) => {
            console.log(err.error.response);
            setErrorMsg(err.error.response.data.error);
            setErrorPopup(true);
          })
          .finally(() => {});
      }
    });
  };
  const readinessChange = async (readiness) => {
    if (getMaintenanceModeStatus()) {
      // console.log("readinessChange",readiness)
      setIsLoading(true);
      await ticketService
        .updateReadiness(
          localStorage.getItem("token"),
          props.ticketdata.id,
          readiness
        )
        .then((res) => {
          if (res?.data?.data?.success === undefined) {
            setErrorMsg(res.data);
            setErrorPopup(true);
          } else {
            updateTicketDetail(false);
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const sendMessage = async () => {
    setIsLoading(true);
    if (active == "message") {
      await ticketDetails
        .createMessage(localStorage.getItem("token"), props.ticketdata.id, {
          message: message,
          attachments: fileData,
        })
        .then((res) => {
          if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
            // console.log("here")
            setErrorPopup(true);
            //setStatusChange(true)
          } else {
            // setState({...state, notes:[...state.notes,
            //     {
            //         author_id:"Rolland Wong",
            //         body:"<p>"+message+"</p>",
            //         changedDate:"15-Feb-2023 15:21 HKT",
            //         date:"2023-02-15 07:21:48",
            //         id:331642}]})
            updateTicketDetail(false);
            // Swal.fire("Updated Successfully.");
            setMessage("");
            setFileData([]);
            setShowAttachment([]);

            //props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await ticketDetails
        .createNote(localStorage.getItem("token"), props.ticketdata.id, {
          message: message,
          attachments: fileData,
        })
        .then((res) => {
          if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
            // console.log("here")
            setErrorPopup(true);
            //setStatusChange(true)
          } else {
            updateTicketDetail(false);
            //  Swal.fire("Updated Successfully.");
            setMessage("");
            setFileData([]);
            setShowAttachment([]);
            // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const closeModal = () => {
    props.setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handleExitbutton = async () => {
    await Swal.fire({
      title: "Are you sure?",
      text: "Any changes made will not be saved.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        closeModal();
      }
    });
  };
  const convertDate = (startTime) => {
    let end = moment(new Date());
    var duration = moment.duration(end.diff(startTime));
    var totalTime = duration.asHours();
    if (totalTime > 24) {
      totalTime = Math.floor(duration.asDays()) + " day(s)";
    } else {
      totalTime = Math.floor(totalTime) + " hour(s)";
    }

    return totalTime;
  };
  const ticketStatus = async (id, typeid) => {
    setIsTicketStatusLoading(true);
    await ticketDetails
      .getTicketStatus(localStorage.getItem("token"), id, typeid)
      .then((res) => {
        setDisplayTitle(res?.data?.data[0]?.TicketTypeName);
        setTicketStatusName(res?.data?.data);
        setIsTicketStatusLoading(false);

        // if(res?.data?.ticketType?.name.slice(-1) === "s"){
        //     setDisplayTitle(res?.data?.ticketType?.name.slice(0,-1))
        // }else{
        //     setDisplayTitle(res?.data?.ticketType?.name)
        // }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
        setIsTicketStatusLoading(false);
      })
      .finally(() => setIsTicketStatusLoading(false));
  };
  const handleModalPopupOnClick = async (
    site,
    tid,
    ticket_type_id,
    subname
  ) => {
    await ticketDetails
      .getTicketDetail(localStorage.getItem("token"), tid)
      .then((res) => {
        setTicketData(res?.data?.data);
        if (!res?.data?.data) {
          setErrorPopup(true);
        }
      });
    setSiteName(site);
    setFaultParentPopup(true);
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      encodeFileToBase64(file, async (err, base64) => {
        setPayload({
          file_name: file.name,
          content: base64,
        });

        await attechments({
          file_name: file.name,
          content: base64,
        }).then(() => {
          if (i + 1 == event.target.files.length) {
            updateTicketDetail(true);
          }
        });
      });
    }
  };

  const attechments = async (imagedata) => {
    setIsLoading(true);

    await ticketDetails
      .addAttechments(
        localStorage.getItem("token"),
        props.ticketdata.id,
        imagedata
      )
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          let Fdata = res.data.data.id;
          if (active === "log" || active === "message") {
            setShowAttachment((prev) => {
              let file = { name: imagedata.file_name, id: res.data.data.id };
              return [...prev, file];
            });
            setFileData([...fileData, Fdata]);
          }
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally((done) => setIsLoading(false));
  };

  const getAttachment = async (attachmentId, fileName) => {
    setIsLoading(true);
    await ticketDetails
      .getAttachment(
        localStorage.getItem("token"),
        props.ticketdata.id,
        attachmentId
      )
      .then((res) => {
        if (res?.data.data === undefined) {
          // console.log("here")
          setErrorPopup(true);
          setErrorMsg(res.data);
          //setStatusChange(true)
        } else {
          // setToggleEdit(false);
          // updateTicketDetail(false);
          handleDownload(res.data.data.data, fileName);
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const encodeFileToBase64 = (file, callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result.split(",")[1];
      callback(null, base64Data);
    };

    reader.onerror = (error) => {
      callback(error);
    };

    reader.readAsDataURL(file);
  };
  const handleDownload = (base64String, fileName, filetype) => {
    // Convert the base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteArrays = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteArrays]);

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.target = "_blank"; // Open the link in a new tab/window
    link.textContent = "Click here to download"; // Set the link text

    // Append the link to the document
    link.download = fileName;

    link.click();

    URL.revokeObjectURL(blobUrl);

    // const decodedData = atob(base64String);
    // console.log(decodedData)
    // const blob = new Blob([decodedData], { type: 'application/octet-stream' });

    // const downloadUrl = URL.createObjectURL(blob);

    // const link = document.createElement('a');
    // link.href = downloadUrl;
    // link.download = fileName;

    // link.click();

    // URL.revokeObjectURL(downloadUrl);
  };
  const checkPermission = (permissionName) => {
    if (permissionName) {
      const isPermitted = props.permissions.filter(
        (el) => el.name == permissionName && el.is_allowed == 1
      );

      if (isPermitted.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

    return false;
  };
  const updateTicket = async () => {
    console.log(updateTicketDetails);
    setIsLoading(true);
    await ticketDetails
      .updateTicketDetails(
        localStorage.getItem("token"),
        props.ticketdata.id,
        props.ticketdata.tickettypeid,
        updateTicketDetails
      )
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          // console.log("here")
          setErrorPopup(true);

          //setStatusChange(true)
        } else {
          setToggleEdit(false);
          // updateTicketDetail(false);
          props.getTicketDetail(
            props.ticketdata.operatingsite,
            props.ticketdata?.id,
            props.ticketdata?.tickettypeid,
            props.ticketdata?.ticket_sub_type_name,
            props.ticketdata?.tickettype
          );
          Swal.fire({
            title: "Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "confirmBtn",
            },
          });
          setIsUpdateDescriptionList(true);

          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelAttach = async (i) => {
    setIsLoading(true);
    try {
      const delData = await ticketDetails.delAttechments(
        localStorage.getItem("token"),
        state.ticket_id,
        i
      );
      if (delData) {
        updateTicketDetail();
        setShowAttachment((prev) => [...prev.filter((data) => data.id !== i)]);
        setFileData((prev) => prev.filter((data) => data !== i));
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const replace = (description) => {
    let result = description.replace(/<div>/g, " \n");
    result = result.replace(/<\/div>/g, "");
    result = result.replace(/<br \/>/g, "\n");
    result = result.replace(/<br>/g, "\n");
    result = result.replace(/&nbsp;/g, " ");
    console.log(result);
    return result;
  };
  const separateArrayDateWise = (history) => {
    const data = history.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return d - c;
    });
    // Use reduce to separate array based on date
    const separatedByDate = data.reduce((acc, obj) => {
      const date = moment(obj.date).format("MMMM DD, YYYY");
      // If the date key doesn't exist in the accumulator, create an array for it
      if (!acc[date]) {
        acc[date] = [];
      }

      // Push the current object to the array corresponding to its date
      acc[date].push(obj);

      return acc;
    }, {});

    const mappedData = Object.entries(separatedByDate).map(([date, items]) => ({
      date,
      items,
    }));
    return mappedData;
  };
  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (days >= 7) {
      if (days <= 13) {
        result = `a week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  const getMaintenanceModeStatus = () => {
    const status = contextStore.getMaintenanceModeStatus;
    if (status && status?.status_current == 3) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      class="modal show bd-example-modal-lg"
      id="main-model"
      style={{ display: "block" }} /*onClick={()=> closeModal()}*/
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.4)"
        loaderColor="rgb(248, 153, 3)"
      />
      <div class="modal-dialog modal-xl lay-2-3pop" id="capture">
        <div class="modal-content fault-content" id="content-area">
          <FirstRow
            state={state}
            handleChange={readinessChange}
            isEdit={toggleEdit}
            site={props?.sitename || ""}
            permission={checkPermission() && getMaintenanceModeStatus()}
            priority={priority}
            updateTicketDetail={updateTicketDetails}
            isTypeTrue={istypetrue}
            setUpdateTicketDetails={setUpdateTicketDetails}
            handleExit={handleExitbutton}
          ></FirstRow>
          <SecondRow
            title={displaytitle ? displaytitle : ""}
            type={"(Ticket Type - " + state?.type + ")"}
            refresh={updateTicketDetail}
            handlePrint={handlePrint}
            permission={checkPermission}
          ></SecondRow>

          <div>
            <div class="paging_tab">
              {ticketstatusname && !isTicketStatusLoading ? (
                ticketstatusname.map((data, key, elements) => (
                  // console.log(elements[key].stage_id);
                  <div class="item3" key={key}>
                    <button
                      className={
                        state.ticket_status_id == data?.stage_id
                          ? `btn btn-light tab-button-style tab-btn3 btstyle${key} t-menu-active`
                          : `btn btn-light tab-button-style tab-btn3 btstyle${key}`
                      }
                      onClick={() => {
                        if (checkPermission("Change Ticket Status")) {
                          updateStage(data?.stage_id, key);
                        }
                      }}
                    >
                      {data?.stage_name}{" "}
                    </button>
                    {state.ticket_status_id == data?.stage_id ? (
                      <img
                        src={
                          elements[0]?.stage_id == state.ticket_status_id
                            ? ""
                            : "img/second.svg"
                        }
                        alt=""
                        className="imgstyle-ststus"
                      />
                    ) : elements[key - 1]?.stage_id ==
                      state.ticket_status_id ? (
                      <img
                        src="img/first.svg"
                        alt=""
                        className="imgstyle-ststus"
                      />
                    ) : (
                      <img
                        src="img/third.svg"
                        alt=""
                        className="imgstyle-ststus"
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="statusLoadingIndicator">
                  {" "}
                  <CircularProgress
                    size={30}
                    style={{ marginRight: "1rem" }}
                  />{" "}
                  Loading status...
                </div>
              )}
            </div>
          </div>
          <div class="modal-body" style={{ paddingTop: "1rem" }}>
            <div class="" style={{ height: "cal(100%-0%)" }}>
              <div class="" style={{ padding: "0px" }}>
                <div class="main_fault">
                  <div className="card-body" style={{ overflow: "hidden" }}>
                    <div class="card-details">
                      <div class="row">
                        <DescriptionAndAssignSection
                          title={
                            "(#" +
                              state?.ticket_id +
                              ")" +
                              " " +
                              state?.subject || ""
                          }
                          assigneeName={
                            state.assignto
                              ? state.assignto === "OPS Portal Bot"
                                ? `Ops Portal Bot (${state.activity_user})`
                                : state.assignto
                              : "N/A"
                          }
                          permission={checkPermission}
                          maintenanceStatus={getMaintenanceModeStatus()}
                          assigneeIsCurrentUser={state.assignee_is_current_user}
                          assignees={assignees}
                          handleAssign={assignTaskTo}
                          handleUnassign={unassignTaskTo}
                          assigneeId={state.assignee_id}
                          isEdit={toggleEdit}
                          setIsEdit={setToggleEdit}
                          description={
                            toggleEdit
                              ? updateTicketDetails.description.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )
                              : state?.description.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                ) || ""
                          }
                          handleChangeDescription={handleChangeDescription}
                          handleUpdate={updateTicket}
                          handleDiscard={handleDiscard}
                          draftDescription={draftDescription}
                        ></DescriptionAndAssignSection>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label
                            style={{ fontSize: "0.875rem", color: "#000000" }}
                          >
                            Customer :
                          </label>
                          <b class="ticket-data2">{state?.company || "-"}</b>
                        </div>
                        <div class="col-md-6">
                          <div className="d-flex">
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#000000",
                                }}
                              >
                                {t("ticket.type")}:
                              </label>
                            </div>

                            <div>
                              {toggleEdit &&
                              istypetrue &&
                              getMaintenanceModeStatus() ? (
                                <select
                                  style={{
                                    height: "35px",
                                    borderColor: "#ff7a00",
                                    boxShadow: "0 0 0 1px #ff7a00",
                                  }}
                                  defaultValue={parseInt(state.ticket_type)}
                                  onChange={(event) =>
                                    setUpdateTicketDetails((prev) => ({
                                      ...prev,
                                      ticket_type_id: parseInt(
                                        event.target.value
                                      ),
                                    }))
                                  }
                                >
                                  {ticketType.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <b class="ticket-data2">{state?.type || "-"}</b>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label
                            style={{ fontSize: "0.875rem", color: "#000000" }}
                          >
                            Customer Email :
                          </label>
                          <b class="ticket-data2">
                            {state?.customer_email || "-"}
                          </b>
                        </div>
                        <div class="col-md-6">
                          <label
                            style={{ fontSize: "0.875rem", color: "#000000" }}
                          >
                            Parent Ticket :
                          </label>
                          <b
                            class="ticket-data2"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleModalPopupOnClick(
                                props?.sitename,
                                state?.related_incident_ticket_id,
                                state?.tickettypeid
                              )
                            }
                          >
                            {state?.related_incident_ticket_id || "-"}
                          </b>
                        </div>
                        <div class="col-md-6">
                          <label
                            style={{ fontSize: "0.875rem", color: "#000000" }}
                          >
                            {t("ticket.designated_company")}:
                          </label>
                          <b class="ticket-data2">
                            {state?.designatedcompany || "-"}
                          </b>
                        </div>
                        <div class="col-md-6">
                          <div className="d-flex">
                            <div>
                              <label
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#000000",
                                }}
                              >
                                {t("ticket.services_id")}:
                              </label>
                            </div>

                            <div>
                              {toggleEdit &&
                              istypetrue &&
                              getMaintenanceModeStatus() ? (
                                <>
                                  <SingleSelect
                                    options={[
                                      { label: "Select Option", value: null },
                                      ...services,
                                    ]}
                                    customStyles={customStyles}
                                    setAllSpace={setAllSpace}
                                    setUpdateTicketDetails={
                                      setUpdateTicketDetails
                                    }
                                    value={state?.services_id}
                                  />
                                </>
                              ) : (
                                <b class="ticket-data2">
                                  {state?.service || "-"}
                                </b>
                              )}
                            </div>
                          </div>
                        </div>
                        {state?.portal_code ? (
                          <div class="col-md-12">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              Portal Code :
                            </label>
                            <b class="ticket-data2">
                              {state?.portal_code || "-"}
                            </b>
                          </div>
                        ) : (
                          " "
                        )}
                        <div class="col-md-6">
                          <div>
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <div>
                                {" "}
                                <label
                                  style={{
                                    fontSize: "0.875rem",
                                    color: "#000000",
                                  }}
                                >
                                  Impacted Device :
                                </label>
                              </div>
                              <div>
                                {toggleEdit &&
                                istypetrue &&
                                getMaintenanceModeStatus() ? (
                                  <select
                                    style={{
                                      height: "35px",
                                      borderColor: "#ff7a00",
                                      boxShadow: "0 0 0 1px #ff7a00",
                                    }}
                                    defaultValue={parseInt(state.impacted_ids)}
                                    onChange={(event) =>
                                      setUpdateTicketDetails((prev) => ({
                                        ...prev,
                                        impacted_device_id: parseInt(
                                          event.target.value
                                        ),
                                      }))
                                    }
                                  >
                                    <option value="">Select option</option>
                                    {devices.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  <b class="ticket-data2">
                                    {state?.impacted_device || ""}
                                  </b>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label
                            style={{ fontSize: "0.875rem", color: "#000000" }}
                          >
                            Space ID :
                          </label>
                          <b class="ticket-data2">
                            {toggleEdit &&
                            istypetrue &&
                            getMaintenanceModeStatus()
                              ? allSpace.join(", ")
                              : state?.space || "-"}
                          </b>
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col-md-12">
                          <div style={{ position: "relative" }}>
                            <TicketDetailTab
                              setActive={setActive}
                              active={active}
                              permission={checkPermission}
                              follows={follows}
                              unfollow={unfollow}
                              follow={follow}
                              setButtonText={setButtonText}
                              setIcon={setIcon}
                              setButtonStyle={setButtonStyle}
                              icon={icon}
                              buttonStyle={buttonStyle}
                              buttonText={buttonText}
                              setAddFollower={setAddFollower}
                              delfollower={delfollower}
                              follower={follower}
                              ticketType="fault"
                            ></TicketDetailTab>

                            {active != "attachment" && active != "history" && (
                              <LogNoteAndMessage
                                permission={checkPermission}
                                setMessage={setMessage}
                                message={message}
                                handleImageClick={handleImageClick}
                                fileInputRef={fileInputRef}
                                handleFileChange={handleFileChange}
                                sendMessage={sendMessage}
                                active={active}
                                history={
                                  active == "message"
                                    ? state?.messages
                                    : state?.notes.length > 0
                                    ? state?.notes.filter(
                                        (el) => el.type == "note"
                                      )
                                    : []
                                }
                                separateArrayDateWise={separateArrayDateWise}
                                getTime={getTime}
                                getAttachment={getAttachment}
                                handleDelAttach={handleDelAttach}
                              ></LogNoteAndMessage>
                            )}
                            {active == "attachment" && (
                              <AttachmentTable
                                attachments={state.attachments}
                                ticketId={props.ticketdata.id}
                                setErrorMsg={setErrorMsg}
                                setErrorPopup={setErrorPopup}
                                handleDownload={handleDownload}
                                setIsLoading={setIsLoading}
                                handleDelAttach={handleDelAttach}
                                handleFileChange={handleFileChange}
                                fileInputRef={fileInputRef}
                                handleImageClick={handleImageClick}
                                permission={checkPermission}
                              ></AttachmentTable>
                            )}
                            {active == "history" && (
                              <TicketHistory
                                notes={state.notes}
                                separateArrayDateWise={separateArrayDateWise}
                                getTime={getTime}
                                getAttachment={getAttachment}
                                handleDelAttach={handleDelAttach}
                              ></TicketHistory>
                            )}
                          </div>
                        </div>
                      </div>

                      {showAttachment.length > 0 && (
                        <div className="show-Attach-outer">
                          {" "}
                          <div className="attach-outer">Attachments</div>
                          <div className="file-name-box">
                            {showAttachment.map((val, index) => {
                              return (
                                <div className="down-file" key={index}>
                                  <div>{val.name}</div>{" "}
                                  <div className="down-file-inner">
                                    <div>
                                      <img
                                        src="images/fclose.svg"
                                        onClick={() => handleDelAttach(val.id)}
                                        width={8}
                                        alt=""
                                        style={{ paddingTop: "2px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {addfollower && (
            <AddFollowers
              show={addfollower}
              setShow={setAddFollower}
              ticketId={props.ticketdata.id}
              getFollowList={getFollowList}
            />
          )}
          {errorpopup && (
            <ErrorModel
              show={errorpopup}
              setShow={setErrorPopup}
              errorMsg={errorMsg}
            />
          )}
          {ticketdata
            ? faultpaprentpopup && (
                <FaultParentPopup
                  show={faultpaprentpopup}
                  setShow={setFaultParentPopup}
                  ticketdata={ticketdata}
                  sitename={sitename}
                />
              )
            : errorpopup && (
                <ErrorModel
                  show={errorpopup}
                  setShow={setErrorPopup}
                  errorMsg={errorMsg}
                />
              )}
        </div>
      </div>
    </div>
  );
};
export default FaultPopup;
