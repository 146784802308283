import { useState, useContext } from "react"
import DownloadIcon from "@mui/icons-material/Download"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import Tooltip from "@mui/material/Tooltip"
import AuthContext from "context"
import moment from "moment"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import Papa from "papaparse"
import bcmService from "services/bcmService"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  height: "20rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}

export const DownloadPopup = ({ name, setIsLoading }) => {
  const authContext = useContext(AuthContext)
  const [bcmRawData, setRawBCMData] = useState()
  const [download, setDownload] = useState("none")
  const [noData, setNoData] = useState("none")
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [breaker, setBreaker] = useState("")
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [year, setYear] = useState(new Date().getFullYear())

  const renderMonth = () => {
    const months = []
    for (let i = 1; i <= 12; i++) {
      months.push(moment(i, "M").format("MMM"))
    }
    return months.map((m, key) => (
      <option
        value={key + 1}
        key={key}
        disabled={isFutureMonth(key)}
        className={isFutureMonth(key) ? "disabled_months" : ""}
      >
        {m}
      </option>
    ))
  }

  const isFutureMonth = (key) => {
    if (year === moment().year()) {
      if (key <= moment().month()) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  const renderYear = () => {
    const years = []
    for (let i = 2024; i <= moment().format("YYYY"); i++) {
      years.push(moment(i, "YYYY").format("YYYY"))
    }
    return years.map((y, key) => (
      <option value={y} key={y}>
        {y}
      </option>
    ))
  }

  // fetching data from jdbc
  const getRawBCMData = async () => {
    setIsLoading(true)
    await bcmService
      .getRawBCMData(authContext.token(), breaker, year, month)
      .then((res) => {
        if (res.data.data.length === 0 || res.data.data === undefined) {
          setNoData("block")
        } else {
          setRawBCMData(res.data.data)
          setDownload(true)
          setNoData("none")
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // converting to zip and downloading
  const handleDownload = async () => {
    try {
      const csvData = Papa.unparse(bcmRawData)

      const zip = new JSZip()

      // Adding data to file
      zip.file(`${breaker}.csv`, csvData)

      const content = await zip.generateAsync({ type: "blob" })

      saveAs(content, `${breaker}.zip`)
    } catch (err) {
      console.error(
        `Error creating and downloading the ${breaker}.zip file`,
        err
      )
    }
  }

  return (
    <>
      <Modal
        sx={{ zIndex: 10 }}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            id="modal-modal-title"
            component="h2"
            sx={{ fontWeight: "bold", fontSize: "2rem", letterSpacing: "3px" }}
          >
            Raw Data Download
            <button
              style={{ background: "transparent", border: "none" }}
              onClick={() => {
                setDownload("none")
                handleClose()
              }}
            >
              <CloseIcon fontSize="large" />
            </button>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ fontSize: "1.5rem", letterSpacing: "2px" }}
          >
            {name}
          </Typography>

          {/* buttons div */}
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="choose_date">
              <select
                style={{ cursor: "pointer", width: "6rem", fontSize: "1.2rem" }}
                className="form-select w-3rem"
                aria-label="Default select example"
                onChange={(e) => {
                  setDownload("none")
                  setMonth(e.target.value)
                }}
                value={month}
              >
                {renderMonth()}
              </select>
            </div>

            <div className="choose_date">
              <select
                style={{ cursor: "pointer", width: "6rem", fontSize: "1.2rem" }}
                className="form-select w-3rem"
                aria-label="Default select example"
                onChange={(e) => {
                  if (e.target.value === moment().year()) {
                    setMonth(authContext.getMonthYear.month - 1)
                  }
                  setDownload("none")
                  setYear(e.target.value)
                }}
                value={year}
              >
                {renderYear()}
              </select>
            </div>

            <Tooltip title="Get Data" arrow placement="top">
              <button
                onClick={getRawBCMData}
                style={{
                  backgroundColor: "rgb(17, 38, 60)",
                  color: "white",
                  padding: ".5rem 1rem",
                  border: "none",
                  width: "6rem",
                  fontSize: "1.2rem",
                }}
              >
                GO
              </button>
            </Tooltip>

            <button
              className="excel_icon cap_icon"
              style={{
                background: "transparent",
                border: "none",
                display: download,
              }}
              onClick={handleDownload}
            >
              <Tooltip title="Download" arrow placement="top">
                <img alt="excel" src="\images\excel.png" width="40px" />
              </Tooltip>
            </button>
          </div>

          {/* err text */}
          <h4
            style={{
              color: "rgb(245, 78, 78)",
              fontSize: "1rem",
              marginTop: "1rem",
              display: noData,
            }}
          >
            Raw BCM Data not available for{" "}
            <span
              style={{ display: "block", color: "red", fontWeight: "bold" }}
            >
              {breaker}
            </span>
          </h4>
        </Box>
      </Modal>
      <button
        onClick={() => {
          setBreaker(name)
          handleOpen()
        }}
        style={{ background: "transparent", border: "none" }}
      >
        <DownloadIcon />
      </button>
    </>
  )
}
