import AuthContext from 'context'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateTicketService from 'services/createTicketService'
import Swal from 'sweetalert2'

const DIMENSIONS_TABLE = 'vw_uom'

const useGetDimensions = () => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  const [weightDimensions, setWeightDimensions] = useState([])
  const [lengthDimensions, setLengthDimensions] = useState([])

  const getValues = (dto, name) => {
    return _.orderBy(dto
      .filter(item => item.name === name)
      .map(item => {
        const { value, id } = item
        return { ...item, label: value, value: id }
      }), ['label'], ['asc'])
  }

  const getHandlingInstructions = async () => {
    try {
      const getDimensionDto = await CreateTicketService
        .getTableContent(authContext.token(), DIMENSIONS_TABLE)
      setWeightDimensions(
        getValues(getDimensionDto, 'Weight')
      )
      setLengthDimensions(
        getValues(getDimensionDto, 'Length / Distance')
      )
    } catch (err) {
      await Swal.fire(t('error'), err.message)
    }
  }

  useEffect(() => {
    getHandlingInstructions()
  }, [])

  return {
    weightDimensions,
    lengthDimensions
  }
}

export default useGetDimensions
