import {
  useMsal
} from "@azure/msal-react";
import StorageContext from "context";
import React, { useState } from "react";
import Loading from "react-fullscreen-loading";
import { useTranslation } from "react-i18next";
const Logout = () => {
  // let navigate = useNavigate();

  const contextData = React.useContext(StorageContext);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  // const [invisibleMenu, setInvisibleMenu] = React.useState(false);
  // const [state, setState] = React.useState({
  //   initialName: "",
  //   fullName: "",
  // });
  // const location = useLocation();
  // const isInitialMount = useRef(true);
  const { instance } = useMsal();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [contextData.getAuth]);

  const onLogout = (event) => {
    event.preventDefault();

    contextData.logout();

    instance.logoutPopup({
      postLogoutRedirectUri: "/",
    });
    //navigate(`/`);
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.87)"
        loaderColor="rgb(248, 153, 3)"
      />
      <a
        onClick={onLogout}
        style={{ cursor: "pointer" }}
        className="dropdown-item ai-icon"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-danger"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
          <polyline points="16 17 21 12 16 7"></polyline>
          <line x1="21" y1="12" x2="9" y2="12"></line>
        </svg>
        <span className="ms-2">{t('header.logout')} </span>
      </a>
    </React.Fragment>
  );
};

export default Logout;
