import AuthContext from 'context';
import { useContext, useEffect, useState } from 'react';
import CreateTicketService from 'services/createTicketService';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const useGetTicketTypes = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [ticketTypes, setTicketTypes] = useState([]);

  const getTicketTypes = async () => {
    const getTicketTypesDto = await CreateTicketService.getNsTicketSubtypes(authContext.token());
    const data = getTicketTypesDto.data?.data;
    // console.log(data)
    setTicketTypes(
      _.orderBy(data, ['name'], ['asc']).map((type) => {
        const { id, name } = type;
        return {
          ...type,
          label: t(`create-ticket.remote-hands.${name.split(' ').join('_')}`),
          value: id
        };
      })
    );
  };

  useEffect(() => {
    getTicketTypes();
  }, []);

  return {
    ticketTypes
  };
};

export default useGetTicketTypes;
