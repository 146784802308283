import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class UploadImages {

	/*
	|-------------------------------------------------------------------------------
	| 	UploadRack Elevation
	|-------------------------------------------------------------------------------
	*/
	async uploadRackElevation (token,data){

		return await axios(token).post(`UploadRackElevationImage`,data);
	}
	/*
	|-------------------------------------------------------------------------------
	| 	Upload Topology
	|-------------------------------------------------------------------------------
	*/
	async uploadTopologyElevation (token,data){

		return await axios(token).post(`UploadTopologyImage`,data);
	}
	/*
	|-------------------------------------------------------------------------------
	| 	Upload Fiber Map
	|-------------------------------------------------------------------------------
	*/
	async uploadFiberMapElevation (token,data){

		return await axios(token).post(`UploadFiberMapImage`,data);
	}
}

export default new UploadImages()