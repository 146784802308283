import { XError } from "components/common"
import AuthContext from "context"
import moment from "moment"
import React, { useContext, useEffect, useRef, useState } from "react"
// import Loading from "react-fullscreen-loading";
import Common from "services/commonService"
import networkServices from "services/networkServices"
import Swal from "sweetalert2"
import { LogoutOnSessionExpire } from "common/helpers"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

export default function EditNetwork(props) {
  const [isLoading, setIsLoading] = useState(false)
  const modalRef = useRef(null)
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const [hostName, setHostName] = useState("")
  const [makeid, setMake] = useState("")
  const [modelName, setModelName] = useState("")
  const [function_name, setFunctionName] = useState("")
  const [sn, setSN] = useState("")
  const [cabinet_id, setCabinetID] = useState("")
  const [device_status, setDeviceStatus] = useState("")
  const [top_u_position, setTopUPosition] = useState("")
  const [support_status, setSupportStatus] = useState("")
  const [support_expiry, setSupportExpiry] = useState("")
  const [makeData, setMakeData] = useState([])
  const [networkFunction, setNetworkFunction] = useState([])
  const [modelData, setModelData] = useState([])
  const [deviceStatusData, setDeviceStatusData] = useState([])
  const [cabinet, setCabinetData] = useState([])
  const [error, setError] = useState({
    name: "",
    status: "",
  })

  const closeModal = () => {
    setHostName("")
    setMake("")
    setModelName("")
    setSN("")
    setFunctionName("")
    setCabinetID("")
    setDeviceStatus("")
    setSupportExpiry("")
    setTopUPosition("")
    setSupportStatus("")

    modalRef.current.click()
    props.setShow(false)
  }
  useEffect(() => {
    setIsLoading(true)
    networkServices.getMake(authContext.getToken).then((res) => {
      setMakeData(res.data.data)
      setIsLoading(false)
    })
    //networkServices.getModel(authContext.getToken).then(res =>setModelData(res.data.data));

    setIsLoading(true)
    networkServices
      .getNetworkFunction(authContext.getToken)
      .then((res) => setNetworkFunction(res.data.data))

    //networkServices.getDeviceStatus(authContext.getToken).then(res =>setDeviceStatusData(res.data.data));
    // Common.status().then((res) => setDeviceStatusData(res.data.data))
    if (authContext.getStatusData) {
      setDeviceStatusData(authContext.getStatusData)
    }
    setIsLoading(false)

    //CabinetService.getAllCabinets(authContext.getToken).then(res => setCabinetData(res.data.data));

    setHostName(props.network_data.name)
    setMake(props.network_data.make)
    getModelById(props.network_data.make)
    setModelName(props.network_data.model)
    setSN(props.network_data.sn)
    setFunctionName(props.network_data.function)
    setCabinetID(props.network_data.cabinet_id)
    setDeviceStatus(props.network_data.device_status)
    setSupportExpiry(props.network_data.support_expiry)
    setTopUPosition(props.network_data.top_u_position)
    setSupportStatus(props.network_data.support_status)
    setCabinetData(props.cabinetData)
  }, [props.show, , props.cabinetData])

  // if (isLoading === true) {
  //   return (
  //     <Loading
  //       loading={isLoading}
  //       background="rgba(0, 0, 0, 0.87)"
  //       loaderColor="rgb(248, 153, 3)"
  //     />
  //   );
  // }

  const getModelById = async (id) => {
    setIsLoading(true)
    networkServices
      .getModel(authContext.getToken, id)
      .then((res) => setModelData(res.data.data))
    setIsLoading(false)
    if (id === "null") {
      setMake("")
    }
    setModelName("")
  }

  const checkSetValue = async (id) => {
    if (id === "null") {
      setModelName("")
    }
  }

  const deleteNetworkDevice = async () => {
    setIsLoading(true)
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await networkServices
          .deleteNetworkDevice(authContext.getToken, {
            id: props.network_data.id,
            data_center_id: props.network_data.data_center,
          })
          .then(async (res) => {
            setIsLoading(false)
            props.selectDataCenterFloor(props.data_center_id)
            closeModal()
            Swal.fire(t("network.network_device_deleted"))
          })
          .catch((err) => {
            LogoutOnSessionExpire(err)
            let error = {
              id: "",
              data_center_id: "",
            }
            const errors = err?.response?.data?.errors

            if (
              errors?.id !== undefined ||
              errors?.id !== "" ||
              errors?.id !== null
            ) {
              error.id = errors.id
            }

            if (
              errors?.data_center_id !== undefined ||
              errors?.data_center_id !== "" ||
              errors?.data_center_id !== null
            ) {
              error.data_center_id = errors.data_center_id
            }
            setError({ ...error })
          })
      }
    })
  }

  const onSubmit = async () => {
    setIsLoading(true)
    if (checkValidation()) {
      await networkServices
        .updateNetworkDevice(authContext.getToken, {
          id: props.network_data.id,
          name: hostName,
          desc: "",
          make: makeid,
          model: modelName,
          function_name: function_name,
          sn: sn,
          data_center: props.network_data.data_center,
          cabinet_id: cabinet_id,
          device_status: device_status,
          support_status: support_status,
          top_u_position: top_u_position,
          support_expiry: support_expiry,
        })
        .then(async (res) => {
          setIsLoading(false)
          props.selectDataCenterFloor(props.data_center_id)
          closeModal()
          Swal.fire(t("network.network_device_updated"))
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  const checkValidation = () => {
    let error = {
      make: "",
      model: "",
      function: "",
    }

    let flag = true

    if (makeid === "" || makeid === null || makeid === undefined) {
      error.make = "The make field is required."
      flag = false
    }
    if (modelName === "" || modelName === null || modelName === undefined) {
      error.model = "The model field is required."
      flag = false
    }
    if (
      function_name === "" ||
      function_name === null ||
      function_name === undefined
    ) {
      error.function = "The function field is required."
      flag = false
    }

    setError({ ...error })

    return flag
  }

  return (
    <div
      className="modal show bd-example-modal-lg"
      style={{ display: "block" }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59" style={{ marginBottom: "1rem" }}>
            <h3 className="modal-title">
              {t("network.update_device_details")}
            </h3>
            <button
              style={{
                cursor: "pointer",
                background: "transparent",
                border: "none",
              }}
              data-bs-dismiss="modal"
              ref={modalRef}
              onClick={() => props.setShow(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("network.hostname")}
                        </label>
                        <input
                          type="text"
                          value={hostName}
                          className="form-control"
                          onChange={(event) => setHostName(event.target.value)}
                          placeholder="Hostname"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("network.vendor")}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={makeid}
                          onChange={(event) => {
                            setMake(event.target.value)
                            getModelById(event.target.value)
                          }}
                        >
                          <option value="null">{t("network.choose")}...</option>
                          {makeData &&
                            makeData.map((m) => {
                              return (
                                <option key={m.id} value={m.id}>
                                  {m.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.make} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("network.function")}{" "}
                          <small className="text-danger">*</small>
                        </label>

                        <select
                          className="default-select form-control wide"
                          value={function_name}
                          onChange={(event) =>
                            setFunctionName(event.target.value)
                          }
                        >
                          <option value="">{t("network.choose")}...</option>
                          {networkFunction &&
                            networkFunction.map((nf) => {
                              return (
                                <option key={nf.id} value={nf.id}>
                                  {nf.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.function} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("network.model")}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={modelName}
                          onChange={(event) => {
                            setModelName(event.target.value)
                            checkSetValue(event.target.value)
                          }}
                        >
                          <option value="null">{t("network.choose")}...</option>
                          {modelData &&
                            modelData.map((md) => {
                              return (
                                <option key={md.id} value={md.id}>
                                  {md.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.model} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("network.serial_number")}
                        </label>
                        <input
                          type="text"
                          value={sn}
                          className="form-control"
                          onChange={(event) => setSN(event.target.value)}
                          placeholder={t("network.serial_number")}
                        />
                      </div>
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("network.cabinet_id")}
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={cabinet_id}
                          onChange={(event) => setCabinetID(event.target.value)}
                        >
                          <option>{t("network.choose")}...</option>
                          {cabinet &&
                            cabinet.map((md) => {
                              return (
                                <option key={md.id} value={md.id}>
                                  {md.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("network.status")}
                        </label>

                        <select
                          className="default-select form-control wide"
                          value={device_status}
                          onChange={(event) =>
                            setDeviceStatus(event.target.value)
                          }
                        >
                          <option>{t("network.choose")}...</option>
                          {deviceStatusData &&
                            deviceStatusData.map((status) => {
                              if (status.status_type_id === 2) {
                                return (
                                  <option value={status.id} key={status.id}>
                                    {status.status_name}
                                  </option>
                                )
                              }
                            })}
                        </select>
                      </div>
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {t("network.top_u_position")}
                        </label>
                        <input
                          type="text"
                          value={top_u_position}
                          className="form-control"
                          placeholder="Top U Position"
                          onChange={(event) =>
                            setTopUPosition(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <h5
                        className="mt-3"
                        style={{
                          color: "#147AD6",
                          fontWeight: "700",
                          marginBottom: "0px",
                        }}
                      >
                        {t("network.maintenance_and_support")}
                      </h5>
                      <div className="col-md-6 mt-2">
                        <label className="form-label">
                          {" "}
                          {t("network.status")}
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={support_status}
                          onChange={(event) =>
                            setSupportStatus(event.target.value)
                          }
                        >
                          <option>{t("network.choose")}...</option>
                          <option value={1}>Active</option>
                          <option value={2}>Inactive</option>
                        </select>
                      </div>
                      <div className="col-md-6 mt-2">
                        <label className="form-label">
                          {t("network.expiry_date")}
                        </label>
                        <input
                          type="date"
                          value={support_expiry}
                          className="form-control"
                          placeholder="Expiry Date"
                          onChange={(event) =>
                            setSupportExpiry(event.target.value)
                          }
                          onFocus="(this.type='date')"
                          min={moment(new Date()).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div
                      className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                      role="toolbar"
                    >
                      <div className="delt">
                        <button
                          type="button"
                          onClick={() => deleteNetworkDevice()}
                          className="btn btn-outline-primary mr_1 red_color"
                          style={{ marginRight: "1rem" }}
                        >
                          <img
                            alt=""
                            src="\images\trash-2.svg"
                            style={{
                              width: "11px",
                              marginTop: "-0.188rem",
                              marginRight: "0.5rem",
                            }}
                          />
                          {t("common.delete")}
                        </button>
                      </div>
                      <div className="usr_cncl">
                        <button
                          type="button"
                          onClick={closeModal}
                          className="btn btn-outline-primary mr_1"
                          style={{ marginRight: "1rem" }}
                        >
                          {" "}
                          {t("common.cancel")}{" "}
                        </button>
                        {isLoading ? (
                          <button type="button" className="btn btn-primary">
                            {" "}
                            {t("common.loading")} ...{" "}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => onSubmit()}
                            className="btn btn-primary"
                          >
                            {t("common.save")}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
