import React, { useContext, useEffect, useState } from 'react';
import CrossConnectService from 'services/crossConnectService';
import AuthContext from 'context';

const useGetAddConnectData = ({ dcId, countryId, state }) => {
  const {
    installedbase_id,
    cc_media_type_id,
    accounts_id,
    a_side_space_id,
    z_side_space_id,
    status,
    pmi,
    desc,
  } = state;

  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [mediaTypes, setMediaType] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [floors, setFloors] = useState([]);
  const [cabinets, setCabinets] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [aSideFloor, setASideFloor] = useState(null);
  const [aSideCabinets, setASideCabinets] = useState([]);
  const [zSideCabinets, setZSideCabinets] = useState([]);
  const [zSideFloor, setZSideFloor] = useState(null);

  const customerFilter = (c) => {
    const account = accounts.find(
      (acc) => acc.id === parseInt(accounts_id)
    );
    if (c.account_code && account.account_code === c.account_code) {
      return true;
    }
    if (!c.account_code && account.name === c.customer) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (cabinets) {
      setASideCabinets(cabinets);
      setZSideCabinets(cabinets);
      if (accounts_id) {
        setASideCabinets(cabinets.filter((c) => customerFilter(c)));
      }      
    }
  }, [cabinets]);


  useEffect(() => {
    if (aSideFloor && accounts_id) {
      setASideCabinets(
        cabinets
          .filter((c) => c.floor_id === parseInt(aSideFloor))
          .filter((c) => customerFilter(c))
      );
    } else if (aSideFloor) {
      setASideCabinets(
        cabinets.filter((c) => c.floor_id === parseInt(aSideFloor))
      );
    } else if (accounts_id) {
      setASideCabinets(cabinets.filter((c) => customerFilter(c)));
    } else {
      setASideCabinets(cabinets);
    }
  }, [aSideFloor, accounts_id]);

  useEffect(() => {
    if (zSideFloor) {
      setZSideCabinets(
        cabinets
          .filter((c) => c.floor_id === parseInt(zSideFloor))
          .filter((c) => c.id !== parseInt(a_side_space_id))
      );
    } else {
      setZSideCabinets(
        cabinets.filter((c) => c.id !== parseInt(a_side_space_id))
      );
    }
  }, [zSideFloor, a_side_space_id]);

  const get = async () => {
    const dto = await CrossConnectService.getAddCrossConnectsData({
      token: authContext.token(),
      dcId,
      countryId,
    });
    const dataObject = dto.data?.data;
    if (dataObject) {
      setAccounts(dataObject.accounts);
      setStatuses(dataObject.statuses);
      setFloors(dataObject.floors);
      setCabinets(dataObject.floorCabinets);
      setMediaType(dataObject.mediaTypes);
    }
  };

  useEffect(() => {
    if (dcId && countryId) {
      get();
    }
  }, []);
  return {
    mediaTypes,
    accounts,
    floors,
    cabinets,
    statuses,
    loading,
    aSideFloor,
    aSideCabinets,
    zSideCabinets,
    zSideFloor,
    setASideFloor,
    setZSideFloor,
  };
};

export default useGetAddConnectData;
