/* eslint-disable default-case */
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js"
import {
  S1,
  S2,
  S3,
  S4,
  Today,
  P0,
  P1,
  P2,
  P3,
  I,
  E,
  BlueColor,
  GreenColor,
  YellowColor,
  OrangeColor,
  GreenColor2,
  BlueColor2,
  YellowColor2,
} from "components/ColorTile"
import AuthContext from "context"
import React, { useContext, useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
ChartJS.register(ArcElement, Tooltip, Legend)

// custom tooltip function
function external(context) {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip")

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div")
    tooltipEl.id = "chartjs-tooltip"
    tooltipEl.innerHTML =
      "<div class='tooltip-arrow'></div><div class='tooltip-content'></div>"
    document.body.appendChild(tooltipEl)
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform")
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign)
  } else {
    tooltipEl.classList.add("no-transform")
  }

  function getBody(bodyItem) {
    let indicator = bodyItem.lines[0].split(":")[0] // text part
    let value = bodyItem.lines[0].split(":")[1] // the value
    return [indicator, value]
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || []
    const bodyLines = tooltipModel.body.map(getBody)

    let innerHtml = "<div>"

    titleLines.forEach(function (title) {
      innerHtml += "<tr><th>" + title + "</th></tr>"
    })
    innerHtml += "</div><span>"

    bodyLines.forEach(function (body, i) {
      const colors = tooltipModel.labelColors[i]
      let style = "background: black"
      let style2 = "background: " + colors.backgroundColor
      style2 += "; padding: .2rem .5rem"
      style2 += "; border-radius: .25rem"
      style += "; border-radius: .25rem"
      style += "; white-space: nowrap"
      if (
        bodyLines[0][0] === "Blocked" ||
        bodyLines[0][0] === "Reserved" ||
        bodyLines[0][0] === "ROFR"
      ) {
        style2 += "; color: white"
      } else {
        style2 += "; color: black"
      }
      if (bodyLines[0][0] === "Blocked") {
        style2 += "; border: 1px solid white"
      }
      style += "; padding: .5rem"
      style += "; color: white"
      style += "; font-size: 14px"
      const span =
        '<span style="' +
        style +
        '">' +
        '<span style="' +
        style2 +
        '">' +
        bodyLines[0][0] +
        "</span>" +
        " : " +
        bodyLines[0][1] +
        "</span>"
      innerHtml += "<span>" + span + "</span>"
    })
    innerHtml += "</span>"

    let tableRoot = tooltipEl.querySelector("div")
    tableRoot.innerHTML = innerHtml
  }

  const position = context.chart.canvas.getBoundingClientRect()
  const offset = 10

  // Set initial position near the cursor
  let leftPos = position.left + window.pageXOffset + tooltipModel.caretX + 30
  let topPos = position.top + window.pageYOffset + tooltipModel.caretY + -10

  // Display, position, and set styles for TEXT inside only
  tooltipEl.style.opacity = 1
  tooltipEl.style.transition = "opacity .3s, left .25s, top .25s"
  tooltipEl.style.position = "absolute"
  tooltipEl.style.zIndex = 5
  tooltipEl.style.left = `${leftPos}px`
  tooltipEl.style.top = `${topPos}px`
  tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`
  tooltipEl.style.pointerEvents = "none"

  function adjustTooltipPosition() {
    const tooltipRect = tooltipEl.getBoundingClientRect()
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    // Check if the tooltip is outside the right edge of the viewport
    if (tooltipRect.right + tooltipRect.width + 100 > viewportWidth) {
      tooltipEl.style.left = `${leftPos - tooltipRect.width - 60}px`
      tooltipEl.classList.add("tooltip-left")
      tooltipEl.classList.remove("tooltip-right")
    } else {
      tooltipEl.classList.add("tooltip-right")
      tooltipEl.classList.remove("tooltip-left")
    }

    // Check if the tooltip is outside the left edge of the viewport
    if (tooltipRect.left < 0) {
      tooltipEl.style.left = `${offset}px`
    }

    // Check if the tooltip is outside the bottom edge of the viewport
    if (tooltipRect.bottom > viewportHeight) {
      tooltipEl.style.top = `${topPos - tooltipRect.height - offset}px`
    }

    // Check if the tooltip is outside the top edge of the viewport
    if (tooltipRect.top < 0) {
      tooltipEl.style.top = `${offset}px`
    }
  }

  // Adjust tooltip position initially
  adjustTooltipPosition()

  // Follow the mouse pointer
  // context.chart.canvas.addEventListener('mousemove', function(event) {
  //   if (tooltipModel.opacity !== 0) {
  //     tooltipEl.style.left = event.clientX + 30 + "px";
  //     tooltipEl.style.top = event.clientY + -10 + "px";
  //   }
  // });

  // Move if too close to the pointer
  context.chart.canvas.addEventListener("mousemove", function (event) {
    const tooltipRect = tooltipEl.getBoundingClientRect()
    const pointerX = event.clientX
    const pointerY = event.clientY

    const distanceX = Math.abs(pointerX - tooltipRect.left)
    const distanceY = Math.abs(pointerY - tooltipRect.top)
    const threshold = 25 // Distance threshold to move the tooltip
    if (distanceX < threshold && distanceY < threshold) {
      tooltipEl.style.left = `${pointerX + 20}px`
      // tooltipEl.style.top = `${topPos + -10}px`

      // Ensure the tooltip is fully visible within the viewport
      adjustTooltipPosition()
    }
  })
}

const graphSetting = {
  maintainAspectRatio: false,
  rotation: -90,
  circumference: 180,
  cutout: 60,
  plugins: {
    tooltip: {
      enabled: false,
      external: external, // custom tooltip
      // boxPadding: 7, // spacing of the text from the clr box
      // xAlign: "left", // direction of tooltip
      // displayColors: false, // show or hide the clr box
    },
    legend: {
      display: false,
    },
    datalabels: {
      formatter: (value) => {
        return value
      },
    },
  },
}

const Card = ({ cardData, state, category }) => {
  const [graphData, setGraphData] = useState(cardData)
  const { t } = useTranslation()
  useEffect(() => {
    getInstall()
    getDeinstall()
    getUpgrade()
    getOthers()
    console.log("char data hiii-", graphData)
    // console.log("char data hiii-",state)
  }, [state])

  const getInstall = () => {
    let p0 = 0
    let p1 = 0
    let p2 = 0
    let p3 = 0
    let total = 0

    state &&
      state.map((data) => {
        // console.log("hiii----",data.status)

        if (category === "installed_base") {
          if (data.name === "In Service") {
            p0 += data.result.add
          }

          if (data.name === "Pending Provisioning") {
            p1 += data.result.add
          }
          if (data.name === "Pending Change") {
            p2 += data.result.add
          }
          if (data.name === "Customer Acceptance") {
            p3 += data.result.add
          }
        } else {
          if (data.name === "In Service (PP)") {
            p0 += data.result.add
          }

          if (data.name === "New") {
            p1 += data.result.add
          }
          if (data.name === "Work In Progress") {
            p2 += data.result.add
          }
          if (data.name === "Customer Acceptance") {
            p3 += data.result.add
          }
        }
      })
    let incidents = graphData.filter((data) => data.title === "Install")
    incidents[0].totalNumber = p0 + p1 + p2 + p3
    incidents[0].graph = [p0, p1, p2, p3]

    const res = graphData.map(
      (obj) => incidents.find((o) => o.title === obj.title) || obj
    )
    // console.log("oyeee---",res)
    setGraphData(res)
  }
  const getDeinstall = () => {
    let s1 = 0
    let s2 = 0
    let s3 = 0
    let s4 = 0
    let total = 0
    state &&
      state.map((data) => {
        if (category === "installed_base") {
          if (data.name === "In Service") {
            s1 += data.result.delete
          }
          if (data.name === "Pending Provisioning") {
            s2 += data.result.delete
          }
          if (data.name === "Pending Change") {
            s3 += data.result.delete
          }
          if (data.name === "Customer Acceptance") {
            s4 += data.result.delete
          }
        } else {
          if (data.name === "In Service (PP)") {
            s1 += data.result.delete
          }
          if (data.name === "New") {
            s2 += data.result.delete
          }
          if (data.name === "Work In Progress") {
            s3 += data.result.delete
          }
          if (data.name === "Customer Acceptance") {
            s4 += data.result.delete
          }
        }
      })

    let faults = graphData.filter((data) => data.title === "Deinstall")
    faults[0].totalNumber = s1 + s2 + s3 + s4
    faults[0].graph = [s1, s2, s3, s4]

    const res = graphData.map(
      (obj) => faults.find((o) => o.title === obj.title) || obj
    )

    setGraphData(res)
  }

  const getUpgrade = () => {
    let s1 = 0
    let s2 = 0
    let s3 = 0
    let s4 = 0
    let total = 0
    state &&
      state.map((data) => {
        if (category === "installed_base") {
          if (data.name === "In Service") {
            s1 += data.result.changed
          }
          if (data.name === "Pending Provisioning") {
            s2 += data.result.changed
          }
          if (data.name === "Pending Change") {
            s3 += data.result.changed
          }
          if (data.name === "Customer Acceptance") {
            s4 += data.result.changed
          }
        } else {
          if (data.name === "In Service (PP)") {
            s1 += data.result.changed
          }
          if (data.name === "New") {
            s2 += data.result.changed
          }
          if (data.name === "Work In Progress") {
            s3 += data.result.changed
          }
          if (data.name === "Customer Acceptance") {
            s4 += data.result.changed
          }
        }
      })

    let faults = graphData.filter((data) => data.title === "Upgrade/Downgrade")
    faults[0].totalNumber = s1 + s2 + s3 + s4
    faults[0].graph = [s1, s2, s3, s4]

    const res = graphData.map(
      (obj) => faults.find((o) => o.title === obj.title) || obj
    )
    setGraphData(res)
  }
  const getOthers = () => {
    let s1 = 0
    let s2 = 0
    let s3 = 0
    let s4 = 0
    let total = 0
    state &&
      state.map((data) => {
        if (category === "installed_base") {
          if (data.name === "In Service") {
            s1 += data.result.others
          }
          if (data.name === "Pending Provisioning") {
            s2 += data.result.others
          }
          if (data.name === "Pending Change") {
            s3 += data.result.others
          }
          if (data.name === "Customer Acceptance") {
            s4 += data.result.others
          }
        } else {
          if (data.name === "In Service (PP)") {
            s1 += data.result.others
          }
          if (data.name === "New") {
            s2 += data.result.others
          }
          if (data.name === "Work In Progress") {
            s3 += data.result.others
          }
          if (data.name === "Customer Acceptance") {
            s4 += data.result.others
          }
        }
      })

    let faults = graphData.filter((data) => data.title === "Others")
    faults[0].totalNumber = s1 + s2 + s3 + s4
    faults[0].graph = [s1, s2, s3, s4]

    const res = graphData.map(
      (obj) => faults.find((o) => o.title === obj.title) || obj
    )
    setGraphData(res)
  }
  const getGraphData = (args) => {
    if (
      args.title === "Install" ||
      args.title === "Deinstall" ||
      args.title === "Upgrade/Downgrade" ||
      args.title === "Others"
    ) {
      return {
        labels: ["In Service", "Provisioning", "Upgrade/Downgrade", "Others"],
        datasets: [
          {
            data: args.graph,
            backgroundColor: ["#70ad47", "#1b70c0", "#f2dc34", "#f89903"],
            hoverBackgroundColor: ["#70ad47", "#1b70c0", "#f2dc34", "#f89903"],
            borderColor: ["#70ad47", "#1b70c0", "#f2dc34", "#f89903"],
            borderWidth: 3,
          },
        ],
      }
    } else {
      return {
        datasets: [
          {
            data: args.graph,
            backgroundColor: ["#f78600"],
            hoverBackgroundColor: ["#f78600"],
            borderColor: ["#f78600"],
            borderWidth: 3,
          },
        ],
      }
    }
  }

  const labelColor = (text, i) => {
    let label
    if (category === "installed_base") {
      switch (text) {
        case "In Service":
          label = <GreenColor key={text} />
          break
        case "Pending Provisioning":
          label = <BlueColor key={text} />
          break
        case "Pending Change":
          label = <YellowColor key={text} />
          break
        case "Customer Acceptance":
          label = <OrangeColor key={text} />
          break
      }
    } else {
      switch (text) {
        case "In Service (PP)":
          label = <GreenColor2 key={text} />
          break
        case "New":
          label = <BlueColor2 key={text} />
          break
        case "Work In Progress":
          label = <YellowColor2 key={text} />
          break
        case "Customer Acceptance":
          label = <OrangeColor key={text} />
          break
      }
    }

    return label
  }

  const labelColor2 = (text, i) => {
    let label

    switch (text) {
      case "In Service (PP)":
        label = <GreenColor key={text} />
        break
      case "New":
        label = <BlueColor key={text} />
        break
      case "Work In Progress":
        label = <YellowColor key={text} />
        break
      case "Customer Acceptance":
        label = <OrangeColor key={text} />
        break
    }
    return label
  }

  return (
    graphData &&
    graphData.map((data, index) => {
      return (
        <div className="col-lg-3 col-md-12 col-sm-12 col-12" key={index}>
          <div
            className={"grid_card set_h " + data.className}
            style={{ cursor: "pointer" }}
          >
            <div className="card_head">
              <div className="txt_card">
                <h3>{t(`${data.title}`)}</h3>
              </div>
              <div className="txt_card_2">
                {data.types.map((type, i) => {
                  return labelColor(type.title)
                })}
              </div>
            </div>
            <div
              className="card_diag outer"
              style={{ height: "200px", width: "190px" }}
            >
              <Doughnut data={getGraphData(data)} options={graphSetting} />
              <p className="percent">
                {data.totalNumber} <br />
                <span style={{ fontSize: "smaller" }}>
                  {t(`${data.title}`)}
                </span>
              </p>
            </div>
          </div>
        </div>
      )
    })
  )
}

export default Card
