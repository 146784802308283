import React, { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import XError from "components/Auth/Card/XError"; // Adjust the import according to your file structure
import { t } from "i18next"; // Adjust according to your i18n setup
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import TechDocsService from "services/techDocsService";
import AuthContext from "context";
import Select from "react-select";
import JSZip from "jszip";

const UploadDocsModal = ({
  isOpen,
  setIsLoading,
  closeModal,
  types,
  dataCenter,
  getTechDocsUploadedFile,
}) => {
  const authContext = useContext(AuthContext);
  const maxSize = process.env.REACT_APP_CAPACITY_LAYOUT_MAX_FILE_SIZE;
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const [notes, setNotes] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    type: "",
  });
  const [file, setFile] = useState(null);
  const [display, setDisplay] = useState("");
  const [progress, setProgress] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };
  const customStylesForSelect = {
    control: (provided, state) => ({
      ...provided,
      // Change border color based on focus state
      border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change colors as needed
      "&:hover": {
        border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change hover color if needed
      },
      boxShadow: null,
      height: "3rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
      color: "#8392A5",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
      color: "#8392A5",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "18rem",
    }),
  };

  const handleClose = () => {
    setFile(null);
    setSelectedTypeId("");
    setIsSubmitted(false);
    setMessage({
      message: "",
      type: "",
    });
    setNotes("");
    setDisplay("");
    setProgress(0);
    closeModal();
  };

  const formatOptions = (data) => {
    return data.map((item) => ({ value: item.id, label: item.name }));
  };

  const onFileChange = (e) => {
    setMessage({
      message: "",
      type: "",
    });
    const fileName = e.target.files[0].name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    const fileSize = e.target.files[0].size;
    const size = Math.round(fileSize / 1024);
    if (maxSize >= size) {
      // if (
      //   fileExtension === "pdf" ||
      //   fileExtension === "xls" ||
      //   fileExtension === "xlsx"
      // ) {
        setIsValid(true);
        setFile(e.target.files[0]);
      // } else {
      //   setFile(null);
      //   setMessage({
      //     message: "Invalid file type please select PDF , XLS and XLSX file.",
      //     type: "danger",
      //   });
      //   setIsValid(false);
      // }
    } else {
      setFile(null);
      setMessage({
        message: "File too Big, please select a file less than 10mb",
        type: "danger",
      });
    }
  };

  const submitExcelUpload = async () => {
    setIsSubmitted(true);
    if (file != null && selectedTypeId != "") {
      setIsLoading(true);
      const zip = new JSZip();
      zip.file(file.name, file);
      try {
        const zipBlob = await zip.generateAsync({ type: "blob" });
        const formData = new FormData();
        formData.append("file", zipBlob, file.name);
        await TechDocsService.uploadFile(
          authContext.token(),
          dataCenter,
          selectedTypeId,
          notes,
          formData
        ).then((res) => {
          getTechDocsUploadedFile();
          handleClose();
        });
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="modal-header mt-59">
        <h3 className="modal-title">{t("layouts.upload_file")}</h3>
        <span onClick={handleClose}>
          <CloseIcon style={{ cursor: "pointer" }} />
        </span>
      </div>
      <div className="modal-body">
        <div className="card">
          <div
            className="card-body"
            style={{ padding: "0px", overflow: "hidden" }}
          >
            <div className="basic-form">
              <form>
                <div className="mb-3 col-12 col-sm-12 col-lg-8 mt-2313">
                  <label className="form-label">Type</label>
                  <Select
                    styles={customStylesForSelect}
                    options={formatOptions(types)}
                    value={formatOptions(types).filter(
                      (el) => el.value == selectedTypeId
                    )}
                    onChange={(option) => {
                      setSelectedTypeId(option.value);
                    }}
                    placeholder={""}
                    menuPlacement="auto"
                  />

                  <XError
                    show={isSubmitted == true && selectedTypeId == ""}
                    errorMessage={"The Type field is required."}
                  ></XError>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-lg-8 mt-2313">
                    <p className="fake_label">{t("layouts.select_file")}</p>
                    <div className="de_drag">
                      <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">
                          <img
                            src="images/image.svg"
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                          <p className="de_browse">
                            <span>{t("layouts.browse")}</span>
                          </p>
                          {display ? <h5>{display}</h5> : ""}
                          <p className="de_max_file_s">
                            {t("layouts.max_file_size_10_mb")}
                          </p>
                        </label>
                        <input
                          className="form-control d-none"
                          type="file"
                          id="formFile"
                          onClick={(e) => (e.target.value = null)}
                          // accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={onFileChange}
                        />
                      </div>
                    </div>
                    <label className="form-label">
                      {file != null ? file?.name : ""}
                    </label>
                    <XError
                      show={
                        isSubmitted == true &&
                        file == null &&
                        message.message == ""
                      }
                      errorMessage={"The File field is required."}
                    ></XError>

                    <XError
                      show={message.message != ""}
                      errorMessage={message.message}
                    ></XError>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-12 col-lg-8 mt-2313">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      {t("layouts.notes")}
                    </label>
                    <textarea
                      className="form-control"
                      // value={state.notes}
                      onChange={(event) => {}}
                      id="exampleFormControlTextarea1"
                      placeholder="Your Notes"
                      rows="3"
                      style={{ height: "100px" }}
                      onChange={(event) => setNotes(event.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div
                  className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                  role="toolbar"
                >
                  <button
                    type="button"
                    className="btn save-btn-new mr_1"
                    onClick={submitExcelUpload}
                    style={{
                      background: "#ff7a00",
                      color: "#fff",
                      borderRadius: "0.25rem",
                      marginRight: "1rem",
                    }}
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    className="btn cancelBtn"
                    onClick={handleClose}
                    style={{ borderRadius: "0.25rem" }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadDocsModal;
