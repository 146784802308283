import AuthContext from "context";
import React, { useContext, useEffect, useRef, useState } from "react";
import Loading from "views/super-admin/pre-loader/loader";
import NetworkRoomService from "services/networkRoomService";
import RoleService from "services/roleServices";
import Layout from "../Component/LayoutWithDataCenter";
import AddRoom from "./componentRoom/addModal";
import EditRoom from "./componentRoom/editModal";
import { subMenu } from "./data";
import "./roomStyle.css";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";

const Room = () => {
  const authContext = useContext(AuthContext);
  const initialMount = useRef(true);
  const [dataCenter, setDataCenter] = useState([]);
  const [countryName, setCountryName] = useState("Country");
  const [activeTab, setActiveTab] = useState(
    authContext.getNetworkDataCenter?.id
  );
  const { t } = useTranslation();
  const [country, setCountry] = useState(0);
  const [openAddModal, setOpenAddModel] = useState(false);
  const [openEditModal, setOpenEditModel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeDataCenterObj, setActiveDataCenterObj] = useState({});
  const [networkRooms, setNetworkRooms] = useState([]);
  const [selectedRoom, setSeletedRoom] = useState([]);
  const [ascending, setAscending] = useState(true);
  const [ascendingFloor, setAscendingFloor] = useState(true);
  const [ascendingType, setAscendingType] = useState(true);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    //getDataCenter();
    if (activeTab && dataCenter.length) {
      getNetworkRooms(activeTab);
    }
  }, [activeTab, triggerRefresh]);

  const getNetworkRooms = async (activeTab) => {
    setIsLoading(true);
    await NetworkRoomService.getAllNetworkRoom(
      authContext.token(),
      activeTab
    ).then((res) => {
      setActiveDataCenterObj(
        dataCenter && dataCenter.filter((center) => center.id === activeTab)[0]
      );
      setNetworkRooms(res.data.data); 
    }).catch((err) => {
      LogoutOnSessionExpire(err); 
    }).finally(() => setIsLoading(false));
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
        initialMount={initialMount}
        dataCenter={dataCenter}
        setDataCenter={setDataCenter}
        countryName={countryName}
        setCountryName={setCountryName}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        country={country}
        setCountry={setCountry}
        subMenu={subMenu}
      >
        <div className="tblop-150">
          <div className="ttl_op-150">
            <h6>{activeDataCenterObj?.name}</h6>
            <a
              href="#sd"
              onClick={() => setOpenAddModel(true)}
              style={{
                color: "#147AD6",
                fontWeight: 600,
                fontSize: "0.813rem",
                cursor: "pointer",
              }}
              data-bs-toggle="modal"
              data-bs-target=".bd-example-modal-lg"
            >
              <img
                alt=""
                src="/images/plus-circle-blue.svg"
                style={{ width: "1rem" }}
              />
              &nbsp;{t("cabling.add_room")}
            </a>
          </div>
          <div className="op-150_table mt-1">
            <div
              className="table-responsive"
              style={{
                overflow: "auto",
                height: "350px",
              }}
            >
              <table
                className="table table-responsive-md"
                style={{
                  border: "1px solid #eee",
                }}
              >
                <thead style={{ borderBottom: "1px solid #eee" }}>
                  <tr style={{ borderBottom: "1px solid #eee" }}>
                    {/* <th>Name 
										<span>
										<i 
										className="fa fa-caret-down" 
										aria-hidden="true"></i>
										</span>
									</th> */}
                    <th
                      onClick={() => {
                        setAscending(!ascending);
                        if (ascending === true) {
                          networkRooms.sort((a, b) =>
                            a.name > b.name ? 1 : -1
                          );
                        }
                        if (ascending === false) {
                          networkRooms.sort((a, b) =>
                            a.name < b.name ? 1 : -1
                          );
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("cabling.name")}{" "}
                      <i
                        className={`fa fa-solid fa-sort-${
                          ascending ? "down" : "up"
                        }`}
                      ></i>
                    </th>
                    {/* <th>Floor 
										<span>
										<i 
										className="fa fa-caret-down" 
										aria-hidden="true"></i>
										</span>
									</th> */}
                    <th></th>
                    <th
                      onClick={() => {
                        setAscendingFloor(!ascendingFloor);
                        if (ascendingFloor === true) {
                          networkRooms.sort((a, b) =>
                            a.floor_name > b.floor_name ? 1 : -1
                          );
                        }
                        if (ascendingFloor === false) {
                          networkRooms.sort((a, b) =>
                            a.floor_name < b.floor_name ? 1 : -1
                          );
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("cabling.floor")}{" "}
                      <i
                        className={`fa fa-solid fa-sort-${
                          ascendingFloor ? "down" : "up"
                        }`}
                      ></i>
                    </th>
                    {/* <th>Type 
										<span>
										<i 
										className="fa fa-caret-down" 
										aria-hidden="true"></i>
										</span>
									</th> */}
                    <th
                      onClick={() => {
                        setAscendingType(!ascendingType);
                        if (ascendingType === true) {
                          networkRooms.sort((a, b) =>
                            a.room_type_name > b.room_type_name ? 1 : -1
                          );
                        }
                        if (ascendingType === false) {
                          networkRooms.sort((a, b) =>
                            a.room_type_name < b.room_type_name ? 1 : -1
                          );
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("cabling.type")}{" "}
                      <i
                        className={`fa fa-solid fa-sort-${
                          ascendingType ? "down" : "up"
                        }`}
                      ></i>
                    </th>
                    <th>{t("cabling.rack")}</th>
                    <th>{t("cabling.remarks")}</th>
                  </tr>
                </thead>
                <tbody>
                  {networkRooms &&
                    networkRooms.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td width="2%">{data?.name}</td>
                          <td width="1%">
                            <span
                              onClick={() => {
                                setOpenEditModel(true);
                                setSeletedRoom(data);
                              }}
                            >
                              <i
                                className="fas fa-edit"
                                data-bs-toggle="modal"
                                data-bs-target=".update_Popup"
                              ></i>
                              <span className="edit"></span>
                            </span>
                          </td>
                          <td width="1%">{data?.floor_name} </td>
                          <td width="3%">{data?.room_type_name}</td>
                          <td width="6%">
                            {/* <span 
												style={{marginlLft: "-13px"}}>
												<i 
												className="fa fa-plus" 
												aria-hidden="true"></i>
												</span> PEK1-01-MMR1-0201 */}
                            {data?.cabinets?.name && data?.cabinets?.name}
                          </td>
                          <td width="20%">{data?.remarks}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {openAddModal === true ? (
          <AddRoom
            closeModal={setOpenAddModel}
            activeDataCenter={activeDataCenterObj}
            getNetworkRooms={getNetworkRooms}
          />
        ) : null}
        {openEditModal === true ? (
          <EditRoom
            closeModal={setOpenEditModel}
            activeDataCenter={activeDataCenterObj}
            getNetworkRooms={getNetworkRooms}
            selectedRoom={selectedRoom}
          />
        ) : null}
      </Layout>
    </React.Fragment>
  );
};

export default Room;
