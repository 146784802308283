import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "context";
import ticketDetails from "services/ticketDetails";
import moment from "moment";
import ErrorModel from "../errormodel";
import Swal from "sweetalert2";
import Loading from "../../../pre-loader/loader";
import { NonEditable, priority } from "../shared/data";
import createTicketService from "services/createTicketService";
import useGetCompanies from "../shared/useGetCompanies";
import _ from "lodash";
import MultiSelectField from "../shared/select";
import DateTime from "../shared/DateTimeComp";
import AddPakage from "../shared/addPakageDetails";
import SingleSelect from "../shared/singleSelect";
import useGetHandlingInstructions from "views/super-admin/Tickets/ShipmentTicketForm/hooks/useGetHandlingInstructions";
import AttachmentTable from "../attachmentTable";
import ticketService from "services/ticketService";
import AddFollowers from "../addfollowers";
import provisioningService from "services/provisioningService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import DescriptionAndAssignSection from "./shared/descriptionAndAssignSection";
import FirstRow from "./shared/firstRow";
import SecondRow from "./shared/secondeRow";
import DraftDescription from "./shared/draftDescription";
import TicketDetailTab from "../shared/ticketDetailTabs";
import LogNoteAndMessage from "../shared/logNote&Message";
import ShipmentInboundPackageDetail from "../shared/shipmentOutboundPackageDetail";
import TicketHistory from "../shared/ticketHistory";
import CircularProgress from "@mui/material/CircularProgress";

const ShipmentInboundPopup = (props) => {
  const { t } = useTranslation();

  const contextStore = useContext(AuthContext);
  const [ticketstatusname, setTicketStatusName] = useState([]);
  const [displaytitle, setDisplayTitle] = useState("");
  const [errorpopup, setErrorPopup] = useState(false);
  const [messagenotes, setMessageNotes] = useState([]);
  const [active, setActive] = useState("shipIn");
  const [statusChange, setStatusChange] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const [refreshstatus, setRefreshStatus] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [updateTicketDetails, setUpdateTicketDetails] = useState({
    description: "",
    is_inbound: true,
  });
  const [services, setServices] = useState([]);
  const [allSpace, setAllSpace] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [DesignatedId, setDesignatedId] = useState("");
  const [toggleLine, setToggleLine] = useState(false);
  const [shiplist, setshiplist] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [payload, setPayload] = useState({});
  const [showAttachment, setShowAttachment] = useState([]);
  const [istypetrue, setistypetrue] = useState(true);
  const [fileData, setFileData] = useState([]);
  const fileInputRef = useRef(null);
  const [assignees, setAssignees] = useState([]);
  const [filteredAssignees, setFilteredAssignees] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [state, setState] = useState({
    ticket_id: "",
    company: "",
    type: "",
    service_id: "",
    priority: "",
    description: "",
    subject: "",
    notes: [],
    ticket_status_id: "",
    ticket_status: "",
    messages: [],
    tickettypeid: "",
    team_id: "",
    assignto: "",
    designatedcompany: "",
    visitors: [],
    customer_email: "",
    attachments: [],
    visit_date: "",
    special_visit_name: "",
    service_ids: "",
    space_ids: "",
    site_access_visiting_rooms: "",
    handling_instruction: "",
    handling_instructions: "",
    require_loading_dock: "",
    shipment_details: [],
    courier: "",
    delivery_date_start: "",
    delivery_date_end: "",
    vehicle_license_plate: "",
    expected_Delivary_date: "",
    shipment_date_start: "",
    shipment_date_end: "",
    service: "",
    space: "",
    free_due_date: "",
    charge_due_date: "",
    grace_period_due_date: "",
    logs: [],
    readiness_id: "",
    assignee: "",
    assignee_id: "",
    assignee_is_current_user: false,
    portal_code: "",
  });
  const [follower, setFollower] = useState([]);
  const [follows, setFollows] = useState(false);
  const [userFollow, setUserFollows] = useState("");
  const [addfollower, setAddFollower] = useState(false);
  const [isUpdateDescriptionList, setIsUpdateDescriptionList] = useState(false);
  const [draftDescription, setDraftDescription] = useState("");
  const [isTicketStatusLoading, setIsTicketStatusLoading] = useState(false);

  const handlePrint = () => {
    let followerDiv = document.querySelector("#follwerDropdown");
    followerDiv.classList.remove("dropdown-menu");
    followerDiv.classList.add("follow-dropdown-menu");
    let descriptionDiv = document.querySelector("#descriptionBox");
    descriptionDiv.setAttribute("style", "height:100%;word-break: break-all;");
    html2canvas(document.querySelector("#capture"), {
      windowHeight: document.querySelector("#content-area").scrollHeight + 500,
    }).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      window.open(
        doc.output("bloburl", { filename: "new-file.pdf" }),
        "_blank"
      );
      followerDiv.classList.add("dropdown-menu");
      descriptionDiv.setAttribute(
        "style",
        "height:150px;word-break: break-all;"
      );

      // doc.save('file.pdf');
    });
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#ff7a00",
      boxShadow: "0 0 0 1px #ff7a00",
      "&:hover": {
        borderColor: "#ff7a00", // Maintain the same border color on hover
        boxShadow: "0 0 0 1px #ff7a00", // Maintain the same box shadow on hover
      },
    }),
  };
  const handleChangeDescription = (e) => {
    let strippedString = e.target.value.replace(/(<([^>]+)>)/gi, "");
    setUpdateTicketDetails((prev) => ({
      ...prev,
      description: strippedString,
    }));
  };
  // console.log(updateTicketDetails);
  const getCustomers = async (companyId, token) => {
    const getCustomersDto = await createTicketService.getCustomers(
      token,
      companyId
    );
    setCustomers(
      getCustomersDto.data?.data
        .filter((customer) => !customer.ns_is_deleted)
        .map((customer) => {
          const { id, name } = customer;
          return { ...customer, label: name, value: id };
        })
    );
  };

  const getServiceIds = async (companyId, siteId, token) => {
    const getServiceIdsDto = await createTicketService.getServiceIds({
      token: token,
      companyId,
      siteId,
    });
    setServices(
      _.orderBy(getServiceIdsDto.data?.data, ["name"], ["asc"])
        .filter((service) => service.ns_is_remote_hands === false)
        .map((service) => {
          const { id, name } = service;
          return { ...service, label: name, value: id };
        })
    );
  };

  const { handlingInstructions } = useGetHandlingInstructions();

  useEffect(() => {
    getFollowList();
    if (updateTicketDetails.designated_company_id) {
      getServiceIds(
        updateTicketDetails.designated_company_id,
        parseInt(props.ticketdata.operatingsiteid),
        localStorage.getItem("token")
      );
      getCustomers(
        updateTicketDetails.designated_company_id,
        localStorage.getItem("token")
      );
    }
  }, [updateTicketDetails.designated_company_id]);
  useEffect(() => {
    if (props.show) {
      document
        .getElementById("main-model")
        .addEventListener("click", async function (e) {
          if (
            document.getElementById("content-area") != null &&
            document.getElementById("content-area").contains(e.target)
          ) {
            // Clicked in box
          } else {
            // await Swal.fire({
            //   title: 'Are you sure?',
            //   text: 'Any changes made will not be saved.',
            //   icon: 'warning',
            //   showCancelButton: true,
            //   confirmButtonColor: '#3085d6',
            //   cancelButtonColor: '#d33',
            //   confirmButtonText: 'Yes'
            // }).then((res) => {
            //   if (res.isConfirmed) {
            //     closeModal();
            //   }
            // });
            // Clicked outside the box
          }
        });
    }

    if (props.ticketdata.messages === null) {
      props.ticketdata.messages = "";
    } else {
      props.ticketdata.messages.map((val) => {
        val.type = "message";
        return val;
      });
    }
    if (props.ticketdata.notes === null) {
      props.ticketdata.notes = "";
    } else {
      props.ticketdata.notes.map((val) => {
        val.type = "note";
        return val;
      });
    }
    if (props.ticketdata.logs === null) {
      props.ticketdata.logs = "";
    } else {
      props.ticketdata.logs.map((val) => {
        val.type = "logs";
        return val;
      });
    }
    let service_id = [];
    let space_id = [];
    let room_ids = [];
    if (
      props.ticketdata.site_access_services !== null &&
      props.ticketdata.site_access_services.length > 0
    ) {
      props.ticketdata.site_access_services.map((data) => {
        service_id.push(data.name);
      });
    }
    if (
      props.ticketdata.site_access_spaces !== null &&
      props.ticketdata.site_access_spaces.length > 0
    ) {
      props.ticketdata.site_access_spaces.map((data) => {
        space_id.push(data.name);
      });
    }
    if (
      props?.ticketdata?.site_access_visiting_rooms !== null &&
      props?.ticketdata?.site_access_visiting_rooms.length > 0
    ) {
      props.ticketdata.site_access_visiting_rooms.map((data) => {
        room_ids.push(data.name);
      });
    }
    setState({
      ticket_id: props.ticketdata.id,
      company: props.ticketdata.company,
      type: props.ticketdata.tickettype,
      service_id: props.ticketdata.serviceid,
      priority: props.ticketdata.priority,
      description: props?.ticketdata?.description,
      subject: props.ticketdata.subject,
      notes: [
        ...props.ticketdata.notes,
        ...props.ticketdata.messages,
        ...props.ticketdata.logs,
      ],
      ticket_status_id: props.ticketdata.ticket_statusid,
      ticket_status: props.ticketdata.ticket_status,
      messages: props.ticketdata.messages,
      tickettypeid: props.ticketdata.tickettypeid,
      team_id: props.ticketdata.teamid,
      assignto: state.assignto ? state.assignto : props.ticketdata.assignto,
      designatedcompany: props.ticketdata.designatedcompany,
      attachments: props.ticketdata.attachments,
      visitors: props.ticketdata.visitors,
      customer_email: props.ticketdata.partner_email,
      visit_date:
        props.ticketdata.site_visit_date_start +
        " - " +
        props.ticketdata.site_visit_date_end,
      special_visit_name: props.ticketdata.special_visit_area_name,
      service_ids: service_id.length > 0 ? service_id.toString() : "null",
      space_ids: space_id.length > 0 ? space_id.toString() : "null",
      site_access_visiting_rooms:
        room_ids.length > 0 ? room_ids.toString() : "null",
      handling_instruction: props.ticketdata.details.handling_instruction,
      handling_instructions: props.ticketdata.details.handling_instruction,
      require_loading_dock: props.ticketdata.details.require_loading_dock,
      shipment_details: props.ticketdata.shipment_details,
      courier: props.ticketdata.details.courier,
      delivery_date_start: props.ticketdata.details.delivery_date_start,
      delivery_date_end: props.ticketdata.details.delivery_date_end,
      vehicle_license_plate: props.ticketdata.details.vehicle_license_plate,
      expected_Delivary_date: props.ticketdata.details.expected_shipment_date,
      shipment_date_start: props.ticketdata.details.shipment_date_start,
      shipment_date_end: props.ticketdata.details.shipment_date_end,
      service: props.ticketdata.details.service,
      space: props.ticketdata.details.space,
      free_due_date: props.ticketdata.details.free_due_date,
      charge_due_date: props.ticketdata.details.charge_due_date,
      grace_period_due_date: props.ticketdata.details.grace_period_due_date,
      services_id: {
        value: props.ticketdata?.details?.service_id,

        label: props.ticketdata?.details?.service,
        space: props.ticketdata?.details?.space,
      },
      priority_id: props.ticketdata.priorityid,
      readiness_id: props.ticketdata.readiness_id,
      assignee: props.ticketdata.assignto,
      assignee_id: props.ticketdata.assignto_id,
      assignee_is_current_user: state.assignee_is_current_user
        ? state.assignee_is_current_user
        : props.ticketdata.assignee_is_current_user,
      portal_code: props.ticketdata.portal_code,
    });
    // if (NonEditable.some((data) => data === props.ticketdata.ticket_status)) {
    //   setistypetrue(false);

    //   setUpdateTicketDetails({
    //     description: props.ticketdata.description
    //   });
    // } else {
    setistypetrue(true);
    setDesignatedId(parseInt(props.ticketdata.designated_company_id));
    setUpdateTicketDetails({
      description: props.ticketdata.description,
      is_inbound: true,
      courier_company: props.ticketdata.details.courier
        ? props.ticketdata.details.courier
        : undefined,
      car_license: props.ticketdata.details.vehicle_license_plate,
      loading_dock_required: props.ticketdata.details.require_loading_dock,
      service_id:
        props.ticketdata?.details?.service_id != null
          ? [props.ticketdata?.details?.service_id]
          : null,
      customer_id: parseInt(props.ticketdata.company_id),
      designated_company_id: parseInt(props.ticketdata.designated_company_id),
      priority_id: parseInt(props.ticketdata.priorityid),

      shipment_details: props.ticketdata.shipment_details.map((data) => ({
        ...data,
        dimension_uom: undefined,
        dimension_uom: data.dimension_uom_id,
        weight_uom: data.uom_id,
        handling_instruction: props.ticketdata.details.handling_instruction,
      })),
    });
    setshiplist(props.ticketdata.shipment_details);
    // }

    setStatusChange(false);
  }, [props.show, statusChange, toggleEdit, props.ticketdata]);
  useEffect(() => {
    ticketStatus(props?.ticketdata?.teamid, props?.ticketdata?.tickettypeid);
  }, [props.show]);
  const updateTicketDetail = async (status) => {
    setIsLoading(true);
    await ticketDetails
      .getTicketDetail(contextStore.token(), props.ticketdata.id)
      .then((res) => {
        //setTicketData(res?.data?.data);
        if (!res?.data?.data) {
          setErrorPopup(true);
          setErrorMsg(res.data);
        }

        let ticket_Detail = res?.data?.data;
        if (ticket_Detail.messages === null) {
          ticket_Detail.messages = "";
        } else {
          ticket_Detail.messages.map((val) => {
            val.type = "message";
            return val;
          });
        }
        if (ticket_Detail.attachments === null) {
          ticket_Detail.attachments = [];
        } else {
          ticket_Detail.attachments.map((val) => {
            val.type = "attachments";
            return val;
          });
        }
        if (ticket_Detail.notes === null) {
          ticket_Detail.notes = "";
        } else {
          ticket_Detail.notes.map((val) => {
            val.type = "note";
            return val;
          });
        }
        if (ticket_Detail.logs === null) {
          ticket_Detail.logs = "";
        } else {
          ticket_Detail.logs.map((val) => {
            val.type = "logs";
            return val;
          });
        }
        let service_id = [];
        let space_id = [];
        let room_ids = [];
        if (
          ticket_Detail.site_access_services !== null &&
          ticket_Detail.site_access_services.length > 0
        ) {
          ticket_Detail.site_access_services.map((data) => {
            service_id.push(data.name);
          });
        }
        if (
          ticket_Detail.site_access_spaces !== null &&
          ticket_Detail.site_access_spaces.length > 0
        ) {
          ticket_Detail.site_access_spaces.map((data) => {
            space_id.push(data.name);
          });
        }
        if (
          props?.ticketdata?.site_access_visiting_rooms !== null &&
          props?.ticketdata?.site_access_visiting_rooms.length > 0
        ) {
          props.ticketdata.site_access_visiting_rooms.map((data) => {
            room_ids.push(data.name);
          });
        }
        setState({
          ticket_id: ticket_Detail.id,
          company: ticket_Detail.company,
          type: ticket_Detail.tickettype,
          service_id: ticket_Detail.serviceid,
          priority: ticket_Detail.priority,
          description: ticket_Detail?.description,
          subject: ticket_Detail.subject,
          notes: [
            ...ticket_Detail.notes,
            ...ticket_Detail.messages,
            ...ticket_Detail.logs,
          ],
          ticket_status_id: ticket_Detail.ticket_statusid,
          ticket_status: ticket_Detail.ticket_status,
          messages: ticket_Detail.messages,
          tickettypeid: ticket_Detail.tickettypeid,
          team_id: ticket_Detail.teamid,
          assignto: ticket_Detail.assignto,
          designatedcompany: ticket_Detail.designatedcompany,
          visitors: ticket_Detail.visitors,
          customer_email: ticket_Detail.partner_email,
          visit_date:
            ticket_Detail.site_visit_date_start +
            " - " +
            ticket_Detail.site_visit_date_end,
          special_visit_name: ticket_Detail.special_visit_area_name,
          service_ids: service_id.length > 0 ? service_id.toString() : "null",
          space_ids: space_id.length > 0 ? space_id.toString() : "null",
          site_access_visiting_rooms:
            room_ids.length > 0 ? room_ids.toString() : "null",
          handling_instruction: ticket_Detail.details.handling_instruction,
          handling_instructions: ticket_Detail.details.handling_instruction,
          require_loading_dock: ticket_Detail.details.require_loading_dock,
          shipment_details: ticket_Detail.shipment_details,
          courier: ticket_Detail.details.courier,
          delivery_date_start: ticket_Detail.details.delivery_date_start,
          delivery_date_end: ticket_Detail.details.delivery_date_end,
          vehicle_license_plate: ticket_Detail.details.vehicle_license_plate,
          expected_Delivary_date: ticket_Detail.details.expected_shipment_date,
          shipment_date_start: ticket_Detail.details.shipment_date_start,
          shipment_date_end: ticket_Detail.details.shipment_date_end,
          service: ticket_Detail.details.service,
          space: ticket_Detail.details.space,
          free_due_date: ticket_Detail.details.free_due_date,
          charge_due_date: ticket_Detail.details.charge_due_date,
          attachments: ticket_Detail.attachments,
          grace_period_due_date: ticket_Detail.details.grace_period_due_date,
          services_id: {
            value: ticket_Detail?.details?.service_id,

            label: ticket_Detail?.details?.service,
            space: props.ticketdata?.details?.space,
          },
          priority_id: ticketDetails.priorityid,
          readiness_id: ticket_Detail.readiness_id,
          assignee: ticket_Detail.assignto,
          assignee_id: ticket_Detail.assignto_id,
          assignee_is_current_user: ticket_Detail.assignee_is_current_user,
          portal_code: ticket_Detail.portal_code,
        });
        if (status) {
        } else {
          Swal.fire("Updated Succesfully.");
        }

        // if (NonEditable.some((data) => data === props.ticketdata.ticket_status)) {
        //   setUpdateTicketDetails({
        //     description: ticket_Detail.description
        //   });
        //   setistypetrue(false);
        // } else {
        setistypetrue(true);
        setDesignatedId(parseInt(ticket_Detail.designated_company_id));

        setUpdateTicketDetails({
          description: ticket_Detail.description,
          is_inbound: true,
          courier_company: ticket_Detail.details.courier
            ? ticket_Detail.details.courier
            : undefined,
          car_license: ticket_Detail.details.vehicle_license_plate,
          loading_dock_required: ticket_Detail.details.require_loading_dock,
          service_id:
            ticket_Detail?.details?.service_id != null
              ? [ticket_Detail?.details?.service_id]
              : null,
          customer_id: parseInt(ticket_Detail.company_id),
          designated_company_id: parseInt(ticket_Detail.designated_company_id),
          priority_id: parseInt(ticket_Detail.priorityid),
          shipment_details: ticketDetails?.shipment_details.map((data) => ({
            ...data,
            dimension_uom: undefined,

            dimension_uom: data.dimension_uom_id,
            weight_uom: data.uom_id,
            handling_instruction: ticket_Detail.details.handling_instruction,
          })),
        });
        setshiplist(ticketDetails.shipment_details);
        // }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      encodeFileToBase64(file, async (err, base64) => {
        setPayload({
          file_name: file.name,
          content: base64,
        });
        await attechments({
          file_name: file.name,
          content: base64,
        }).then(() => {
          if (i + 1 == event.target.files.length) {
            updateTicketDetail(true);
          }
        });
      });
    }
  };

  const attechments = async (imagedata) => {
    setIsLoading(true);

    await ticketDetails
      .addAttechments(contextStore.token(), props.ticketdata.id, imagedata)
      .then((res) => {
        console.log(res);
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          let Fdata = res.data.data.id;
          if (active === "log" || active === "message") {
            setShowAttachment((prev) => {
              let file = { name: imagedata.file_name, id: res.data.data.id };
              return [...prev, file];
            });
            setFileData([...fileData, Fdata]);
          }
          updateTicketDetail(true);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally((done) => setIsLoading(false));
  };

  const getAttachment = async (attachmentId, fileName) => {
    setIsLoading(true);
    await ticketDetails
      .getAttachment(
        localStorage.getItem("token"),
        props.ticketdata.id,
        attachmentId
      )
      .then((res) => {
        if (res?.data.data === undefined) {
          // console.log("here")
          setErrorPopup(true);
          setErrorMsg(res.data);
          //setStatusChange(true)
        } else {
          // setToggleEdit(false);
          // updateTicketDetail(false);
          handleDownload(res.data.data.data, fileName);
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateStage = async (stageId, selectedkey) => {
    let currentStatus;
    ticketstatusname &&
      ticketstatusname.map((data, key, elements) => {
        if (state.ticket_status_id == data?.stage_id) {
          currentStatus = key;
        }
      });

    if (selectedkey <= currentStatus) {
      await Swal.fire("Error", "You can't select the previous stage.");
    } else {
      Swal.fire({
        text: t("common.confirm_status_update"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          await ticketDetails
            .updateStage(contextStore.token(), props.ticketdata.id, stageId)
            .then((res) => {
              if (
                res?.data?.code !== undefined ||
                res?.data?.error !== undefined
              ) {
                console.log(res);
                setErrorMsg(res.data);
                console.log("here");
                setErrorPopup(true);

                //setStatusChange(true)
              } else {
                updateTicketDetail(false);
                // Swal.fire("Updated Succesfully.");
                // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
              }
            })
            .catch((err) => {
              console.log(err.error.response);
              setErrorMsg(err.error.response.data.error);
              setErrorPopup(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    }
  };

  useEffect(() => {
    getAssignees();
  }, []);
  const getAssignees = async () => {
    setIsLoading(true);
    await provisioningService
      .getAssignees(localStorage.getItem("token"))
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);

          //setStatusChange(true)
        } else {
          setAssignees(res.data.data);
          setFilteredAssignees(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const HandleSearch = (val) => {
    setSearchValue(val);
    setFilteredAssignees(filterByValue(assignees, val));
  };
  const handleDiscard = () => {
    setToggleEdit(false);
    setAllSpace([]);
    setUpdateTicketDetails((prev) => ({
      ...prev,
      description: props.ticketdata.description,
    }));
  };
  const filterByValue = (array, value) => {
    return array.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  const assignTaskTo = async (email) => {
    setIsLoading(true);
    await ticketDetails
      .assignToMe(localStorage.getItem("token"), props.ticketdata.id, {
        email: email,
      })
      .then((res) => {
        console.log("test", res.data);
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
        } else {
          updateTicketDetail(false);
          // Swal.fire("Updated Successfully.");
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const unassignTaskTo = async () => {
    setIsLoading(true);
    const assignee = assignees.filter(
      (assignee) => assignee.id === state.assignee_id
    );
    const email = assignee[0].email;
    await ticketDetails
      .unassignFromMe(localStorage.getItem("token"), props.ticketdata.id, {
        email: email,
      })
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
        } else {
          updateTicketDetail(false);
          // Swal.fire("Updated Successfully.");
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const [buttonText, setButtonText] = useState("Follow");
  const [icon, setIcon] = useState(follows ? "fas fa-check" : "black");
  const [buttonStyle, setButtonStyle] = useState({
    color: follows ? "#2cc970" : "black",
  });

  const follow = async () => {
    setIsLoading(true);
    await ticketService
      .subscribe(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          setFollows(true);
          getFollowList();
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const unfollow = async () => {
    setIsLoading(true);
    await ticketService
      .unsubscribe(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          setFollows(false);
          getFollowList();
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFollowList = async () => {
    setIsLoading(true);
    await ticketService
      .getFollower(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        const userfollow = res.data.data.find(
          (team) => team.name === contextStore.getAuth?.name
        );
        const userExists = Boolean(userfollow);
        if (userExists) {
          setFollows(true);
          setButtonText("Following");
          setIcon("fas fa-check");
          setButtonStyle({ color: "#2cc970" });
        } else {
          setFollows(false);
          setButtonText("Follow");
          setIcon("");
          setButtonStyle({ color: "black" });
        }
        setFollower(res.data.data);
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const delfollower = async (contact_id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "you want to remove follower.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        ticketService
          .removeFollower(
            localStorage.getItem("token"),
            props.ticketdata.id,
            contact_id
          )
          .then((res) => {
            getFollowList();
          })
          .catch((err) => {
            console.log(err.error.response);
            setErrorMsg(err.error.response.data.error);
            setErrorPopup(true);
          })
          .finally(() => {});
      }
    });
  };

  const readinessChange = async (readiness) => {
    if (getMaintenanceModeStatus()) {
      // console.log("readinessChange",readiness)
      setIsLoading(true);
      await ticketService
        .updateReadiness(
          localStorage.getItem("token"),
          props.ticketdata.id,
          readiness
        )
        .then((res) => {
          if (res?.data?.data?.success === undefined) {
            setErrorMsg(res.data);
            setErrorPopup(true);
          } else {
            updateTicketDetail(false);
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const sendMessage = async () => {
    setIsLoading(true);
    if (active == "message") {
      await ticketDetails
        .createMessage(contextStore.token(), props.ticketdata.id, {
          message: message,
          attachments: fileData,
        })
        .then((res) => {
          if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
            console.log("here");
            setErrorMsg(res.data);
            setErrorPopup(true);
            //setStatusChange(true)
          } else {
            // setState({...state, notes:[...state.notes,
            //     {
            //         author_id:"Rolland Wong",
            //         body:"<p>"+message+"</p>",
            //         changedDate:"15-Feb-2023 15:21 HKT",
            //         date:"2023-02-15 07:21:48",
            //         id:331642}]})
            updateTicketDetail(false);
            // Swal.fire("Updated Succesfully.");
            setMessage("");
            setFileData([]);
            //props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
            setShowAttachment([]);
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await ticketDetails
        .createNote(contextStore.token(), props.ticketdata.id, {
          message: message,
          attachments: fileData,
        })
        .then((res) => {
          if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
            console.log("here");
            setErrorMsg(res.data);
            setErrorPopup(true);
            //setStatusChange(true)
          } else {
            updateTicketDetail(false);
            //  Swal.fire("Updated Succesfully.");
            setMessage("");
            setFileData([]);
            setShowAttachment([]);
            // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const closeModal = () => {
    props.setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handleExitbutton = async () => {
    await Swal.fire({
      title: "Are you sure?",
      text: "Any changes made will not be saved.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        closeModal();
      }
    });
  };
  const convertDate = (startTime) => {
    let end = moment(new Date());
    var duration = moment.duration(end.diff(startTime));
    var totalTime = duration.asHours();
    if (totalTime > 24) {
      totalTime = Math.floor(duration.asDays()) + " day(s)";
    } else {
      totalTime = Math.floor(totalTime) + " hour(s)";
    }

    return totalTime;
  };
  const ticketStatus = async (id, typeid) => {
    setIsTicketStatusLoading(true);
    await ticketDetails
      .getTicketStatus(localStorage.getItem("token"), id, typeid)
      .then((res) => {
        setDisplayTitle(res?.data?.data[0]?.TicketTypeName);
        setTicketStatusName(res?.data?.data);
        setIsTicketStatusLoading(false);

        // if(res?.data?.ticketType?.name.slice(-1) === "s"){
        //     setDisplayTitle(res?.data?.ticketType?.name.slice(0,-1))
        // }else{
        //     setDisplayTitle(res?.data?.ticketType?.name)
        // }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
        setIsTicketStatusLoading(false);
      })
      .finally(() => setIsTicketStatusLoading(false));
  };

  const delVisitor = (id) => {
    setUpdateTicketDetails((prev) => {
      let newList = prev.shipment_details.filter((data, i) => i !== id);

      return { ...prev, shipment_details: [...newList] };
    });

    setState((prev) => {
      let newList = prev.shipment_details.filter((data, i) => i != id);

      return { ...prev, shipment_details: [...newList] };
    });
  };
  const encodeFileToBase64 = (file, callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result.split(",")[1];
      callback(null, base64Data);
    };

    reader.onerror = (error) => {
      callback(error);
    };

    reader.readAsDataURL(file);
  };
  const handleDownload = (base64String, fileName, filetype) => {
    // Convert the base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteArrays = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteArrays]);

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.target = "_blank"; // Open the link in a new tab/window
    link.textContent = "Click here to download"; // Set the link text

    // Append the link to the document
    link.download = fileName;

    link.click();

    URL.revokeObjectURL(blobUrl);

    // const decodedData = atob(base64String);
    // console.log(decodedData)
    // const blob = new Blob([decodedData], { type: 'application/octet-stream' });

    // const downloadUrl = URL.createObjectURL(blob);

    // const link = document.createElement('a');
    // link.href = downloadUrl;
    // link.download = fileName;

    // link.click();

    // URL.revokeObjectURL(downloadUrl);
  };

  const checkPermission = (permissionName) => {
    if (permissionName) {
      const isPermitted = props.permissions.filter(
        (el) => el.name == permissionName && el.is_allowed == 1
      );

      if (isPermitted.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

    return false;
  };

  const updateTicket = async () => {
    console.log(updateTicketDetails);
    setIsLoading(true);
    await ticketDetails
      .updateTicketDetails(
        contextStore.token(),
        props.ticketdata.id,
        props.ticketdata.tickettypeid,
        updateTicketDetails
      )
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          // console.log("here")
          setErrorPopup(true);
          setErrorMsg(res.data);

          //setStatusChange(true)
        } else {
          setToggleEdit(false);
          // updateTicketDetail(false);
          props.getTicketDetail(
            props.ticketdata.operatingsite,
            props.ticketdata?.id,
            props.ticketdata?.tickettypeid,
            props.ticketdata?.ticket_sub_type_name,
            props.ticketdata?.tickettype
          );
          Swal.fire({
            title: "Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "confirmBtn",
            },
          });
          setIsUpdateDescriptionList(true);

          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelAttach = async (i) => {
    setIsLoading(true);
    try {
      const delData = await ticketDetails.delAttechments(
        contextStore.token(),
        state.ticket_id,
        i
      );
      if (delData) {
        updateTicketDetail();
        setShowAttachment((prev) => [...prev.filter((data) => data.id !== i)]);
        setFileData((prev) => prev.filter((data) => data !== i));
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const replace = (description) => {
    let result = description.replace(/<div>/g, " \n");
    result = result.replace(/<\/div>/g, "");
    result = result.replace(/<br \/>/g, "\n");
    result = result.replace(/<br>/g, "\n");
    result = result.replace(/&nbsp;/g, " ");
    console.log(result);
    return result;
  };
  const separateArrayDateWise = (history) => {
    const data = history.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return d - c;
    });
    // Use reduce to separate array based on date
    const separatedByDate = data.reduce((acc, obj) => {
      const date = moment(obj.date).format("MMMM DD, YYYY");
      // If the date key doesn't exist in the accumulator, create an array for it
      if (!acc[date]) {
        acc[date] = [];
      }

      // Push the current object to the array corresponding to its date
      acc[date].push(obj);

      return acc;
    }, {});

    const mappedData = Object.entries(separatedByDate).map(([date, items]) => ({
      date,
      items,
    }));
    return mappedData;
  };
  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (days >= 7) {
      if (days <= 13) {
        result = `a week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  const getMaintenanceModeStatus = () => {
    const status = contextStore.getMaintenanceModeStatus;
    if (status && status?.status_current == 3) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      class="modal show bd-example-modal-lg"
      id="main-model"
      style={{ display: "block" }} /*onClick={()=> closeModal()}*/
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.4)"
        loaderColor="rgb(248, 153, 3)"
      />
      <div class="modal-dialog modal-xl lay-2-3pop" id="capture">
        <div class="modal-content fault-content" id="content-area">
          <div class="modal-header mt-59">
            {toggleLine && (
              <AddPakage
                setToggleLine={setToggleLine}
                setUpdateTicketDetails={setUpdateTicketDetails}
                setState={setState}
              />
            )}
          </div>

          <FirstRow
            state={state}
            handleChange={readinessChange}
            isEdit={toggleEdit}
            site={props?.sitename || ""}
            permission={checkPermission() && getMaintenanceModeStatus()}
            priority={priority}
            updateTicketDetail={updateTicketDetails}
            isTypeTrue={istypetrue}
            setUpdateTicketDetails={setUpdateTicketDetails}
            handleExit={handleExitbutton}
          ></FirstRow>
          <SecondRow
            title={displaytitle ? displaytitle : ""}
            type={"(Ticket Type - " + state?.type + ")"}
            refresh={updateTicketDetail}
            handlePrint={handlePrint}
            permission={checkPermission}
          ></SecondRow>

          <div>
            <div class="paging_tab">
              {ticketstatusname && !isTicketStatusLoading ? (
                ticketstatusname.map((data, key, elements) => (
                  // console.log(elements[key].stage_id);
                  <div class="item3" key={key}>
                    <button
                      className={
                        state.ticket_status_id == data?.stage_id
                          ? `btn btn-light tab-button-style tab-btn3 btstyle${key} t-menu-active`
                          : `btn btn-light tab-button-style tab-btn3 btstyle${key}`
                      }
                      onClick={() => {
                        if (checkPermission("Change Ticket Status")) {
                          updateStage(data?.stage_id, key);
                        }
                      }}
                    >
                      {data?.stage_name}{" "}
                    </button>
                    {state.ticket_status_id == data?.stage_id ? (
                      <img
                        src={
                          elements[0]?.stage_id == state.ticket_status_id
                            ? ""
                            : "img/second.svg"
                        }
                        alt=""
                        className="imgstyle-ststus"
                      />
                    ) : elements[key - 1]?.stage_id ==
                      state.ticket_status_id ? (
                      <img
                        src="img/first.svg"
                        alt=""
                        className="imgstyle-ststus"
                      />
                    ) : (
                      <img
                        src="img/third.svg"
                        alt=""
                        className="imgstyle-ststus"
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="statusLoadingIndicator">
                  {" "}
                  <CircularProgress
                    size={30}
                    style={{ marginRight: "1rem" }}
                  />{" "}
                  Loading status...
                </div>
              )}
            </div>
          </div>
          <div class="modal-body" style={{ paddingTop: "1rem" }}>
            <div class="" style={{ height: "cal(100%-0%)" }}>
              <div class="">
                <div class="main_fault">
                  <div className="card-body" style={{ overflow: "hidden" }}>
                    <div class="card-details">
                      <div class="row">
                        <DescriptionAndAssignSection
                          title={
                            "(#" +
                              state?.ticket_id +
                              ")" +
                              " " +
                              state?.subject || ""
                          }
                          assigneeName={
                            state.assignto
                              ? state.assignto === "OPS Portal Bot"
                                ? `Ops Portal Bot (${state.activity_user})`
                                : state.assignto
                              : "N/A"
                          }
                          permission={checkPermission}
                          maintenanceStatus={getMaintenanceModeStatus()}
                          assigneeIsCurrentUser={state.assignee_is_current_user}
                          assignees={assignees}
                          handleAssign={assignTaskTo}
                          handleUnassign={unassignTaskTo}
                          assigneeId={state.assignee_id}
                          isEdit={toggleEdit}
                          setIsEdit={setToggleEdit}
                          description={
                            toggleEdit
                              ? updateTicketDetails.description.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )
                              : state?.description.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                ) || ""
                          }
                          handleChangeDescription={handleChangeDescription}
                          handleUpdate={updateTicket}
                          handleDiscard={handleDiscard}
                          draftDescription={draftDescription}
                        ></DescriptionAndAssignSection>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div className="d-flex">
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#000000",
                                  minWidth: "61px",
                                }}
                              >
                                Customer :
                              </label>
                            </div>

                            <div>
                              <b class="ticket-data2">
                                {state?.company || "-"}
                              </b>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div className="d-flex align-items-center">
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#000000",
                                  marginRight: "3px",
                                }}
                              >
                                Expected Deliver Date :
                              </label>
                            </div>
                            <div>
                              {toggleEdit &&
                              istypetrue &&
                              getMaintenanceModeStatus() ? (
                                <div className="d-flex gap-2">
                                  <div style={{ marginBottom: "20px" }}>
                                    <DateTime
                                      setUpdateTicketDetails={
                                        setUpdateTicketDetails
                                      }
                                      id={"expected_shipment_date"}
                                      value={state?.expected_Delivary_date}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <b class="ticket-data2">
                                  {state?.expected_Delivary_date || "-"}
                                </b>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label
                            style={{ fontSize: "0.8rem", color: "#000000" }}
                          >
                            Customer Email :
                          </label>
                          <b class="ticket-data2">
                            {state?.customer_email || "-"}
                          </b>
                        </div>
                        <div class="col-md-6">
                          <div className="d-flex align-items-center">
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#000000",
                                  marginRight: "3px",
                                }}
                              >
                                Service ID :
                              </label>
                            </div>
                            <div>
                              {toggleEdit &&
                              istypetrue &&
                              getMaintenanceModeStatus() ? (
                                <>
                                  <SingleSelect
                                    customStyles={customStyles}
                                    options={services}
                                    setAllSpace={setAllSpace}
                                    setUpdateTicketDetails={
                                      setUpdateTicketDetails
                                    }
                                    value={state?.services_id}
                                  />
                                </>
                              ) : (
                                <b class="ticket-data2">
                                  {state?.service || "-"}
                                </b>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label
                            style={{ fontSize: "0.8rem", color: "#000000" }}
                          >
                            Designated Company :
                          </label>
                          <b class="ticket-data2">
                            {state?.designatedcompany || "-"}
                          </b>
                        </div>
                        <div class="col-md-6">
                          <label
                            style={{ fontSize: "0.8rem", color: "#000000" }}
                          >
                            Space ID :
                          </label>
                          <b class="ticket-data2">
                            {toggleEdit &&
                            istypetrue &&
                            getMaintenanceModeStatus()
                              ? allSpace.join(",")
                              : state?.space || "-"}
                          </b>
                        </div>
                        {state?.portal_code ? (
                          <div class="col-md-12">
                            <label
                              style={{ fontSize: "0.8rem", color: "#000000" }}
                            >
                              Portal Code :
                            </label>
                            <b class="ticket-data2">
                              {state?.portal_code || "-"}
                            </b>
                          </div>
                        ) : (
                          " "
                        )}
                        <div class="col-md-4">
                          <div className="d-flex align-items-center">
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#000000",
                                  paddingRight: ".15rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Handling Instruction:
                              </label>
                            </div>
                            {toggleEdit &&
                            istypetrue &&
                            getMaintenanceModeStatus() ? (
                              <div>
                                <select
                                  style={{
                                    height: "35px",
                                    borderColor: "#ff7a00",
                                    boxShadow: "0 0 0 1px #ff7a00",
                                  }}
                                  defaultValue={state?.handling_instruction}
                                  onChange={(event) =>
                                    setUpdateTicketDetails((prev) => ({
                                      ...prev,
                                      handling_instruction: event.target.value,
                                    }))
                                  }
                                >
                                  {handlingInstructions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.key}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              <b class="ticket-data2">
                                {state?.handling_instructions || "-"}
                              </b>
                            )}
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div
                            className="d-flex align-items-center"
                            style={{ marginBottom: "1rem" }}
                          >
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#000000",
                                  paddingRight: ".35rem",
                                  marginLeft: "2rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Shipment Date:
                              </label>
                            </div>
                            <div>
                              {toggleEdit &&
                              istypetrue &&
                              getMaintenanceModeStatus() ? (
                                <div className="d-flex gap-2">
                                  <DateTime
                                    setUpdateTicketDetails={
                                      setUpdateTicketDetails
                                    }
                                    id={"shipment_date_start"}
                                    value={state?.shipment_date_start}
                                  />

                                  <DateTime
                                    setUpdateTicketDetails={
                                      setUpdateTicketDetails
                                    }
                                    id={"shipment_date_end"}
                                    value={state?.shipment_date_end}
                                  />
                                </div>
                              ) : (
                                <b class="ticket-data2">
                                  {!!state?.shipment_date_start &&
                                  !!state?.shipment_date_end
                                    ? state?.shipment_date_start +
                                      " to " +
                                      state?.shipment_date_end
                                    : "-" || "-"}
                                </b>
                              )}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div className="d-flex">
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#000000",
                                }}
                              >
                                Courier Company :
                              </label>
                            </div>

                            <div>
                              {toggleEdit &&
                              istypetrue &&
                              getMaintenanceModeStatus() ? (
                                <div>
                                  <input
                                    type="text"
                                    style={{
                                      marginLeft: "10px",
                                      border: "1px solid #ff7a00",
                                      boxShadow: " 0 0 0 1px #ff7a00",
                                      padding: "0.25rem",
                                      outline: "none",
                                    }}
                                    className="ticket-data-inp"
                                    onChange={(event) =>
                                      setUpdateTicketDetails((prev) => ({
                                        ...prev,
                                        courier_company: event.target.value,
                                      }))
                                    }
                                    defaultValue={state?.courier}
                                  />
                                </div>
                              ) : (
                                <b class="ticket-data2">
                                  {state?.courier || "-"}
                                </b>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div className="d-flex">
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#000000",
                                  marginLeft: "28px",
                                }}
                              >
                                Car License Number :
                              </label>
                            </div>

                            <div>
                              {toggleEdit &&
                              istypetrue &&
                              getMaintenanceModeStatus() ? (
                                <div>
                                  <input
                                    type="text"
                                    style={{
                                      marginLeft: "10px",
                                      border: "1px solid #ff7a00",
                                      boxShadow: " 0 0 0 1px #ff7a00",
                                      padding: "0.25rem",
                                      outline: "none",
                                    }}
                                    className="ticket-data-inp"
                                    onChange={(event) =>
                                      setUpdateTicketDetails((prev) => ({
                                        ...prev,
                                        car_license: event.target.value,
                                      }))
                                    }
                                    defaultValue={state?.vehicle_license_plate}
                                  />
                                </div>
                              ) : (
                                <b class="ticket-data2">
                                  {state?.vehicle_license_plate || "-"}
                                </b>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div className="d-flex">
                            <div>
                              {" "}
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#000000",
                                }}
                              >
                                Loading Dock Required?
                              </label>{" "}
                            </div>

                            <div>
                              {toggleEdit &&
                              istypetrue &&
                              getMaintenanceModeStatus() ? (
                                <div>
                                  <input
                                    type="checkbox"
                                    style={{ marginLeft: "10px" }}
                                    className="ticket-data-inp"
                                    checked={
                                      updateTicketDetails?.loading_dock_required
                                    }
                                    onChange={(event) =>
                                      setUpdateTicketDetails((prev) => ({
                                        ...prev,
                                        loading_dock_required: !prev.loading_dock_required,
                                      }))
                                    }
                                  />
                                </div>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={state?.require_loading_dock}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label
                            style={{ fontSize: "0.8rem", color: "#000000" }}
                          >
                            Free End Date :
                          </label>
                          <b class="ticket-data2">
                            {state?.free_due_date || "-"}
                          </b>
                        </div>
                        <div class="col-md-4">
                          <label
                            style={{
                              fontSize: "0.8rem",
                              color: "#000000",
                              marginLeft: "28px",
                            }}
                          >
                            Charge End Date :
                          </label>
                          <b class="ticket-data2">
                            {state?.charge_due_date || "-"}
                          </b>
                        </div>
                        <div class="col-md-4">
                          <label
                            style={{ fontSize: "0.8rem", color: "#000000" }}
                          >
                            Grace End Date :
                          </label>
                          <b class="ticket-data2">
                            {state?.grace_period_due_date || "-"}
                          </b>
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col-md-12">
                          <div style={{ position: "relative" }}>
                            <TicketDetailTab
                              setActive={setActive}
                              active={active}
                              permission={checkPermission}
                              follows={follows}
                              unfollow={unfollow}
                              follow={follow}
                              setButtonText={setButtonText}
                              setIcon={setIcon}
                              setButtonStyle={setButtonStyle}
                              icon={icon}
                              buttonStyle={buttonStyle}
                              buttonText={buttonText}
                              setAddFollower={setAddFollower}
                              delfollower={delfollower}
                              follower={follower}
                              ticketType="shipmentInbound"
                            ></TicketDetailTab>{" "}
                            {active != "shipIn" &&
                              active != "attachment" &&
                              active != "history" && (
                                <LogNoteAndMessage
                                  permission={checkPermission}
                                  setMessage={setMessage}
                                  message={message}
                                  handleImageClick={handleImageClick}
                                  fileInputRef={fileInputRef}
                                  handleFileChange={handleFileChange}
                                  sendMessage={sendMessage}
                                  active={active}
                                  history={
                                    active == "message"
                                      ? state?.messages
                                      : state?.notes.length > 0
                                      ? state?.notes.filter(
                                          (el) => el.type == "note"
                                        )
                                      : []
                                  }
                                  separateArrayDateWise={separateArrayDateWise}
                                  getTime={getTime}
                                  getAttachment={getAttachment}
                                  handleDelAttach={handleDelAttach}
                                ></LogNoteAndMessage>
                              )}
                            {active == "shipIn" && (
                              <ShipmentInboundPackageDetail
                                permission={getMaintenanceModeStatus()}
                                shipment_details={state.shipment_details}
                                toggleEdit={toggleEdit}
                                istypetrue={istypetrue}
                                delVisitor={delVisitor}
                                setToggleLine={setToggleLine}
                                permission={checkPermission}
                              ></ShipmentInboundPackageDetail>
                            )}
                            {active == "attachment" && (
                              <AttachmentTable
                                attachments={state.attachments}
                                ticketId={props.ticketdata.id}
                                setErrorMsg={setErrorMsg}
                                setErrorPopup={setErrorPopup}
                                handleDownload={handleDownload}
                                setIsLoading={setIsLoading}
                                handleDelAttach={handleDelAttach}
                                handleFileChange={handleFileChange}
                                fileInputRef={fileInputRef}
                                handleImageClick={handleImageClick}
                                permission={checkPermission}
                              ></AttachmentTable>
                            )}
                            {active == "history" && (
                              <TicketHistory
                                notes={state.notes}
                                separateArrayDateWise={separateArrayDateWise}
                                getTime={getTime}
                                getAttachment={getAttachment}
                                handleDelAttach={handleDelAttach}
                              ></TicketHistory>
                            )}
                          </div>
                        </div>
                      </div>
                      {showAttachment.length > 0 && active != "attachment" && (
                        <div className="show-Attach-outer">
                          {" "}
                          <div className="attach-outer">Attachments</div>
                          <div className="file-name-box">
                            {showAttachment.map((val, index) => {
                              return (
                                <div className="down-file" key={index}>
                                  <div>{val.name}</div>{" "}
                                  <div className="down-file-inner">
                                    <div>
                                      <img
                                        src="images/fclose.svg"
                                        onClick={() => handleDelAttach(val.id)}
                                        width={8}
                                        alt=""
                                        style={{ paddingTop: "2px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {addfollower && (
            <AddFollowers
              show={addfollower}
              setShow={setAddFollower}
              ticketId={props.ticketdata.id}
              getFollowList={getFollowList}
            />
          )}
          {errorpopup && (
            <ErrorModel
              show={errorpopup}
              setShow={setErrorPopup}
              errorMsg={errorMsg}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ShipmentInboundPopup;
