import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class TechDocsService {
  async getAllTechDocsTypes(token, q) {
    return await axios(token).get(`techDocsTypes`);
  }

  async createTechDocsType(token, data) {
    return await axios(token).post(`techDocsTypes`, data);
  }
  /*
	|-------------------------------------------------------------------------------
	|  Account Update
	|-------------------------------------------------------------------------------
	*/
  async updateTechDocsType(token, data, id) {
    return await axios(token).put(`techDocsTypes?id=${id}`, data);
  }
  /*
	|-------------------------------------------------------------------------------
	| Destroy Account
	|-------------------------------------------------------------------------------
	*/
  async deleteTechDocsType(token, id) {
    return await axios(token).delete(`techDocsTypes?id=${id}`);
  }

  async uploadFile(token, dataCenter, typeId, notes, data) {
    return await axios(token).post(
      `uploadTechDocs?dataCenter=${dataCenter}&&typeId=${typeId}&&notes=${notes}`,
      data
    );
  }

  async getTechDocsUploadedFile(token, body) {
    return await axios(token).post(`getUploadedTechDocsList`, body);
  }

  async downloadTechDocsFile(token, fileName) {
    return await axios(token).get(`downloadTechDocsFile?fileName=${fileName}`, {
      responseType: "blob",
    });
  }
}

export default new TechDocsService();
