import AuthContext from "context";
import { useContext, useEffect, useState } from "react";
import CreateTicketService from "services/createTicketService";
import RoleService from "services/roleServices";
import _ from "lodash";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const useDataCenter = ({ countryId }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  const [dataCenters, setDataCenters] = useState([]);
  const getDataCenters = async () => {
    await RoleService.dataCenter(authContext.token()).then((dto) => {
      if (dto.data?.data) {
        let finalDc = dto.data?.data.filter((data) =>
          countryId !== null ? data.country_id === countryId : data
        );
        const Fdc = finalDc.map((item) => ({
          id: item.id,
          value: item.id,
          label: item.name,
        }));
        setDataCenters(Fdc);
      }
    });
  };

  useEffect(() => {
    // getDataCenters();
  }, [countryId]);

  return {
    dataCenters,
  };
};

export default useDataCenter;
