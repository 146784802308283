import { LogoutOnSessionExpire } from "common/helpers";
import { XError, XAlert } from "components/common";
import AuthContext from "context";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  SHEETS,
  mapPerfReport,
  getCapacityBreakdown,
  checkFieldsPresence,
  mapDesignPueAndTargetPueForAllMonths,
} from "./parseExcel";
import DataCenterPerformance from "services/DataCenterPerformance";
import CloseIcon from "@mui/icons-material/Close";
import { isEmpty } from "lodash";
import { CheckBox } from "@mui/icons-material";

const ExcelJS = require("exceljs");

const ExcelUploadModal = ({
  setShowExcelUploadModal,
  setExcelReportData,
  refresh,
  dataCenter,
}) => {
  const authContext = useContext(AuthContext);

  const [message, setMessage] = useState({
    message: "",
    type: "",
  });

  const [valid, setValid] = useState(false);

  const [error, setError] = useState({
    dataCenterName: "",
    reportingMonth: "",
  });
  const [parsingError, setParsingError] = useState(null);
  const [recordExist, setRecordExist] = useState(false);
  const [existedRecord, setExistedRecord] = useState([]);
  const [existedDesignPue, setExistedDesignPue] = useState([]);
  const [existedTargetPue, setExistedTargetPue] = useState([]);
  const [payloadForPue, setPayloadForPue] = useState([]);
  const [data, setData] = useState([]);
  const [report, setReport] = useState({});
  const [requestInFlight, setRequestInFlight] = useState(false);
  const [isDateError, setIsDateError] = useState(false);
  const [excelErr, setExcelErr] = useState(false);
  const [excelInvalidFields, setExcelInvalidFields] = useState([{}]);
  const sendRequest = async () => {
    const addToPayload = {
      dataCenterId: authContext.getNetworkDataCenter.id,
      countryId: authContext?.getNetworkDataCenter.country_id,
      year: moment(report.reportingMonth).year(),
      month: moment(report.reportingMonth).month() + 1,
    };

    await DataCenterPerformance.uploadPerformanceReport(authContext.token(), {
      ...report,
      ...addToPayload,
    });
    // console.log(payloadForPue);
    if (payloadForPue.length > 0) {
      await DataCenterPerformance.updateOrCreatePue(authContext.token(), {
        data_center_id: authContext.getNetworkDataCenter.id,
        data: payloadForPue,
      }).then(async (res) => {
        refresh(dataCenter);
      });
    }
    await Swal.fire("success", "Done!", "success");
    setExcelReportData(addToPayload);
    setRequestInFlight(false);
    closeModal();
  };

  const checkExcelData = () => {
    if (excelErr) {
      Swal.fire({
        icon: "warning",
        cancelButtonColor: "#FF0000",
        showCancelButton: true,
        confirmButtonText: "Proceed with data",
        confirmButtonColor: "#FE8600",
        // custom html code for the UI in list form
        html: `
        <div style="display: flex; flex-direction: column; align-items: center;">
            <p>There is error in data for following:-</p>
            <ul>
            ${excelInvalidFields
              .map(
                (item) =>
                  `<li style="padding-block: 3px;">
                <b>${item.roomName}</b>
                <span style="text-transform: capitalize;">${item.field.replace(
                  /_/g,
                  " "
                )}</span> </li> </br>`
              )
              .join("")}
            </ul>
        </div>`,
      }).then((result) => {
        if (result.isConfirmed) {
          submitExcelUpload();
        }
      });
    } else {
      submitExcelUpload();
    }
  };

  const submitExcelUpload = async () => {
    setRequestInFlight(true);
    try {
      const designPueForSelectedMonth = data.filter(
        (el) => el.month == moment(report.reportingMonth).month() + 1
      );
      if (
        designPueForSelectedMonth.length > 0 &&
        designPueForSelectedMonth[0].designPue &&
        report.dp_design_pue &&
        report.dp_design_pue != designPueForSelectedMonth[0].designPue
      ) {
        const result = await Swal.fire({
          text: `The value of 'Design PUE' is different at column 'B32'(${
            report.dp_design_pue
          }) from column '${String.fromCharCode(
            67 + moment(report.reportingMonth).month()
          )}46'(${
            designPueForSelectedMonth[0].designPue
          }). It will be overwritten by value in column '${String.fromCharCode(
            67 + moment(report.reportingMonth).month()
          )}46'.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, overwrite it!",
        });
        if (result.isConfirmed) {
          report.dp_design_pue = designPueForSelectedMonth[0].designPue;

          if (recordExist) {
            const result = await Swal.fire({
              title: "Are you sure?",
              text: "It will overwrite an existing record",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, overwrite it!",
              customClass: {
                cancelButton: "cancelBtn",
                confirmButton: "confirmBtn",
              },
            });
            if (result.isConfirmed) {
              await sendRequest();
            } else {
              setRequestInFlight(false);
            }
          } else {
            await sendRequest();
          }
        } else {
          setRequestInFlight(false);
        }
      } else {
        if (recordExist) {
          const result = await Swal.fire({
            title: "Are you sure?",
            text: "It will overwrite an existing record",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, overwrite it!",
            customClass: {
              cancelButton: "cancelBtn",
              confirmButton: "confirmBtn",
            },
          });
          if (result.isConfirmed) {
            await sendRequest();
          } else {
            setRequestInFlight(false);
          }
        } else {
          await sendRequest();
        }
      }
    } catch (e) {
      await Swal.fire("error", "Something went wrong");
      setRequestInFlight(false);
      LogoutOnSessionExpire(e);
    }
  };

  const closeModal = () => {
    setShowExcelUploadModal(false);
  };

  const checkValidation = async () => {
    setRecordExist(false);

    const {
      name: currentDataCenterName,
      id: currentDataCenterId,
    } = authContext?.getNetworkDataCenter;
    let error = {
      dataCenterName: "",
      reportingMonth: "",
    };

    const { dataCenterName, reportingMonth } = report;
    const reportingMonthNumber = moment(reportingMonth).month();
    const reportingYearNumber = moment(reportingMonth).year();
    const currentDateMonthNumber = moment(new Date()).month();
    const currentDateYearNumber = moment(new Date()).year();

    let flag = true;
    if (currentDataCenterName !== dataCenterName) {
      error.dataCenterName = "Incorrect data center";
      flag = false;
    }

    if (
      reportingYearNumber == currentDateYearNumber &&
      reportingMonthNumber > currentDateMonthNumber
    ) {
      setIsDateError(true);
      error.reportingMonth = `Reporting month should be ${moment()
        .subtract(0, "month")
        .format("MMM-YYYY")} or earlier.`;
    } else {
      setIsDateError(false);
    }
    // if (reportingMonthNumber !== currentDateMonthNumber - 1) {
    //   error.reportingMonth = `Reporting month should be  ${moment()
    //     .subtract(1, 'month')
    //     .format('MMM-YYYY')}`;
    // }

    if (parsingError) {
      setMessage({
        message: `Error processing excel document. ${parsingError}`,
        type: "danger",
      });
      flag = false;
    } else {
      const dto = await DataCenterPerformance.index(authContext.token(), {
        dataCenterId: currentDataCenterId,
        month: reportingMonthNumber + 1,
        year: reportingYearNumber,
      });
      setExistedRecord(dto.data.yearlyData);
      if (dto.data && dto.data.data && dto.data.data.id) {
        setRecordExist(true);
        setMessage({
          message:
            "Such record does already exist in the database. Pressing `Upload` button will overwrite the existing one",
          type: "warning",
        });
      }
    }
    setError({ ...error });
    setValid(flag);
    return flag;
  };

  const onFileChange = (e) => {
    setParsingError(null);
    setMessage({
      message: "",
      type: "",
    });
    setError({
      dataCenterName: "",
      reportingMonth: "",
    });
    const reportDataList = [];
    const reportData = {};
    const file = e.target.files[0];
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();

    const processPerformanceReportSheet = (sheet) => {
      try {
        sheet.eachRow((row, rowIndex) => {
          const extractedFromRow = mapPerfReport(row.values, rowIndex);
          extractedFromRow.forEach((rec) => {
            reportDataList.push(rec);
          });
        });
        checkFieldsPresence(reportDataList);
      } catch (err) {
        throw `Problem with DC Op Performance Report Sheet. ${JSON.stringify(
          err
        )}`;
      }
    };
    const processDesignPueAndTargetForAllMonths = (sheet) => {
      try {
        let months = [];
        let designPue = [];
        let targetPue = [];

        sheet.eachRow((row, rowIndex) => {
          if (rowIndex == 44) {
            months = mapDesignPueAndTargetPueForAllMonths(row.values, rowIndex);
          }
          if (rowIndex == 46) {
            designPue = mapDesignPueAndTargetPueForAllMonths(
              row.values,
              rowIndex
            );
          }
          if (rowIndex == 47) {
            targetPue = mapDesignPueAndTargetPueForAllMonths(
              row.values,
              rowIndex
            );
          }
        });

        const data = {
          months: months,
          designPue: designPue,
          targetPue: targetPue,
        };
        const dataArray = Object.keys(data.months).map((month) => ({
          month: new Date(data.months[month]).getMonth() + 1,
          year: new Date(data.months[month]).getFullYear(),
          designPue: data.designPue[month],
          targetPue: data.targetPue[month],
        }));
        setData(dataArray);
      } catch (err) {
        throw `Problem with DC Op Performance Report Sheet. ${JSON.stringify(
          err
        )}`;
      }
    };

    const processCapacityBreakdownSheet = (sheet) => {
      try {
        const allRows = [];
        sheet.eachRow((row, rowIndex) => {
          allRows.push({ index: rowIndex, row: row.values });
        });
        const result = getCapacityBreakdown(allRows);

        console.log(result.invalidFields);

        if (result.invalidFields.length > 0) {
          setExcelInvalidFields((prevData) => [...result.invalidFields]);
          setExcelErr(true);
        } else {
          setExcelInvalidFields([]);
          setExcelErr(false);
        }

        reportData["capacity_breakdown"] = result;
      } catch (err) {
        throw `Problem with Capacity Breakdown Sheet ${
          err && JSON.stringify(err)
        }`;
      }
    };

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;
      wb.xlsx.load(buffer).then((workbook) => {
        workbook.eachSheet((sheet) => {
          try {
            const { orderNo } = sheet;
            if (orderNo === SHEETS.PERFORMANCE_REPORT) {
              processPerformanceReportSheet(sheet);
              processDesignPueAndTargetForAllMonths(sheet);
            }
            if (orderNo === SHEETS.CAPACITY_BREKDOWN) {
              processCapacityBreakdownSheet(sheet);
            }
          } catch (err) {
            setParsingError(err);
          }
        });
        reportDataList.forEach(([key, value]) => {
          reportData[key] = value;
        });
        // console.log(reportData);
        setReport(reportData);
      });
    };
  };

  useEffect(() => {
    if (report && report.dataCenterName) {
      checkValidation();
    }
    if (parsingError && !report.dataCenterName) {
      setMessage({
        message: "Unable to find data center in excel document",
        type: "danger",
      });
      setValid(false);
    }
  }, [report]);

  useEffect(() => {
    // console.log(data);
    if (data && data.length > 0 && existedRecord) {
      const x = compareArrays(existedRecord, data);
      const a = x.filter((el) => {
        if (
          el.old_design_pue != null &&
          el.new_design_pue != null &&
          Number(el.old_design_pue) != el.new_design_pue
        ) {
          el.isSelectedDesignPue = false;
          return el;
        }
      });
      setExistedDesignPue(a);
      const b = x.filter((el) => {
        if (
          el.old_target_pue != null &&
          el.new_target_pue != null &&
          Number(el.old_target_pue) != el.new_target_pue
        ) {
          el.isSelectedTargetPue = false;
          return el;
        }
      });
      setExistedTargetPue(b);
      let newArray = [];
      const c = x.forEach((data) => {
        const x = {};
        if (data.old_target_pue == null && data.new_target_pue != null) {
          x.target_pue = data.new_target_pue;
        }
        if (data.old_design_pue == null && data.new_design_pue != null) {
          x.design_pue = data.new_design_pue;
        }
        if (!isEmpty(x)) {
          x.month = data.month;
          x.year = data.year;
          newArray.push(x);
        }
      });
      // console.log(newArray);
      setPayloadForPue(newArray);
    }
  }, [existedRecord, data]);

  // useEffect(() => {
  //   console.log(payloadForPue);
  // }, [payloadForPue]);
  // Function to compare arrays and create the third array
  function compareArrays(array1, array2) {
    let newArray = [];
    array2.forEach((item2) => {
      let found = false;
      array1.forEach((item1) => {
        if (item1.month === item2.month && item1.year === item2.year) {
          newArray.push({
            month: item1.month,
            year: item1.year,
            old_design_pue: item1.design_pue,
            new_design_pue: item2.designPue || null,
            old_target_pue: item1.target_pue,
            new_target_pue: item2.targetPue || null,
          });
          found = true;
        }
      });
      if (!found) {
        newArray.push({
          month: item2.month,
          year: item2.year,
          old_design_pue: null,
          new_design_pue: item2.designPue || null,
          old_target_pue: null,
          new_target_pue: item2.targetPue || null,
        });
      }
    });
    return newArray;
  }
  const getMonthName = (monthValue) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthValue - 1]; // Adjust index since month numbers start from 1
  };
  const handleChange = (field, data, isSelected) => {
    if (field == "designPue") {
      setExistedDesignPue(
        existedDesignPue.map((el) => {
          if (el.month == data.month) {
            el.isSelectedDesignPue = isSelected;
          }
          return el;
        })
      );
      handlePuePaulod(field, data, isSelected);
    }
    if (field == "targetPue") {
      setExistedTargetPue(
        existedTargetPue.map((el) => {
          if (el.month == data.month) {
            el.isSelectedTargetPue = isSelected;
          }
          return el;
        })
      );
      handlePuePaulod(field, data, isSelected);
    }
  };

  const handlePuePaulod = (field, data, isSelected) => {
    const checkIfExist = payloadForPue.filter((el) => el.month == data.month);
    // console.log(checkIfExist);
    if (field == "targetPue") {
      if (checkIfExist.length == 0 && isSelected == true) {
        setPayloadForPue([
          ...payloadForPue,
          {
            month: data.month,
            year: data.year,
            target_pue: data.new_target_pue,
          },
        ]);
      }
      if (checkIfExist.length > 0 && isSelected == true) {
        setPayloadForPue(
          payloadForPue.map((el) => {
            if (el.month == data.month) {
              el.target_pue = data.new_target_pue;
            }
            return el;
          })
        );
      }
      if (checkIfExist.length > 0 && isSelected == false) {
        if (checkIfExist[0].design_pue) {
          setPayloadForPue(
            payloadForPue.map((el) => {
              if (el.month == data.month) {
                delete el["target_pue"];
              }
              return el;
            })
          );
        } else {
          setPayloadForPue(
            payloadForPue.filter((el) => el.month != data.month)
          );
        }
      }
    } else {
      if (checkIfExist.length == 0 && isSelected == true) {
        setPayloadForPue([
          ...payloadForPue,
          {
            month: data.month,
            year: data.year,
            design_pue: data.new_design_pue,
          },
        ]);
      }
      if (checkIfExist.length > 0 && isSelected == true) {
        setPayloadForPue(
          payloadForPue.map((el) => {
            if (el.month == data.month) {
              el.design_pue = data.new_design_pue;
            }
            return el;
          })
        );
      }
      if (checkIfExist.length > 0 && isSelected == false) {
        if (checkIfExist[0].target_pue) {
          setPayloadForPue(
            payloadForPue.map((el) => {
              if (el.month == data.month) {
                delete el["design_pue"];
              }
              return el;
            })
          );
        } else {
          setPayloadForPue(
            payloadForPue.filter((el) => el.month != data.month)
          );
        }
      }
    }
  };
  return (
    <div
      className="modal show bd-example-modal-lg"
      style={{ display: "block", overflow: "auto" }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title">Excel Upload </h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              onClick={closeModal}
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "auto" }}
              >
                <div clasdsName="basic-form">
                  <form className="mt-5 mb-5">
                    <div className="row p-0 m-0">
                      <div className="col-md-6 mt-2313">
                        <input
                          type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={onFileChange}
                        />
                        {/* <XError message={error.installedbase_id} /> */}
                      </div>
                      {/* <div className="col-md-6 mt-2313"></div> */}
                    </div>
                    <div className="row p-0 m-0">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">Data Center</label>
                        <input
                          type="text"
                          className="form-control"
                          value={report?.dataCenterName || ""}
                          disabled={true}
                        />
                        <XError message={error.dataCenterName} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">Report Date</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            report?.reportingMonth
                              ? moment(report?.reportingMonth).format(
                                  "MMM-YYYY"
                                )
                              : ""
                          }
                          disabled={true}
                        />
                        <XError message={error.reportingMonth} />
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className="mt-3 p-3">
                        <XAlert message={message.message} type={message.type} />
                      </div>
                    </div>
                    {existedDesignPue && existedDesignPue.length > 0 && (
                      <div className="row p-0 m-0">
                        <div className="mt-3 p-3">
                          <p style={{ fontSize: "1rem", fontWeight: 500 }}>
                            Design PUE already exist for the following months,
                            please select the month for which you want to
                            overwrite
                          </p>
                          <table>
                            <thead>
                              <tr>
                                <th>Month</th>
                                <th>Old Value</th>
                                <th>New Value</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {existedDesignPue.map((data) => {
                                return (
                                  <tr>
                                    <td>{getMonthName(data.month)}</td>
                                    <td>{data.old_design_pue}</td>
                                    <td>{data.new_design_pue}</td>
                                    <td>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        checked={
                                          data.isSelectedDesignPue == true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          handleChange(
                                            "designPue",
                                            data,
                                            e.target.checked
                                          );
                                        }}
                                      ></input>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {existedTargetPue && existedTargetPue.length > 0 && (
                      <div className="row p-0 m-0">
                        <div className="mt-3 p-3">
                          <p style={{ fontSize: "1rem", fontWeight: 500 }}>
                            Target PUE already exist for the following months,
                            please select the month for which you want to
                            overwrite:
                          </p>

                          <table>
                            <thead>
                              <tr>
                                <th>Month</th>
                                <th>Old Value</th>
                                <th>New Value</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {existedTargetPue.map((data) => {
                                return (
                                  <tr>
                                    <td>{getMonthName(data.month)}</td>
                                    <td>{data.old_target_pue}</td>
                                    <td>{data.new_target_pue}</td>
                                    <td>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        checked={
                                          data.isSelectedTargetPue == true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          handleChange(
                                            "targetPue",
                                            data,
                                            e.target.checked
                                          );
                                        }}
                                      ></input>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div
                className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                role="toolbar"
              >
                <button
                  type="button"
                  onClick={closeModal}
                  style={{ marginRight: 15 }}
                  className="btn btn-outline-primary mr_1"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    checkExcelData();
                  }}
                  disabled={!valid || requestInFlight || isDateError}
                  className="btn btn-primary"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcelUploadModal;
