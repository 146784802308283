import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class CapacityServices {

	/*
	|-------------------------------------------------------------------------------
	| 	get Monthly utilizastion
	|-------------------------------------------------------------------------------
	*/
	async index (token,{dataCenterId,month,year}){

		const url =`getMonthlyUtilization?data_center_id=${dataCenterId}&month=${month}&year=`+year;

		return await axios(token).get(url);
	}
	/*
	|-------------------------------------------------------------------------------
	| 	Store Monthly utilizastion
	|-------------------------------------------------------------------------------
	*/
	async store (token,data){

		const url =`createMonthlyUtilization`;
		
		return await axios(token).post(url, data);
	}
	async layoutstore (token,data,option){

		const url =`createLayout`;
		
		return await axios(token).post(url, data,option);
	}

	async layoutList (token,data){ 
		const url =`getLayout?data_center_id=${data}`;
		
		return await axios(token).get(url);
	}
	async deleteLayoutFile (token,data){ 
		
		const url =`deleteLayout`;
		
		return await axios(token).post(url,data);
	}
}

export default new CapacityServices()