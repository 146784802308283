import AuthContext from "context";
import { useContext, useEffect, useState } from "react";
import powerDistribution from "services/powerDistribution";

export const useGetBreakersByRoom = (roomId) => {
  const authContext = useContext(AuthContext);

  const [breakerList, setBreakerList] = useState([]);

  const getBreakers = async (roomId) => {
    const getBreakersDto = await powerDistribution.getBreakerByRoomId(authContext.token(), roomId)
    setBreakerList(getBreakersDto.data?.data)
  }

  useEffect(() => {
    if (roomId) {
      getBreakers(roomId);
    }
  }, [roomId])

  return {
    breakerList
  }
}