import { css } from "glamor";
import { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import FaultTicketForm from "../FaultTicketForm";
import RemoteHandsTicketForm from "../RemoteHandsTicketForm";
import { TICKET_MODAL_BACKGROUND_COLOR } from "../shared/constants";
import SiteAccessTicketForm from "../SiteAccessTicketForm";
import CloseConfirm from "./CloseConfirm";
import ShipmentTicketForm from "../ShipmentTicketForm";
import ShipmentConfirmationForm from "../ShipmentTicketForm/RightPart/ShipmentConfirmationForm";
import IncidentTicket from "../IncidentTicketForm";
import StorageContext from "context";

const DEFAULT_MIN_WIDTH = "1000px";

const GenericTicketModal = ({ ticketType, show, setShow }) => {
  const { t } = useTranslation();
  const { id, name, formWidth, formTitle, fontSize } = ticketType;
  const contextData = useContext(StorageContext);

  const styles = {
    modal: {
      minWidth: formWidth || DEFAULT_MIN_WIDTH,
      top: "-3rem !important",
    },
    backgound: {
      backgroundColor: `${TICKET_MODAL_BACKGROUND_COLOR} !important`,
    },
    header: {
      padding: "0!important",
      height: "90px",
    },
    title: {
      fontSize: fontSize || "2.7rem !important",
      lineHeight: "1.2rem !important",
      fontWeight: "500 !important",
      paddingLeft: "20px !important",
    },
    body: {
      minHeigth: "800px !important",
    },
  };

  const [openCloseConfirm, setOpenCloseConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpenCloseConfirm(true);
  };

  const handleCloseModal = () => {
    setShow(false);
    contextData.setAutoRefresh(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} {...css(styles.modal)}>
        <Modal.Header {...css(styles.header, styles.backgound)}>
          <Modal.Title {...css(styles.title)}>
            {t(formTitle || name)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body {...css(styles.backgound, styles.body)}>
          {id === "site-access" && (
            <SiteAccessTicketForm
              closeModal={handleCloseModal}
              setLoading={setLoading}
              id={id}
            />
          )}
          {id === "fault" && (
            <FaultTicketForm
              closeModal={handleCloseModal}
              setLoading={setLoading}
              id={id}
            />
          )}
          {id === "remote-hands" && (
            <RemoteHandsTicketForm
              closeModal={handleCloseModal}
              setLoading={setLoading}
            />
          )}
          {id === "shipment" && (
            <ShipmentTicketForm
              closeModal={handleCloseModal}
              setLoading={setLoading}
              id={id}
            />
          )}
          {id === "shipment-confirmation" && (
            <ShipmentConfirmationForm
              closeModal={handleCloseModal}
              setLoading={setLoading}
            />
          )}

          {id === "incident" && (
            <IncidentTicket
              closeModal={handleCloseModal}
              setLoading={setLoading}
            />
          )}
        </Modal.Body>
      </Modal>
      <CloseConfirm
        show={openCloseConfirm}
        setShow={setOpenCloseConfirm}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default GenericTicketModal;
