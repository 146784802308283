import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { numberFormat, numberFormatToDecimalPlace } from "common/helpers";
import {
  Available,
  Blocked,
  Complete,
  InService,
  Reserved,
  ROFR,
  Unavailable,
} from "components/ColorTile";
import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
ChartJS.register(ArcElement, Tooltip, Legend);

// custom tooltip function
function external(context) {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML =
      "<div class='tooltip-arrow'></div><div class='tooltip-content'></div>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem) {
    let indicator = bodyItem.lines[0].split(":")[0]; // text part
    let value = bodyItem.lines[0].split(":")[1]; // the value
    return [indicator, value];
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = "<div>";

    titleLines.forEach(function (title) {
      innerHtml += "<span>" + title + "</span>";
    });
    innerHtml += "</div><span>";

    bodyLines.forEach(function (body, i) {
      const colors = tooltipModel.labelColors[i];
      let style = "background: black";
      let style2 = "background: " + colors.backgroundColor;
      style2 += "; padding: .2rem .5rem";
      style2 += "; border-radius: .25rem";
      style += "; border-radius: .25rem";
      style += "; white-space: nowrap";
      if (
        bodyLines[0][0] === "Blocked" ||
        bodyLines[0][0] === "Reserved" ||
        bodyLines[0][0] === "ROFR"
      ) {
        style2 += "; color: white";
      } else {
        style2 += "; color: black";
      }
      if (bodyLines[0][0] === "Blocked") {
        style2 += "; border: 1px solid white";
      }
      style += "; padding: .5rem";
      style += "; color: white";
      style += "; font-size: 14px";
      const span =
        '<span style="' +
        style +
        '">' +
        '<span style="' +
        style2 +
        '">' +
        bodyLines[0][0] +
        "</span>" +
        " : " +
        bodyLines[0][1] +
        "</span>";
      innerHtml += "<span>" + span + "</span>";
    });
    innerHtml += "</span>";

    let tableRoot = tooltipEl.querySelector("div");
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  const offset = 10;

  // Set initial position near the cursor
  let leftPos = position.left + window.pageXOffset + tooltipModel.caretX + 30;
  let topPos = position.top + window.pageYOffset + tooltipModel.caretY + -10;

  // Display, position, and set styles for TEXT inside only
  tooltipEl.style.opacity = 1;
  tooltipEl.style.transition = "opacity .3s, left .25s, top .25s";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.zIndex = 5;
  tooltipEl.style.left = `${leftPos}px`;
  tooltipEl.style.top = `${topPos}px`;
  tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
  tooltipEl.style.pointerEvents = "none";

  function adjustTooltipPosition() {
    const tooltipRect = tooltipEl.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Check if the tooltip is outside the right edge of the viewport
    if (tooltipRect.right + tooltipRect.width + 100 > viewportWidth) {
      tooltipEl.style.left = `${leftPos - tooltipRect.width - 60}px`;
      tooltipEl.classList.add("tooltip-left");
      tooltipEl.classList.remove("tooltip-right");
    } else {
      tooltipEl.classList.add("tooltip-right");
      tooltipEl.classList.remove("tooltip-left");
    }

    // Check if the tooltip is outside the left edge of the viewport
    if (tooltipRect.left < 0) {
      tooltipEl.style.left = `${offset}px`;
    }

    // Check if the tooltip is outside the bottom edge of the viewport
    if (tooltipRect.bottom > viewportHeight) {
      tooltipEl.style.top = `${topPos - tooltipRect.height - offset}px`;
    }

    // Check if the tooltip is outside the top edge of the viewport
    if (tooltipRect.top < 0) {
      tooltipEl.style.top = `${offset}px`;
    }
  }

  // Adjust tooltip position initially
  adjustTooltipPosition();

  // Follow the mouse pointer
  // context.chart.canvas.addEventListener('mousemove', function(event) {
  //   if (tooltipModel.opacity !== 0) {
  //     tooltipEl.style.left = event.clientX + 30 + "px";
  //     tooltipEl.style.top = event.clientY + -10 + "px";
  //   }
  // });

  // Move if too close to the pointer
  context.chart.canvas.addEventListener("mousemove", function (event) {
    const tooltipRect = tooltipEl.getBoundingClientRect();
    const pointerX = event.clientX;
    const pointerY = event.clientY;

    const distanceX = Math.abs(pointerX - tooltipRect.left);
    const distanceY = Math.abs(pointerY - tooltipRect.top);
    const threshold = 35; // Distance threshold to move the tooltip
    if (distanceX < threshold && distanceY < threshold) {
      tooltipEl.style.left = `${pointerX + 20}px`;
      // tooltipEl.style.top = `${topPos + 10}px`

      // Ensure the tooltip is fully visible within the viewport
      adjustTooltipPosition();
    }
  });
}

const graphSetting = {
  maintainAspectRatio: false,
  rotation: -90,
  circumference: 180,
  cutout: 60,
  plugins: {
    tooltip: {
      enabled: false,
      external: external, // custom tooltip
      // boxPadding: 7, // spacing of the text from the clr box
      // xAlign: "left", // direction of tooltip
      // displayColors: false, // show or hide the clr box
    },
    legend: {
      display: false,
    },
    datalabels: {
      formatter: (value) => {
        return value;
      },
    },
  },
};

const DashboardCard = ({ cardData }) => {
  const contextStore = useContext(AuthContext);
  let floors = [];
  const [graphData, setGraphData] = useState(cardData);
  const { t } = useTranslation();
  useEffect(() => {
    floors = contextStore.getDataCenterFloor;

    getFloorGraph();
    getCabinetGraph();
    getPowerGraph();
    getCagesGraph();
  }, [contextStore.getDataCenterFloor]);

  const getFloorGraph = () => {
    if (floors.length) {
      let floorData = graphData.filter((data) => data.title === "Floors");
      let inService = floors.filter((floor) => floor.status === 1);

      floorData[0].totalNumber = floors.length;
      floorData[0].graph = [inService.length, floors.length - inService.length];

      const res = graphData.map(
        (obj) => floorData.find((o) => o.title === obj.title) || obj
      );

      setGraphData(res);
    } else {
      let floorData = graphData.filter((data) => data.title === "Floors");
      // let inService = floors.filter(floor => floor.status === 1);

      floorData[0].totalNumber = 0;
      floorData[0].graph = [0, 0];

      const res = graphData.map(
        (obj) => floorData.find((o) => o.title === obj.title) || obj
      );

      setGraphData(res);
    }
  };

  const getCabinetGraph = () => {
    let total = 0;
    let totalAvail = 0;
    let totalsold = 0;
    let totalReserved = 0;
    let totalRofr = 0;
    let totalBlocked = 0;
    let totalComplete = 0;

    if (floors.length) {
      let cabData = graphData.filter((data) => data.title === "Cabinets");
      floors.map((floor) => {
        total += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.design_cabs)),
          0
        );

        totalReserved += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.reserved_cabs)),
          0
        );

        totalRofr += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.rofr_cabs)),
          0
        );

        totalBlocked += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.blocked_cabs)),
          0
        );

        totalsold += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.sold_cabs)),
          0
        );
        if (floor.rooms && floor.rooms.length > 0) {
          floor.rooms.forEach((data) => {
            if (data.cabinets.length) {
              data.cabinets.forEach((cabinet) => {
                if (cabinet.status === 2) {
                  totalComplete += 1;
                }
              });
            }
          });
        }
        // console.log(totalComplete)
      });

      totalAvail =
        total -
        (totalsold + totalReserved + totalRofr + totalBlocked + totalComplete);

      cabData[0].totalNumber = total;
      cabData[0].graph = [
        totalsold,
        totalAvail,
        totalReserved,
        totalRofr,
        totalBlocked,
        totalComplete,
      ];

      const res = graphData.map(
        (obj) => cabData.find((o) => o.title === obj.title) || obj
      );

      setGraphData(res);
    } else {
      let cabData = graphData.filter((data) => data.title === "Cabinets");

      cabData[0].totalNumber = 0;
      cabData[0].graph = [0, 0, 0, 0, 0];

      const res = graphData.map(
        (obj) => cabData.find((o) => o.title === obj.title) || obj
      );

      setGraphData(res);
    }
  };

  const getPowerGraph = () => {
    let totalPower = 0;
    let totalAvail = 0;
    let totalSold = 0;
    let totalReserved = 0;
    let totalRofr = 0;
    let totalBlocked = 0;

    if (floors.length) {
      let powerData = graphData.filter((data) => data.title === "Power (kW)");

      floors.map((power) => {
        totalPower += power.rooms.reduce(
          (previous, current) => (previous += Number(current.design_power)),
          0
        );

        totalReserved += power.rooms.reduce(
          (previous, current) => (previous += Number(current.reserved_power)),
          0
        );

        totalRofr += power.rooms.reduce(
          (previous, current) => (previous += Number(current.rofr_power)),
          0
        );

        totalBlocked += power.rooms.reduce(
          (previous, current) => (previous += Number(current.blocked_power)),
          0
        );

        totalSold += power.rooms.reduce(
          (previous, current) => (previous += Number(current.sold_power)),
          0
        );
      });

      totalAvail =
        totalPower - (totalSold + totalReserved + totalRofr + totalBlocked);

      powerData[0].totalNumber = totalPower.toFixed(3);
      powerData[0].graph = [
        totalSold.toFixed(3),
        totalAvail.toFixed(3),
        totalReserved.toFixed(3),
        totalRofr.toFixed(3),
        totalBlocked.toFixed(3),
      ];

      const res = graphData.map(
        (obj) => powerData.find((o) => o.title === obj.title) || obj
      );

      setGraphData(res);
    } else {
      let powerData = graphData.filter((data) => data.title === "Power (kW)");

      powerData[0].totalNumber = 0;
      powerData[0].graph = [0, 0, 0, 0, 0];

      const res = graphData.map(
        (obj) => powerData.find((o) => o.title === obj.title) || obj
      );

      setGraphData(res);
    }
  };
  const getCagesGraph = () => {
    let totalCages = 0;
    let totalAvail = 0;
    let totalSold = 0;
    let totalReserved = 0;
    let totalRofr = 0;
    let totalBlocked = 0;

    if (floors.length) {
      let cagesData = graphData.filter((data) => data.title === "Cages");

      floors.map((cage) => {
        totalCages += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.design_cages)),
          0
        );

        totalReserved += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.reserved_cages)),
          0
        );

        totalRofr += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.rofr_cages)),
          0
        );

        totalBlocked += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.blocked_cages)),
          0
        );

        totalSold += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.sold_cages)),
          0
        );
      });

      totalAvail =
        totalCages - (totalSold + totalReserved + totalRofr + totalBlocked);

      cagesData[0].totalNumber = totalCages;
      cagesData[0].graph = [
        totalSold,
        totalAvail,
        totalReserved,
        totalRofr,
        totalBlocked,
      ];

      const res = graphData.map(
        (obj) => cagesData.find((o) => o.title === obj.title) || obj
      );

      setGraphData(res);
    } else {
      let cagesData = graphData.filter((data) => data.title === "Cages");

      cagesData[0].totalNumber = 0;
      cagesData[0].graph = [0, 0];

      const res = graphData.map(
        (obj) => cagesData.find((o) => o.title === obj.title) || obj
      );

      setGraphData(res);
    }
  };

  const getGraphData = (args) => {
    if (args.title === "Floors") {
      return {
        labels: ["In Service", "Unavailable"],
        datasets: [
          {
            data: args.graph,
            backgroundColor: ["#FE8600", "#E0E2E5"],
            hoverBackgroundColor: ["#FE8600", "#E0E2E5"],
            borderColor: ["#FE8600", "#E0E2E5"],
            borderWidth: 3,
          },
        ],
      };
    } else if (args.title === "Cabinets") {
      return {
        labels: [
          "In Service",
          "Available",
          "Reserved",
          "ROFR",
          "Blocked",
          "Complete",
        ],
        datasets: [
          {
            data: args.graph,
            backgroundColor: [
              "#FE8600",
              "#3FEB7B",
              "#1b70c0",
              "#595959",
              "#000000",
              "#c2adc2",
            ],
            hoverBackgroundColor: [
              "#FE8600",
              "#3FEB7B",
              "#1b70c0",
              "#595959",
              "#000000",
              "#c2adc2",
            ],
            borderColor: [
              "#FE8600",
              "#3FEB7B",
              "#1b70c0",
              "#595959",
              "#000000",
              "#c2adc2",
            ],
            borderWidth: 3,
          },
        ],
      };
    } else {
      return {
        labels: [
          "In Service",
          "Available",
          "Reserved",
          "ROFR",
          "Blocked",
          "Complete",
        ],
        datasets: [
          {
            data: args.graph,
            backgroundColor: [
              "#FE8600",
              "#3FEB7B",
              "#1b70c0",
              "#595959",
              "#000000",
            ],
            hoverBackgroundColor: [
              "#FE8600",
              "#3FEB7B",
              "#1b70c0",
              "#595959",
              "#000000",
            ],
            borderColor: [
              "#FE8600",
              "#3FEB7B",
              "#1b70c0",
              "#595959",
              "#000000",
            ],
            borderWidth: 3,
          },
        ],
      };
    }
  };

  const labelColor = (text) => {
    let component;

    switch (text) {
      case "In Services":
        component = <InService index={text} key={text} />;
        break;
      case "Reserved":
        component = <Reserved index={text} key={text} />;
        break;
      case "ROFR":
        component = <ROFR index={text} key={text} />;
        break;
      case "Blocked":
        component = <Blocked index={text} key={text} />;
        break;
      case "Available":
        component = <Available index={text} key={text} />;
        break;
      case "Complete":
        component = <Complete index={text} key={text} />;
        break;
      default:
        component = <Unavailable index={text} key={text} />;
    }

    return component;
  };
  return (
    graphData &&
    graphData.map((data, index) => {
      return (
        <div className="col-lg-3 col-md-12 col-sm-12 col-12" key={index}>
          <div className={"grid_card set_h " + data.className}>
            <div className="card_head">
              <div className="txt_card">
                <h3>{t(`dashboard.${data.title}`)}</h3>
              </div>
              <div className="txt_card_2">
                {data.types.map((type) => {
                  return labelColor(type.title);
                })}
              </div>
            </div>
            <div
              className="card_diag outer"
              style={{ height: "200px", width: "200px" }}
            >
              <Doughnut data={getGraphData(data)} options={graphSetting} />
              <p className="percent">
                {numberFormatToDecimalPlace(data.totalNumber)} <br />
                <span>{t(`dashboard.${data.title}`)}</span>{" "}
              </p>
            </div>
          </div>
        </div>
      );
    })
  );
};

export default DashboardCard;
