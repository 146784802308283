import { axiosWithToken as axios } from 'utils/axios';

class TicketDetails {
  async getTicketDetail(token, tid) {
    return await axios(token).get(`getTicketDeatils?tid=` + tid);
  }

  async getTicketStatus(token, ticket_id, typeid) {
    return await axios(token).get(
      `getTicketTypeStatus?ticket_id=` + ticket_id + `&&typeid=` + typeid
    );
  }

  async assignToMe(token, ticket_id, body) {
    return await axios(token).post(`assignTo?tid=` + ticket_id, body);
  }
  async unassignFromMe(token, ticket_id, body) {
    return await axios(token).post(`unassignFrom?tid=` + ticket_id, body);
  }
  async updateStage(token, ticket_id, stageId) {
    return await axios(token).post(`updateStage?tid=` + ticket_id, { stageId: stageId });
  }

  async createNote(token, ticket_id, note) {
    return await axios(token).post(`createNote?tid=` + ticket_id, { note: note });
  }

  async createMessage(token, ticket_id, message) {
    return await axios(token).post(`createMessage?tid=` + ticket_id, { content: message });
  }
  async addAttechments(token, ticket_id, message) {
    return await axios(token).post(`attechments`, { ticket_id, message });
  }

  async setTimeSheet(token, options) {
    return await axios(token).post(`setTimesheet`, options);
  }

  async delTimeSheet(token, options) {
    return await axios(token).post(`deleteTimesheet`, options);
  }

  async addTimeSheet(token, options) {
    return await axios(token).post(`startTimer`, options);
  }

  async resumeTimeSheet(token, options) {
    return await axios(token).post(`pauseTimer`, options);
  }

  async updateTicketDetails(token, ticketId, tickettypeid, option) {
    return await axios(token).post(
      `updateTicketDetails?tid=${ticketId}&ticketTypeId=${tickettypeid}`,
      option
    );
  }

  async delAttechments(token, ticket_id, attach_id) {
    return await axios(token).post(`deleteAttachment?tid=${ticket_id}`, {
      attachment_id: attach_id
    });
  }
  async escalateTicketNotification(token, tid) {
    return await axios(token).get(`escalateTicketNotification?tid=` + tid);
  }
  async getAttachment(token, tid, attachmentId) {
    return await axios(token).get(`getAttachment?tid=` + tid + `&&attachmentId=` + attachmentId);
  }
}

export default new TicketDetails();
