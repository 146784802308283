import moment from "moment"; 
import { useState } from "react";
function TableRows({rowsData, deleteTableRows, handleChange}) {  
    return(
        
        rowsData.map((data, index)=>{
            const {date, employee, desc, startTime, endtime, duration}= data;
            const time1 = moment(startTime, 'hh:mm'); //first time
            const time2 = moment(endtime, 'hh:mm'); //second time   
            let minutes = isNaN(time2.diff(time1, 'minutes')) ? 0 : time2.diff(time1, 'minutes'); //Get the minutes diff  
            console.log(minutes)
            return( 
                <tr key={index}>
                    <td>
                        <input type="date" value={date} onChange={(evnt)=>(handleChange(index, evnt))} name="date" className="form-control"/>
                    </td>
                    <td><input type="text" value={employee}  onChange={(evnt)=>(handleChange(index, evnt))} name="employee" className="form-control"/> </td>
                    <td><input type="text" value={desc}  onChange={(evnt)=>(handleChange(index, evnt))} name="desc" className="form-control" /> </td>
                    <td><input type="time" value={startTime}  onChange={(evnt)=>(handleChange(index, evnt))} name="startTime" className="form-control" /> </td>
                    <td><input type="time" value={endtime}  onChange={(evnt)=>(handleChange(index, evnt))} name="endtime" className="form-control" /> </td>
                    <td><input type="text" value={minutes != 0 ? (minutes < 15 ? 15 : minutes > 15 && minutes < 30 ? 30 : minutes) : "0"}  name="duration" className="form-control"  readOnly/> </td>
                    <td><button className="btn btn-danger btn-sm" onClick={()=>(deleteTableRows(index))} style={{padding:"8px",width:"35px"}}>x</button></td>
                </tr>

            )
        })
   
    )
    
}

export default TableRows;