import { Row } from "react-bootstrap";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BoxSelect from "../shared/BoxSelect";
import { TICKET_MODAL_BACKGROUND_COLOR } from "../shared/constants";
import TicketSelectField from "../shared/TicketSelectField";
import useIncidentTicketForm from "./useIncidentTicketForm";
import * as utils from "../shared/utils";
import Description from "./Description";
import { css } from "glamor";
import TicketButton from "../shared/TicketButton";
import TicketInputField from "../shared/TicketInputField";
const IncidentTicket = ({ closeModal, setLoading }) => {
  const { t } = useTranslation();
  const form = useIncidentTicketForm({ closeModal, setLoading });

  const globalOption = {
    label: t("global"),
    value: "global",
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "500px",
    },
    icon: {
      marginLeft: "10px",
    },
  };

  // const handleSubmit = () => {
  //   if (form.isActive('submit')) {
  //     form.formik.setFieldValue('buttonBeingClicked', 'submit')
  //   }
  // }

  return (
    <form onSubmit={form.formik.handleSubmit}>
      <div {...css(styles.container)}>
        <div>
          <Row>
            <BoxSelect
              id="country"
              setFieldValue={form.formik.setFieldValue}
              value={form.formik.values.country}
              options={[...form.countries]}
              label={t("create-ticket.site-access.country").toUpperCase()}
              placeholder={t(
                "create-ticket.site-access.operating-site-placeholder"
              )}
              active
            />
          </Row>
          <Row>
            <TicketSelectField
              id="TicketTypes"
              label={t("create-ticket.remote-hands.ticket-type").toUpperCase()}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive("TicketTypes")}
              options={[...form.ticketTypes]}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t(
                `create-ticket.remote-hands.ticket-type-placeholder`
              )}
              value={form.formik.values.TicketTypes}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <BoxSelect
              id="operatingSite"
              setFieldValue={form.formik.setFieldValue}
              value={form.formik.values.operatingSite}
              options={form.operatingSites}
              label={t(
                "create-ticket.site-access.operating-site"
              ).toUpperCase()}
              placeholder={t(
                "create-ticket.site-access.operating-site-placeholder"
              )}
              active={form.isActive("operatingSite")}
            />
          </Row>
          <Row>
            <TicketSelectField
              id="ImpactedDevice"
              label={t("create-ticket.incident-ticket.impacted_device")}
              setFieldValue={form.formik.setFieldValue}
              options={form.devices}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={"Select Impacted Device"}
              value={form.formik.values.ImpactedDevice}
              active={t("create-ticket.incident-ticket.impacted_device")}
            />
          </Row>
          <Row>
            <TicketSelectField
              id="category"
              label={t("create-ticket.incident-ticket.category")}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive("category")}
              options={form.category}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t("create-ticket.incident-ticket.select_category")}
              value={form.formik.values.category.category}
            />
          </Row>

          <Row>
            <TicketSelectField
              id="Subcategory"
              label={t("create-ticket.incident-ticket.sub_category")}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive("Subcategory")}
              options={form.formik.values.category.subCategory}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t(
                "create-ticket.incident-ticket.select_sub_category"
              )}
              value={form.formik.values.Subcategory}
            />
          </Row>

          <Row>
            <TicketSelectField
              id="severity"
              setFieldValue={form.formik.setFieldValue}
              value={form.formik.values.severity}
              options={form.formik.values.category.severity}
              label={t("create-ticket.fault-ticket.severity")}
              placeholder={t("create-ticket.fault-ticket.severity-placeholder")}
              active
            />
          </Row>
          <Row>
            <TicketInputField
              id="ticketSubject"
              value={form.formik.ticketSubject}
              setFieldValue={form.formik.setFieldValue}
              label={t("create-ticket.site-access.ticket-subject")}
              required
              active={form.isActive("ticketSubject")}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            />
          </Row>
          <Row>
            <Description
              active={form.isActive("description")}
              value={form.formik.values.description}
              setFieldValue={form.formik.setFieldValue}
            />
          </Row>
        </div>
        <div>
          <Row className="mt-4 d-flex justify-content-end gap-3">
            <TicketButton
              label={t("cancel")}
              variant="secondary"
              width="150px"
              active={true}
              closeModal={closeModal}
            />
            <TicketButton
              label={t("create-ticket.label")}
              variant="primary"
              icon={
                <i
                  {...css(styles.icon)}
                  className="fa-regular fa-pen-to-square"
                />
              }
              // handleClick={handleSubmit}
              active={form.isActive("submit")}
              buttonType="submit"
              width="200px"
            />
          </Row>
        </div>
      </div>
    </form>
  );
};

export default IncidentTicket;
