import AuthContext from "context";
import { useContext, useEffect, useState } from "react";
import CreateTicketService from "services/createTicketService";
import RoleService from "services/roleServices";
import _ from "lodash";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const useGetOperatingSites = ({
  countryId,
  companyId,
  setLoading,
  notFetchCustomers,
  country,
  countries,
  isShowResellerContacts,
}) => {
  // console.log('country--', country);
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [operatingSites, setOperatingSites] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [dataCenters, setDataCenters] = useState([]);

  const getDataCenters = async () => {
    const dto = await RoleService.dataCenter(authContext.token());
    if (dto.data?.data) {
      setDataCenters(dto.data?.data);
    }
  };

  const getCustomers = async (isShowResellerContact) => {
    const getCustomersDto = await CreateTicketService.getCompaniesContacts(
      authContext.token(),
      companyId,
      isShowResellerContact
    );
    setCustomers([]);
    setCustomers(
      getCustomersDto.data?.data
        .filter((customer) => !customer.ns_is_deleted)
        .map((customer) => {
          const { id, name } = customer;
          return { ...customer, label: name, value: id };
        })
    );
  };

  // const getCompaniesContacts = async () => {
  //   const getCustomersDto = await CreateTicketService.getCompaniesContacts(
  //     authContext.token(),
  //     companyId,
  //     isShowResellerContacts
  //   );

  //   const resellerContacts = getCustomersDto.data?.data
  //     .filter((customer) => !customer.ns_is_deleted)
  //     .map((customer) => {
  //       const { id, name } = customer;
  //       return { ...customer, label: name, value: id };
  //     });
  //   resellerContacts.forEach((ele) => {
  //     customers.push(ele);
  //   });
  //   setCustomers(customers);
  // };
  const getPermittedSites = (permittedSites, dataCenter) => {
    const fristNames = permittedSites.map((item) => item.name);
    const filteredSecondArray = dataCenter.filter((item) =>
      fristNames.includes(item.name)
    );
    return filteredSecondArray;
  };
  const getOperatingSites = async () => {
    setOperatingSites([]);
    const setValue = (list) => {
      setOperatingSites(
        _.orderBy(list, ["name"], ["asc"]).map((site) => {
          const { id, name } = site;
          return { ...site, label: name, value: id };
        })
      );
    };
    try {
      let code =
        country === "global"
          ? ""
          : countries.find((team) => team.country_code === country).name;
      country = code === "South Korea" ? "Korea" : code;
      const data = await CreateTicketService.getOperatingSites(
        authContext.token(),
        country
      );
      const sites = data;
      if (sites && sites.length > 0) {
        setValue(getPermittedSites(dataCenters, sites));
      } else if (sites.length === 0) {
        await Swal.fire("Error", "No Operating Sites for this Company.");
        setValue([]);
      }
    } catch (err) {
      await Swal.fire(t("error"), err.message);
    }
  };

  useEffect(() => {
    setDataCenters(authContext.getPermittedDataCenter);
  }, [authContext.getPermittedDataCenter]);

  useEffect(() => {
    if (companyId) {
      try {
        setLoading(true);
        if (!notFetchCustomers) {
          getCustomers(false);
        }
        getOperatingSites();
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  }, [companyId]);
  useEffect(() => {
    if (companyId) {
      getCustomers(isShowResellerContacts);
    }
  }, [isShowResellerContacts]);

  return {
    operatingSites,
    customers,
    dataCenters,
  };
};

export default useGetOperatingSites;
