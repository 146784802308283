import axios from "axios";
import baseURL from "./baseURL";
import Swal from "sweetalert2";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router-dom";

//api, localdedc,stgdedc,proddedc
const endPoint = process.env.REACT_APP_ENDPOINT;
const subcriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
let timer;

let second = process.env.REACT_APP_IDLE_TIME_OUT_IN_SEC;
const TenantId = process.env.REACT_APP_TENANT_ID;
const domain = window.location.origin;

let url = `https://login.microsoftonline.com/${TenantId}/oauth2/logout?post_logout_redirect_uri=${domain}`;

//prod
//7acfb9fa31f644b2ad9fb1cc6c6769de

//dev
//869163acffda4d148b9f490c72b8b13f

const startTimer = () => {
  timer = setInterval(() => {
    if (second === 60) {
      let timerInterval;

      Swal.fire({
        title: "Are you still there?",
        html: `<p>If not, we'll close this session in: <b>00:59</b></p>`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/><path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg> I'm here`,
        cancelButtonText: `
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ff7a00"><path d="M0 0h24v24H0z" fill="none"/><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg> Sign me Out`,
        timer: 60000,
        timerProgressBar: true,
        customClass: {
          cancelButton: "signoutBtn",
          confirmButton: "confirmBtn",
        },
        didOpen: () => {
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = `00:${second}`;
          }, 1000);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          clearInterval(timerInterval);
          resetTimer();
          second = process.env.REACT_APP_IDLE_TIME_OUT_IN_SEC;
          startTimer();
        } else {
          clearInterval(timerInterval);
          logoutAction();
        }
      });
    }
    if (second > 0) {
      second--;
    } else {
      clearInterval(timer);
      //logoutAction();
    }
  }, 1000);
};
const logoutAction = () => {
  const token = localStorage.getItem("token");
  if (!!token) {
    axiosWithToken(token).get(`LogoutSession`);
  }
  localStorage.clear();
  window.location.pathname = url;
};

const resetTimer = () => {
  if (timer) clearTimeout(timer);
};

const axiosWithToken = (token) => {
  const AuthHeader = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: localStorage.getItem("token"),
    "Ocp-Apim-Subscription-Key": subcriptionKey,
    "Ocp-Apim-Trace": true,
  };

  const api = axios.create({
    baseURL: `${baseURL + endPoint}/`,
    headers: AuthHeader,
  });

  api.interceptors.response.use(
    (response) => {
      if (
        response?.data?.code === 401 ||
        response?.data?.code === 403 ||
        (response?.data?.message !== undefined &&
          response?.data?.message.includes("401"))
      ) {
        localStorage.removeItem("token");
        Swal.fire({
          title: "Unauthorised",
          html: `<p>Your token has been expired. Please re-login.</p>`,
          icon: "warning",
          confirmButtonText: "Login Again",
          customClass: {
            cancelButton: "signoutBtn",
            confirmButton: "confirmBtn",
          },
        }).then((result) => {
          logoutAction();
        });
      } else {
        if (response.data.user) {
          localStorage.setItem("token", response.data.user.access_token);
        }
        return response;
      }
      //   if (response.data && response.data.message) {
      //     throw { message: response.data.message };
      //   }
    },
    (error) => {
      console.log(error.response);
      if (error.response?.status === 401 || error.response?.status === 403) {
        if (error.response?.data?.error) {
          if (error.response?.data?.error?.message.includes("Maintenance")) {
            const errorReponse = JSON.parse(
              error.response?.data?.error?.message
            );
            if (errorReponse.user.role.name == "Super Admin") {
              window.location.pathname =
                process.env.REACT_APP_TENANT_ID + "setting-maintenance-boss";
            } else {
              localStorage.removeItem("token");
              logoutAction();
            }
          } else {
            localStorage.removeItem("token");
            Swal.fire({
              title: "Unauthorised",
              html: `<p>Your token has been expired. Please re-login.</p>`,
              icon: "warning",
              confirmButtonText: "Login Again",
              customClass: {
                cancelButton: "signoutBtn",
                confirmButton: "confirmBtn",
              },
            }).then((result) => {
              logoutAction();
            });
          }
        } else {
          if (error.response?.data?.message.includes("Maintenance")) {
            const errorReponse = JSON.parse(error.response?.data?.message);
            if (errorReponse.user.role.name == "Super Admin") {
              window.location.pathname =
                process.env.REACT_APP_TENANT_ID + "setting-maintenance-boss";
            } else {
              localStorage.removeItem("token");
              logoutAction();
            }
          } else {
            localStorage.removeItem("token");
            Swal.fire({
              title: "Unauthorised",
              html: `<p>Your token has been expired. Please re-login.</p>`,
              icon: "warning",
              confirmButtonText: "Login Again",
              customClass: {
                cancelButton: "signoutBtn",
                confirmButton: "confirmBtn",
              },
            }).then((result) => {
              logoutAction();
            });
          }
        }
      }
      if (
        error.response?.status === 400 &&
        error.response?.data?.error?.error == "invalid_grant"
      ) {
        localStorage.removeItem("token");
        Swal.fire({
          title: "Unauthorised",
          html: `<p>Your token has been expired. Please re-login.</p>`,
          icon: "warning",
          confirmButtonText: "Login Again",
          customClass: {
            cancelButton: "signoutBtn",
            confirmButton: "confirmBtn",
          },
        }).then((result) => {
          logoutAction();
        });
      }
      if (error.response?.status === 500) {
        Swal.fire({
          title: "Error",
          html: `<p>${error.response?.statusText}</p>`,
          icon: "error",
          confirmButtonText: "Close",
          customClass: {
            cancelButton: "signoutBtn",
            confirmButton: "confirmBtn",
          },
        }).then((result) => {
          console.log(result);
        });
      }
      if (error?.response?.data?.error?.user) {
        localStorage.setItem(
          "token",
          error.response.data.error.user.access_token
        );
      }
      throw {
        error,
      };
    }
  );

  api.interceptors.request.use((req) => {
    if (localStorage.getItem("istimeout") === "false") {
      resetTimer();
      second = process.env.REACT_APP_IDLE_TIME_OUT_IN_SEC;
      startTimer();
    }

    return req;
  });

  return api;
};

const axiosWithoutToken = () => {
  const AuthHeader = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Ocp-Apim-Subscription-Key": subcriptionKey,
    "Ocp-Apim-Trace": true,
  };

  return axios.create({
    baseURL: `${baseURL + endPoint}/`,
    headers: AuthHeader,
  });
};

export { axiosWithoutToken, axiosWithToken };
