import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
const Result = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const modalRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    setIsOpen(props.show)
  }, [props.show])

  // const closeModal = () => {
  // 	modalRef.current.click();
  // }

  return (
    <div
      className="modal fade bd-example-modal-lg"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t("reports.results")} </h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              ref={modalRef}
              onClick={() => props.setShow(false)}
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div className="card-body" style={{ padding: "0px" }}>
                <div className="basic-form">{props.data.new_value}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Result
