import { css } from 'glamor';
import { TICKET_COMMON_STYLES } from '../constants';
import './TicketInputField.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const SpaceField = ({ SpaceValue, services }) => {
  const { t } = useTranslation();
  const [spaceids, setSpaceIds] = useState([]);
  const styles = {
    backgound: {
      backgroundColor: `${'rgba(240,240,240,1)'} !important`
    },
    borderStyle: {
      border: '0px'
    },
    padding: {
      paddingLeft: '0px'
    },
    ...TICKET_COMMON_STYLES
  };

  useEffect(() => {
    handleVal(SpaceValue, services);
  }, [SpaceValue, services]);

  const handleVal = (SpaceValue, services) => {
    let vals = [];
    let result = [];
    if (Array.isArray(SpaceValue)) {
      for (let i = 0; i < SpaceValue.length; i++) {
        vals.push(SpaceValue[i]?.id);
      }
    } else {
      vals.push(SpaceValue);
    }
    for (let i = 0; i < vals.length; i++) {
      let data = services.filter((data) => data.id == vals[i]);
      if (data[0]?.space) {
        result.push(data[0]?.space);
      }
    }
    setSpaceIds([...result]);
  };
  return (
    <div className="ticket-input-field form-floating" {...css(styles.borderBottom, styles.padding)}>
      <input
        {...css(styles.backgound, styles.borderStyle)}
        type="text"
        value={spaceids}
        className="form-control"
        id="floatingInput"
        readOnly
      />
      <label htmlFor="floatingInput" style={{ color: 'black !important', fontSize: '17px' }}>
      {t('create-ticket.site-access.space-id')}
      </label>
    </div>
  );
};

export default SpaceField;
