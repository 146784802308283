import AuthContext from "context";
import { useState, useContext } from "react";
import { LogoutOnSessionExpire } from "common/helpers";
import CabinetServices from "services/CabinetService";
import powerDistribution from "services/powerDistribution";
import bcmService from "services/bcmService";

const useGetCabinetsAndBreakers = ({
  selectedRoom,
  setIsLoading,
  setselectedRoom,
  nextCabinet,
  setNextCabinet,
  setMountForFetch,
  mountForFetch,
  nextBreaker,
  setNextBreaker,
  query,
  setQuery,
  setPageNumber,
  setItemsPerPage,
}) => {
  const contextStore = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [cabinetAscending, setCabinetAscending] = useState(true);
  const [cabinets, setCabinets] = useState([]);
  const [breakers, setBreakers] = useState([]);
  const [lastRefreshTime, setLastRefreshTime] = useState();
  const [total, setTotal] = useState(0);
  const filterCabinets = async (page, pageSize, room, isQuery) => {
    try {
      setIsLoading(true);
      setCabinetAscending(true);
      setselectedRoom(room);
      let body = {
        room_id: room.id,
        page: page,
        pageSize: pageSize,
      };
      if (query !== "" && isQuery !== false) {
        body[query.name] = query["value"];
      }
      await CabinetServices.searchCabinet(contextStore.token(), body)
        .then((res) => {
          if (res.data.data.length > 0) {
            setCabinets(
              res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1))
            );
            setTotal(res.data.total);
          } else {
            setCabinets([]);
            setTotal(0);
          }
        })
        .catch((err) => LogoutOnSessionExpire(err))
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const filterBreakers = async (page, pageSize, room, isQuery) => {
    try {
      setIsLoading(true);
      setselectedRoom(room);

      let body = {
        room_id: room.id,
        page: page,
        pageSize: pageSize,
      };
      if (query !== "" && isQuery != false) {
        body[query.name] = query["value"];
      }
      await powerDistribution
        .getBreakerList(contextStore.token(), body)
        .then((res) => {
          if (res.data.data.length > 0) {
            setBreakers(
              res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1))
            );
            setTotal(res.data.total);
          } else {
            setBreakers([]);
            setTotal(0);
          }
        })
        .catch((err) => LogoutOnSessionExpire(err))
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getCabinetsData = async (e, loader = true) => {
    try {
      if (e && e.id) {
        cleanUpTask();
        setCabinetAscending(true);
        if (loader) {
          setIsLoading(true);
        }

        setselectedRoom(e);
        await CabinetServices.selectByHallId(contextStore.token(), {
          room_id: e.id,
          offset: loader ? 0 : nextCabinet,
        })
          .then((res) => {
            setLastRefreshTime(res.data.lastRefresh);
            //setStatusPmi(res.data.pmi.length);
            if (res.data.data.length === 0) {
              setMountForFetch(false);
            }
            //  if(res.data.data.length > 0){
            if (loader) {
              setCabinets(
                res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1))
              );
            } else {
              setCabinets([
                ...cabinets,
                ...res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)),
              ]);
            }

            //  }
          })
          .catch((err) => LogoutOnSessionExpire(err))
          .finally(() => setIsLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBreakersData = async (room, loader = true) => {
    if (room && room.id) {
      cleanUpTask();
      if (loader) {
        setIsLoading(true);
      }
      setselectedRoom(room);
      try {
        const offset = loader ? 0 : nextBreaker;
        const dto = await powerDistribution.getBreakerByRoomIdws(
          contextStore.token(),
          room.id,
          offset
        );
        if (dto.data.data.length === 0) {
          setMountForFetch(false);
        }
        if (loader) {
          setBreakers(dto.data.data);
        } else {
          setBreakers([...breakers, ...dto.data.data]);
        }

        setIsLoading(false);
      } catch (err) {
        LogoutOnSessionExpire(err);
        setIsLoading(false);
      }
    }
  };

  const cleanUpTask = () => {
    setSearchQuery("");
    setselectedRoom(null);
  };

  const cleanOffset = () => {
    setNextCabinet(0);
    setNextBreaker(0);
  };

  return {
    searchQuery,
    setSearchQuery,
    cabinetAscending,
    setCabinetAscending,
    cabinets,
    breakers,
    getCabinetsData,
    getBreakersData,
    filterCabinets,
    filterBreakers,
    setCabinets,
    setBreakers,
    lastRefreshTime,
    total,
  };
};

export default useGetCabinetsAndBreakers;
