import AuthContext from "context";
import React, { useContext, useEffect, useState } from 'react';
import { oddoTest } from 'services/auth';
import { LogoutOnSessionExpire } from 'common/helpers';

const OddoTest = () => {

	const [state,setState] = useState("");
	const [dstate,setDState] = useState("");
	const authContext = useContext(AuthContext);

	useEffect(() => {
		
		oddoTest(authContext.token()).then(res => {

			setState(res.data.data);
		}).catch(err => {
			LogoutOnSessionExpire(err)
			setDState(JSON.stringify(err))
		})
		
	},[]);



	return (
		<React.Fragment >
		{
			state && state.map(s => {
				return <div style={{marginLeft:'2%',marginTop:'2%'}}>
				<p>id: {s.id} </p>
				<p>create_date: {s.create_date} </p>
				<p>stage_update: {s.stage_update} </p>
				<p>last_update: {s.last_update} </p>
				<p>assign_date: {s.assign_date} </p>
				<p>close_date: {s.close_date} </p>
				<p>operatingsite: {s.operatingsite} </p>
				<p>tickettypeid: {s.tickettypeid} </p>
				<p>tickettype: {s.tickettype} </p>
				<p>teamid: {s.teamid} </p>
				<p>team: {s.team} </p>
				<p>priority: {s.priority} </p>
				<p>company: {s.company} </p>
				<p>subject: {s.subject} </p>
				<p>description: {s.description} </p><br/>
				<hr/>

				</div>
			})
		}
		
		<br/>
		<br/>
		<h2> {dstate} </h2>
		</React.Fragment>
	);
}

export default OddoTest;