import { Line2 } from "components/graph"
import React, { useEffect, useRef } from "react"
import "./cabinet-popup.css"
import CloseIcon from "@mui/icons-material/Close"

const DataFloorPopup = (props) => {
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false)
  const modalRef = useRef(null)

  useEffect(() => {
    // setIsOpen(props.show);
  }, [props.show])

  // const closeModal = () => {
  // 	modalRef.current.click();
  // }

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  const getPowerStatus = (data) => {
    if (Number(data.status) === 1) {
      let available = Number(data.design_cabs) - Number(data.sold_cabs)

      let soldPer = (data.sold_cabs * 100) / data.design_cabs
      let availPer = (available * 100) / data.design_cabs

      return (
        <Line2
          totalText={data.design_cabs}
          inServiceText={data.sold_cabs}
          availableText={available}
          inServicePercent={soldPer}
          availablePercent={availPer}
          totalPercent={0}
        />
      )
    } else {
      return (
        <Line2
          totalText={data.design_cabs}
          inServiceText={0}
          availableText={0}
          inServicePercent={0}
          availablePercent={0}
          totalPercent={100}
        />
      )
    }
  }

  return (
    <div>
      {props.fileExists === false && (
        <div
          className="not-found-popup modal show bd-example-modal-lg"
          style={{ display: "block" }}
          id="exampleModalCenter"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header mt-24">
                <h3 className="modal-title notfound">Info not found</h3>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  data-bs-dismiss="modal"
                  ref={modalRef}
                  onClick={() => props.setShow(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.fileExists && (
        <div
          className=" modal show bd-example-modal-lg"
          style={{ display: "block" }}
          id="exampleModalCenter"
        >
          <div className="room-pek1 modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header mt-24">
                <h3 className="modal-title">
                  {props.selectedFloor.datacenter.name +
                    "-" +
                    props.selectedFloor?.name}
                </h3>

                <CloseIcon
                  style={{ cursor: "pointer" }}
                  data-bs-dismiss="modal"
                  ref={modalRef}
                  onClick={() => props.setShow(false)}
                />
              </div>

              <div className="modal-body" style={{ paddingBottom: "2.5rem" }}>
                <div className="card">
                  <div className="card-body" style={{ padding: "0px" }}>
                    {/* <p style={{ color: "#92979A", fontSize: "0.813rem" }}>
                      3 Floor: Rooms, Network Rooms and Cabling
                    </p> */}
                    <div className="svg-pic">
                      {showTooltip && (
                        <div className="pop-hover">
                          <p>statistics</p>
                          <p>lorem ipsum: 4</p>
                          <div className="triangle-bottom"></div>
                        </div>
                      )}
                      {props.selectedFloor.data_halls &&
                        props.selectedFloor.data_halls.map((data, i) => {
                          return (
                            <div className={"room-" + (i + 1)} key={i}>
                              {getPowerStatus(data)}
                            </div>
                          )
                        })}
                      {/* <div className={"room-1"}>
                                                         <img src={"images/"+props.selectedDataCenter.country_id+"/"+props.selectedDataCenter.id+"/"+props.selectedFloor.id+"/pke-d.png"} width="120px"/>
                                                      </div>
                                                      <div className="room-2">
                                                         <img src={"images/"+props.selectedDataCenter.country_id+"/"+props.selectedDataCenter.id+"/"+props.selectedFloor.id+"/pke-d.png"} width="120px"/>
                                                      </div>
                                                      <div className="room-3">
                                                         <img src={"images/"+props.selectedDataCenter.country_id+"/"+props.selectedDataCenter.id+"/"+props.selectedFloor.id+"/pke-d.png"} width="120px"/>
                                                      </div>
                                                      <div className="room-4">
                                                         <img src={"images/"+props.selectedDataCenter.country_id+"/"+props.selectedDataCenter.id+"/"+props.selectedFloor.id+"/pke-d.png"} width="120px"/>
                                                      </div> */}

                      <img src={props.filePath} alt="file" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DataFloorPopup
