import { XError } from "components/common"
import AuthContext from "context"
import React, { useContext, useEffect, useState } from "react"
import Common from "services/commonService"
import Room from "services/roomServices"
import Swal from "sweetalert2"
import "./capacityStyle.css"
import { LogoutOnSessionExpire } from "common/helpers"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

const EditRoom = (props) => {
  const authContext = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [statusData, setStatusData] = useState([])
  const { t } = useTranslation()
  const [state, setState] = useState({
    room_id: "",
    name: "",
    design_cabs: "",
    design_cages: "",
    design_power: "",
    sold_power: "",
    sold_cabs: "",
    sold_cages: "",
    reserved_power: "",
    reserved_cabs: "",
    reserved_cages: "",
    rofr_power: "",
    rofr_cabs: "",
    rofr_cages: "",
    blocked_power: "",
    blocked_cabs: "",
    blocked_cages: "",
    available_power: "",
    available_cabs: "",
    available_cages: "",
    status: "",
  })
  const [error, setError] = useState({
    name: "",
    design_cabs: "",
    design_cages: "",
    design_power: "",
    sold_power: "",
    sold_cabs: "",
    sold_cages: "",
    reserved_power: "",
    reserved_cabs: "",
    reserved_cages: "",
    rofr_power: "",
    rofr_cabs: "",
    rofr_cages: "",
    blocked_power: "",
    blocked_cabs: "",
    blocked_cages: "",
    available_power: "",
    available_cabs: "",
    available_cages: "",
    status: "",
  })

  useEffect(() => {
    if (authContext.getStatusData) {
      setStatusData(authContext.getStatusData)
    }
    // Common.status().then((res) => setStatusData(res.data.data))
    console.log(props)
    setState({
      room_id: props.editRoom.id,
      name: props.editRoom.name,
      design_cabs: props.editRoom.design_cabs,
      design_cages: props.editRoom.design_cages,
      design_power: props.editRoom.design_power,
      sold_cabs: props.editRoom.sold_cabs,
      sold_cages: props.editRoom.sold_cages,
      sold_power: props.editRoom.sold_power,
      reserved_cabs: props.editRoom.reserved_cabs,
      reserved_cages: props.editRoom.reserved_cages,
      reserved_power: props.editRoom.reserved_power,
      rofr_cabs: props.editRoom.rofr_cabs,
      rofr_cages: props.editRoom.rofr_cages,
      rofr_power: props.editRoom.rofr_power,
      blocked_cabs: props.editRoom.blocked_cabs,
      blocked_cages: props.editRoom.blocked_cages,
      blocked_power: props.editRoom.blocked_power,
      available_power: props.editRoom.available_power,
      available_cages: props.editRoom.available_cages,
      available_cabs: props.editRoom.available_cabs,
      status: props.editRoom.status,
    })

    return () => {
      //setCountries([]);
      //setDataCenters([]);
      setState({})
    }
  }, [props.show])

  const deleteRoom = async () => {
    closeModal()

    setState({ ...state, room_id: props.editRoom.id })
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await Room.deleteRoom(authContext.getToken, {
          ...state,
          room_id: props.editRoom.id,
        })
          .then(async (res) => {
            setIsLoading(false)
            props.setMountComponent(!props.mountComponent)
            props.selectDataCenterFloor(
              authContext.getNetworkDataCenter,
              props.floorKey
            )
            closeModal()
          })
          .catch((err) => {
            LogoutOnSessionExpire(err)

            setIsLoading(false)
            let error = {
              floor_id: "",
              name: "",
              design_cabs: "",
              design_cages: "",
              design_power: "",
              sold_power: "",
              sold_cabs: "",
              sold_cages: "",
              reserved_power: "",
              reserved_cabs: "",
              reserved_cages: "",
              rofr_power: "",
              rofr_cabs: "",
              rofr_cages: "",
              blocked_power: "",
              blocked_cabs: "",
              blocked_cages: "",
              available_power: "",
              available_cabs: "",
              available_cages: "",
              status: "",
            }
            const errors = err?.response?.data?.errors

            if (
              errors?.name !== undefined ||
              errors?.name !== "" ||
              errors?.name !== null
            ) {
              error.name = errors.name
            }
            if (
              errors?.design_cabs !== undefined ||
              errors?.design_cabs !== "" ||
              errors?.design_cabs !== null
            ) {
              error.design_cabs = errors.design_cabs
            }
            if (
              errors?.design_power !== undefined ||
              errors?.design_power !== "" ||
              errors?.design_power !== null
            ) {
              error.design_power = errors.design_power
            }
            if (
              errors?.design_cages !== undefined ||
              errors?.design_cages !== "" ||
              errors?.design_cages !== null
            ) {
              error.design_cages = errors.design_cages
            }
            if (
              errors?.sold_cabs !== undefined ||
              errors?.sold_cabs !== "" ||
              errors?.sold_cabs !== null
            ) {
              error.sold_cabs = errors.sold_cabs
            }
            if (
              errors?.sold_power !== undefined ||
              errors?.sold_power !== "" ||
              errors?.sold_power !== null
            ) {
              error.sold_power = errors.sold_power
            }
            if (
              errors?.sold_cages !== undefined ||
              errors?.sold_cages !== "" ||
              errors?.sold_cages !== null
            ) {
              error.sold_cages = errors.sold_cages
            }
            if (
              errors?.reserved_power !== undefined ||
              errors?.reserved_power !== "" ||
              errors?.reserved_power !== null
            ) {
              error.reserved_power = errors.reserved_power
            }
            if (
              errors?.reserved_cabs !== undefined ||
              errors?.reserved_cabs !== "" ||
              errors?.reserved_cabs !== null
            ) {
              error.reserved_cabs = errors.reserved_cabs
            }
            if (
              errors?.reserved_cages !== undefined ||
              errors?.reserved_cages !== "" ||
              errors?.reserved_cages !== null
            ) {
              error.reserved_cages = errors.reserved_cages
            }
            if (
              errors?.rofr_power !== undefined ||
              errors?.rofr_power !== "" ||
              errors?.rofr_power !== null
            ) {
              error.rofr_power = errors.rofr_power
            }
            if (
              errors?.rofr_cabs !== undefined ||
              errors?.rofr_cabs !== "" ||
              errors?.rofr_cabs !== null
            ) {
              error.rofr_cabs = errors.rofr_cabs
            }
            if (
              errors?.rofr_cages !== undefined ||
              errors?.rofr_cages !== "" ||
              errors?.rofr_cages !== null
            ) {
              error.rofr_cages = errors.rofr_cages
            }
            if (
              errors?.blocked_power !== undefined ||
              errors?.blocked_power !== "" ||
              errors?.blocked_power !== null
            ) {
              error.blocked_power = errors.blocked_power
            }
            if (
              errors?.blocked_cabs !== undefined ||
              errors?.blocked_cabs !== "" ||
              errors?.blocked_cabs !== null
            ) {
              error.blocked_cabs = errors.blocked_cabs
            }
            if (
              errors?.blocked_cages !== undefined ||
              errors?.blocked_cages !== "" ||
              errors?.blocked_cages !== null
            ) {
              error.blocked_cages = errors.blocked_cages
            }
            if (
              errors?.available_power !== undefined ||
              errors?.available_power !== "" ||
              errors?.available_power !== null
            ) {
              error.available_power = errors.available_power
            }
            if (
              errors?.available_cabs !== undefined ||
              errors?.available_cabs !== "" ||
              errors?.available_cabs !== null
            ) {
              error.available_cabs = errors.available_cabs
            }
            if (
              errors?.available_cages !== undefined ||
              errors?.available_cages !== "" ||
              errors?.available_cages !== null
            ) {
              error.available_cages = errors.available_cages
            }
          })
      }
    })
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    if (checkValidation()) {
      setState({ ...state, room_id: props.editRoom.id })
      console.log(props.editRoom.id)
      await Room.updateRoom(authContext.getToken, {
        ...state,
        room_id: props.editRoom.id,
      })
        .then(async (res) => {
          setIsLoading(false)

          props.setMountComponent(!props.mountComponent)

          //props.selectDataCenterFloor(props.data_center_id, props.floorIndex);
          closeModal()
          Swal.fire(t("datacenter.room_updated"))
          //props.selectDataCenterFloor(props.dataCenterId)
          props.selectDataCenterFloor(
            authContext.getNetworkDataCenter,
            props.floorKey
          )
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)

          setIsLoading(false)
          let error = {
            floor_id: "",
            name: "",
            cabinet: "",
            power: "",
            soldCabinet: "",
            cages: "",
            soldCages: "",
            soldkva: "",
            status: "",
          }
          const errors = err?.response?.data?.errors

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors.name
          }
          if (
            errors?.cabinet !== undefined ||
            errors?.cabinet !== "" ||
            errors?.cabinet !== null
          ) {
            error.cabinet = errors.cabinet
          }
          if (
            errors?.power !== undefined ||
            errors?.power !== "" ||
            errors?.power !== null
          ) {
            error.power = errors.power
          }
          // if(errors?.soldCabinet !== undefined || errors?.soldCabinet !== "" || errors?.soldCabinet !== null){
          // 	error.soldCabinet = errors.soldCabinet;
          // }
          if (
            errors?.cages !== undefined ||
            errors?.cages !== "" ||
            errors?.cages !== null
          ) {
            error.cages = errors.cages
          }
          // if(errors?.soldCages !== undefined || errors?.soldCages !== "" || errors?.soldCages !== null){
          // 	error.soldCages = errors.soldCages;
          // }
        })
    }
  }

  const checkValidation = () => {
    let error = {
      name: "",
      cabinet: "",
      power: "",
      soldCabinet: "",
      cages: "",
      soldCages: "",
      soldkva: "",
      status: "",
    }

    const { name, cabinet, power, cages, status } = state
    console.log(state)
    let flag = true

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required."
      flag = false
    }

    // if (cabinet === "" || cabinet === null || cabinet === undefined) {

    // 	error.cabinet = "The cabinet field is required.";
    // 	flag = false;
    // }
    // if (power === "" || power === null || power === undefined) {

    // 	error.power = "The power field is required.";
    // 	flag = false;
    // }
    // if (soldCabinet === "" || soldCabinet === null || soldCabinet === undefined) {

    // 	error.soldCabinet = "The soldCabinet field is required.";
    // 	flag = false;
    // }
    // if (cages === "" || cages === null || cages === undefined) {

    // 	error.cages = "The cages field is required.";
    // 	flag = false;
    // }
    if (status === "" || status === null || status === undefined) {
      error.status = "The status field is required."
      flag = false
    }
    setError({ ...error })
    return flag
  }

  const closeModal = () => {
    props.setShow(false)
  }

  return (
    <div
      className="modal show"
      style={{ display: "block" }}
      id="exampleModalCenter"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t("datacenter.edit_room")} </h3>
            <CloseIcon style={{ cursor: "pointer" }} onClick={closeModal} />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("datacenter.name")}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={state.name}
                          name="name"
                          onChange={(event) =>
                            setState({ ...state, name: event.target.value })
                          }
                        />
                        <XError message={error.name} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313 dt_td">
                        <table>
                          <thead>
                            <tr style={{ borderBottom: "2px solid black" }}>
                              <th></th>
                              <th
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {t("datacenter.cabEs")}
                              </th>
                              <th
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {t("datacenter.cages")}
                              </th>
                              <th
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {t("datacenter.kws")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{t("datacenter.total")}: </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.design_cabs || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      design_cabs: target.value
                                    })
                                  }
                                /> */}
                                {state?.design_cabs}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.design_cages || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      design_cages: target.value
                                    })
                                  }
                                /> */}
                                {state?.design_cages}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.design_power || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      design_power: target.value
                                    })
                                  }
                                /> */}
                                {state?.design_power}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("datacenter.sold")}: </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.sold_cabs || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      sold_cabs: target.value
                                    })
                                  }
                                /> */}
                                {state?.sold_cabs}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.sold_cages || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      sold_cages: target.value
                                    })
                                  }
                                /> */}
                                {state?.sold_cages}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.sold_power || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      sold_power: target.value
                                    })
                                  }
                                /> */}
                                {state?.sold_power}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("datacenter.reserved")}: </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.reserved_cabs || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      reserved_cabs: target.value
                                    })
                                  }
                                /> */}
                                {state?.reserved_cabs}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.reserved_cages || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      reserved_cages: target.value
                                    })
                                  }
                                /> */}
                                {state?.reserved_cages}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.reserved_power || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      reserved_power: target.value
                                    })
                                  }
                                /> */}
                                {state?.reserved_power}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>{t('datacenter.rofr')}: </td>
                              <td>
                                <input
                                  type="text"
                                  value={state?.rofr_cabs || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      rofr_cabs: target.value
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={state?.rofr_cages || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      rofr_cages: target.value
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={state?.rofr_power || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      rofr_power: target.value
                                    })
                                  }
                                />
                              </td>
                            </tr> */}
                            <tr>
                              <td>{t("datacenter.blocked")}: </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.blocked_cabs || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      blocked_cabs: target.value
                                    })
                                  }
                                /> */}
                                {state?.blocked_cabs}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.blocked_cages || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      blocked_cages: target.value
                                    })
                                  }
                                /> */}
                                {state?.blocked_cages}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.blocked_power || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      blocked_power: target.value
                                    })
                                  }
                                /> */}
                                {state?.blocked_power}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("datacenter.available")}: </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.available_cabs || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      available_cabs: target.value
                                    })
                                  }
                                /> */}
                                {state?.available_cabs
                                  ? state?.available_cabs
                                  : "0.00"}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.available_cages || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      available_cages: target.value
                                    })
                                  }
                                /> */}
                                {state?.available_cages
                                  ? state?.available_cages
                                  : "0.00"}
                              </td>
                              <td>
                                {/* <input
                                  type="text"
                                  value={state?.available_power || ''}
                                  onChange={({ target }) =>
                                    setState({
                                      ...state,
                                      available_power: target.value
                                    })
                                  }
                                /> */}
                                {state?.available_power
                                  ? state?.available_power
                                  : "0.00000"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("datacenter.status")}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <select
                          value={state.status}
                          onChange={(event) => {
                            setState({
                              ...state,
                              status: event.target.value,
                            })
                          }}
                          className="default-select form-control wide"
                        >
                          {statusData &&
                            statusData.map((status) => {
                              if (status.status_type_id === 1) {
                                return (
                                  <option value={status.id} key={status.id}>
                                    {status.status_name}
                                  </option>
                                )
                              }
                            })}
                        </select>
                        <XError message={error.status} />
                      </div>
                    </div>

                    <div
                      className="toolbar toolbar-bottom mt-51 d-flex justify-content-between"
                      role="toolbar"
                    >
                      <div className="delt">
                        <button
                          type="button"
                          onClick={() => deleteRoom()}
                          className="btn btn-outline-primary mr_1 red_color"
                        >
                          <img
                            src="\images\trash-2.svg"
                            alt=""
                            style={{
                              width: "11px",
                              marginTop: "-0.188rem",
                              marginRight: "0.5rem",
                            }}
                          />
                          {t("common.delete")}
                        </button>
                      </div>
                      <div className="usr_cncl">
                        <button
                          type="button"
                          onClick={closeModal}
                          style={{ marginRight: "1rem" }}
                          className="btn btn-outline-primary"
                        >
                          {t("common.cancel")}
                        </button>

                        {isLoading ? (
                          <button type="button" className="btn btn-primary">
                            {" "}
                            {t("common.loading")}...{" "}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => onSubmit(props.data_center_id)}
                            className="btn btn-primary"
                          >
                            {" "}
                            {t("common.save")}{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditRoom
