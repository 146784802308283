import { XAlert } from "components/common";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useContext } from "react";
import JSZip from "jszip";
import BcmService from "services/bcmService";
import AuthContext from "context";
import Swal from "sweetalert2";

const BcmExcelUploadModal = ({
  setShowExcelUploadModal,
  dataCenter,
  setIsLoading,
  isLoading,
  refresh,
}) => {
  const authContext = useContext(AuthContext);
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    type: "",
  });
  const [file, setFile] = useState(null);
  useEffect(() => {
    console.log(dataCenter);
  }, [dataCenter]);

  const closeModal = () => {
    setShowExcelUploadModal(false);
  };

  const onFileChange = (e) => {
    setMessage({
      message: "",
      type: "",
    });
    const fileName = e.target.files[0].name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (fileExtension !== "xlsx") {
      setMessage({
        message: "Please select a file with .xlsx extension.",
        type: "danger",
      });
      setIsValid(false);
    } else {
      setIsValid(true);
      setFile(e.target.files[0]);
    }
  };

  const submitExcelUpload = async () => {
    setIsLoading(true);
    const zip = new JSZip();
    zip.file(file.name, file);
    try {
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const formData = new FormData();
      formData.append("file", zipBlob, file.name);
      await BcmService.uploadBcmMappingFile(
        authContext.token(),
        dataCenter,
        formData
      ).then((res) => {
        setShowExcelUploadModal(false);
        refresh();
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error.error.response);
      if (error?.error?.response?.status == 400) {
        setIsValid(false);
        setMessage({ message: error.error.response.data, type: "danger" });
      }
    }
  };
  return (
    <div
      className="modal show bd-example-modal-lg"
      style={{
        display: "block",
        overflow: "auto",
        zIndex: isLoading ? 1 : 1050,
      }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title">
              Excel Upload for BCM Mapping {dataCenter}{" "}
            </h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              onClick={closeModal}
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "auto" }}
              >
                <div clasdsName="basic-form">
                  <form className="mt-5 mb-5">
                    <div className="row p-0 m-0">
                      <div className="col-md-6 mt-2313">
                        <input
                          type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={onFileChange}
                        />
                      </div>
                    </div>

                    <div className="row p-0 m-0">
                      <div className="mt-3 p-3">
                        <XAlert message={message.message} type={message.type} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                role="toolbar"
              >
                <button
                  type="button"
                  onClick={closeModal}
                  style={{ marginRight: 15 }}
                  className="btn btn-outline-primary mr_1"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  type="button"
                  onClick={() => submitExcelUpload()}
                  disabled={!isValid}
                  className="btn btn-primary"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BcmExcelUploadModal;
