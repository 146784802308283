import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";
import Toggle from "../Shared/toggle";

const Colocation = ({
  permission,
  specificPermission,
  onSubmit,
  data,
  getRoleDetail,
}) => {
  const [permissionState, setPermissionState] = useState([]);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (data && data?.role_provisioning && data?.role_provisioning.length > 0) {
      const roleProvisioning = data?.role_provisioning.filter(
        (el) => el.provisioning_type_id === 1
      )[0];
      if (roleProvisioning) {
        setIsAllowed(roleProvisioning.is_allowed);
      }
      if (
        roleProvisioning &&
        roleProvisioning.roleProvisioningPermission &&
        roleProvisioning.roleProvisioningPermission.length > 0
      ) {
        const updatedArray = [...permission, ...specificPermission].map(
          (item2) => {
            const matchedItem = roleProvisioning.roleProvisioningPermission.find(
              (item1) => item1.provisioning_permission_id === item2.id
            );
            if (matchedItem) {
              return {
                ...item2,
                value: matchedItem.is_allowed,
              };
            }
            return item2;
          }
        );
        setPermissionState(updatedArray);
      } else {
        setPermissionState([...permission, ...specificPermission]);
      }
    } else {
      setPermissionState([...permission, ...specificPermission]);
    }
  }, [permission]);

  useEffect(() => {
    console.log(permissionState);
  }, [permissionState]);

  useEffect(() => {
    if (data && data?.role_provisioning && data?.role_provisioning.length > 0) {
      const roleProvisioning = data?.role_provisioning.filter(
        (el) => el.provisioning_type_id === 1
      )[0];
      if (roleProvisioning) {
        setIsAllowed(roleProvisioning.is_allowed);
      }
      if (
        roleProvisioning &&
        roleProvisioning.roleProvisioningPermission &&
        roleProvisioning.roleProvisioningPermission.length > 0
      ) {
        const updatedArray = [...permission, ...specificPermission].map(
          (item2) => {
            const matchedItem = roleProvisioning.roleProvisioningPermission.find(
              (item1) => item1.provisioning_permission_id === item2.id
            );
            if (matchedItem) {
              return {
                ...item2,
                value: matchedItem.is_allowed,
              };
            }
            return item2;
          }
        );
        setPermissionState(updatedArray);
      }
    }
  }, [data]);

  const getValue = (id) => {
    return permissionState.find((el) => el.id === id).value === 1;
  };

  const setValue = (id, event) => {
    const updatedState = permissionState.map((item) =>
      item.id === id ? { ...item, value: event.target.checked ? 1 : 0 } : item
    );
    setPermissionState(updatedState);
  };

  const selectAll = () => {
    const updatedState = permissionState.map((item) => ({
      ...item,
      value: 1,
    }));
    setPermissionState(updatedState);
  };
  const unSelectAll = () => {
    const updatedState = permissionState.map((item) => ({
      ...item,
      value: 0,
    }));
    setPermissionState(updatedState);
  };

  const checkIfSelectAll = () => {
    return permissionState.filter((el) => el.value == 0).length == 0
      ? true
      : false;
  };

  const handleConfirm = () => {
    const payload = {
      permission: permissionState,
      provisioningType: 1,
      isAllowed: isAllowed ? 1 : 0,
      roleId: data.id,
    };
    onSubmit(payload);
  };

  const handleCancel = () => {
    if (data && data?.role_provisioning && data?.role_provisioning.length > 0) {
      const roleProvisioning = data?.role_provisioning.filter(
        (el) => el.provisioning_type_id === 1
      )[0];

      if (roleProvisioning) {
        setIsAllowed(roleProvisioning.is_allowed);
      }
      if (
        roleProvisioning &&
        roleProvisioning.roleProvisioningPermission &&
        roleProvisioning.roleProvisioningPermission.length > 0
      ) {
        const updatedArray = [...permission, ...specificPermission].map(
          (item2) => {
            const matchedItem = roleProvisioning.roleProvisioningPermission.find(
              (item1) => item1.provisioning_permission_id === item2.id
            );
            if (matchedItem) {
              return {
                ...item2,
                value: matchedItem.is_allowed,
              };
            }
            return item2;
          }
        );
        setPermissionState(updatedArray);
      } else {
        setIsAllowed(false);
        setPermissionState([...permission, ...specificPermission]);
      }
    } else {
      setIsAllowed(false);
      setPermissionState([...permission, ...specificPermission]);
    }
  };
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography sx={{ paddingLeft: "1rem", fontWeight: "bold" }}>
          Install Base - Colocation
        </Typography>
        <Toggle isAllowed={isAllowed} setIsAllowed={setIsAllowed}></Toggle>
      </AccordionSummary>
      <AccordionDetails>
        {checkIfSelectAll() == false && (
          <Button
            variant="outlined"
            sx={{
              marginLeft: "1rem",
              textTransform: "none",
              borderColor: "#FE8600", // Custom border color
              color: "#FE8600", // Custom text color
              "&:hover": {
                borderColor: "#FE8600", // Custom border color on hover
                color: "#FE8600", // Custom text color on hover
              },
            }}
            onClick={selectAll}
          >
            Select All
          </Button>
        )}
        {checkIfSelectAll() == true && (
          <Button
            variant="outlined"
            sx={{
              marginLeft: "1rem",
              textTransform: "none",
              borderColor: "#FE8600", // Custom border color
              color: "#FE8600", // Custom text color
              "&:hover": {
                borderColor: "#FE8600", // Custom border color on hover
                color: "#FE8600", // Custom text color on hover
              },
            }}
            onClick={unSelectAll}
          >
            Unselect All
          </Button>
        )}
        <div className="row mt-3 px-5">
          {permissionState &&
            permissionState.length > 0 &&
            permission &&
            permission.length > 0 &&
            permission.map((el) => (
              <div className="col-md-4" key={el.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValue(el.id)}
                      onChange={(event) => setValue(el.id, event)}
                    />
                  }
                  label={el.name}
                  sx={{ color: el.name === "Archive" ? "red" : "" }}
                />
              </div>
            ))}
        </div>

        <div className="specific_box">
          <Typography
            sx={{
              paddingLeft: "1rem",
              marginTop: "1rem",
              fontWeight: "bold",
            }}
          >
            Specific:
          </Typography>
          <div className="mt-3 px-5">
            {permissionState &&
            permissionState.length > 0 &&
            specificPermission &&
            specificPermission.length > 0 ? (
              specificPermission.map((el) => (
                <div className="col-md-4" key={el.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValue(el.id)}
                        onChange={(event) => setValue(el.id, event)}
                      />
                    }
                    label={el.name}
                  />
                </div>
              ))
            ) : (
              <div className="col-md-4">
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={"N/A"}
                  disabled
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="toolbar toolbar-bottom mt-51"
          style={{ textAlign: "right", padding: ".5rem 0rem" }}
          role="toolbar"
        >
          <button
            type="button"
            style={{ marginRight: "1rem" }}
            className="btn btn-outline-primary mr_1"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            style={{ borderRadius: "4px" }}
            className="btn btn-primary"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Colocation;
