import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TICKET_MODAL_BACKGROUND_COLOR } from "../../shared/constants";
import TicketSelectField from "../../shared/TicketSelectField";
import BoxSelect from "../../shared/BoxSelect";
import "./LeftPart.scss";
import * as utils from "../../shared/utils";
import TicketSwitchButton from "../../shared/TicketSwitchButton";
import { css } from "glamor";
import TicketInputField from "../../shared/TicketInputField";
import TicketLabelAndDate from "../../shared/TicketLabelAndDate";
import { useContext, useEffect, useState } from "react";
import ShipmentTicketFormContext from "../context/ShipmentTicketFormContext";
import SpaceField from "../../shared/TicketInputField/spaceField";
import TicketCheckBox from "../../shared/TicketCheckBox";

const LeftPart = () => {
  const { t } = useTranslation();
  const form = useContext(ShipmentTicketFormContext);
  const [isEndCustomer, setEndCustomer] = useState(false);
  const [isShowResellerContacts, setShowResellerContacts] = useState(false);
  const globalOption = {
    label: t("global"),
    value: "global",
  };

  const getColStyle = (index) => {
    return {
      paddingLeft: index === 0 ? "0px !important" : null,
    };
  };

  const shipmentTypeButtons = [
    {
      controlId: "inbound",
      label: t("create-ticket.shipment.switch-button-inbound").toUpperCase(),
      selected: form.formik.values.shipmentType === "inbound",
    },
    {
      controlId: "outbound",
      label: t("create-ticket.shipment.switch-button-outbound").toUpperCase(),
      selected: form.formik.values.shipmentType === "outbound",
    },
  ];

  const handleShipmentTypeButtonClick = (id, controlId) => {
    const { setFieldValue } = form.formik;
    if (controlId === "inbound") {
      setFieldValue(id, "inbound");
    } else if (controlId === "outbound") {
      setFieldValue(id, "outbound");
    }
  };

  const handelCheckboxToggle = () => {
    setShowResellerContacts(!isShowResellerContacts);
    form.formik.setFieldValue(
      "isShowResellerContacts",
      !isShowResellerContacts
    );
    form.formik.setFieldValue("customer", "");
  };

  useEffect(() => {
    if (form.formik.values.companyName != "") {
      const isEndCustomer = utils.isSelectedCompanyEndCustomer(
        form.formik.values.companyName,
        form.companies
      );
      setEndCustomer(isEndCustomer);
    }
    form.formik.setFieldValue("isShowResellerContacts", false);
  }, [form.formik.values.companyName]);
  useEffect(() => {
    if (form.handlingInstructions && form.handlingInstructions.length > 0) {
      form.formik.setFieldValue(
        "handlingInstruction",
        form.handlingInstructions[0].id
      );
    }
  }, [form.handlingInstructions]);

  return (
    <>
      <div className="create-ticket-left-part-upper-section">
        <Row>
          <BoxSelect
            id="country"
            setFieldValue={form.formik.setFieldValue}
            value={form.formik.values.country}
            options={[...form.countries]}
            label={t("create-ticket.site-access.country").toUpperCase()}
            placeholder={t(
              "create-ticket.site-access.operating-site-placeholder"
            )}
            active
          />
        </Row>
        <Row>
          <TicketSelectField
            id="companyName"
            label={t("create-ticket.site-access.company-name")}
            setFieldValue={form.formik.setFieldValue}
            required
            active={form.isActive("companyName")}
            options={form.companies}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            placeholder={t(
              "create-ticket.site-access.company-name-placeholder"
            )}
            value={form.formik.values.companyName}
            isCompanyLoading={form.isCompanyLoading}
          />
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <BoxSelect
            id="operatingSite"
            setFieldValue={form.formik.setFieldValue}
            value={form.formik.values.operatingSite}
            options={form.operatingSites}
            label={t("create-ticket.site-access.operating-site").toUpperCase()}
            placeholder={t(
              "create-ticket.site-access.operating-site-placeholder"
            )}
            active={form.isActive("operatingSite")}
          />
        </Row>

        <Row className="p_relative">
          {isEndCustomer &&
            form.formik.values.companyName &&
            form.isActive("customer") && (
              <div className="show_reseller_contacts">
                <TicketCheckBox
                  label="Show Reseller Contacts"
                  checked={form.formik.values.isShowResellerContacts}
                  active={true}
                  handelCheckboxToggle={() => handelCheckboxToggle()}
                />{" "}
              </div>
            )}
          <TicketSelectField
            id="customer"
            label={t("create-ticket.site-access.customer")}
            setFieldValue={form.formik.setFieldValue}
            active={form.isActive("customer")}
            options={form.customers}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            placeholder={t("create-ticket.site-access.customer-placeholder")}
            value={form.formik.values.customer}
          />
        </Row>
        <div className="mt-3 pt-1 d-flex flex-row justify-content-between">
          {shipmentTypeButtons.map((button, index) => (
            <Col key={button.controlId} {...css(getColStyle(index))}>
              <TicketSwitchButton
                id="shipmentType"
                controlId={button.controlId}
                label={button.label}
                handleClick={handleShipmentTypeButtonClick}
                active={form.isActive("shipmentType")}
                selected={button.selected}
                extraStyles={{
                  width: "210px",
                }}
              />
            </Col>
          ))}
        </div>
        <Row>
          <TicketInputField
            id="subject"
            value={form.formik.subject}
            setFieldValue={form.formik.setFieldValue}
            label={t("create-ticket.site-access.ticket-subject")}
            active={form.isActive("subject")}
            required
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
          />
        </Row>
        <Row>
          <TicketInputField
            id="description"
            value={form.formik.values.description}
            setFieldValue={form.formik.setFieldValue}
            label={t("create-ticket.site-access.description")}
            active={form.isActive("description")}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
          />
        </Row>
        <Row>
          <TicketSelectField
            id="handlingInstruction"
            label={t("create-ticket.shipment.handlingInstruction")}
            setFieldValue={form.formik.setFieldValue}
            required
            active={form.isActive("handlingInstruction")}
            options={form.handlingInstructions}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            value={form.formik.values.handlingInstruction}
            defaultValue={form.handlingInstructions[0]}
          />
        </Row>
        <Row>
          <TicketSelectField
            id="serviceId"
            label={t("create-ticket.site-access.service-id")}
            setFieldValue={form.formik.setFieldValue}
            active={form.isActive("serviceId")}
            options={form.services}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            placeholder={t("create-ticket.site-access.service-id-placeholder")}
            value={form.formik.values.serviceId}
            // menuPlacement="top"
            isServiceLoading={form.isServiceLoading}
          />
        </Row>
        <Row>
          <SpaceField
            SpaceValue={form.formik.values.serviceId}
            services={form.services}
          />
        </Row>
        <Row>
          <TicketLabelAndDate
            label={
              form.formik.values.shipmentType === "inbound"
                ? t("create-ticket.shipment.expected-delivery-date")
                : t("create-ticket.shipment.expected-pickup-date")
            }
            id="expectedDate"
            isTopPlacement
            value={form.formik.values.expectedDate}
            setFieldValue={form.formik.setFieldValue}
            active={form.isActive("expectedDate")}
            // active
            required
            format="YYYY-MM-DD"
          />
        </Row>
        <Row>
          <Col className="p-0">
            <TicketInputField
              id="courierCompany"
              value={form.formik.courierCompany}
              setFieldValue={form.formik.setFieldValue}
              label={t("create-ticket.shipment.courier-company")}
              active={form.isActive("expectedDate")}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            />
          </Col>
          <Col className="p-0" style={{ marginLeft: "15px" }}>
            <TicketInputField
              id="carLicense"
              value={form.formik.carLicense}
              setFieldValue={form.formik.setFieldValue}
              label={t("create-ticket.shipment.car-license")}
              active={form.isActive("expectedDate")}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LeftPart;
