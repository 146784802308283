import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class User {
  async index(token, body) {
    return await axios(token).post(`UserList`, body);
  }

  async store(token, data) {
    return await axios(token).post(`UserCreate`, data);
  }
  async changeStatus(token, data) {
    return await axios(token).post(`UserStatus`, data);
  }
  async update(token, data) {
    return await axios(token).post("UserUpdate", data);
  }
  async destroy(token, data) {
    return await axios(token).post("DeleteUser", data);
  }
  async resend(token, data) {
    return await axios(token).post("ResendEmail", data);
  }
  async getPosition(token) {
    return await axios(token).get("getPositions");
  }
  async getDepartment(token) {
    return await axios(token).get("getDepartments");
  }

  async updateDefaultPage(token, data) {
    return await axios(token).post("defaultPage", data);
  }
  // async getFilterUser(token,data){
  // 	return await axios(token).get('getFilterUser',data);
  // }
}

export default new User();
