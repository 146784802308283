import AuthContext from 'context';
import React, { useContext, useRef, useState, useEffect } from 'react';
import RoleModel from 'services/roleServices';
import { subMenu } from '../PowerDistribution/data';
import LayoutWithDataCenter from '../../Component/LayoutWithDataCenter';
import './CrossConnect.css';
import Loading from 'views/super-admin/pre-loader/loader';
import CrossConnectTable from './CrossConnectTable';

const CrossConnect = (props) => {
  const authContext = useContext(AuthContext);
  const [dataCenter, setDataCenter] = useState([]);
  const [countryName, setCountryName] = useState('Country');
  const initialMount = useRef(true);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [activeTab, setActiveTab] = useState(
    authContext.getNetworkDataCenter?.id
  );
  const [country, setCountry] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  return (
    <>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <LayoutWithDataCenter
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
        initialMount={initialMount}
        dataCenter={dataCenter}
        setDataCenter={setDataCenter}
        countryName={countryName}
        setCountryName={setCountryName}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        country={country}
        setCountry={setCountry}
        subMenu={subMenu}
      >
        {/*Cross Connect Block*/}
        <CrossConnectTable 
          selectedDataCenter={authContext.getNetworkDataCenter}
          setIsLoading={setIsLoading}
          isReadOnly={false}
        />
      </LayoutWithDataCenter>
    </>
  );
};

export default CrossConnect;
