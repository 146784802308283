import { XError } from "components/common"
import AuthContext from "context"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import CabinetService from "services/CabinetService"
import Common from "services/commonService"
import floorServices from "services/floorServices"
import powerDistribution from "services/powerDistribution"
import Swal from "sweetalert2"
import { LogoutOnSessionExpire } from "common/helpers"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
const CreateRRP = (props) => {
  const authContext = useContext(AuthContext)
  const [state, setState] = useState({
    name: "",
    desc: "",
    make_id: "",
    model_id: "",
    num_breakers: "",
    sn: "",
    ups_id: "",
    kW: "",
    data_center_id: props.activeDataCenter,
    room_id: "",
    floor_id: "",
    cabinet_id: "",
    status: "",
    support_status: "",
    support_expiry: "",
    is_auto_breaker: "",
  })
  const [error, setError] = useState({
    name: "",
    status: "",
    num_breakers: "",
    support_status: "",
  })
  const [floor, setFloor] = useState([])
  const [room, setRoom] = useState([])
  const [status, setStatus] = useState([])
  const [cabinet, setCabinet] = useState([])
  const [upss, setUpss] = useState([])
  const { t } = useTranslation()

  const closeModal = () => {
    props.setModalState({
      UPSCreateIsON: false,
      UPSUpdateIsON: false,
      RRPCreateIsON: false,
      RRPUpdateIsON: false,
      BreakerCreateIsON: false,
      BreakerUpdateIsON: false,
    })
    props.setIsValueChange(!props.isValueChange)
  }

  useEffect(() => {
    if (authContext.getStatusData) {
      const statusData = authContext.getStatusData.filter(
        (data) => data.status_type_id === 6
      )
      setStatus(statusData)
      //set first value selected
      if (statusData && statusData.length) {
        setState({
          ...state,
          status: statusData[0].id,
        })
      }
    }
    // Common.status().then((res) => {
    //   const statusData = res.data.data.filter(
    //     (data) => data.status_type_id === 6
    //   )
    //   setStatus(statusData)
    //   //set first value selected
    //   if (statusData && statusData.length) {
    //     setState({
    //       ...state,
    //       status: statusData[0].id,
    //     })
    //   }
    // })
    floorServices
      .floorByDataCenterId(authContext.token(), { id: props.activeDataCenter })
      .then((res) => setFloor(res.data.data))
    powerDistribution
      .getUPS(authContext.token(), props.activeDataCenter)
      .then((res) => setUpss(res.data.data))
    //CabinetService.get
  }, [])

  const getRoom = (id) => {
    const data = floor.filter((d) => d.id === Number(id))
    setRoom(data[0].rooms.filter((d) => d.room_type_id === 7))
    setCabinet([])
  }

  const getCabinets = async (id) => {
    if (id !== "") {
      CabinetService.getAllCabinetsRoomID(authContext.token(), id).then((res) =>
        setCabinet(res.data.data)
      )
    } else {
      setCabinet([])
    }
  }

  const submitRppData = async () => {
    if (checkValidation()) {
      await powerDistribution
        .createRPP(authContext.getToken, state)
        .then(async (res) => {
          closeModal()
          Swal.fire(t("pwrdist.new_rpp_is_created"))
          //props.selectDataCenterFloor(props.dataCenterId)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          let error = {
            name: "",
            status: "",
            num_breakers: "",
            support_status: "",
          }
          const errors = err?.response?.data?.errors

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors?.name
          }

          if (
            errors?.status !== undefined ||
            errors?.status !== "" ||
            errors?.status !== null
          ) {
            error.status = errors?.status
          }

          if (
            errors?.num_breakers !== undefined ||
            errors?.num_breakers !== "" ||
            errors?.num_breakers !== null
          ) {
            error.num_breakers = errors?.num_breakers
          }

          if (
            errors?.support_status !== undefined ||
            errors?.support_status !== "" ||
            errors?.support_status !== null
          ) {
            error.support_status = errors?.support_status
          }

          setError({ ...error })
        })
    }
  }

  const checkValidation = () => {
    let error = {
      name: "",
      status: "",
      num_breakers: "",
      support_status: "",
    }

    const { name, status, num_breakers, support_status } = state

    let flag = true

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required."
      flag = false
    }

    if (status === "" || status === null || status === undefined) {
      error.status = "The status field is required."
      flag = false
    }

    if (
      num_breakers === "" ||
      num_breakers === null ||
      num_breakers === undefined
    ) {
      error.num_breakers = "The no. of breaker field is required."
      flag = false
    }

    if (
      support_status === "" ||
      support_status === null ||
      support_status === undefined
    ) {
      error.support_status = "The expiry status field is required."
      flag = false
    }
    setError({ ...error })
    return flag
  }

  return (
    <div
      className="modal show bd-example-modal-lg"
      style={{ display: "block" }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t("pwrdist.add_device_details")}</h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              onClick={closeModal}
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.hostname")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("pwrdist.hostname")}
                          onChange={(event) =>
                            setState({ ...state, name: event.target.value })
                          }
                        />
                        <XError message={error.name} />
                      </div>
                      <div className="col-md-6 mt-2313"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.serial_number")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("pwrdist.serial_number")}
                          onChange={(event) =>
                            setState({ ...state, sn: event.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.status")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              status: event.target.value,
                            })
                          }
                        >
                          {status &&
                            status.map((status, i) => {
                              //if (status.status_type_id === 6) {
                              return (
                                <option value={status.id} key={status.id}>
                                  {status.status_name}
                                </option>
                              )
                              //}
                            })}
                        </select>
                        <XError message={error.status} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.floor")}
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) => {
                            setState({
                              ...state,
                              floor_id: event.target.value,
                            })
                            getRoom(event.target.value)
                          }}
                        >
                          <option value="">{t("pwrdist.choose")} ...</option>
                          {floor &&
                            floor.map((floor) => {
                              return (
                                <option value={floor.id} key={floor.id}>
                                  {floor.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.room")}{" "}
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) => {
                            setState({
                              ...state,
                              room_id: event.target.value,
                            })
                            getCabinets(event.target.value)
                          }}
                        >
                          <option value="">{t("pwrdist.choose")} ...</option>
                          {room &&
                            room.map((hall) => {
                              return (
                                <option value={hall.id} key={hall.id}>
                                  {hall.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.cabinet_id")}
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              cabinet_id: event.target.value,
                            })
                          }
                        >
                          <option value="">{t("pwrdist.choose")} ...</option>
                          {cabinet &&
                            cabinet.map((cab) => {
                              return (
                                <option value={cab.id} key={cab.id}>
                                  {cab.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.of_breakers")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={t("pwrdist.of_breakers")}
                          onChange={(event) =>
                            setState({
                              ...state,
                              num_breakers: event.target.value,
                            })
                          }
                        />
                        <div className="form-check mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                            id="flexCheckDefault"
                            onChange={(event) => {
                              setState({
                                ...state,
                                is_auto_breaker: event.target.value,
                              })
                            }}
                          />
                          <XError message={error.num_breakers} />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                            style={{
                              fontSize: "0.938rem",
                              fontWeight: 500,
                              marginTop: "0.4rem",
                            }}
                          >
                            {t("pwrdist.auto_create_breaker_ids")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-md-6 col-sm-6"
                        style={{ marginTop: "-15px" }}
                      >
                        <label className="form-label">
                          {t("pwrdist.ups_id")}
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({ ...state, ups_id: event.target.value })
                          }
                        >
                          <option value="">{t("pwrdist.choose")} ...</option>
                          {upss &&
                            upss.map((up) => {
                              return (
                                <option value={up.id} key={up.id}>
                                  {up.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mt-2313">
                        <p className="blue_txt_de">
                          {t("pwrdist.maintenance_and_support")}
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.status")}{" "}
                          <span className="red_star">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              support_status: event.target.value,
                            })
                          }
                        >
                          <option value="">{t("pwrdist.choose")}...</option>
                          <option value={1}>Active</option>
                          <option value={2}>Inactive</option>
                        </select>
                        <XError message={error.support_status} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("pwrdist.expiry_date")}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name=""
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(event) =>
                            setState({
                              ...state,
                              support_expiry: event.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                role="toolbar"
              >
                <button
                  type="button"
                  onClick={closeModal}
                  style={{ marginRight: 15 }}
                  className="btn btn-outline-primary mr_1"
                >
                  {" "}
                  {t("common.cancel")}{" "}
                </button>
                <button
                  type="button"
                  onClick={() => submitRppData()}
                  className="btn btn-primary"
                >
                  {t("common.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateRRP
