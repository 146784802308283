import ticketService from 'services/ticketService';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from './table';
import RemoteHandPopup from 'views/super-admin/Ticket/components/Ticket Detail Popup/remote-hands';
import SiteFaultPopup from 'views/super-admin/Ticket/components/Ticket Detail Popup/site-access-popup';
import FaultPopup from 'views/super-admin/Ticket/components/Ticket Detail Popup/fault';
import IncidentManagementPopup from 'views/super-admin/Ticket/components/Ticket Detail Popup/incident-management';
import ShipmentInboundPopup from 'views/super-admin/Ticket/components/Ticket Detail Popup/shipment-inbound';
import ShipmentOutboundPopup from 'views/super-admin/Ticket/components/Ticket Detail Popup/shipment-outbound';
import ErrorModel from 'views/super-admin/Ticket/components/errormodel';
import ticketDetails from 'services/ticketDetails';
import SerachBox from '../searchBox';
const TicketsTab = (props) => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [ticketList, setTicketList] = useState([]);
  const [ticketdata, setTicketData] = useState([]);
  const [faultpopup, setFaultPopup] = useState(false);
  const [siteAccess, setSiteAccess] = useState(false);
  const [shipmentout, setShipmentOut] = useState(false);
  const [shipmentin, setShipmentIn] = useState(false);
  const [incident, setIncident] = useState(false);
  const [errorpopup, setErrorPopup] = useState(false);
  const [helpdesk, setHelpdesk] = useState('');
  const [sitename, setSiteName] = useState('');
  const [errorMsg, setErrorMsg] = useState('Something went Wrong !');
  const [menu, setMenu] = useState(0);
  const [remotehand, setRemoteHand] = useState(false);
  const [timeDetail, setTimeDetails] = useState({});
  const [query, setQuery] = useState('');
  const [ticketSubTypeOpt, setTicketSubTypeOpt] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filteredSearchCategory, setFilteredSearchCategory] = useState([]);
  const [openCount, setOpenCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);

  useEffect(() => {
    props.setIsLoading(true);
    getTicketList();
  }, [props.data, query]);

  // useEffect(() => {
  //   console.log('shipmentin' + shipmentin);
  //   console.log('faultpopup' + faultpopup);
  //   console.log('incident' + incident);
  //   console.log('siteAccess' + siteAccess);
  //   console.log('shipmentout' + shipmentout);
  // }, [shipmentin, shipmentout, faultpopup, incident, siteAccess]);

  const columns = [
    {
      name: 'id',
      value: t(`account.ticket_table.ticket_id`)
    },
    {
      name: 'subject',
      value: t(`account.ticket_table.ticket_subject`)
    },
    {
      name: 'end_customer',
      value: 'End Customer'
    },
    {
      name: 'tickettype',
      value: t(`account.ticket_table.ticket_category`)
    },
    {
      name: 'operatingsite',
      value: t(`account.ticket_table.site`)
    },
    {
      name: 'priority',
      value: t(`account.ticket_table.severity`)
    },
    {
      name: 'ticket_status',
      value: t(`account.ticket_table.status`)
    },
    {
      name: 'action',
      value: 'Actions'
    }
  ];

  const searchCategory = [
    { name: 'ID', field: 'id' },
    { name: 'Subject', field: 'subject' },
    { name: 'Ticket Type', field: 'tickettype' },
    { name: 'Site', field: 'site' },
    { name: 'Severity', field: 'severity' },
    { name: 'Status', field: 'stage' },
    { name: 'End Customer', field: 'end_customer' }
  ];

  useEffect(() => {
    if (!props.isReseller) {
      setFilteredColumns(columns.filter((el) => el.name != 'end_customer'));
      setFilteredSearchCategory(searchCategory.filter((el) => el.field != 'end_customer'));
    } else {
      setFilteredColumns(columns);
      setFilteredSearchCategory(searchCategory);
    }
  }, [props.isReseller]);

  useEffect(() => {
    console.log(filteredColumns);
  }, [filteredColumns]);
  const handleModalPopupOnClick = async (site, tid, ticket_type_id, subname, ticket_type_name) => {
    props.setIsLoading(true);
    await ticketDetails.getTicketDetail(localStorage.getItem('token'), tid).then((res) => {
      // setSubType(res?.data?.data.tickettype)
      // console.log(localStorage.getItem('token'));
      // console.log(res?.data?.timeDetail);
      setTicketData(res?.data?.data);
      setHelpdesk(res?.data?.helpdesk?.value);
      setTimeDetails(res?.data?.timeDetail);
      if (!res?.data?.data) {
        setErrorMsg(res.data);
        setErrorPopup(true);
      }
    });
    setSiteName(site);
    const ticketTypeId = getTicketTypeId(ticket_type_id);
    if (ticketTypeId === 4) {
      setSiteAccess(true);
    } else if (ticketTypeId === 3) {
      if (ticket_type_name === 'Inbound') {
        setShipmentIn(true);
      } else {
        setShipmentOut(true);
      }
    } else if (ticketTypeId === 1) {
      setIncident(true);
    } else if (ticketTypeId == 5) {
      setRemoteHand(true);
    } else {
      setFaultPopup(true);
    }
  };

  const getTicketTypeId = (ticket_type_id) => {
    const data = ticketSubTypeOpt;
    const ticketType = data.filter(
      (ticketType) => ticketType.oddo_ticket_type_id == ticket_type_id
    );
    return ticketType[0].ticket_type_id;
  };
  //Get Ticket list API call
  const getTicketList = async () => {
    const body = {
      page: pageNumber,
      filters: [
        {
          name: 'tickettype',
          value: 0
        },
        {
          name: 'country',
          value: 0
        },
        {
          name: 'company_id',
          value: props.data.customer_id
        }
      ]
    };
    if (query !== '') {
      body.filters = body.filters.filter((el) => el.name != query.name);
      body.filters.push({
        name: query.name,
        value: query.value
      });
    }
    await ticketService
      .getTicketsListByContactId(localStorage.getItem('token'), props.data.customer_id, body)
      .then((res) => {
        if (res.data.error) {
          props.setErrorMsg(res.data);
          props.setErrorPopup(true);
          props.setIsLoading(false);
        } else {
          setOpenCount(res.data.ticket.open);
          setClosedCount(res.data.ticket.closed);
          setTotal(res.data.ticket.total);
          setTicketList(res.data.ticket.rows);
          setTicketSubTypeOpt(res.data.ticketsubtype);

          props.setIsLoading(false);
        }
      })
      .finally(() => {
        props.setIsLoading(false);
      });
    // .catch((err) => {
    //   props.setErrorPopup(true);
    //   props.setIsLoading(false);
    //   props.setErrorMsg(err);
    // });
  };

  //Get ticket type id and ticket type name
  const getTicketTypeNameAndId = (ticketTypes, ticketSubTypeId) => {
    const result = ticketTypes.filter((data) => data.id == ticketSubTypeId)[0];
    return {
      ticketTypeName: result.ticketType.name,
      ticketTypeId: result.ticket_type_id
    };
  };

  return (
    <div>
      <div className="row">
        <div
          className="pb-2"
          style={{
            fontSize: '1rem',
            fontWeight: 600,
            color: 'red',
            marginTop: '-1.5rem'
          }}
        >
          Total: {openCount + closedCount}, Open: {openCount}, Closed: {closedCount}
        </div>
        <div>
          <SerachBox
            searchCategory={filteredSearchCategory}
            query={query}
            setQuery={setQuery}
            setIsLoading={props.setIsLoading}
            setPageNumber={setPageNumber}
          ></SerachBox>
        </div>
      </div>
      <Table
        columns={filteredColumns}
        data={ticketList}
        setItemsPerPage={setItemsPerPage}
        setPageNumber={setPageNumber}
        total={total}
        pageNumber={pageNumber}
        handleClick={handleModalPopupOnClick}
        setIsLoading={props.setIsLoading}
      ></Table>
      {ticketdata
        ? faultpopup && (
            <FaultPopup
              show={faultpopup}
              setShow={setFaultPopup}
              ticketdata={ticketdata}
              sitename={sitename}
              menu={menu}
              handleClose={() => {
                getTicketList();
              }}
            />
          )
        : errorpopup && (
            <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />
          )}
      {ticketdata
        ? remotehand && (
            <RemoteHandPopup
              show={remotehand}
              setShow={setRemoteHand}
              ticketdata={ticketdata}
              sitename={sitename}
              timeDetails={timeDetail}
              menu={menu}
              handleClose={() => {
                getTicketList();
              }}
            />
          )
        : errorpopup && <ErrorModel show={errorpopup} setShow={setErrorPopup} />}
      {ticketdata
        ? siteAccess && (
            <SiteFaultPopup
              show={siteAccess}
              setShow={setSiteAccess}
              ticketdata={ticketdata}
              sitename={sitename}
              menu={menu}
              handleClose={() => {
                getTicketList();
              }}
            />
          )
        : errorpopup && (
            <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />
          )}
      {ticketdata
        ? shipmentout && (
            <ShipmentOutboundPopup
              show={shipmentout}
              setShow={setShipmentOut}
              ticketdata={ticketdata}
              sitename={sitename}
              menu={menu}
              helpdesk={helpdesk}
              handleClose={() => {
                getTicketList();
              }}
            />
          )
        : errorpopup && (
            <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />
          )}
      {ticketdata
        ? shipmentin && (
            <ShipmentInboundPopup
              show={shipmentin}
              setShow={setShipmentIn}
              ticketdata={ticketdata}
              sitename={sitename}
              menu={menu}
              helpdesk={helpdesk}
              handleClose={() => {
                getTicketList();
              }}
            />
          )
        : errorpopup && (
            <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />
          )}
      {ticketdata
        ? incident && (
            <IncidentManagementPopup
              show={incident}
              setShow={setIncident}
              ticketdata={ticketdata}
              sitename={sitename}
              menu={menu}
              handleClose={() => {
                getTicketList();
              }}
            />
          )
        : errorpopup && (
            <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />
          )}
      {errorpopup ? (
        <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />
      ) : (
        ''
      )}
    </div>
  );
};

export default TicketsTab;
