import React from "react";
import { useTranslation } from "react-i18next";
const MenuTab = ({ setMenuTab, menuTab, getFloorData }) => {
  const { t } = useTranslation();
  //Status
  //Inventory = 1;
  //Capacity = 2;
  //Cabling = 3;

  const onMenuChange = (menu) => {
    switch (menu) {
      case 1:
        getFloorData();
        setMenuTab({
          inventory: true,
          capacity: false,
          cabling: false,
          network: false,
          techDocs: false,
        });
        break;
      case 2:
        // getFloorData();
        setMenuTab({
          inventory: false,
          crossConnect: true,
          capacity: false,
          cabling: false,
          network: false,
          techDocs: false,
        });
        break;
      case 3:
        setMenuTab({
          inventory: false,
          capacity: true,
          cabling: false,
          network: false,
          techDocs: false,
        });
        break;
      case 4:
        setMenuTab({
          inventory: false,
          capacity: false,
          cabling: true,
          network: false,
          techDocs: false,
        });
        break;
      case 5:
        setMenuTab({
          inventory: false,
          capacity: false,
          cabling: false,
          network: true,
          techDocs: false,
        });
        break;
      case 6:
        setMenuTab({
          inventory: false,
          capacity: false,
          cabling: false,
          network: false,
          techDocs: true,
        });
        break;
      default:
        setMenuTab({
          inventory: true,
          capacity: false,
          cabling: false,
          network: false,
          techDocs: false,
        });
    }
  };

  return (
    <div className="profile-tab menu_tab_btn">
      <div className="custom-tab-1">
        <ul className="nav nav-tabs">
          <li
            className="nav-item"
            onClick={() => onMenuChange(1)}
            style={{ cursor: "pointer" }}
          >
            <button
              className={
                menuTab.inventory ? "btn btn-secondary" : "btn btn-light"
              }
              type="button"
            >
              {t("dashboard.inventory")}
            </button>
          </li>
          <li
            className="nav-item"
            onClick={() => onMenuChange(2)}
            style={{ cursor: "pointer" }}
          >
            <button
              className={
                menuTab.crossConnect ? "btn btn-secondary" : "btn btn-light"
              }
              type="button"
            >
              {t("dashboard.cross_connect")}
            </button>
          </li>
          {/* <li className="nav-item" onClick={() => onMenuChange(3)} style={{cursor:"pointer"}}> 
						<button 
						className={menuTab.capacity?"btn btn-secondary":"btn btn-light"}  
						type="button"
						
						> 
							{t('dashboard.capacity')}
						</button>
					</li> */}
          <li
            className="nav-item"
            onClick={() => onMenuChange(4)}
            style={{ cursor: "pointer" }}
          >
            <button
              className={
                menuTab.cabling ? "btn btn-secondary" : "btn btn-light"
              }
              type="button"
            >
              {t("dashboard.cabling_and_patch")}
            </button>
          </li>
          <li
            className="nav-item"
            onClick={() => onMenuChange(5)}
            style={{ cursor: "pointer" }}
          >
            <button
              className={
                menuTab.network ? "btn btn-secondary" : "btn btn-light"
              }
              type="button"
            >
              {t("dashboard.network_svcs")}
            </button>
          </li>
          <li
            className="nav-item"
            onClick={() => onMenuChange(6)}
            style={{ cursor: "pointer" }}
          >
            <button
              className={
                menuTab.techDocs ? "btn btn-secondary" : "btn btn-light"
              }
              type="button"
            >
              {t("dashboard.tech_docs")}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuTab;
