import { numberFormat, numberFormatToDecimalPlace } from "common/helpers";
import { isNumber } from "lodash";
import React, { useEffect } from "react";

const Line2 = (props) => {
  const {
    inServiceText,
    availableText,
    inServicePercent,
    availablePercent,
    reserved,
    rofr,
    blocked,
    complete,
    reservedPer,
    rofrPer,
    blockedPer,
    completePer,
  } = props;

  return (
    <div style={{ width: "100%" }}>
      <div className="graph-line">
        <span>
          {numberFormatToDecimalPlace(inServiceText)}/
          {numberFormatToDecimalPlace(availableText)}/
          {numberFormatToDecimalPlace(reserved)}/{numberFormat(rofr)}/
          {numberFormatToDecimalPlace(blocked)}
          {complete != undefined
            ? "/" + numberFormatToDecimalPlace(complete)
            : ""}
        </span>
      </div>

      <div className="graph-line">
        {isNumber(inServicePercent) && (
          <div
            style={{
              width: `${inServicePercent}%`,
              backgroundColor: "#FE8600",
              height: "0.3rem",
              float: "center",
            }}
          ></div>
        )}

        {isNumber(availablePercent) && (
          <div
            style={{
              width: `${availablePercent}%`,
              backgroundColor: "#3FEB7B",
              height: "0.3rem",
              float: "center",
            }}
          ></div>
        )}
        <div
          style={{
            width: `${reservedPer}%`,
            backgroundColor: "#1b70c0",
            height: "0.3rem",
            float: "center",
          }}
        ></div>

        {isNumber(rofrPer) && (
          <div
            style={{
              width: `${rofrPer}%`,
              backgroundColor: "#595959",
              height: "0.3rem",
              float: "center",
            }}
          ></div>
        )}
        {isNumber(blockedPer) && (
          <div
            style={{
              width: `${blockedPer}%`,
              backgroundColor: "#000000",
              height: "0.3rem",
              float: "center",
            }}
          ></div>
        )}
        {isNumber(completePer) && (
          <div
            style={{
              width: `${completePer}%`,
              backgroundColor: "#c2adc2",
              height: "0.3rem",
              float: "center",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Line2;
